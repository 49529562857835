import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import { readUtmParamsFromCookie } from 'utils/utm'
import { getCurrentI18nInfo } from 'utils/i18n'
import { initAppboySdk, createAppboyUser } from 'sdks/appboy'

import { isNewUser } from '../utils'
import { SideEffect } from '../types'

const afterAccountCreatedAppboyEffect: SideEffect = {
  name: 'After account creation: Appboy',
  isSilent: true,
  condition: () => isNewUser(),
  execute: async () => {
    const { getAttribute } = UserAccountStore
    const utmData = readUtmParamsFromCookie()
    const { language } = getCurrentI18nInfo()
    await initAppboySdk()
    await createAppboyUser({
      userId: getAttribute('id'),
      country: getAttribute('country'),
      email: getAttribute('email'),
      name: getAttribute('name'),
      gender: getAttribute('gender'),
      language,
      hasNewsletterAccepted: !!getAttribute('newsletter_sign_up'),
      usersrc: utmData.utm_source || 'web',
      usercmp: utmData.utm_campaign || 'undefined',
    })
  },
}

export default afterAccountCreatedAppboyEffect
