import { ReactElement, useEffect, useRef } from 'react'
import * as Sentry from '@sentry/gatsby'

import {
  useUserAccount,
  AuthenticationState,
} from 'gatsby-plugin-8fit-user-account'

/**
 * The sole purpose of this component is to keep the user scope in Sentry in
 * sync with the authentication / logout of the user.
 */
const SentryUserSession = ({ children }: { children: ReactElement }) => {
  const { authenticationState, getAttribute } = useUserAccount()
  const idRef = useRef<{ id?: string }>({})

  useEffect(() => {
    if (authenticationState === AuthenticationState.Authenticated) {
      const id = getAttribute('id')
      idRef.current.id = id
      Sentry.addBreadcrumb({
        category: 'Auth',
        message: `Logged in as user ${id}`,
        level: Sentry.Severity.Info,
      })
      Sentry.configureScope((scope) => {
        scope.setUser({
          id,
          email: getAttribute('email'),
          username: getAttribute('name'),
          ip_address: getAttribute('ip'),
        })
      })
      Sentry.setTags({
        is_pro: getAttribute('is_pro'),
        country: getAttribute('country'),
        currency: getAttribute('currency'),
      })
    } else if (authenticationState === AuthenticationState.None) {
      Sentry.addBreadcrumb({
        category: 'Auth',
        message: `Logged out as user ${idRef.current.id}`,
        level: Sentry.Severity.Info,
      })
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [authenticationState, getAttribute])

  return children
}

export default SentryUserSession
