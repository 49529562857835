import React, { FunctionComponent } from 'react'

import WebsiteHeader from 'components/websiteHeader'
import Footer from 'components/footer'

import Layout, { LayoutProps } from '.'
import { root, contentOrigin, contentContainer } from './website.module.scss'

export interface WebsiteLayoutProps extends LayoutProps {
  showHeader?: boolean
  showFooter?: boolean
}

const WebsiteLayout: FunctionComponent<WebsiteLayoutProps> = ({
  children,
  showHeader = true,
  showFooter = true,
  ...props
}) => (
  <Layout className={root} {...props}>
    <div className={contentOrigin}>
      {showHeader && <WebsiteHeader hrefLang={props.hrefLang} />}
      {children}
      {showFooter && <Footer />}
    </div>
  </Layout>
)

export default WebsiteLayout

export const WebsiteLayoutContentContainer: FunctionComponent = ({
  children,
}) => <div className={contentContainer}>{children}</div>
