import React from 'react'

import WorkoutCard from 'components/workout-card'

import ActivityCardsCarousel from '.'
import { workouts as workoutsStyle } from './recipes-workouts.module.scss'

type WorkoutList = Pick<
  Contentful.WorkoutClass,
  'title' | 'description' | 'durationMins' | 'featureImage' | 'fields'
>[]

interface Props {
  title: string
  description: string
  workouts: WorkoutList
}

const WorkoutCardsCarousel = ({ title, description, workouts }: Props) => {
  return (
    <ActivityCardsCarousel
      title={title}
      titleId="workouts"
      description={description}
      className={workoutsStyle}
    >
      {workouts.map(
        ({ title, description, durationMins, featureImage, fields }) => (
          <WorkoutCard
            key={fields.path}
            title={title}
            description={description}
            durationMins={durationMins}
            image={(featureImage as EF.FixedImageNode).fields.asset.fixed}
            linkTo={fields.path}
          />
        )
      )}
    </ActivityCardsCarousel>
  )
}

export default WorkoutCardsCarousel
