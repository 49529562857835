import React, { ReactNode } from 'react'

import { classNames } from 'utils/dom'

import { root } from './index.module.scss'

const TagUniversal = ({
  text,
  className,
}: {
  text: ReactNode
  className?: string
}) => {
  return <span className={classNames([root, className])}>{text}</span>
}

export default TagUniversal
