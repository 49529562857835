import React from 'react'
import Link from 'gatsby-link'
import GatsbyImage from 'gatsby-image'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Container, Row, Col } from 'components/grid'

import { CategoryNavigationProps, CategorySectionProps } from '../types'

import {
  root,
  title,
  categoryNavigationList,
  categorySection,
  categorySectionRoot,
  categoryIcon,
  contentWrapper,
  categoryLink,
  categoryName,
  subcategoryList,
  subcategoryItem,
  subcategoryLink,
} from './CategoryNavigationDesktop.module.scss'

const CategorySection = ({
  name,
  path,
  icon,
  subCategories,
}: CategorySectionProps) => (
  <div className={categorySectionRoot}>
    <div className={categoryIcon}>{icon && <GatsbyImage {...icon} />}</div>
    <div className={contentWrapper}>
      <DocumentOutlineSection title={name}>
        <Link to={path} className={categoryLink}>
          <H className={categoryName} />
        </Link>
        <ul className={subcategoryList}>
          {subCategories.map(({ name, path }) => (
            <li key={path} className={subcategoryItem}>
              <Link to={path} className={subcategoryLink}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </DocumentOutlineSection>
    </div>
  </div>
)

const CategoryNavigationDesktop = ({
  categoryList,
}: CategoryNavigationProps) => (
  <nav className={root}>
    <DocumentOutlineSection title={i18n._(t`Article categories`)}>
      <H className={title} />
      <Container fluid>
        <Row tagName="ul" className={categoryNavigationList}>
          {categoryList.map(
            ({ name, path, categoryIconDesktop, subCategories }) => (
              <Col tagName="li" key={path} className={categorySection}>
                <CategorySection
                  name={name}
                  path={path}
                  icon={categoryIconDesktop}
                  subCategories={subCategories}
                />
              </Col>
            )
          )}
        </Row>
      </Container>
    </DocumentOutlineSection>
  </nav>
)

export default CategoryNavigationDesktop
