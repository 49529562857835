import { useEffect, useCallback } from 'react'
import { navigate } from 'gatsby'

import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import { wait } from 'utils/async'
import { useGetParams } from 'hooks'

/**
 * This page implements the Become User feature from the 8fit backend admin
 * panel. You need a token parameter for this to work.
 */
const BecomeUserPage = () => {
  const { token } = useGetParams()
  const { becomeUser } = useUserAccount()

  const runBecomeUser = useCallback(async () => {
    if (!token) return
    try {
      // There might be network requests still going on, fetching the user
      // account data. So, wait for a ¾ sec.
      await wait(750)
      await becomeUser(token)
      navigate('/profile/')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      // eslint-disable-next-line no-alert
      alert('An error occurred')
    }
  }, [token, becomeUser])

  useEffect(() => {
    runBecomeUser()
  }, [token, runBecomeUser])

  return null
}

export default BecomeUserPage
