import { loadScript } from 'utils/dom'
import { getCurrentI18nInfo } from 'utils/i18n'
import { areUrlsAllowed } from 'utils/onetrust'

const getFbSdk = () => (isFbSdkPresent() ? window.FB : null)

export const getFbSdkUrl = () => {
  const { language = 'en', territory = 'US' } = getCurrentI18nInfo()
  return `https://connect.facebook.net/${language}_${territory}/sdk.js`
}

const loadFbSdk = () =>
  new Promise((resolve, reject) => {
    if (typeof window !== 'object')
      return new Error('Can only load facebook SDK on the client side')
    const sdkReady = () => resolve(window.FB)
    if (window.FB) return sdkReady()
    window.fbAsyncInit = sdkReady
    const fbSdkUrl = getFbSdkUrl()
    loadScript(fbSdkUrl).catch(reject)
  })

export const isFbSdkPresent = () => typeof window === 'object' && window.FB

export const initFbSdk = async (appId) => {
  const isPresent = isFbSdkPresent()
  const FB = await loadFbSdk()
  if (!isPresent) {
    FB.init({
      appId: appId,
      version: 'v3.3',
      xfbml: false,
      cookie: true,
      status: true,
    })
  }
  return FB
}

export const canInitFbSdk = () => {
  const fbSdkUrl = getFbSdkUrl()
  return !!areUrlsAllowed([fbSdkUrl])
}

export const retrieveFbAuthResponse = () => {
  if (!isFbSdkPresent()) throw new Error('Facebook SDK not initialized yet')
  const FB = getFbSdk()
  const authResponse = FB.getAuthResponse()

  if (authResponse) return authResponse

  return new Promise((resolve, reject) => {
    FB.login(
      (response) => {
        if (response.authResponse) resolve(response.authResponse)
        else reject(response)
      },
      { scope: 'email, public_profile' }
    )
  })
}
