import React, { FunctionComponent } from 'react'
import { Trans, t } from '@lingui/macro'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { H } from 'react-document-section'

import { i18n } from 'utils/i18n'
import { Visible, Container, Row, Col, breakpoints } from 'components/grid'
import ArticlePreviewPolaroid from 'components/articlePreview/polaroid'
import WebsiteLayout from 'components/layout/website'
import { useMatchMedia } from 'hooks'
import Carousel from 'components/carousel'
import { SecondaryButtonLink } from 'components/Buttons'

import {
  background,
  container,
  carouselWrapper,
  title,
  description,
  pageTitleImage,
  pageTitle,
  carousel,
  carouselItem,
  buttonContainer,
  articleButton,
} from './index.module.scss'

interface BreadcrumbItem {
  displayName: string
  fields: { path: string }
}

interface Article {
  node: {
    title: string
    featureImage: EF.FixedImageNode
    fields: {
      path: string
      breadcrumb: BreadcrumbItem[]
    }
  }
}

interface Props {
  data: {
    notFound: EF.LocalFluidImage
    latestArticles: {
      edges: Article[]
    }
  }
  pageContext: { locale: string }
}

const renderLatestArticles = (articles: Article[]) =>
  articles.map(
    ({
      node: {
        fields: { path: articlePath, breadcrumb },
        featureImage,
        title,
      },
    }) => {
      const {
        fields: { path: categoryPath },
        displayName,
      } = breadcrumb.slice(1, -1).pop() || {
        displayName: '',
        fields: { path: '' },
      }

      return (
        <ArticlePreviewPolaroid
          className={carouselItem}
          key={title}
          title={title}
          image={featureImage}
          path={articlePath}
          category={displayName}
          parentPath={categoryPath}
        />
      )
    }
  )

const NotFoundPage: FunctionComponent<Props> = ({
  data: { latestArticles, notFound },
}: Props) => {
  const { matches: isLarge } = useMatchMedia(`(min-width: ${breakpoints.lg}px)`)
  return (
    <WebsiteLayout
      title={i18n._(t`Page Not Found`)}
      pageTitle={i18n._(t`Page Not Found`)}
      hrefLang={[
        { node_locale: 'de', fields: { path: '/de/404/' } },
        { node_locale: 'fr', fields: { path: '/fr/404/' } },
        { node_locale: 'es', fields: { path: '/es/404/' } },
        { node_locale: 'it', fields: { path: '/it/404/' } },
        { node_locale: 'pt-BR', fields: { path: '/pt-br/404/' } },
      ]}
      defaultLang={{ fields: { path: '/404/' } }}
    >
      <div className={background}>
        <Container>
          <Row>
            <Col>
              <div className={container}>
                <div className={pageTitleImage}>
                  <H className={pageTitle} />
                  <Image fluid={notFound.childImageSharp.fluid} alt="404" />
                </div>
                <p className={title}>
                  <Trans>
                    Whoops, this page can’t be found. Fear not, we’ll sort it
                    out
                  </Trans>
                </p>
                <p className={description}>
                  <Trans>
                    In the meantime, enjoy one of our latest articles.
                  </Trans>
                </p>
                <div className={carouselWrapper}>
                  <Carousel
                    className={carousel}
                    gap={isLarge ? 80 : 20}
                    defaultIndex={isLarge ? 1 : 0}
                    keyPrefix="related-article"
                    showDots
                  >
                    {renderLatestArticles(latestArticles.edges)}
                  </Carousel>
                  <Visible lg>
                    <div className={buttonContainer}>
                      <SecondaryButtonLink
                        path={i18n._(t`/articles/`)}
                        className={articleButton}
                        text={i18n._(t`View all articles`)}
                      />
                    </div>
                    <p>{i18n._(t`/eau/`)}</p>
                  </Visible>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </WebsiteLayout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFound($locale: String!) {
    notFound: file(name: { eq: "404" }) {
      childImageSharp {
        fluid(maxWidth: 367) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    latestArticles: allContentfulArticlePage(
      filter: {
        node_locale: { eq: $locale }
        slug: { ne: null }
        content: { raw: { ne: null } }
      }
      sort: { fields: originalPublishDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          fields {
            path
            breadcrumb {
              ... on ContentfulCategory {
                displayName
                fields {
                  path
                }
              }
            }
          }
          title
          description {
            description
          }
          featureImage {
            fields {
              asset {
                fixed(width: 280, height: 172) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
