import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'
import * as Sentry from '@sentry/gatsby'

import {
  UserAccountStore,
  useUserAccount,
  AuthenticationState,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import { useDidMount } from 'hooks'
import { signInWithGoogle, initFirebase, getFirebase } from 'sdks/google'
import {
  retrieveFbAuthResponse,
  initFbSdk,
  isFbSdkPresent,
} from 'sdks/facebook'
import Layout from 'components/layout'
import ToastMessage, { ToastStatus } from 'components/toast-message'

import {
  root,
  container,
  loginContainer,
  title as titleStyle,
  toastMessage,
} from './index.module.scss'
import Background from './Background'
import SocialLogin, { optionGoogle, optionFacebook } from './SocialLogin'
import NavBar from './NavBar'
import LoginForm from './LoginForm'

interface LoginPageProps {
  data: {
    site: EF.Site
  }
}

const hrefLang = [
  { node_locale: 'en-US', fields: { path: '/login/' } },
  { node_locale: 'de', fields: { path: '/de/login/' } },
  { node_locale: 'fr', fields: { path: '/fr/login/' } },
  { node_locale: 'es', fields: { path: '/es/login/' } },
  { node_locale: 'it', fields: { path: '/it/login/' } },
  { node_locale: 'pt-BR', fields: { path: '/pt-br/login/' } },
]

const LoginPage = ({
  data: {
    site: {
      siteMetadata: { facebookAppId, firebase: firebaseId },
    },
  },
}: LoginPageProps) => {
  const [SDKLoaded, setSDKLoaded] = useState<{ [key: string]: boolean }>({
    Facebook: false,
    Google: false,
  })
  const [isSubmitRequested, setIsSubmitRequested] = useState(false)
  const [isCurrentlyInOAuthPopup, setIsCurrentlyInOAuthPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { authenticationState } = useUserAccount()
  const pageTitle = i18n._(t('user.profile.login')`Log in`)

  useDidMount(() => {
    const loaded = { ...SDKLoaded }
    initFirebase(firebaseId)
      .catch(() => {
        // Silently fail
      })
      .finally(() => {
        loaded[optionGoogle] = !!getFirebase()
        setSDKLoaded({ ...loaded })
      })
    initFbSdk(facebookAppId)
      .catch(() => {
        // Silently fail
      })
      .finally(() => {
        loaded[optionFacebook] = !!isFbSdkPresent()
        setSDKLoaded({ ...loaded })
      })
  })

  useEffect(() => {
    if (authenticationState === AuthenticationState.Authenticated) {
      navigate(i18n._(t`/profile/`))
    }
  }, [authenticationState])

  const onPressSocialLogin = async (type: string) => {
    if (isCurrentlyInOAuthPopup) return
    setIsCurrentlyInOAuthPopup(true)

    try {
      if (type === optionGoogle) {
        const {
          credential: { idToken },
        } = await signInWithGoogle()
        await UserAccountStore.loginWithGoogle(idToken)
      } else if (type === optionFacebook) {
        const { accessToken } = await retrieveFbAuthResponse()
        await UserAccountStore.loginWithFacebook(accessToken)
      }
      await UserAccountStore.fetchAccount()
    } catch (err) {
      setIsCurrentlyInOAuthPopup(false)
      setErrorMessage(i18n._(t`wsf.error.oauth_failed`))

      Sentry.addBreadcrumb({
        category: 'Login',
        data: err,
        level: Sentry.Severity.Error,
      })
    }
  }

  const onPressEmailLogin = async (email: string, password: string) => {
    setIsSubmitRequested(true)
    try {
      await UserAccountStore.loginWithEmail(email, password)
      await UserAccountStore.fetchAccount()
    } catch (err) {
      setIsSubmitRequested(false)

      if (err?.status === 429) {
        setErrorMessage(
          i18n._(
            t(
              'login.dialog_error.attempts_limit'
            )`You have reached the maximum login attempts. Please try again in 24 hours.`
          )
        )
      } else {
        setErrorMessage(
          i18n._(t('login.dialog_error.email')`Incorrect email or password.`)
        )
      }

      Sentry.addBreadcrumb({
        category: 'Login',
        data: err,
        level: Sentry.Severity.Error,
      })
    }
  }

  return (
    <Layout
      className={root}
      title={pageTitle}
      pageTitle={`${pageTitle} | 8fit`}
      hrefLang={hrefLang}
      defaultLang={{ fields: { path: '/login/' } }}
    >
      <NavBar hrefLang={hrefLang} />
      <DocumentOutlineSection title={pageTitle}>
        <div className={container}>
          <Background />
          <main className={loginContainer}>
            <H className={titleStyle} />
            <LoginForm
              disabled={isSubmitRequested || isCurrentlyInOAuthPopup}
              onPressEmailLogin={onPressEmailLogin}
            />
            <SocialLogin
              SDKLoaded={SDKLoaded}
              onSelect={onPressSocialLogin}
              disabled={isSubmitRequested}
            />
          </main>
        </div>
      </DocumentOutlineSection>
      {errorMessage && (
        <ToastMessage
          className={toastMessage}
          message={errorMessage}
          onPressClose={() => setErrorMessage('')}
          status={ToastStatus.ERROR}
        />
      )}
    </Layout>
  )
}

export default LoginPage

export const pageQuery = graphql`
  query LoginPage {
    site {
      siteMetadata {
        facebookAppId
        firebase {
          apiKey
          authDomain
          databaseURL
          projectId
          storageBucket
          messagingSenderId
        }
      }
    }
  }
`
