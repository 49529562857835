import { serialize } from 'utils/url'

interface FacebookShareParams {
  appId: string
  url?: string
  link?: string
  picture?: string
  name?: string
  redirectUri?: string
}

interface TwitterShareParams {
  via: string
  text: string
}

interface PinterestShareParams {
  media: string
  description: string
  url: string
}

/**
 * Generate a url for facebook sharing
 * Find documentation here: https://developers.facebook.com/docs/sharing/reference/feed-dialog/#params
 */
export const facebookShareUrl = ({
  appId,
  url,
  link,
  picture,
  name,
  redirectUri,
}: FacebookShareParams) =>
  `https://www.facebook.com/dialog/feed${serialize({
    app_id: appId,
    url,
    link,
    picture,
    name,
    redirect_uri: redirectUri,
  })}`

/**
 * Generate a url for twitter sharing
 * Find documentation here: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
 */
export const twitterShareUrl = ({ via, text }: TwitterShareParams) =>
  `https://twitter.com/intent/tweet${serialize({
    via,
    text,
  })}`

/**
 * Generate a url for pinterest sharing
 * Seems to use an undocumented or deprecated API
 */
export const pinterestShareUrl = ({
  media,
  description,
  url,
}: PinterestShareParams) =>
  `https://pinterest.com/pin/create/link/${serialize({
    media,
    description,
    url,
  })}`
