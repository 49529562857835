import React from 'react'
import { Trans } from '@lingui/macro'

import {
  container,
  divider,
  dividerLine,
  dividerText,
  choiceList,
  choiceItem,
  choice,
} from './SocialLogin.module.scss'

export const optionGoogle = 'Google'
export const optionFacebook = 'Facebook'
const options = [optionFacebook, optionGoogle]

const SocialLogin = ({
  onSelect,
  SDKLoaded,
  disabled,
}: {
  onSelect: (type: string) => Promise<void>
  SDKLoaded: { [key: string]: boolean }
  disabled?: boolean
}) => {
  if (!SDKLoaded[optionGoogle] && !SDKLoaded[optionFacebook]) return null

  return (
    <section data-disabled={disabled} className={container}>
      <div className={divider}>
        <div className={dividerLine} />
        <div className={dividerText}>
          <Trans id="user.profile.login.with">or log in with</Trans>
        </div>
      </div>
      <ul className={choiceList}>
        {options.map((type) => {
          if (!SDKLoaded[type]) return null
          return (
            <li key={type} className={choiceItem}>
              <button
                type="button"
                onClick={() => onSelect(type)}
                className={choice}
                data-value={type}
                aria-label={type}
              />
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default SocialLogin
