import React, { FunctionComponent, ReactNode } from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { H } from 'react-document-section'

import { classNames } from 'utils/dom'
import WebsiteLayout from 'components/layout/website'
import QuoteText from 'components/quote-text'
import ScrollIndicator from 'components/scroll-indicator'
import { Row, Col, Container } from 'components/grid'
import Carousel from 'components/carousel'

import {
  root,
  pageSection,
  pageSectionGridContainer,
  pageSectionBackgroundContainer,
  headerSection,
  headerBackgroundContainer,
  headerContentContainer,
  headerBackground,
  pageHeader,
  quoteSection,
  quote,
  carouselImage,
} from './careers.module.scss'

type CarouselImage = EF.LocalFluidImage & { name: string; id: string }

interface PageQueryResult {
  headerBackground: EF.LocalFluidImage
  carouselImages: {
    edges: { node: CarouselImage }[]
  }
}

export const pageQuery = graphql`
  {
    headerBackground: file(relativePath: { eq: "careers/header-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, maxHeight: 600, cropFocus: NORTHWEST) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    carouselImages: allFile(
      filter: { relativePath: { regex: "/careers/carousel/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const path = '/careers/'

const Section: FunctionComponent<{
  className: string
  renderBackground?(): ReactNode
}> = ({ children, renderBackground = () => null, className = '' }) => (
  <section className={classNames([pageSection, className])}>
    <Container className={pageSectionGridContainer}>
      <Row>
        <Col>{children}</Col>
      </Row>
    </Container>
    <div className={pageSectionBackgroundContainer}>{renderBackground()}</div>
  </section>
)

const CareersPage: FunctionComponent<{ data: PageQueryResult }> = ({
  data,
}) => {
  return (
    <WebsiteLayout
      hrefLang={[]}
      defaultLang={{ fields: { path } }}
      title="Careers"
    >
      <main className={root}>
        <section className={classNames([pageSection, headerSection])}>
          <div className={headerBackgroundContainer}>
            <Image
              className={headerBackground}
              fluid={data.headerBackground.childImageSharp.fluid}
              alt=""
            />
          </div>
          <div className={headerContentContainer}>
            <H data-testid="pageTitle" className={pageHeader} />
          </div>
          <ScrollIndicator isShifted />
        </section>
        <Section className={quoteSection}>
          <blockquote className={quote}>
            <QuoteText>
              If you are interested in joining us, please send your CV to
              recruiting@8fit.com.
            </QuoteText>
          </blockquote>
        </Section>
        <Carousel keyPrefix="press" hanger="0%" anchor="0%" showButtons>
          {data.carouselImages.edges.map(({ node }) => (
            <Image
              fluid={node.childImageSharp.fluid}
              key={node.id}
              className={carouselImage}
              alt=""
            />
          ))}
        </Carousel>
      </main>
    </WebsiteLayout>
  )
}

export default CareersPage
