import { noop } from 'lodash/fp'

const getStorageFns = (storageName: 'localStorage' | 'sessionStorage') => {
  const getImpl = <T>(fnName: string): T =>
    typeof window === 'object' &&
    typeof window[storageName] === 'object' &&
    typeof window[storageName][fnName] === 'function'
      ? window[storageName][fnName].bind(window[storageName])
      : noop

  return {
    getItem: getImpl<typeof window.localStorage.getItem>('getItem'),
    setItem: getImpl<typeof window.localStorage.setItem>('setItem'),
    removeItem: getImpl<typeof window.localStorage.removeItem>('removeItem'),
    clear: getImpl<typeof window.localStorage.clear>('clear'),
  }
}

export const localStorage = getStorageFns('localStorage')

export const sessionStorage = getStorageFns('sessionStorage')
