import React, { FunctionComponent } from 'react'

import WebsiteHeader from 'components/websiteHeader'
import Footer from 'components/footer'

import Layout, { LayoutProps } from '.'
import { root, contentOrigin } from './seamless-website.module.scss'

const SeamlessWebsiteLayout: FunctionComponent<LayoutProps> = ({
  children,
  ...props
}) => {
  return (
    <Layout className={root} {...props}>
      <div className={contentOrigin}>
        <WebsiteHeader hrefLang={props.hrefLang} />
        {children}
        <Footer />
      </div>
    </Layout>
  )
}

export default SeamlessWebsiteLayout
