import { loadScript } from 'utils/dom'
import { getCurrentI18nInfo } from 'utils/i18n'

let firebase

export const getFirebase = () => firebase

const loadFirebaseAuthSdk = () =>
  new Promise((resolve, reject) => {
    if (typeof window !== 'object')
      return reject(new Error('Can only load Firebase SDK on the client side'))
    if (window.firebase) return resolve(window.firebase)
    Promise.resolve()
      .then(() =>
        loadScript('https://www.gstatic.com/firebasejs/5.11.1/firebase-app.js')
      )
      .then(() =>
        loadScript('https://www.gstatic.com/firebasejs/5.11.1/firebase-auth.js')
      )
      .then(() => resolve(window.firebase))
      .catch(reject)
  })

export const initFirebase = async (config = {}) => {
  if (firebase) return firebase
  firebase = await loadFirebaseAuthSdk()
  firebase.initializeApp(config)
  const { locale } = getCurrentI18nInfo()
  firebase.auth().languageCode = locale.replace('-', '_')
}

export const signInWithGoogle = async () => {
  if (!firebase) throw new Error("Firebase wasn't initialized")
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}
