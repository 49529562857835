import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { badge } from './index.module.scss'

const query = graphql`
  query AppStoreBadgeQuery {
    file(name: { eq: "app-store-2017" }) {
      childImageSharp {
        fluid(
          maxWidth: 133
          maxHeight: 72
          sizes: "(min-width: 304px) 122px, (min-width: 768px) 133px, calc(50vw - 60px)"
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
          srcWebp
          srcSetWebp
        }
      }
    }
  }
`

const AppStoreBadge = () => {
  const { file } = useStaticQuery<{ file: EF.LocalFluidImage }>(query)
  return (
    <div className={badge}>
      <GatsbyImage fluid={file.childImageSharp.fluid} />
    </div>
  )
}

export default AppStoreBadge
