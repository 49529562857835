import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { H } from 'react-document-section'

import WebsiteLayout, {
  WebsiteLayoutContentContainer,
} from 'components/layout/website'
import RichTextField from 'components/rich-text-field'
import { useUtmParams } from 'hooks'
import { Container, Row, Col } from 'components/grid'

import { title, rtf } from './index.module.scss'

export interface ContentfulLegalPage {
  title: string
  content: EF.RTFRawQueryResponse
  fields: {
    hrefLang: EF.HrefLangNode[]
    defaultLang: EF.DefaultLangNode
  }
}

export interface LegalPageQueryResult {
  contentfulLegalPage: ContentfulLegalPage
}

interface LegalPageProps {
  data: LegalPageQueryResult
  pageContext: {
    hideHeader?: boolean
    hideFooter?: boolean
  }
}

const LegalPage: FunctionComponent<LegalPageProps> = ({
  data: { contentfulLegalPage: page },
  pageContext: { hideHeader, hideFooter },
}) => {
  const { utm_medium: platform } = useUtmParams()

  const isInApp = platform === 'in-app'

  return (
    <WebsiteLayout
      hrefLang={page.fields.hrefLang}
      defaultLang={page.fields.defaultLang}
      title={page.title}
      showHeader={!(hideHeader || isInApp)}
      showFooter={!(hideFooter || isInApp)}
    >
      <WebsiteLayoutContentContainer>
        <Container>
          <Row>
            <Col>
              <H className={title} />
            </Col>
          </Row>
          <Row>
            <Col className={rtf}>
              <RichTextField data={page.content} />
            </Col>
          </Row>
        </Container>
      </WebsiteLayoutContentContainer>
    </WebsiteLayout>
  )
}

export default LegalPage

export const pageQuery = graphql`
  query LegalPage($id: String!) {
    contentfulLegalPage(id: { eq: $id }) {
      title
      content {
        raw
      }
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
    }
  }
`
