import React from 'react'

import ResponsiveSwitch from 'components/utils/responsiveUtils/responsive-switch'

import { CategoryNavigationProps } from './types'
import CategoryNavigationMobile from './mobile'
import CategoryNavigationDesktop from './desktop'

const CategoryNavigation = (props: CategoryNavigationProps) => (
  <ResponsiveSwitch
    query="(min-width: 1200px)"
    renderFalse={<CategoryNavigationMobile {...props} />}
    renderTrue={<CategoryNavigationDesktop {...props} />}
  />
)

export default CategoryNavigation
