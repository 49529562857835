import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import { getCurrentI18nInfo } from 'utils/i18n'

import { SideEffect } from '../types'

const setLocaleEffect: SideEffect = {
  name: 'Set Locale',
  isSilent: true,
  condition: () => UserAccountStore.getAttribute('locale') === undefined,
  execute: async () => {
    const { backendLocale } = getCurrentI18nInfo()
    UserAccountStore.setAttribute('locale', backendLocale)
    UserAccountStore.setAttribute('backendLocale', backendLocale)
  },
}

export default setLocaleEffect
