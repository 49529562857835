import React from 'react'

import { getCategoryCopy } from 'components/recipe-card/utils'

import TagUniversal from '../tag-universal'

const RecipeCategoryLabel = ({
  category,
  className,
}: {
  category: Contentful.RecipeCategory
  className?: string
}) => {
  return <TagUniversal className={className} text={getCategoryCopy(category)} />
}

export default RecipeCategoryLabel
