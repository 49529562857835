import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'

import { SideEffect } from '../types'

const updateAccountInfoEffect: SideEffect = {
  name: 'Update user account info',
  isSilent: true,
  condition: () => {
    const { getAttribute } = UserAccountStore
    return !!getAttribute('onboarded') && !!getAttribute('id')
  },
  execute: async () => {
    const { getAttribute } = UserAccountStore
    if (getAttribute('isNewUser')) {
      await UserAccountStore.updateAccount()
    } else {
      await UserAccountStore.fetchAccount()
    }
  },
}

export default updateAccountInfoEffect
