// extracted by mini-css-extract-plugin
export var headlineSection = "GIuik";
export var headlineText = "FBrgi";
export var root = "ylCx+";
export var sectionText = "ylgDi";
export var sectionTitle = "Q9Vvl";
export var title = "f7w84";
export var userJourneySection = "JbWpD";
export var uspSection = "hnD+6";
export var valuesSection = "ZtdDO";
export var whoWeAreSection = "_9ng5K";