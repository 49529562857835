import React, { useState, useCallback, FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import { scrollWindow } from 'utils/dom'
import { Col, Container, Row } from 'components/grid'
import SeamlessWebsiteLayoutAndMeta from 'components/layout/layout-meta'
import UserJourneySection from 'components/user-journey/cow'
import DownloadAppCtaSection from 'components/download-app-cta-section'

import { PlayButton } from './components/atomic-components'
import Hero from './sections/hero'
import WorkoutDetailsSection from './sections/workout-details'
import VideoSection from './sections/video'
import RecommendationsSection from './sections/recommendations'
import { playButtonSection, playButton } from './index.module.scss'

interface ContentfulWorkoutClassQueryResult {
  data: {
    contentfulWorkoutClass: Contentful.WorkoutClass & {
      ogImage: EF.ResizeImageNode
    }
  }
}

const WorkoutClassPage: FunctionComponent<ContentfulWorkoutClassQueryResult> = ({
  data: {
    contentfulWorkoutClass: {
      title,
      description,
      durationMins,
      node_locale: locale,
      muscleGroup,
      equipment,
      ogImage,
      videoUrl,
      fields: { pageTitle, defaultLang },
      featureImage,
      previewImages,
      recommendations,
    },
  },
}) => {
  const [isVideoShown, setIsVideoShown] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const onClickPlay = useCallback(() => {
    scrollWindow(window.scrollTo, { top: 0, behavior: 'smooth' })
    setIsVideoShown(true)
    setIsVideoPlaying(true)
  }, [])

  const onVideoPlay = useCallback(() => {
    setIsVideoPlaying(true)
  }, [])

  const onVideoPause = useCallback(() => {
    setIsVideoPlaying(false)
  }, [])

  const onVideoEnd = useCallback(() => {
    setIsVideoShown(false)
  }, [])

  return (
    <SeamlessWebsiteLayoutAndMeta
      title={title}
      pageTitle={pageTitle}
      description={description}
      hrefLang={[]}
      defaultLang={defaultLang}
      ogImage={ogImage}
      locale={locale}
    >
      {isVideoShown ? (
        <VideoSection
          url={videoUrl}
          onPlay={onVideoPlay}
          onPause={onVideoPause}
          onEnded={onVideoEnd}
          playing={isVideoPlaying}
        />
      ) : (
        <Hero
          image={featureImage as EF.FluidImageNode}
          onClickPlay={onClickPlay}
        />
      )}
      <main>
        <WorkoutDetailsSection
          description={description}
          durationMins={durationMins}
          equipment={equipment}
          muscleGroup={muscleGroup}
          previewImages={previewImages}
        />
        <Container>
          <Row>
            <Col className={playButtonSection}>
              {!isVideoPlaying ? (
                <PlayButton className={playButton} onClick={onClickPlay} />
              ) : null}
            </Col>
          </Row>
        </Container>
        <UserJourneySection />
        <RecommendationsSection recommendations={recommendations} />
        <DownloadAppCtaSection />
      </main>
    </SeamlessWebsiteLayoutAndMeta>
  )
}

export default WorkoutClassPage

export const pageQuery = graphql`
  query WorkoutClassQuery($id: String!) {
    contentfulWorkoutClass(id: { eq: $id }) {
      title
      description
      node_locale
      durationMins
      muscleGroup
      equipment
      videoUrl
      ogImage: featureImage {
        fields {
          asset {
            title
            resize(
              width: 1200
              height: 630
              cropFocus: LEFT
              resizingBehavior: FILL
            ) {
              src
              width
              height
            }
          }
        }
      }
      fields {
        pageTitle
        defaultLang {
          fields {
            path
          }
        }
        normalizedSlug
      }
      featureImage {
        fields {
          asset {
            fluid(
              cropFocus: CENTER
              maxHeight: 860
              maxWidth: 1750
              resizingBehavior: FILL
              sizes: "(min-width: 768px) 1750px, 875px"
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      previewImages {
        fields {
          asset {
            fixed(width: 310, height: 237, resizingBehavior: FILL) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
      recommendations {
        fields {
          path
        }
        title
        durationMins
        description
        featureImage {
          fields {
            asset {
              id
              fixed(
                width: 309
                height: 214
                cropFocus: LEFT
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`
