import React from 'react'
import { Trans, t } from '@lingui/macro'
import { DocumentOutlineSection, H } from 'react-document-section'
import Modal from 'react-modal'

import { i18n } from 'utils/i18n'
import { Row, Col, Container } from 'components/grid'
import { CloseButton } from 'components/Buttons'
import VisaMasterSVG from 'assets/images/cvc-visa-master.svg'
import AmexSVG from 'assets/images/cvc-amex.svg'
import { classNames } from 'utils/dom'

import {
  background,
  modal,
  closeButton,
  title as titleStyle,
  sectionTitle,
  description,
  space,
  imageCol,
  cardImage,
} from './security-code-popup.module.scss'

const SecurityCodePopup = ({
  isOpen,
  onPressClose,
}: {
  isOpen: boolean
  onPressClose: () => void
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onPressClose}
    overlayClassName={background}
    className={modal}
  >
    <DocumentOutlineSection title={i18n._(t`Finding your security code`)}>
      <Container>
        <Row>
          <Col>
            <CloseButton
              type="button"
              className={closeButton}
              onClick={onPressClose}
            />
            <H className={titleStyle} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <DocumentOutlineSection
              title={i18n._(t`If you card is Visa, Master`)}
            >
              <H className={sectionTitle} />
              <p className={description}>
                <Trans id="payment.desc.cvc.visa">
                  You can find the security code in the back of your credit
                  card. The number should be 3 digits.
                </Trans>
              </p>
            </DocumentOutlineSection>
          </Col>
          <Col xs={12} md={6} className={imageCol}>
            <img
              src={VisaMasterSVG}
              className={cardImage}
              alt=""
              role="presentation"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <DocumentOutlineSection title={i18n._(t`If you card is Amex`)}>
              <H className={classNames([sectionTitle, space])} />
              <p className={description}>
                <Trans id="payment.desc.cvc.amex">
                  You can find the security code in the front of your card. The
                  number should be 4 digits.
                </Trans>
              </p>
            </DocumentOutlineSection>
          </Col>
          <Col xs={12} md={6} className={imageCol}>
            <img
              src={AmexSVG}
              className={cardImage}
              alt=""
              role="presentation"
            />
          </Col>
        </Row>
      </Container>
    </DocumentOutlineSection>
  </Modal>
)

export default SecurityCodePopup
