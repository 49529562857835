import React from 'react'
import type { FunctionComponent } from 'react'

import { SupportedLocaleName } from 'utils/i18n'
import SeamlessWebsiteLayout from 'components/layout/seamless-website'
import Hreflang from 'components/meta/Hreflang'
import OpenGraphTags from 'components/meta/open-graph-tags'

interface Props {
  title: string
  pageTitle: string
  description: string
  hrefLang: EF.HrefLangNode[]
  defaultLang: EF.DefaultLangNode
  ogImage: EF.ResizeImageNode
  locale: SupportedLocaleName
}

const SeamlessWebsiteLayoutAndMeta: FunctionComponent<Props> = ({
  title,
  pageTitle,
  description,
  hrefLang,
  defaultLang,
  ogImage: {
    fields: { asset: ogImage },
  },
  locale,
  children,
}) => {
  return (
    <SeamlessWebsiteLayout
      title={title}
      pageTitle={pageTitle}
      description={description}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <Hreflang />
      <OpenGraphTags
        title={pageTitle}
        description={description}
        image={{ alt: ogImage.title, ...ogImage.resize }}
        locale={locale}
      />
      {children}
    </SeamlessWebsiteLayout>
  )
}

export default SeamlessWebsiteLayoutAndMeta
