import React, { ReactNode, ReactElement } from 'react'
import { Link } from 'gatsby'
import { t } from '@lingui/macro'

import {
  useSignupRouterInfo,
  RouterScreenType,
} from 'templates/signup/signup-router'
import { ArrowBackButton } from 'components/Buttons'
import {
  SignupSegmentCompletion,
  SignupSegmentType,
} from 'templates/signup/types'
import { i18n } from 'utils/i18n'
import Logo from 'components/logo'

import {
  segmentWrapper,
  onboardingRoot,
  onboardingBox,
  header,
  onboardingScreens,
  completionSegmentBoxRoot,
  backButton,
  homepageLink,
  logo,
  navigationContainer,
} from './segment.module.scss'
import Navigation from './navigation'

interface BackButtonProps {
  screen: { screenType: string }
  currentScreenIndex?: number
}

interface SegmentWrapperProps {
  prev: boolean
  current: boolean
  next: boolean
  children?: ReactNode | ReactNode[]
}

const BackButton = ({
  screen: { screenType },
  currentScreenIndex,
}: BackButtonProps) => {
  const { goToPrevScreen, prevScreens } = useSignupRouterInfo()

  if (
    screenType === 'SignupScreenComplete' ||
    currentScreenIndex === 0 ||
    currentScreenIndex === -1 ||
    prevScreens.length === 0
  )
    return null

  const onClick = async () => {
    try {
      await goToPrevScreen()
    } catch (e) {}
  }

  return <ArrowBackButton className={backButton} onClick={onClick} />
}

const SegmentWrapper = ({
  prev,
  current,
  next,
  children,
}: SegmentWrapperProps) => (
  <div
    className={segmentWrapper}
    data-prev={prev}
    data-current={current}
    data-next={next}
  >
    {children}
  </div>
)

const OnboardingSegment = ({
  children,
}: {
  children?: ReactNode
}): ReactElement => {
  const { screen } = useSignupRouterInfo()
  return (
    <div className={onboardingRoot}>
      <Navigation className={navigationContainer} currentStep={screen.step} />
      <div className={onboardingBox}>
        <div className={header}>
          <BackButton screen={screen} currentScreenIndex={screen.step} />
          <Link to={i18n._(t`/`)} className={homepageLink}>
            <Logo alt={i18n._(t`Go to homepage`)} className={logo} />
          </Link>
        </div>
        <div className={onboardingScreens}>{children}</div>
      </div>
    </div>
  )
}

const AccountCreationSegment = ({
  children,
}: {
  children?: ReactNode
}): ReactElement => (
  <div className={onboardingRoot}>
    <Navigation className={navigationContainer} currentStep={4} />
    <div className={onboardingBox}>
      <nav className={header}>
        <Link to={i18n._(t`/`)} className={homepageLink}>
          <Logo alt={i18n._(t`Go to homepage`)} className={logo} />
        </Link>
      </nav>
      <div className={onboardingScreens}>{children}</div>
    </div>
  </div>
)

const CompletionSegment = ({
  children,
  segment,
}: {
  children?: ReactNode
  segment: SignupSegmentCompletion
}): ReactElement => {
  const { plansScreen } = segment
  const { screen, segment: currentSegment } = useSignupRouterInfo()
  const otherScreenChildren = React.Children.toArray(children)
  const plansScreenChild = plansScreen ? otherScreenChildren.shift() : null
  const isCurrentSegmentCompletionSegment = currentSegment === segment
  const isCurrentScreenPlansScreen = plansScreen?.slug === screen.slug

  return (
    <div>
      {plansScreenChild}
      <div
        className={completionSegmentBoxRoot}
        data-active={
          !isCurrentScreenPlansScreen && isCurrentSegmentCompletionSegment
        }
      >
        {otherScreenChildren}
      </div>
    </div>
  )
}

const SignupSegment = (props: {
  prev: boolean
  current: boolean
  next: boolean
  segment: SignupSegmentType & {
    segmentScreens: RouterScreenType[]
  }
  children?: ReactNode
}) => {
  const {
    prev,
    current,
    next,
    segment: { segmentType },
  } = props

  const Component = {
    SignupSegmentOnboarding: OnboardingSegment,
    SignupSegmentAccountCreation: AccountCreationSegment,
    SignupSegmentCompletion: CompletionSegment,
  }[segmentType]

  return (
    <SegmentWrapper {...{ prev, current, next }}>
      {/*
       // @ts-ignore TODO: Component type is too mixed. */}
      <Component {...props} />
    </SegmentWrapper>
  )
}

export default SignupSegment
