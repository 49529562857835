import React, { useState } from 'react'
import { t } from '@lingui/macro'

import EyeOpen from 'assets/images/icons/ic-eye-open.svg'
import EyeClosed from 'assets/images/icons/ic-eye-closed.svg'
import { i18n } from 'utils/i18n'

import Input, { InputProps } from '.'
import { container, eyeButton, icon } from './password-input.module.scss'

const PasswordInput = (props: InputProps) => {
  const [revealed, toggleRevealed] = useState(false)

  const onPressShowPassword = () => {
    toggleRevealed(!revealed)
  }

  return (
    <div className={container}>
      <Input {...props} type={revealed ? 'text' : 'password'} />
      <button className={eyeButton} type="button" onClick={onPressShowPassword}>
        <img
          className={icon}
          src={revealed ? EyeClosed : EyeOpen}
          alt={revealed ? i18n._(t`Hide password`) : i18n._(t`Show password`)}
        />
      </button>
    </div>
  )
}

export default PasswordInput
