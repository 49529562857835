import React from 'react'
import { plural, t } from '@lingui/macro'

import { breakpoints } from 'components/grid'
import { useMatchMedia } from 'hooks'
import DurationLabel from 'components/labels/duration-label'
import CaloriesLabel from 'components/labels/calories-label'
import { i18n } from 'utils/i18n'

import {
  servingsLabel,
  root,
  content,
  key as keyStyle,
  value as valueStyle,
} from './meta-info-list.module.scss'

const MetaInfoList = ({
  preparationTimeMin,
  servingCount,
  calories,
}: Pick<
  Contentful.Recipe,
  'preparationTimeMin' | 'servingCount' | 'calories'
>) => {
  // We're using max-width here for once, meaning that we're not going mobile
  // first but mobile second. That is because we're primarily targeting desktop
  // browsers with this page
  const { matches: isSmall } = useMatchMedia(`(max-width: ${breakpoints.sm}px)`)

  let metaInfoList = [
    {
      key: i18n._(t('recipe.label.preparation_time')`Preparation time`),
      value: <DurationLabel min={preparationTimeMin} />,
    },
    {
      key: i18n._(t('recipe.label.calories')`Calories`),
      value: <CaloriesLabel cal={calories} />,
    },
    {
      key: i18n._(t('recipe.label.servings')`Servings`),
      value: (
        <span className={servingsLabel}>
          {i18n._(
            plural({
              value: servingCount,
              one: '# serving',
              other: '# servings',
            })
          )}
        </span>
      ),
    },
  ]

  if (isSmall) {
    // On screens that are too small for these values to be in 1 line the order is different
    const [durationInfo, caloriesInfo, servingsInfo] = metaInfoList
    metaInfoList = [durationInfo, servingsInfo, caloriesInfo]
  }

  return (
    <dl className={root}>
      <div className={content}>
        {metaInfoList.map(({ key, value }) => (
          <React.Fragment key={key}>
            <dt className={keyStyle}>{key}:</dt>
            <dd className={valueStyle}>{value}</dd>
          </React.Fragment>
        ))}
      </div>
    </dl>
  )
}

export default MetaInfoList
