import { number } from '@lingui/core'

import { i18n } from './i18n'

export const formatPrice = (
  amount: number,
  currency: Intl.NumberFormatOptions['currency'],
  minimumFractionDigits: Intl.NumberFormatOptions['minimumFractionDigits'] = 0
) =>
  number(i18n.language, {
    style: 'currency',
    currency,
    minimumFractionDigits,
  })(amount)

const humanizedFractionMap = Object.entries({
  0: '',
  [1 / 8]: '⅛',
  [1 / 5]: '⅕',
  [1 / 4]: '¼',
  [1 / 3]: '⅓',
  [3 / 8]: '⅜',
  [2 / 5]: '⅖',
  [1 / 2]: '½',
  [3 / 5]: '⅗',
  [5 / 8]: '⅝',
  [2 / 3]: '⅔',
  [3 / 4]: '¾',
  [4 / 5]: '⅘',
  [7 / 8]: '⅞',
})

const findHumanizedFraction = (fraction: number) => {
  for (let i = 1; i < humanizedFractionMap.length; i++) {
    const prevEntry = humanizedFractionMap[i - 1]
    const currEntry = humanizedFractionMap[i]
    const prevVal = Number(prevEntry[0])
    const currVal = Number(currEntry[0])
    if (fraction >= prevVal && fraction <= currVal) {
      const diffToPrev = fraction - prevVal
      const diffToCurr = Math.abs(fraction - currVal)
      return diffToPrev < diffToCurr ? prevEntry[1] : currEntry[1]
    }
  }
  return humanizedFractionMap[humanizedFractionMap.length - 1][1]
}

export const formatFraction = (nr: number) => {
  const fraction = nr % 1
  const intVal = nr | 0
  if (!fraction) return String(intVal || '')
  // If the fraction is almost 1, just round up
  if (fraction > 0.95) return String(intVal + 1)
  return `${intVal || ''}${findHumanizedFraction(fraction)}`
}
