// extracted by mini-css-extract-plugin
export var back = "tYFiz";
export var chevron = "+r46U";
export var chevronLeft = "Xni7E";
export var chevronRight = "Sf0Ih";
export var close = "kN4Oc";
export var closeCross = "jQSv0";
export var icon = "Khbwx";
export var imageIcon = "U1N5S";
export var leftArrow = "fTy6M";
export var primary = "_0Zb0Z";
export var secondary = "RIRC7";
export var srOnlyText = "_25zdx";
export var text = "U0S4n";