import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { Container, Row, Col } from 'components/grid'

import {
  root,
  phone as phoneStyle,
  card as cardStyle,
  phoneChrome,
} from './index.module.scss'

interface PhoneWorkoutsQueryResult {
  phone: EF.LocalFluidImage
  cards: {
    nodes: EF.LocalFluidImage[]
  }
}

const query = graphql`
  query PhoneWorkouts {
    phone: file(
      name: { eq: "workouts-phone" }
      relativeDirectory: { eq: "about/usp-section" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 1715
          maxHeight: 884
          sizes: "(min-width: 576px) 1275px, (min-width: 1200px) 1715px, 931px"
          srcSetBreakpoints: [
            931
            1275
            1396
            1715
            1862
            1912
            2550
            2572
            3430
          ]
          pngQuality: 60
          webpQuality: 85
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    cards: allFile(
      filter: { relativeDirectory: { eq: "about/usp-section/workout-cards" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          fluid(
            maxWidth: 335
            sizes: "(min-width: 576px) 249px, (min-width: 1200px) 335px, 182px"
            srcSetBreakpoints: [182, 249, 273, 335, 364, 373, 498, 502, 670]
            jpegQuality: 75
            webpQuality: 85
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

const PhoneWorkouts = () => {
  const { phone, cards } = useStaticQuery<PhoneWorkoutsQueryResult>(query)

  return (
    <div className={root} role="presentation">
      <Container>
        <Row>
          <Col>
            <div className={phoneStyle}>
              <GatsbyImage fluid={phone.childImageSharp.fluid} />
              {cards.nodes.map((card, i) => (
                <div className={cardStyle} data-index={i} key={i}>
                  <GatsbyImage fluid={card.childImageSharp.fluid} />
                </div>
              ))}
              <div className={phoneChrome} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PhoneWorkouts
