import React from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { formatFraction } from 'utils/format'

import {
  title,
  list,
  ingredient,
  ingredientMain,
  ingredientReplacement,
} from './ingredients.module.scss'

interface Props {
  ingredients: Contentful.Recipe['fields']['structuredIngredientList']
}

const formatMeasure = ({ amount, unit }: { amount: number; unit: string }) => {
  if (amount === 0) {
    return unit
  }
  const formattedAmount = formatFraction(amount)
  if (unit === 'g') {
    return `${formattedAmount}${unit}`
  }
  return `${formattedAmount} ${unit}`
}

const IngredientsSection = ({ ingredients }: Props) => {
  return (
    <DocumentOutlineSection
      title={i18n._(t('recipe.ingredients.title')`Ingredients`)}
    >
      <H className={title} />
      <ul className={list}>
        {ingredients.map(({ required, name, replacement, measure }, i) => (
          <li key={`${name}-${i}`} className={ingredient}>
            <p className={ingredientMain}>
              {required
                ? null
                : `${i18n._(t('recipe.ingredients.optional')`Optional`)}: `}
              {formatMeasure(measure)} {name}
            </p>
            {replacement ? (
              <p className={ingredientReplacement}>
                {`${i18n._(
                  t('recipe.ingredients.replacement')`Alternative`
                )}: `}
                {replacement}
              </p>
            ) : null}
          </li>
        ))}
      </ul>
    </DocumentOutlineSection>
  )
}

export default IngredientsSection
