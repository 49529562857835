import React, {
  Children,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { useMatchMedia } from 'hooks'
import { classNames } from 'utils/dom'
import { breakpoints, Col, Container, Row } from 'components/grid'
import FunnelCta from 'components/funnel-cta'
import CarouselInGrid from 'components/carousel-in-grid'

import {
  root,
  anchor,
  title as titleStyle,
  description as descriptionStyle,
  carouselPlaceholder,
  activityCardListStatic,
  activityCardWrapperStatic,
  cta,
} from './index.module.scss'

interface Props {
  title: string
  titleId: string
  description: string
  className?: string
}

// This is rendered while we're on the server side because React messes up the
// hydration of the page if we're rendering a carousel but then hydrate to the
// static view (on Desktop)
const renderPlaceholder = () => <span className={carouselPlaceholder} />

// On smaller viewports we're rendering a carousel
const renderCarousel = (activityCardsList: ReactNode[]) => {
  return (
    <CarouselInGrid>
      {activityCardsList.map((activityCard, i) => (
        <React.Fragment key={i}>{activityCard}</React.Fragment>
      ))}
    </CarouselInGrid>
  )
}

// On desktop we're rendering a static view
const renderStaticView = (activityCardsList: ReactNode[]) => {
  return (
    <Container>
      <Row>
        <Col xs={12} xlOffset={1} xl={10}>
          <div className={activityCardListStatic}>
            {activityCardsList.map((activityCard, i) => (
              <div key={i} className={activityCardWrapperStatic}>
                {activityCard}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const ActivityCardsCarousel: FunctionComponent<Props> = ({
  title,
  titleId,
  description,
  className,
  children,
}) => {
  const { matches: isExtraLarge } = useMatchMedia(
    `(min-width: ${breakpoints.xl}px)`
  )
  const [activityCardsList, setActivityCardsList] = useState(
    renderPlaceholder()
  )

  // This hack is unfortunately necessary because React doesn't hydrate the section
  // correctly for whatever reason
  useEffect(() => {
    setActivityCardsList(
      isExtraLarge
        ? renderStaticView(Children.toArray(children))
        : renderCarousel(Children.toArray(children))
    )
  }, [isExtraLarge, children])

  return (
    <DocumentOutlineSection title={title}>
      <section className={classNames([root, className])}>
        <Container>
          <Row>
            <Col xs={12} mdOffset={1} md={10}>
              <div className={anchor} id={titleId} />
              <H className={titleStyle} />
              <p className={descriptionStyle}>{description}</p>
            </Col>
          </Row>
        </Container>
        {activityCardsList}
        <Container>
          <Row>
            <Col>
              <FunnelCta text={i18n._(t`Try for free`)} className={cta} />
            </Col>
          </Row>
        </Container>
      </section>
    </DocumentOutlineSection>
  )
}

export default ActivityCardsCarousel
