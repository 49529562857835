import React, { useState } from 'react'
import { H } from 'react-document-section'
import { t } from '@lingui/macro'
import * as Sentry from '@sentry/gatsby'

import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import SeamlessWebsiteLayout from 'components/layout/seamless-website'
import { Container, Row, Col } from 'components/grid'
import Breadcrumb from 'components/breadcrumb'
import { PrimaryButton } from 'components/Buttons'
import StripePaymentForm, {
  useStripeCCFormStateContext,
} from 'components/payment/StripePaymentForm'
import SupportedCards from 'components/PlanTable/SupportedCards'
import StripePaymentInput from 'components/payment/StripePaymentInput'
import { handleScaResponse } from 'components/payment/lib/stripe'
import SecurityCodePopup from 'components/payment-page/security-code-popup'
import ToastMessage, { ToastStatus } from 'components/toast-message'

import AuthenticatedPage from '../AuthenticatedPage'
import { navigateToProfileWithToast } from '../utils'

import {
  breadcrumb,
  title,
  subtitle,
  formPlaceholder,
  paymentInputContainer,
  paymentOptions,
  securityPopupButtonContainer,
  securityPopupButton,
  submitButton,
} from './index.module.scss'

interface UpdatePaymentPageProps {
  pageContext: {
    hrefLang: EF.HrefLangNode[]
    defaultLang: EF.DefaultLangNode
  }
}

interface Toast {
  message: string
  status: ToastStatus
}

const getBreadcrumbItems = () => [
  {
    href: i18n._(t`/profile/`),
    text: i18n._(t('user.profile.title')`Profile`),
  },
  {
    href: i18n._(t`/profile/subscribe/`),
    text: i18n._(t('user.profile.upgrade.cc')`Update credit card`),
  },
]

const renderStripeFormPlaceholder = () => <div className={formPlaceholder} />

const SubmitButton = ({ text }: { text: string }) => {
  const { isValid, isSubmitting } = useStripeCCFormStateContext()

  return (
    <PrimaryButton
      block
      text={text}
      disabled={!isValid || isSubmitting}
      className={submitButton}
    />
  )
}

const UpdatePaymentPage = ({
  pageContext: { hrefLang, defaultLang },
}: UpdatePaymentPageProps) => {
  const [showSecurityCodePopup, setShowSecurityCodePopup] = useState(false)
  const [toast, setToast] = useState<Toast | null>(null)

  const toggleShowSecurityCodePopup = () => {
    setShowSecurityCodePopup(!showSecurityCodePopup)
  }

  const onCloseToastMessage = () => {
    setToast(null)
  }

  const showError = (message: string) => {
    Sentry.addBreadcrumb({
      category: 'Web Profile',
      level: Sentry.Severity.Error,
      message,
    })
    setToast({
      message,
      status: ToastStatus.ERROR,
    })
  }

  const onSubscribe = async ({ error, token }: stripe.TokenResponse) => {
    const genericErrMsg = i18n._(
      t('error.message.default')`Unfortunately an error occurred.`
    )
    if (!token) {
      // Yes, error.message is human-readable and, no, it's not translated
      // but at least we're giving the user information if present
      showError(error?.message || genericErrMsg)
      return
    }

    try {
      const apiResponse = await UserAccountStore.updateStripePayment({
        stripe_token: token.id,
      })
      await handleScaResponse(apiResponse)
      navigateToProfileWithToast('ccUpdateSuccess', ToastStatus.SUCCESS)
    } catch (e) {
      Sentry.captureException(e)
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      showError(genericErrMsg)
    }
  }

  return (
    <AuthenticatedPage>
      <SeamlessWebsiteLayout
        defaultLang={defaultLang}
        hrefLang={hrefLang}
        title={i18n._(t('user.profile.upgrade.cc')`Update credit card`)}
      >
        <Container>
          <Row>
            <Col>
              <Breadcrumb items={getBreadcrumbItems()} className={breadcrumb} />
            </Col>
          </Row>
        </Container>
        <main>
          <Container>
            <Row>
              <Col>
                <H className={title} />
                <p className={subtitle}>
                  {i18n._(
                    t(
                      'user.profile.change.subscription.details'
                    )`Any changes will be applied in the next billing date.`
                  )}
                </p>
              </Col>
            </Row>
            <StripePaymentForm
              onSubscribeStart={onCloseToastMessage}
              onSubscribe={onSubscribe}
              renderPlaceholder={renderStripeFormPlaceholder}
            >
              <Row>
                <Col xs={12}>
                  <div className={paymentInputContainer}>
                    <SupportedCards className={paymentOptions} />
                    <StripePaymentInput />
                    <div className={securityPopupButtonContainer}>
                      <button
                        type="button"
                        className={securityPopupButton}
                        onClick={toggleShowSecurityCodePopup}
                      >
                        {i18n._(
                          t('signup.payment.cvc.button')`How can I find?`
                        )}
                      </button>
                    </div>
                    <SubmitButton
                      text={i18n._(t('user.profile.payment.update')`Update`)}
                    />
                  </div>
                </Col>
              </Row>
            </StripePaymentForm>
          </Container>
        </main>
        <SecurityCodePopup
          isOpen={showSecurityCodePopup}
          onPressClose={toggleShowSecurityCodePopup}
        />
        {toast && (
          <ToastMessage
            message={toast.message}
            status={toast.status}
            onPressClose={onCloseToastMessage}
          />
        )}
      </SeamlessWebsiteLayout>
    </AuthenticatedPage>
  )
}

export default UpdatePaymentPage
