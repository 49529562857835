// extracted by mini-css-extract-plugin
export var downloadCard = "r1JgH";
export var downloadCardBitmapImage = "oLwjG";
export var downloadCardFigure = "bAFdk";
export var downloadCardImageContainer = "IXROF";
export var downloadCardLabel = "k-W5M";
export var downloadCardVectorImage = "+9I26";
export var downloadCards = "Pfrbv";
export var downloadsSection = "_0tP7n";
export var headerSection = "kloE-";
export var pageHeader = "JxleK";
export var pageSection = "wDeOm";
export var pressReviewSection = "zicDX";
export var root = "xdBAO";
export var sectionHeader = "_0kU3O";