import React from 'react'
import { FixedObject } from 'gatsby-image'
import { t } from '@lingui/macro'

import DurationLabel from 'components/labels/duration-label'
import CaloriesLabel from 'components/labels/calories-label'
import ActivityCard from 'components/activity-card'
import { i18n } from 'utils/i18n'

import { getCategoryCopy } from './utils'

interface RecipeCardProps {
  linkTo: string
  title: string
  preparationTimeMin: number
  calories: number
  categories: Contentful.RecipeCategory[]
  image: FixedObject
}

const RecipeCard = ({
  linkTo,
  image,
  title,
  preparationTimeMin,
  calories,
  categories,
}: RecipeCardProps) => {
  const metaInfoList = [
    {
      key: i18n._(t('recipe.label.preparation_time')`Preparation time`),
      value: <DurationLabel min={preparationTimeMin} />,
    },
    {
      key: i18n._(t('recipe.label.calories')`Calories`),
      value: <CaloriesLabel isShort cal={calories} />,
    },
  ] as const

  const text = categories
    .map((category) => getCategoryCopy(category))
    .join(' • ')

  return (
    <ActivityCard
      linkTo={linkTo}
      wave={3}
      image={image}
      title={title}
      metaInfoList={metaInfoList}
      text={text}
    />
  )
}

export default RecipeCard
