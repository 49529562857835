import React from 'react'
import { navigate } from 'gatsby'

import StyledSelect from 'components/styled-select'
import ControlIcon from 'components/icons/ControlIcon'
import worldIcon from 'assets/images/icons/world.svg'
import { loadLanguageCatalogForPathname } from 'utils/i18n'
import { classNames } from 'utils/dom'

import {
  container,
  languageContainer,
  languageSelectValue,
  chevron,
  logoWorld,
} from './languageSelector.module.scss'
import useLanguageSelector from './useLanguageSelector'

interface Props {
  testID?: string
  hrefLang?: EF.HrefLangNode[]
  className?: string
}

export default function LanguageSelector({
  hrefLang = [],
  testID,
  className,
  ...props
}: Props) {
  const { currentValue, options, displayValue } = useLanguageSelector(hrefLang)

  if (!hrefLang || hrefLang.length < 1) {
    return null
  }

  return (
    <div className={classNames([container, className])}>
      <StyledSelect
        data-testid={testID || 'languageSelector'}
        className={languageContainer}
        displayValue={
          <>
            <img alt="" src={worldIcon} className={logoWorld} />
            <span className={languageSelectValue}>{displayValue}</span>
            <ControlIcon iconName="chevron-down-thin" className={chevron} />
          </>
        }
        value={currentValue}
        options={options}
        onChange={async (e) => {
          const pathname = e.target.value
          await loadLanguageCatalogForPathname(pathname)
          navigate(pathname)
        }}
        {...props}
      />
    </div>
  )
}
