import * as Sentry from '@sentry/gatsby'

const trackingUrls = [
  'clarity.ms/collect',
  'clarity.ms/eus2/collect',
  'www.google-analytics.com/j/collect',
  'stats.g.doubleclick.net/j/collect',
  'trc-events.taboola.com',
]
const trackingUrlsPattern = new RegExp(
  trackingUrls.join('|').replace('.', '\\.')
)

Sentry.init({
  dsn:
    'https://436f70f3891e46ce8377868bdcb3fbcf@o18746.ingest.sentry.io/1232712',
  environment: process.env.GATSBY_ENV_NAME,
  beforeBreadcrumb(event) {
    // Filter out event tracking from the breadcrumbs to reduce noise
    if (event.category === 'xhr' && trackingUrlsPattern.test(event.data?.url)) {
      return null
    }
    return event
  },
})
