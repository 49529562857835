import React from 'react'
import Image, { FluidObject } from 'gatsby-image'
import { H } from 'react-document-section'

import { Container, Row, Col, Hidden, Visible } from 'components/grid'
import ScrollIndicator from 'components/scroll-indicator'
import Logo from 'components/logo'
import { PrimaryButtonLink } from 'components/Buttons'

import {
  container,
  headerContainer,
  headerTextContainer,
  logo,
  title,
  description as descriptionStyle,
  featureImageContainer,
  featureImage,
  headerButton,
} from './Header.module.scss'

interface HeaderProps {
  description: string
  headerButtonText: string
  desktopFeatureImage: {
    fluid: FluidObject
  }
  mobileFeatureImage: {
    fluid: FluidObject
  }
  signupPath: string
}

const FeatureImage = ({ image }: { image: { fluid: FluidObject } }) => {
  return (
    <div className={featureImageContainer}>
      <div className={featureImage}>
        <Image fluid={image.fluid} />
      </div>
      <ScrollIndicator isShifted />
    </div>
  )
}

const Header = ({
  description,
  headerButtonText,
  desktopFeatureImage,
  mobileFeatureImage,
  signupPath,
}: HeaderProps) => {
  return (
    <div className={container}>
      <Visible lg>
        <FeatureImage image={desktopFeatureImage} />
      </Visible>
      <div className={headerContainer}>
        <Container>
          <Row>
            <Col>
              <div className={headerTextContainer}>
                <Logo className={logo} />
                <H className={title} data-testid="pageTitle" />
                <p className={descriptionStyle}>{description}</p>
                <div className={headerButton}>
                  <PrimaryButtonLink
                    path={signupPath}
                    text={headerButtonText}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Hidden lg>
        <FeatureImage image={mobileFeatureImage} />
      </Hidden>
    </div>
  )
}

export default Header
