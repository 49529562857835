import React from 'react'
import Modal from 'react-modal'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t, Trans } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { SecondaryButton, PrimaryButton, CloseButton } from 'components/Buttons'

import {
  background,
  modal,
  closeButton,
  title,
  description,
  button,
} from './WarningPopup.module.scss'

const DowngradeWarningPopup = ({
  isOpen,
  onPressClose,
  yearlySaving,
  onPressDowngrade,
}: {
  isOpen: boolean
  onPressClose: () => void
  yearlySaving: string
  onPressDowngrade: () => void
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onPressClose}
      overlayClassName={background}
      className={modal}
    >
      <CloseButton
        type="button"
        className={closeButton}
        onClick={onPressClose}
      />
      <DocumentOutlineSection
        title={i18n._(
          t(
            'user.profile.downgrade.question'
          )`Are you sure you want to downgrade?`
        )}
      >
        <H className={title} />
        <p className={description}>
          <Trans id="user.profile.subscription.prompt">
            Your current subscription saves you {yearlySaving} yearly. Are you
            sure you want to continue?
          </Trans>
        </p>
        <SecondaryButton
          className={button}
          text={i18n._(t('user.profile.downgrade.cta')`Downgrade`)}
          onClick={onPressDowngrade}
          block
        />
        <PrimaryButton text={i18n._(t`Back`)} onClick={onPressClose} block />
      </DocumentOutlineSection>
    </Modal>
  )
}

export default DowngradeWarningPopup
