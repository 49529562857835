import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'

import { SideEffect } from '../types'
import { isOnboardingFilled } from '../utils'

const registerOnboardedEffect: SideEffect = {
  name: 'Register "onboarded"',
  isSilent: true,
  condition: () =>
    !UserAccountStore.getAttribute('onboarded') && isOnboardingFilled(),
  execute: async () => {
    UserAccountStore.setAttribute('onboarded', true)
  },
}

export default registerOnboardedEffect
