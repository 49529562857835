import React, { useState } from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'
import * as Sentry from '@sentry/gatsby'

import { i18n } from 'utils/i18n'
import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import { useGetParams } from 'hooks'
import Layout from 'components/layout'
import ToastMessage, { ToastStatus } from 'components/toast-message'
import Background from 'templates/login/Background'

import PasswordResetForm from './PasswordResetForm'
import {
  root,
  container,
  passwordResetContainer,
  title,
  toastMessage,
} from './index.module.scss'

const hrefLang = [
  { node_locale: 'en-US', fields: { path: '/password-reset/' } },
  { node_locale: 'de', fields: { path: '/de/password-reset/' } },
  { node_locale: 'fr', fields: { path: '/fr/password-reset/' } },
  { node_locale: 'es', fields: { path: '/es/password-reset/' } },
  { node_locale: 'it', fields: { path: '/it/password-reset/' } },
  { node_locale: 'pt-BR', fields: { path: '/pt-br/password-reset/' } },
]

const PasswordResetPage = () => {
  const [isSubmitRequested, setIsSubmitRequested] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)

  const pageTitle = i18n._(t('password_reset.title')`Reset your password`)

  const { reset_password_token } = useGetParams()

  const onPressPasswordReset = async (newPassword: string) => {
    setIsSubmitRequested(true)
    try {
      await UserAccountStore.resetPassword({
        newPassword,
        token: reset_password_token,
      })
      setSuccess(true)
      setIsSubmitRequested(false)
    } catch (err) {
      setIsSubmitRequested(false)
      setErrorMessage(
        i18n._(t('error.message.default')`Unfortunately an error occurred.`)
      )
      Sentry.addBreadcrumb({
        category: 'Password Reset',
        data: err,
        level: Sentry.Severity.Error,
      })
    }
  }

  return (
    <Layout
      className={root}
      title={pageTitle}
      pageTitle={`${pageTitle} | 8fit`}
      hrefLang={hrefLang}
      defaultLang={{ fields: { path: '/password-reset/' } }}
    >
      <DocumentOutlineSection title={pageTitle}>
        <div className={container}>
          <Background />
          <main className={passwordResetContainer}>
            <H className={title} />
            <PasswordResetForm
              disabled={isSubmitRequested}
              onPressPasswordReset={onPressPasswordReset}
              success={success}
            />
          </main>
        </div>
      </DocumentOutlineSection>
      {errorMessage && (
        <ToastMessage
          className={toastMessage}
          message={errorMessage}
          onPressClose={() => setErrorMessage('')}
          status={ToastStatus.ERROR}
        />
      )}
      {success && (
        <ToastMessage
          className={toastMessage}
          message={i18n._(
            t('password-reset.successMessage')`Password successfully updated!`
          )}
          onPressClose={() => setSuccess(false)}
          status={ToastStatus.SUCCESS}
        />
      )}
    </Layout>
  )
}

export default PasswordResetPage
