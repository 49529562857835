import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'

export const getCategoryCopy = (category: Contentful.RecipeCategory) => {
  const copies: Record<Contentful.RecipeCategory, string> = {
    affordable: i18n._(t('recipe.category.affordable')`Affordable`),
    baked_goods: i18n._(t('recipe.category.baked_goods')`Baked goods`),
    diabetic_friendly: i18n._(
      t('recipe.category.diabetic_friendly')`Low sugar`
    ),
    energy_booster: i18n._(t('recipe.category.energy_booster')`Energy booster`),
    family_friendly: i18n._(
      t('recipe.category.family_friendly')`Family friendly`
    ),
    gluten_free: i18n._(t('recipe.category.gluten_free')`Gluten-free`),
    gourmet: i18n._(t('recipe.category.gourmet')`Gourmet`),
    high_protein: i18n._(t('recipe.category.high_protein')`High protein`),
    ketogenic: i18n._(t('recipe.category.ketogenic')`Ketogenic`),
    lactose_free: i18n._(t('recipe.category.lactose_free')`Lactose free`),
    low_carb: i18n._(t('recipe.category.low_carb')`Low carb`),
    low_cholesterol: i18n._(
      t('recipe.category.low_cholesterol')`Low cholesterol`
    ),
    meal_prep: i18n._(t('recipe.category.meal_prep')`Meal prep`),
    quick: i18n._(t('recipe.category.quick')`Quick`),
    rich_in_omega3: i18n._(
      t('recipe.category.rich_in_omega3')`Rich in omega-3`
    ),
    simple: i18n._(t('recipe.category.simple')`Simple`),
  }
  return copies[category]
}
