import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { periodNameMonth } from 'utils/payment'

export const getFaqs = () => {
  return [
    {
      question: i18n._(
        t('faq.question.get.started')`What do I need to get started?`
      ),
      answer: i18n._(
        t(
          'faq.answer.get.started'
        )`Motivation. 8fit workouts are designed so that you can do them at home without any specialized equipment. Our recipes don’t require gourmet ingredients or uncommon kitchen appliances either!`
      ),
    },
    {
      question: i18n._(
        t('faq.question.get.cancellation')`How do I cancel my membership?`
      ),
      answer: i18n._(
        t(
          'faq.answer.cancellation'
        )`Yes. If you have a trial subscription and you’re within the trial period, then you may cancel at any time and you will not be charged. Canceling a subscription after having purchased it will still allow you to have full access to all Pro features until the end of the subscription period.`
      ),
    },
    {
      question: i18n._(
        t(
          'faq.question.get.trial'
        )`What do I get access to during the 14 day trial?`
      ),
      answer: i18n._(
        t(
          'faq.answer.trial'
        )`You get a full access to all of our features including workouts, nutrition, sleep meditation and the exclusive weekly classes.`
      ),
    },
  ]
}

export const getPlanTableContent = () => {
  return {
    monthlyPlan: {
      planName: periodNameMonth(1),
      buttonText: i18n._(t`Select plan`),
      price: i18n._(t`/month`),
    },
    yearlyPlan: {
      planName: i18n._(t`1 year`),
      buttonText: i18n._(t`Try for free`),
      price: i18n._(t`/month`),
      priceBreakdown: i18n._(t`/yearly`),
    },
    quarterlyPlan: {
      planName: periodNameMonth(3),
      buttonText: i18n._(t`Select plan`),
      price: i18n._(t`/month`),
      priceBreakdown: i18n._(t`/quarterly`),
    },
    finePrint: i18n._(
      t(
        'plan.fine.print'
      )`Change or cancel anytime. With yearly you won't be charged until the end of trial.`
    ),
    benefits: [
      i18n._(t`Basic full body program`),
      i18n._(t`Wide variety of customizable workouts`),
      i18n._(t`Exclusive weekly classes`),
      i18n._(t`Personalized meal plans`),
      i18n._(t`Dietary exclusions`),
      i18n._(t`800+ healthy recipes`),
      i18n._(t`Shopping list`),
      i18n._(t`Progress tracking`),
      i18n._(t`Pro free for 2 weeks`),
      i18n._(t`Early access to new content`),
    ],
  }
}
