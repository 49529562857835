import React, { useRef, useState, useEffect, useCallback } from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { throttleRaf, scrollWindow, classNames } from 'utils/dom'
import ControlIcon from 'components/icons/ControlIcon'

import { root, button, icon, text, shifted } from './index.module.scss'

const getBottomPosition = (el: HTMLDivElement | null) =>
  el?.getBoundingClientRect()?.bottom

const shouldBeFixed = (anchorEl: HTMLDivElement | null) => {
  const bottom = getBottomPosition(anchorEl)
  if (bottom !== undefined) {
    const { innerHeight } = window
    return bottom > innerHeight
  }
  return false
}

interface Props {
  isShifted?: boolean
}

const ScrollIndicator = ({ isShifted }: Props) => {
  const anchorRef = useRef<HTMLDivElement>(null)
  const [isFixed, setIsFixed] = useState<boolean>(true)

  const anchorEl = anchorRef.current

  const onClick = useCallback(() => {
    const bottom = getBottomPosition(anchorEl)
    if (bottom !== undefined) {
      scrollWindow(window.scrollBy, {
        top: bottom,
        behavior: 'smooth',
      })
    }
  }, [anchorEl])

  useEffect(() => {
    const handler = throttleRaf(() => {
      setIsFixed(shouldBeFixed(anchorEl))
    })
    window.addEventListener('scroll', handler)
    window.addEventListener('resize', handler)
    handler()
    return () => {
      window.removeEventListener('scroll', handler)
      window.removeEventListener('resize', handler)
    }
  }, [anchorEl])

  return (
    <div
      ref={anchorRef}
      className={classNames([root, isShifted ? shifted : undefined])}
    >
      <button className={button} data-fixed={isFixed} onClick={onClick}>
        <ControlIcon className={icon} iconName="chevron-down-thin" />
        <span className={text}>{i18n._(t`Scroll down`)}</span>
      </button>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<style>.${root} {display: none;}</style>`,
        }}
      />
    </div>
  )
}

export default ScrollIndicator
