import React, { FunctionComponent, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import { useMatchMedia } from 'hooks'
import { breakpoints } from 'components/grid'
import QuoteText from 'components/quote-text'
import Carousel from 'components/carousel'

import {
  logo as logoStyle,
  pressReviewContainer,
  reviewTextWrapper,
  reviewText,
  carousel,
} from './index.module.scss'
import {
  PressImageQueryResult,
  PressReviewLogo,
} from './pressReviewCarouselTypes'
import { getReviews } from './pressReviewCarouselLib'

const LogoImage: FunctionComponent<{
  name: string
  logo?: PressReviewLogo
}> = ({ logo, name }) => {
  if (!logo) return null

  switch (logo.type) {
    case 'bitmap':
      return <Image fixed={logo.image} alt={name} />
    case 'vector':
      return <img src={logo.image} alt={name} />
    default:
      return null
  }
}

const query = graphql`
  {
    vector: allFile(
      filter: { relativeDirectory: { eq: "press" }, extension: { in: ["svg"] } }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    bitmap: allFile(
      filter: {
        relativeDirectory: { eq: "press" }
        extension: { in: ["png", "jpg", "gif"] }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 130, height: 36) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const PressReviewCarousel: FunctionComponent = () => {
  const result = useStaticQuery<PressImageQueryResult>(query)

  const { matches: isExtraLarge } = useMatchMedia(
    `(min-width: ${breakpoints.xl}px)`
  )
  const [currentIndex, setCurrentIndex] = useState(isExtraLarge ? 2 : 0)

  function onIndexChange(index: number) {
    setCurrentIndex(index)
  }

  const reviews = getReviews(result)

  return (
    <>
      <Carousel
        className={carousel}
        gap={40}
        onIndexChange={onIndexChange}
        defaultIndex={currentIndex}
        keyPrefix="press-review"
        showDots
      >
        {reviews.map(({ logo, name }, idx) => (
          <div
            key={`feature-logo-${name}`}
            aria-selected={currentIndex === idx}
            className={logoStyle}
          >
            <LogoImage logo={logo} name={name} />
          </div>
        ))}
      </Carousel>
      <div className={pressReviewContainer}>
        <div className={reviewTextWrapper}>
          <p className={reviewText}>
            <QuoteText>{reviews[currentIndex].text}</QuoteText>
          </p>
        </div>
      </div>
    </>
  )
}

export default PressReviewCarousel
