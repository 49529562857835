import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'

import { wait } from 'utils/async'
import { PrimaryButton } from 'components/Buttons'

import { Notification, useNotificationCentral } from '.'
import {
  modal,
  overlay,
  closeRequested,
  message,
  buttonWrapper,
  button,
} from './overlay.module.scss'

if (typeof window === 'object') {
  ReactModal.setAppElement(document.querySelector('body > div'))
}

const NotificationOverlay = () => {
  const {
    notification: originalNotification,
    clearNotification,
  } = useNotificationCentral()
  const [notification, setNotification] = useState<Notification | null>(null)
  const [isCloseRequested, setCloseRequested] = useState(false)
  useEffect(() => {
    if (originalNotification && originalNotification !== notification) {
      setNotification(originalNotification)
    } else if (notification && !originalNotification) {
      // Make notification disappear
      setCloseRequested(true)
      wait(500).then(() => {
        setCloseRequested(false)
        setNotification(null)
      })
    }
  }, [notification, originalNotification])
  const labelId = 'notification-overlay-label'
  return (
    <ReactModal
      isOpen={!!notification}
      onRequestClose={() => {
        if (notification && notification.dismissable) {
          clearNotification()
        }
      }}
      aria={{
        labelledby: labelId,
      }}
      className={`${modal} ${isCloseRequested ? closeRequested : ''}`}
      overlayClassName={`${overlay} ${isCloseRequested ? closeRequested : ''}`}
    >
      {notification && (
        <>
          <div id={labelId} className={message}>
            {notification.message}
          </div>
          {notification.buttonLabel && (
            <div className={buttonWrapper}>
              <PrimaryButton
                className={button}
                onClick={clearNotification}
                text={notification.buttonLabel}
              />
            </div>
          )}
        </>
      )}
    </ReactModal>
  )
}

export default NotificationOverlay
