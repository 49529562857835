import React from 'react'
import { H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Container, Row, Col } from 'components/grid'
import ScrollIndicator from 'components/scroll-indicator'
import { AppStoreButton, PlayStoreButton } from 'components/store-button'
import FunnelCta from 'components/funnel-cta'

import Background from './background'
import StoreRatingRow from './store-rating-row'
import {
  root,
  contentTop,
  contentBottom,
  title,
  featureList,
  featureListItem,
  contentBottomInner,
  signupCtaWrapper,
  scrollIndicatorWrapper,
  appStoreButton,
  playStoreButton,
  bottomRow,
} from './index.module.scss'

const HomepageHeader = () => {
  const features = [
    i18n._(t('homepage.header.feature.workouts')`Fun, varied workouts`),
    i18n._(t('homepage.header.feature.meals')`Healthy & personalised meals`),
    i18n._(t('homepage.header.feature.sleep_meditation')`Sleep meditation`),
    i18n._(t('homepage.header.feature.holistic')`Whole-body health`),
  ]
  return (
    <header className={root}>
      <Background />
      <Container>
        <Row className={contentTop}>
          <Col xs={12} smOffset={1} sm={10}>
            <Row>
              <Col>
                <StoreRatingRow />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={10} md={12} lg={10} xl={6}>
                <H className={title} data-testid="pageTitle" />
                <ul className={featureList}>
                  {features.map((feature) => (
                    <li key={feature} className={featureListItem}>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={contentBottom}>
          <Col>
            <div className={contentBottomInner}>
              <Row between="xs">
                <Col xs={12} sm={10} smOffset={1}>
                  <div className={signupCtaWrapper}>
                    <FunnelCta
                      text={i18n._(
                        t('homepage.header.signup_cta')`Try for FREE`
                      )}
                    />
                  </div>
                </Col>
              </Row>
              <Row reverse="xs" className={bottomRow}>
                <Col
                  xs={12}
                  sm={4}
                  smOffset={1}
                  md={3}
                  lg={2}
                  xl={2}
                  xlOffset={10}
                >
                  <div className={appStoreButton}>
                    <AppStoreButton />
                  </div>
                  <div className={playStoreButton}>
                    <PlayStoreButton />
                  </div>
                </Col>
                <Col xs={12} sm={12}>
                  <div className={scrollIndicatorWrapper}>
                    <ScrollIndicator />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default HomepageHeader
