import React from 'react'
import Modal from 'react-modal'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t, Trans } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { SecondaryButton, PrimaryButton, CloseButton } from 'components/Buttons'

import {
  background,
  modal,
  closeButton,
  title,
  description,
  button,
} from './WarningPopup.module.scss'

const CancelWarningPopup = ({
  isOpen,
  endDate,
  onPressClose,
  onPressCancel,
}: {
  isOpen: boolean
  endDate: string
  onPressClose: () => void
  onPressCancel: () => void
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onPressClose}
      overlayClassName={background}
      className={modal}
    >
      <CloseButton
        type="button"
        className={closeButton}
        onClick={onPressClose}
      />
      <DocumentOutlineSection
        title={i18n._(
          t(
            'user.profile.autorenew.stop'
          )`Are you sure you want to stop your auto-renew?`
        )}
      >
        <H className={title} />
        <p className={description}>
          <Trans id="user.profile.autoewnew.stop.description">
            You will lose access to all your Pro benefits, including meal plans,
            weekly classes and your full fitness program on {endDate}.
          </Trans>
        </p>
        <SecondaryButton
          className={button}
          text={i18n._(t('user.profile.autorenew.stop.cta')`Stop auto-renewal`)}
          onClick={onPressCancel}
          block
        />
        <PrimaryButton text={i18n._(t`Back`)} onClick={onPressClose} block />
      </DocumentOutlineSection>
    </Modal>
  )
}

export default CancelWarningPopup
