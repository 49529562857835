import React, { Component } from 'react'

import { CategoryNavigationProps } from '../types'

import CategorySection from './CategorySection'
import { categoryNavigationList, categorySection } from './index.module.scss'

export default class CategoryNavigationMobile extends Component<
  CategoryNavigationProps
> {
  state = {
    openItem: null,
  }

  handleToggleExpand = (clickedItem: string) => {
    this.setState({
      openItem: this.state.openItem === clickedItem ? null : clickedItem,
    })
  }

  render() {
    const { openItem } = this.state
    const { categoryList } = this.props

    return (
      <nav>
        <ul className={categoryNavigationList}>
          {categoryList.map(
            ({ name, path, categoryIconMobile, subCategories }) => (
              <li key={name} className={categorySection}>
                <CategorySection
                  name={name}
                  path={path}
                  icon={categoryIconMobile}
                  subCategories={[{ name, path }, ...subCategories]}
                  opened={openItem === name}
                  onItemClick={() => this.handleToggleExpand(name)}
                />
              </li>
            )
          )}
        </ul>
      </nav>
    )
  }
}
