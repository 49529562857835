import React from 'react'
import { isFinite } from 'lodash'
import { plural } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { DEFAULT_CURRENCY } from 'templates/signup/constants'
import Price from 'components/price'

import {
  container,
  originalPrice as originalPriceStyle,
  discountPrice as discountPriceStyle,
  periodName as periodNameStyle,
} from './DiscountPrice.module.scss'

interface DiscountPriceProps {
  originalPrice: number
  discountPrice?: number
  period: string
  className?: string
  currencyCode?: EF.supportedCurrency
}

const periodNamePerYear = (count: number) =>
  i18n._(plural({ value: count, one: 'per year', other: 'per # years' }))

const periodNamePerMonth = (count: number) =>
  i18n._(plural({ value: count, one: 'per month', other: 'per # months' }))

const periodName = (): { [key: string]: string } => ({
  '10.years': periodNamePerYear(10),
  '1.month': periodNamePerMonth(1),
  '1.year': periodNamePerYear(1),
  '3.months': periodNamePerMonth(3),
  '6.months': periodNamePerMonth(6),
})

const DiscountPrice = ({
  originalPrice,
  discountPrice,
  period = '',
  className = '',
  currencyCode = DEFAULT_CURRENCY,
}: DiscountPriceProps) => {
  const hasDiscountedPrice = isFinite(discountPrice) // Check if it's a valid number
  return (
    <div className={`${container} ${className}`}>
      <div
        data-has-discount={hasDiscountedPrice}
        className={originalPriceStyle}
      >
        <Price value={originalPrice} currencyCode={currencyCode} />
      </div>
      {hasDiscountedPrice && (
        <div className={discountPriceStyle}>
          <Price value={discountPrice as number} currencyCode={currencyCode} />
        </div>
      )}
      <div className={periodNameStyle}>{periodName()[period]}</div>
    </div>
  )
}

export default DiscountPrice
