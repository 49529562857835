import React from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import PhoneIcon from 'assets/images/icons/ic-phone.svg'
import { useMatchMedia } from 'hooks'

import {
  container,
  message,
  circleContainer,
  line,
  circle,
  navItem,
  navText,
  phone,
} from './navigation.module.scss'

interface LeftNavigationProps {
  currentStep: number
  className?: string
}
const Navigation = ({ currentStep, className }: LeftNavigationProps) => {
  const { matches } = useMatchMedia(`(min-width: 768px)`)

  const headerMessages = [
    i18n._(
      t(
        'signup.navigation.message.1'
      )`You’re 5 steps away from a healthier you. Let’s dive in.`
    ),
    i18n._(
      t(
        'signup.navigation.message.2'
      )`Just 4 steps left until you start your journey.`
    ),
    i18n._(t('signup.navigation.message.3')`OK, 3 steps left.`),
    i18n._(t('signup.navigation.message.4')`You’re on the homestretch now.`),
    '',
  ]
  const stepTitles = [
    i18n._(t`Goal`),
    i18n._(t`About you`),
    i18n._(t`Target`),
    i18n._(t`Account`),
    i18n._(t`Start your journey`),
  ]

  const positionX = matches ? {} : { left: `-${110 * (currentStep - 1)}px` }

  return (
    <nav className={className}>
      <div className={container}>
        <p className={message}>{headerMessages[currentStep - 1]}</p>
        <div className={circleContainer} style={positionX}>
          <span className={line} />
          {stepTitles.map((item, index) => (
            <div
              key={item}
              data-active={index + 1 === currentStep}
              className={navItem}
            >
              <span className={circle} data-active={index + 1 === currentStep}>
                {index + 1 === stepTitles.length ? (
                  <img src={PhoneIcon} alt="" className={phone} />
                ) : (
                  index + 1
                )}
              </span>
              <div className={navText}>{stepTitles[index]}</div>
            </div>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default Navigation
