import React, { DetailedHTMLProps, AnchorHTMLAttributes } from 'react'

import { AdjustUniversalLinkProps, AdjustLinkProps } from './types'
import usePlaformStoreUrl from './use-platform-store-url'

interface AdjustLinkInnerProps {
  props: DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
  url: string
}

const AdjustLinkInner = ({
  props: { children, ...rest },
  url,
}: AdjustLinkInnerProps) => {
  return typeof children === 'function' ? (
    children(url)
  ) : (
    <a href={url} {...rest} data-testid="adjustTrackerLink">
      {children}
    </a>
  )
}

const AdjustLink = ({ preferAndroidStore, ...props }: AdjustLinkProps) => {
  const storeUrl = usePlaformStoreUrl(preferAndroidStore)
  return <AdjustLinkInner props={props} url={storeUrl} />
}

export const AdjustUniversalLink = ({
  preferAndroidStore,
  ...props
}: AdjustUniversalLinkProps) => {
  const storeUrl = usePlaformStoreUrl(preferAndroidStore)
  return <AdjustLinkInner props={props} url={storeUrl} />
}

export default AdjustLink
