import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import {
  getUTCSecondsFromEpoch,
  getBirthDateFromAge,
  getAgeFromBirthDate,
  getDateFromUTCSecondsFromEpoch,
} from 'utils/date-time'

import { SideEffect } from '../types'

const updateBirthdayEffect: SideEffect = {
  name: 'Sync birthday and age',
  isSilent: true,
  isRecurring: true,
  condition: () => {
    const { getAttribute } = UserAccountStore
    const age = Number(getAttribute('age'))
    const birthday = getAttribute('birthday')
    const newBirthday = getUTCSecondsFromEpoch(getBirthDateFromAge(age))
    const ageFromBirthday =
      birthday && getAgeFromBirthDate(getDateFromUTCSecondsFromEpoch(birthday))
    // We're checking age against age instead of birthday against birthday
    // because birthday is given in seconds from the epoch. So, from one
    // screen to the next 1 or more seconds might pass making the newly
    // calculated birthday different to the previously stored one which
    // would make this condition true and cause this side effect to run on
    // every screen change. This doesn't happen with age.
    return (
      (isFinite(newBirthday) && age !== ageFromBirthday) || !!(birthday && !age)
    )
  },
  execute: async () => {
    const { getAttribute, mergeAttributes } = UserAccountStore
    const storedBirthday = getAttribute('birthday')
    const age =
      getAttribute('age') ||
      (storedBirthday &&
        getAgeFromBirthDate(getDateFromUTCSecondsFromEpoch(storedBirthday)))
    const birthday = age && getUTCSecondsFromEpoch(getBirthDateFromAge(age))
    mergeAttributes({ birthday, age })
  },
}

export default updateBirthdayEffect
