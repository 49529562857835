import {
  getAttributeType,
  UserAccountAttributes,
} from 'gatsby-plugin-8fit-user-account'

export enum SegmentType {
  SignupSegmentOnboarding = 'SignupSegmentOnboarding',
  SignupSegmentAccountCreation = 'SignupSegmentAccountCreation',
  SignupSegmentCompletion = 'SignupSegmentCompletion',
}

export enum ScreenType {
  SignupScreenQuestionnaire = 'SignupScreenQuestionnaire',
  SignupScreenBodyfat = 'SignupScreenBodyfat',
  SignupScreenSignup = 'SignupScreenSignup',
  SignupScreenPlansOverview = 'SignupScreenPlansOverview',
  SignupScreenPrepaidSubscription = 'SignupScreenPrepaidSubscription',
  SignupScreenCreditCardPayment = 'SignupScreenCreditCardPayment',
  SignupScreenComplete = 'SignupScreenComplete',
}

export enum SignupFieldTypes {
  SignupSelectField = 'SignupSelectField',
  SignupInputField = 'SignupInputField',
  SignupPolicyConsentField = 'SignupPolicyConsentField',
  SignupNewsletterConsentField = 'SignupNewsletterConsentField',
  SignupPolicyConsentHealthData = 'SignupPolicyConsentHealthData',
}

export enum UNIT_CHOICES {
  WEIGHT = 'weight (kg, lbs)',
  HEIGHT = 'height (ft / in, cm)',
}

export type SignupSelectButtonType = 'large' | 'small'

type selectAttributeName = 'gender' | 'goal'

export interface SignupSelectFieldInterface {
  fieldType: SignupFieldTypes.SignupSelectField
  name: selectAttributeName
  verticalLayout: boolean
  buttonType: SignupSelectButtonType
  choices: {
    value: UserAccountAttributes['gender'] | UserAccountAttributes['goal']
    text: string
  }[]
}

type inputAttributeName =
  | 'age'
  | 'email'
  | 'goal'
  | 'height_cm'
  | 'name'
  | 'weight_gr'

export interface SignupInputFieldInterface {
  fieldType: SignupFieldTypes.SignupInputField
  name: inputAttributeName
  label: string
  type: string
  unitChoice: UNIT_CHOICES
  minValue: number
  maxValue: number
}

export interface SignupPolicyConsentFieldInterface {
  fieldType: SignupFieldTypes.SignupPolicyConsentField
  name: 'policy_last_accepted_at'
  text: string
}

export interface SignupPolicyConsentHealthDataInterface {
  fieldType: SignupFieldTypes.SignupPolicyConsentHealthData
  name: 'policy_health_data_consent'
  text: string
}

export interface SignupNewsletterConsentFieldInterface {
  fieldType: SignupFieldTypes.SignupNewsletterConsentField
  name: 'newsletter_sign_up'
  text: string
}

export type SignupField =
  | SignupSelectFieldInterface
  | SignupInputFieldInterface
  | SignupPolicyConsentFieldInterface
  | SignupPolicyConsentHealthDataInterface
  | SignupNewsletterConsentFieldInterface

export interface Screen {
  title: { title: string } | string
  slug: string
  path: string // will be added later from 'process-signup-data'
  screenIndex?: number
  step: number
  isCurrentScreen: boolean
}

export interface SignupScreenQuestionnaire extends Screen {
  screenType: ScreenType.SignupScreenQuestionnaire
  description: string
  questions: (
    | SignupSelectFieldInterface
    | SignupInputFieldInterface
    | SignupPolicyConsentFieldInterface
    | SignupPolicyConsentHealthDataInterface
  )[]
}

export interface SignupScreenBodyfat extends Screen {
  screenType: ScreenType.SignupScreenBodyfat
  description: string
  label: string
  name: string
  tooltipText: string
}

export interface SignupScreenSignup extends Screen {
  screenType: ScreenType.SignupScreenSignup
  questions: (
    | SignupSelectFieldInterface
    | SignupInputFieldInterface
    | SignupPolicyConsentFieldInterface
    | SignupPolicyConsentHealthDataInterface
  )[]
}

export interface SignupScreenPlansOverview extends Screen {
  screenType: ScreenType.SignupScreenPlansOverview
}

export interface SignupScreenPrepaidSubscription extends Screen {
  screenType: ScreenType.SignupScreenPrepaidSubscription
}

export interface SignupScreenCreditCardPayment extends Screen {
  screenType: ScreenType.SignupScreenCreditCardPayment
}

export interface SignupScreenComplete extends Screen {
  screenType: ScreenType.SignupScreenComplete
}

export type SignupScreenType =
  | SignupScreenQuestionnaire
  | SignupScreenBodyfat
  | SignupScreenSignup
  | SignupScreenPlansOverview
  | SignupScreenPrepaidSubscription
  | SignupScreenCreditCardPayment
  | SignupScreenComplete

export interface SignupSegmentOnboarding {
  segmentType: SegmentType.SignupSegmentOnboarding
  title: { title: string } | string
  screens: (SignupScreenQuestionnaire | SignupScreenBodyfat)[]
}

export interface SignupSegmentAccountCreation {
  segmentType: SegmentType.SignupSegmentAccountCreation
  signupScreen: SignupScreenSignup
  emailSignupScreen: SignupScreenQuestionnaire
  oAuthPolicyConfirmationScreen: SignupScreenQuestionnaire
}

export interface SignupSegmentCompletion {
  segmentType: SegmentType.SignupSegmentCompletion
  plansScreen: SignupScreenPlansOverview
  prepaidSubscriptionScreen?: SignupScreenPrepaidSubscription
  creditCardScreen: SignupScreenCreditCardPayment
  finalScreen: SignupScreenComplete
}

export type SignupSegmentType =
  | SignupSegmentOnboarding
  | SignupSegmentAccountCreation
  | SignupSegmentCompletion

export interface SegmentScreen {
  slug: string
  segment: SignupSegmentType
  segments: SegmentScreen[]
  segmentIndex: number
  screen: SignupScreenType
  screenIndex: number
  fields: (SignupField | SignupScreenType)[]
  nextScreens: (SegmentScreen | undefined)[]
  prevScreens: (SegmentScreen | undefined)[]
  getPrevScreen: (fn: getAttributeType) => SegmentScreen | undefined
  getNextScreen: (fn: getAttributeType) => SegmentScreen | undefined
}

export type getUserAccountAttributeType = getAttributeType

export interface SignupError extends EF.ErrorWithReadableMessage {
  errorKey: 'email_taken' | 'invalid_token' | 'not_permitted'
  errorMessages: string
}

export interface StripeError extends Error {
  message: string
}
