import React, { FunctionComponent } from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'

import LegalLPFooter from './legal-lp-footer'
import SharingFooter, { SharingFooterProps } from './sharing-footer'

interface PCMFooterProps {
  description: SharingFooterProps['description']
  mediaUrl: SharingFooterProps['mediaUrl']
}

const PCMFooter: FunctionComponent<PCMFooterProps> = (props) => (
  <footer>
    <SharingFooter {...props} title={i18n._(t`Sharing is caring`)} />
    <LegalLPFooter />
  </footer>
)

export default PCMFooter
