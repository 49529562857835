import React, { FunctionComponent } from 'react'
import { H, DocumentOutlineSection } from 'react-document-section'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { classNames } from 'utils/dom'
import WebsiteLayout from 'components/layout/website'
import PressReviewCarousel from 'components/press-review-carousel'
import ScrollIndicator from 'components/scroll-indicator'
import { SecondaryButton } from 'components/Buttons'

import {
  root,
  pageSection,
  headerSection,
  downloadsSection,
  pageHeader,
  sectionHeader,
  pressReviewSection,
  downloadCards,
  downloadCard,
  downloadCardFigure,
  downloadCardImageContainer,
  downloadCardBitmapImage,
  downloadCardVectorImage,
  downloadCardLabel,
} from './press.module.scss'

interface DownloadableAsset {
  url: string
  fileName: string
}

interface VectorImage {
  publicURL: string
}

const path = '/press/'

const sectionClassName = (name: string) => classNames([pageSection, name])

const imageIsFixedBitmapImage = (
  image: EF.LocalFluidImage | VectorImage
): image is EF.LocalFluidImage =>
  !!(image as EF.LocalFluidImage).childImageSharp

const getDownloadURL = (url: string) => {
  // Contentful URLs are protocol relative. Add "https://" to the front
  return url.replace(/^\/\//, 'https://')
}

interface DownloadCardProps {
  label: string
  asset: DownloadableAsset
  image: EF.LocalFluidImage | VectorImage
}

const DownloadCard: FunctionComponent<DownloadCardProps> = ({
  label,
  asset,
  image,
}) => (
  <a
    className={downloadCard}
    href={getDownloadURL(asset.url)}
    download={`${asset.fileName}`}
  >
    <figure className={downloadCardFigure}>
      <div className={downloadCardImageContainer}>
        {imageIsFixedBitmapImage(image) ? (
          <Image
            className={downloadCardBitmapImage}
            fluid={image.childImageSharp.fluid}
            alt={label}
          />
        ) : (
          <img
            className={downloadCardVectorImage}
            src={image.publicURL}
            alt={label}
            role="presentation"
          />
        )}
      </div>
      <figcaption className={downloadCardLabel}>{label}</figcaption>
    </figure>
    <SecondaryButton text={i18n._(t`Download`)} />
  </a>
)

interface PageQueryResult {
  logosImage: EF.LocalFluidImage
  logosDownload: { file: DownloadableAsset }
  appScreenshotsImage: EF.LocalFluidImage
  appScreenshotsDownload: { file: DownloadableAsset }
  lifestyleImagesImage: EF.LocalFluidImage
  lifestyleImagesDownload: { file: DownloadableAsset }
}

export const pageQuery = graphql`
  {
    logosImage: file(
      relativeDirectory: { eq: "press" }
      name: { eq: "8fit-logos" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 166, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logosDownload: contentfulAsset(
      file: {
        contentType: { eq: "application/zip" }
        fileName: { eq: "8fit-logos.zip" }
      }
    ) {
      file {
        url
        fileName
      }
    }
    appScreenshotsImage: file(
      relativeDirectory: { eq: "press" }
      name: { eq: "8fit-app-screenshots" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 166, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appScreenshotsDownload: contentfulAsset(
      file: {
        contentType: { eq: "application/zip" }
        fileName: { eq: "8fit-app-screenshots.zip" }
      }
    ) {
      file {
        url
        fileName
      }
    }
    lifestyleImagesImage: file(
      relativeDirectory: { eq: "press" }
      name: { eq: "8fit-lifestyle-images" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 166, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lifestyleImagesDownload: contentfulAsset(
      file: {
        contentType: { eq: "application/zip" }
        fileName: { eq: "8fit-lifestyle-images.zip" }
      }
    ) {
      file {
        url
        fileName
      }
    }
  }
`

interface PressPageProps {
  data: PageQueryResult
}

const PressPage: FunctionComponent<PressPageProps> = ({ data }) => (
  <WebsiteLayout
    hrefLang={[]}
    defaultLang={{ fields: { path } }}
    title={i18n._(t`Press & Media`)}
  >
    <main className={root}>
      <section className={sectionClassName(headerSection)}>
        <H data-testid="pageTitle" className={pageHeader} />
        <p>
          {i18n._(t`For press inquiries, we’re at`)}{' '}
          <a href="mailto:media@8fit.com">media@8fit.com</a>
        </p>
        <ScrollIndicator isShifted />
      </section>
      <section className={sectionClassName(pressReviewSection)}>
        <DocumentOutlineSection title={i18n._(t`In the news`)}>
          <H className={sectionHeader} />
          <PressReviewCarousel />
        </DocumentOutlineSection>
      </section>
      <section className={sectionClassName(downloadsSection)}>
        <DocumentOutlineSection title={i18n._(t`Media assets`)}>
          <H className={sectionHeader} />
          <div className={downloadCards}>
            <DownloadCard
              label={i18n._(t`8fit Logos`)}
              image={data.logosImage}
              asset={data.logosDownload.file}
            />
            <DownloadCard
              label={i18n._(t`App Screenshots`)}
              image={data.appScreenshotsImage}
              asset={data.appScreenshotsDownload.file}
            />
            <DownloadCard
              label={i18n._(t`Lifestyle Images`)}
              image={data.lifestyleImagesImage}
              asset={data.lifestyleImagesDownload.file}
            />
          </div>
        </DocumentOutlineSection>
      </section>
    </main>
  </WebsiteLayout>
)

export default PressPage
