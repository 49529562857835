import { isFiniteNumber } from 'utils/number'
import { parse } from 'utils/url'

export const parseSearch = (search?: string) => {
  const { user_id: userId, score } = parse<'user_id' | 'score'>(search)
  const scoreNum = Number(score)

  return {
    userId: /^[0-9a-zA-Z-_]+$/.test(userId || '') ? userId : undefined,
    score: isFiniteNumber(scoreNum) ? scoreNum : undefined,
  }
}
