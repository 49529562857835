const defaultLocale = 'en-US'
const defaultLanguage = 'en'
const supportedLocales = [
  {
    locale: defaultLocale,
    pathPrefix: '/',
    publicLocale: defaultLanguage,
    backendLocale: 'en',
    language: 'en',
    territory: 'US',
    name: 'English',
  },
  {
    locale: 'de',
    pathPrefix: '/de/',
    publicLocale: 'de-DE',
    backendLocale: 'de',
    language: 'de',
    territory: 'DE',
    name: 'Deutsch',
  },
  {
    locale: 'fr',
    pathPrefix: '/fr/',
    publicLocale: 'fr',
    backendLocale: 'fr',
    language: 'fr',
    territory: 'FR',
    name: 'French',
  },
  {
    locale: 'es',
    pathPrefix: '/es/',
    publicLocale: 'es',
    backendLocale: 'es',
    language: 'es',
    territory: 'ES',
    name: 'Español',
  },
  {
    locale: 'pt-BR',
    pathPrefix: '/pt-br/',
    publicLocale: 'pt-BR',
    backendLocale: 'pt_BR',
    language: 'pt',
    territory: 'BR',
    name: 'Português',
  },
  {
    locale: 'it',
    pathPrefix: '/it/',
    publicLocale: 'it',
    backendLocale: 'it',
    language: 'it',
    territory: 'IT',
    name: 'Italiano',
  },
]

const pathPrefixPattern = new RegExp(
  `^(${supportedLocales
    .map(({ pathPrefix }) => pathPrefix)
    .reverse()
    .join('|')})`
).source

module.exports = {
  defaultLocale,
  defaultLanguage,
  supportedLocales,
  pathPrefixPattern,
}
