import React from 'react'
import type { FunctionComponent } from 'react'

import { useMatchMedia } from 'hooks'
import { breakpoints } from 'components/grid'
import Carousel from 'components/carousel'
import { classNames } from 'utils/dom'

import { wrapper, item } from './index.module.scss'

interface Props {
  wrapperClassName?: string
  itemClassName?: string
}

/**
 * This is a weird name for a component. So, some more description: This is a
 * carousel which goes across the entire screen but makes sure that the first
 * and last items are aligned with the grid. On ≥lg the first item includes an
 * offset of 1 column.
 */
const CarouselInGrid: FunctionComponent<Props> = ({
  children,
  wrapperClassName,
  itemClassName,
}) => {
  const { matches: isLarge } = useMatchMedia(`(min-width: ${breakpoints.lg}px)`)
  const { matches: isExtraLarge } = useMatchMedia(
    `(min-width: ${breakpoints.xl}px)`
  )

  return (
    <div className={classNames([wrapper, wrapperClassName])}>
      <Carousel
        keyPrefix="carousel-in-grid"
        gap={isLarge ? 40 : 15}
        showDots
        hanger={isExtraLarge ? '0px' : undefined}
        defaultIndex={0}
      >
        {React.Children.map(children, (child, i) => (
          <div
            key={i}
            draggable={false}
            className={classNames([item, itemClassName])}
          >
            {child}
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default CarouselInGrid
