import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { DocumentOutlineSection, H } from 'react-document-section'

import SeemlessWebsiteLayout from 'components/layout/seamless-website'
import FixedCta from 'components/fixed-cta'
import { Row, Col, Container } from 'components/grid'
import iconAudacious from 'assets/images/about/icon-audacious.svg'
import iconCompassionate from 'assets/images/about/icon-compassionate.svg'
import iconAccountable from 'assets/images/about/icon-accountable.svg'
import iconFun from 'assets/images/about/icon-fun.svg'
import UserJourneySection from 'components/user-journey/about'
import CloudyDecoratedSection from 'components/cloudy-decorated-section'
import Hreflang from 'components/meta/Hreflang'

import Header from './header'
import Values, { ValueItem } from './values'
import UspSection from './usp-section'
import {
  root,
  title as titleStyle,
  headlineSection,
  headlineText,
  sectionTitle,
  sectionText,
  whoWeAreSection,
  valuesSection,
  uspSection,
  userJourneySection,
} from './index.module.scss'

interface Props {
  data: {
    contentfulAboutPage: {
      fields: {
        path: string
        pageTitle: string
        defaultLang: EF.DefaultLangNode
        hrefLang: EF.HrefLangNode[]
      }
      featureImage: EF.FluidImageNode
      title: string
      headline: {
        headline: string
      }
      whoWeAreTitle: string
      whoWeAreDescription: {
        whoWeAreDescription: string
      }
      valuesTitle: string
      value1Title: string
      value1Description: {
        value1Description: string
      }
      value2Title: string
      value2Description: {
        value2Description: string
      }
      value3Title: string
      value3Description: {
        value3Description: string
      }
      value4Title: string
      value4Description: {
        value4Description: string
      }
      uspTitle: string
    }
  }
}

const Section: FunctionComponent<{
  className: string
  title?: string
}> = ({ children, className = '', title }) => (
  <DocumentOutlineSection title={title}>
    <section className={className}>
      <Container>
        <Row>
          <Col xs={12} xl={10} xlOffset={1}>
            <H className={sectionTitle} />
            {children}
          </Col>
        </Row>
      </Container>
    </section>
  </DocumentOutlineSection>
)

const AboutPage: FunctionComponent<Props> = ({
  data: {
    contentfulAboutPage: {
      fields: { pageTitle, hrefLang, defaultLang },
      featureImage,
      title,
      headline: { headline },
      whoWeAreTitle,
      whoWeAreDescription: { whoWeAreDescription },
      valuesTitle,
      value1Title,
      value1Description: { value1Description },
      value2Title,
      value2Description: { value2Description },
      value3Title,
      value3Description: { value3Description },
      value4Title,
      value4Description: { value4Description },
      uspTitle,
    },
  },
}) => {
  const valuesList: ValueItem[] = [
    {
      title: value1Title,
      description: value1Description,
      icon: iconAudacious,
    },
    {
      title: value2Title,
      description: value2Description,
      icon: iconCompassionate,
    },
    {
      title: value3Title,
      description: value3Description,
      icon: iconAccountable,
    },
    {
      title: value4Title,
      description: value4Description,
      icon: iconFun,
    },
  ]

  return (
    <SeemlessWebsiteLayout
      hrefLang={hrefLang}
      defaultLang={defaultLang}
      title={title}
      pageTitle={pageTitle}
    >
      <Hreflang />
      <main className={root}>
        <Header image={featureImage.fields.asset.fluid} />
        <FixedCta />
        <section className={headlineSection}>
          <Container>
            <Row>
              <Col xs={12} xl={10} xlOffset={1}>
                <H className={titleStyle}>{title}</H>
                <p className={headlineText}>{headline}</p>
              </Col>
            </Row>
          </Container>
        </section>
        <CloudyDecoratedSection>
          <Section className={whoWeAreSection} title={whoWeAreTitle}>
            <p className={sectionText}>{whoWeAreDescription}</p>
          </Section>
        </CloudyDecoratedSection>
        <Section className={valuesSection} title={valuesTitle}>
          <Values valuesList={valuesList} />
        </Section>
        <DocumentOutlineSection title={uspTitle}>
          <CloudyDecoratedSection className={uspSection}>
            <H className={sectionTitle} />
            <UspSection />
          </CloudyDecoratedSection>
        </DocumentOutlineSection>
        <UserJourneySection className={userJourneySection} />
      </main>
    </SeemlessWebsiteLayout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    contentfulAboutPage(id: { eq: $id }) {
      fields {
        path
        pageTitle
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
      featureImage {
        title
        fields {
          asset {
            fluid(
              maxWidth: 1750
              sizes: "(min-width: 1750px) 1750px, (min-width: 600px) 100vw, 600px"
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      title
      headline {
        headline
      }
      whoWeAreTitle
      whoWeAreDescription {
        whoWeAreDescription
      }
      valuesTitle
      value1Title
      value1Description {
        value1Description
      }
      value2Title
      value2Description {
        value2Description
      }
      value3Title
      value3Description {
        value3Description
      }
      value4Title
      value4Description {
        value4Description
      }
      uspTitle
    }
  }
`
