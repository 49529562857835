import { useEffect, ReactElement } from 'react'
import { t } from '@lingui/macro'
import { navigate } from 'gatsby'

import {
  useUserAccount,
  AuthenticationState,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'

const AuthenticatedPage = ({ children }: { children: ReactElement }) => {
  const { authenticationState } = useUserAccount()

  useEffect(() => {
    if (authenticationState !== AuthenticationState.Authenticated) {
      navigate(i18n._(t`/login/`))
    }
  }, [authenticationState])

  return authenticationState === AuthenticationState.Authenticated
    ? children
    : null
}

export default AuthenticatedPage
