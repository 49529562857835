import React from 'react'
import { FixedObject } from 'gatsby-image'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import DurationLabel from 'components/labels/duration-label'
import CategoryLabel from 'components/labels/category-label'
import ActivityCard from 'components/activity-card'

interface ArticlesCardProps {
  linkTo: string
  title: string
  image: FixedObject
  durationMins: number
  category: {
    displayName: string
    identifier: string
  }
}

const ArticlesCard = ({
  linkTo,
  title,
  image,
  durationMins,
  category,
}: ArticlesCardProps) => {
  const metaInfoList = [
    {
      key: i18n._(t('homepage.articles_section.duration')`Duration`),
      value: <DurationLabel min={durationMins} />,
    },
    {
      key: i18n._(t('homepage.articles_section.category')`Category`),
      value: (
        <CategoryLabel
          category={category.displayName}
          identifier={category.identifier}
        />
      ),
    },
  ] as const

  return (
    <ActivityCard
      linkTo={linkTo}
      wave={4}
      image={image}
      title={title}
      metaInfoList={metaInfoList}
      text=""
    />
  )
}

export default ArticlesCard
