import GatsbyImage, { FluidObject } from 'gatsby-image'
import React from 'react'

import { root, image as imageStyle, wave } from './header.module.scss'

interface Props {
  image: FluidObject
  focalArea: 'middle' | 'top' | null
}

const Header = ({ image, focalArea }: Props) => {
  return (
    <header className={root} data-focal-area={focalArea}>
      <div className={imageStyle}>
        <GatsbyImage fluid={image} loading="eager" />
      </div>
      <div className={wave} />
    </header>
  )
}

export default Header
