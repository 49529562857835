import React from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { classNames } from 'utils/dom'
import { PrimaryButton } from 'components/Buttons'

import { enOnlyLabel, playButton } from './atomic-components.module.scss'

export const EnOnlyLabel = () => {
  return (
    <span className={enOnlyLabel}>
      {i18n._(t('en_only.label')`In English`)}
    </span>
  )
}

export const PlayButton = ({
  className,
  onClick,
}: {
  className?: string
  onClick: () => void
}) => {
  return (
    <PrimaryButton
      aria-label={i18n._(t('workout_page.play_button')`Play`)}
      title={i18n._(t('workout_page.play_button')`Play`)}
      text={`► ${i18n._(t('workout_page.play_button')`Play`)}`}
      className={classNames([playButton, className])}
      onClick={onClick}
    />
  )
}
