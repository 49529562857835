import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  FunctionComponent,
} from 'react'
import { upperFirst } from 'lodash/fp'

import { classNames } from 'utils/dom'

export type BreakpointName = 'sm' | 'md' | 'lg' | 'xl'
export type ColSizeName = 'xs' | BreakpointName
export type Modifier =
  | 'start'
  | 'center'
  | 'end'
  | 'top'
  | 'middle'
  | 'bottom'
  | 'around'
  | 'between'
  | 'reverse'

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
} as const

const camelizeClassName = (names: string[] = []) =>
  names.map((name, i) => (i > 0 ? upperFirst(name) : name)).join('')

export const getClassname = (
  parts: string[] = [],
  stylesheet: { [key: string]: string }
) => stylesheet[camelizeClassName(parts)]

export const colSizeNames: ColSizeName[] = ['xs', 'sm', 'md', 'lg', 'xl']
export const modifiers: Modifier[] = [
  'start',
  'center',
  'end',
  'top',
  'middle',
  'bottom',
  'around',
  'between',
  'reverse',
]

export type HTMLElementProps = DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>

export interface VesselProps extends HTMLElementProps {
  gridClassNames?: string[]
  tagName?: string
}

export const Vessel: FunctionComponent<VesselProps> = ({
  gridClassNames = [],
  tagName = 'div',
  className = '',
  ...props
}) => {
  const Comp = tagName as React.ElementType<HTMLElementProps>

  return (
    <Comp className={classNames([...gridClassNames, className])} {...props} />
  )
}
