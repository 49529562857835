// extracted by mini-css-extract-plugin
export var backButton = "jalB3";
export var completionSegmentBoxRoot = "WFatK";
export var header = "VV2ES";
export var homepageLink = "zs1Kl";
export var logo = "iYGpB";
export var navigationContainer = "F7kyY";
export var onboardingBox = "_6Un-e";
export var onboardingRoot = "fc62v";
export var onboardingScreens = "r4lxR";
export var segmentWrapper = "NdVP9";