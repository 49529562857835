import React from 'react'
import { graphql } from 'gatsby'
import { DocumentOutlineSection, H } from 'react-document-section'
import { Trans, t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import WebsiteLayout from 'components/layout/website'
import MetaTagRobots from 'components/meta/robots'
import OpenGraphTags from 'components/meta/open-graph-tags'
import Hreflang from 'components/meta/Hreflang'
import SeoIndexPageHeader from 'components/headers/seoIndexPage'
import Breadcrumb from 'components/breadcrumb'
import FixedCta from 'components/fixed-cta'
import PillNavigation from 'components/pillNavigation'
import ArticlePreviewList from 'components/articlePreview/list'
import Pagination from 'components/pagination'
import { Container, Row, Col, ColProps } from 'components/grid'

import { CategoryPageQueryResult } from './types'
import {
  header as headerStyle,
  descriptionParagraph,
  breadcrumb as breadcrumbStyle,
  subcategoryNavigation,
  categoryPageTitle,
} from './categorySubcategory.module.scss'

const ContainerRowCol = (props: ColProps) => (
  <Container>
    <Row>
      <Col {...props} />
    </Row>
  </Container>
)

const CategorySubcategoryPage = ({
  data: {
    categoryPageData: {
      node_locale: locale,
      title,
      metaDescriptionForSeo: { metaDescriptionForSeo },
      description: { description },
      parentCategory: { siblingCategories },
      subcategories,
      fields: {
        isSubCategory,
        path,
        pageTitle,
        imageMobile,
        imageDesktop,
        ogImage,
        breadcrumb,
        hrefLang,
        defaultLang,
      },
    },
    listOfArticlesData,
  },
  pageContext: { numberOfPages, pageNumber },
}: CategoryPageQueryResult) => (
  <WebsiteLayout
    title={title}
    pageTitle={pageTitle}
    description={metaDescriptionForSeo}
    hrefLang={hrefLang}
    defaultLang={defaultLang}
  >
    <MetaTagRobots noindex={false} nofollow={false} />
    <OpenGraphTags
      title={pageTitle}
      description={metaDescriptionForSeo}
      image={ogImage ? ogImage.fields.asset.resize : null}
      locale={locale}
    />
    <Hreflang />
    <SeoIndexPageHeader
      className={headerStyle}
      imgMobile={imageMobile?.fields.asset.fixed}
      imgDesktop={imageDesktop?.fields.asset.fixed}
    >
      {description.split(/\n+/).map((paragraph, i) => (
        <p key={i} className={descriptionParagraph}>
          {paragraph.trim()}
        </p>
      ))}
    </SeoIndexPageHeader>
    <div className={breadcrumbStyle}>
      <Container>
        <Row>
          <Col>
            <Breadcrumb
              items={breadcrumb.map(({ text, fields: { href } }) => ({
                text,
                href,
              }))}
            />
          </Col>
        </Row>
      </Container>
    </div>
    <FixedCta visibleAfterScrollY={50} />
    <div className={subcategoryNavigation}>
      <Container>
        <Row>
          <Col>
            <PillNavigation
              aria-label={i18n._(t`Subcategory Navigation`)}
              items={(isSubCategory ? siblingCategories : subcategories).map(
                ({ text, fields: { href } }) => ({
                  text,
                  href,
                })
              )}
            />
          </Col>
        </Row>
      </Container>
    </div>
    <DocumentOutlineSection title={i18n._(t`Articles in this category`)}>
      <main>
        <H className={categoryPageTitle} />
        {listOfArticlesData?.edges.length > 0 ? (
          <ArticlePreviewList articleList={listOfArticlesData.edges} />
        ) : (
          <ContainerRowCol>
            <p>
              <Trans id="no-article">
                There are no articles in this category.
              </Trans>
            </p>
          </ContainerRowCol>
        )}
        {numberOfPages > 1 ? (
          <ContainerRowCol>
            <Pagination
              totalPageCount={numberOfPages}
              currentPage={pageNumber}
              basePath={path}
              withMetatags
            />
          </ContainerRowCol>
        ) : null}
      </main>
    </DocumentOutlineSection>
  </WebsiteLayout>
)

export default CategorySubcategoryPage

export const pageQuery = graphql`
  query CategorySubcategoryPage(
    $id: String!
    $slug: String!
    $skip: Int!
    $limit: Int!
    $locale: String!
  ) {
    categoryPageData: contentfulCategory(id: { eq: $id }) {
      node_locale
      title
      metaDescriptionForSeo {
        metaDescriptionForSeo
      }
      description {
        description
      }
      parentCategory {
        siblingCategories: category {
          text: displayName
          fields {
            href: path
          }
        }
      }
      subcategories: category {
        text: displayName
        fields {
          href: path
        }
      }
      fields {
        isSubCategory
        path
        pageTitle
        imageMobile {
          fields {
            asset {
              fixed(width: 130, height: 120) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
          }
        }
        imageDesktop {
          fields {
            asset {
              fixed(width: 800, height: 400, quality: 70) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
          }
        }
        ogImage: imageDesktop {
          fields {
            asset {
              title
              resize(width: 1200, height: 630, cropFocus: BOTTOM_RIGHT) {
                src
                width
                height
              }
            }
          }
        }
        breadcrumb {
          text: displayName
          fields {
            href: path
          }
        }
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
    }
    listOfArticlesData: allContentfulArticlePage(
      filter: {
        node_locale: { eq: $locale }
        category: { slug: { eq: $slug } }
        slug: { regex: "/^(?!___)/", ne: null }
        content: { raw: { ne: null } }
      }
      sort: { fields: originalPublishDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          category {
            displayName
            fields {
              path
            }
          }
          featureImage {
            title
            fields {
              asset {
                fluid(
                  maxWidth: 668
                  maxHeight: 650
                  cropFocus: CENTER
                  quality: 85
                ) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
          fields {
            path
            excerpt
          }
        }
      }
    }
  }
`
