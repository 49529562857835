import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { H } from 'react-document-section'
import { t, Trans } from '@lingui/macro'
import GatsbyImage from 'gatsby-image'

import { i18n } from 'utils/i18n'
import { scrollElementIntoView } from 'utils/dom'
import { PrimaryButton } from 'components/Buttons'
import WhiteWaveSVG from 'assets/images/signup/whiteWave.svg'
import GreenWaveSVG from 'assets/images/profile/green-wave.svg'
import Breadcrumb from 'components/breadcrumb'
import { Container, Row, Col } from 'components/grid'

import {
  background,
  breadcrumb,
  container,
  textContainer,
  title,
  description,
  scrollButton,
  headerImageContainer,
  headerImage,
  greenWave,
  whiteWave,
} from './PaywallHeader.module.scss'

const PaywallHeader = () => {
  const { phoneImage } = useStaticQuery(graphql`
    {
      phoneImage: file(name: { eq: "paywall-header" }) {
        childImageSharp {
          fixed(width: 340, height: 388) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <header className={background}>
      <img
        alt=""
        role="presentation"
        className={greenWave}
        src={GreenWaveSVG}
      />
      <img
        alt=""
        role="presentation"
        className={whiteWave}
        src={WhiteWaveSVG}
      />
      <Container className={container}>
        <Row>
          <Col lg={10} lgOffset={1} md={12} mdOffset={0} xs={12}>
            <Breadcrumb
              items={[
                {
                  text: i18n._(t('user.profile.title')`Profile`),
                  href: i18n._(t`/profile/`),
                },
                {
                  text: i18n._(
                    t('user.profile.plans_overview.title')`Upgrade your journey`
                  ),
                  href: i18n._(t`/profile/plans-overview/`),
                },
              ]}
              className={breadcrumb}
            />
          </Col>
        </Row>
        <Row reverse="md">
          <Col lg={7} md={7} xs={12} className={textContainer}>
            <H className={title} />
            <p className={description}>
              <Trans id="user.profile.upsell.text">
                Kickstart your healthier lifestyle with a personalized meal
                plan, mediation, and longer workouts.
              </Trans>
            </p>
            <PrimaryButton
              className={scrollButton}
              text={i18n._(t`Choose your plan`)}
              onClick={() => {
                scrollElementIntoView('scrollToPositionPlanSection')
              }}
            />
          </Col>
          <Col lg={4} md={5} xs={12}>
            <div className={headerImageContainer}>
              <GatsbyImage
                className={headerImage}
                fixed={phoneImage.childImageSharp.fixed}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default PaywallHeader
