// extracted by mini-css-extract-plugin
export var feedbackInput = "_5ueWE";
export var feedbackSubmitContainer = "Xw1i9";
export var maximumScoreLabel = "bUQXX";
export var minimumScoreLabel = "j6hk3";
export var root = "vqUYa";
export var scoreInput = "gcDEo";
export var scoreInputContainer = "jOXCu";
export var scoreInputLabel = "lHwqg";
export var scoreSelect = "CH58y";
export var scoreSelectInputs = "mX6qt";
export var submitButton = "_98ogW";