import React, { ReactNode } from 'react'

import { screen } from './screen.module.scss'

interface ScreenProps {
  prev: boolean
  current: boolean
  next: boolean
  slug?: string
  children: ReactNode | ReactNode[]
}

const Screen = (props: ScreenProps) => {
  const { prev, current, next, children, ...other } = props
  const shouldDisplay = prev || current || next
  return (
    <div
      data-prev={prev}
      data-current={current}
      data-next={next}
      data-displayed={shouldDisplay}
      aria-hidden={!current}
      className={screen}
      {...other}
    >
      {shouldDisplay ? children : null}
    </div>
  )
}

export default Screen
