export const getBirthDateFromAge = (
  age: number,
  fromDate: Date = new Date()
) => {
  const birthDate = new Date(fromDate.getTime())

  birthDate.setFullYear(birthDate.getFullYear() - age)

  return birthDate
}

export const getAgeFromBirthDate = (
  birthDate: Date,
  fromDate: Date = new Date()
) => fromDate.getFullYear() - birthDate.getFullYear()

export const getUTCSecondsFromEpoch = (date: Date) =>
  Math.round(date.getTime() / 1000)

export const getDateFromUTCSecondsFromEpoch = (seconds: number) =>
  new Date(seconds * 1000)

const second = 1000
const minute = 60 * second
const hour = 60 * minute

const getDateDiff = (date1: Date, date2: Date) => {
  let diff = date2.getTime() - date1.getTime()
  const hours = Math.floor(diff / hour)
  diff %= hour
  const minutes = Math.floor(diff / minute)
  diff %= minute
  const seconds = Math.floor(diff / second)
  return [hours, minutes, seconds]
}

export const getCountdownString = (date: Date, now: Date = new Date()) => {
  if (date <= now) return '00:00:00'

  return getDateDiff(now, date)
    .map((nr) => String(nr).padStart(2, '0'))
    .join(':')
}

const oneDayInMilliseconds = 24 * 60 * 60 * 1000

/**
 * Returns true if the given date is within the last 24 hours
 */
export const isDateToday = (date: Date) =>
  Math.abs(Date.now() - date.getTime()) < oneDayInMilliseconds
