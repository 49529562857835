import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import LandingPageLayout from 'components/layout/LandingPageLayout'
import { Container, Row, Col } from 'components/grid'
import Logo from 'components/logo'
import ScrollIndicator from 'components/scroll-indicator'
import { PCMPageRTF } from 'components/rich-text-field'
import SmartAppBanner from 'components/smartAppBanner'
import PCMFooter from 'components/footer/PCMFooter'
import TextWithImage from 'components/text-with-image'
import CompactUserReview from 'components/userReview/CompactUserReview'
import CompactUniqueSellingPoint from 'components/uniqueSellingPoint/CompactUniqueSellingPoint'
import { UnderlineCalloutHeader } from 'components/UnderlineText'
import AdjustLink from 'components/utils/adjust-link'

import { PCMPageQueryResult } from './pcm-page-types'
import {
  container,
  header,
  logo,
  title as titleStyle,
  description as descriptionStyle,
  headerImageContainer,
  richTextArea,
  uspWrapper,
  userReviewGroupWrapper,
  sectionTitle,
  userReviewGroupTitle,
} from './index.module.scss'

const PCMPage: FunctionComponent<PCMPageQueryResult> = ({
  data: {
    contentfulPcmPage: {
      fields: { hrefLang, defaultLang },
      title,
      pageTitle,
      description,
      featureImage: {
        title: alt,
        fields: { asset: featureImageAsset },
      },
      shareableImage: {
        fields: { asset: shareableImageAsset },
      },
      content,
      useWSFLink,
      bottomCta,
      uniqueSellingPointTitle,
      uniqueSellingPoints,
      userReviews,
    },
  },
}) => {
  return (
    <LandingPageLayout
      title={title}
      pageTitle={pageTitle || title}
      description={description}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <article className={container}>
        {!useWSFLink && <SmartAppBanner />}
        <div className={header}>
          <Container>
            <Logo className={logo} />
            <Row>
              <Col>
                <H className={titleStyle} data-testid="pageTitle" />
                <div className={descriptionStyle}>{description}</div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <div className={headerImageContainer}>
                  <Image fluid={featureImageAsset.fluid} alt={alt} critical />
                  <ScrollIndicator isShifted />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col>
              <div className={richTextArea}>
                <PCMPageRTF data={content} />
              </div>
            </Col>
          </Row>
          {uniqueSellingPointTitle && uniqueSellingPoints && (
            <Row>
              <Col>
                <div className={uspWrapper}>
                  <div className={sectionTitle}>
                    <UnderlineCalloutHeader text={uniqueSellingPointTitle} />
                  </div>
                  <div>
                    {uniqueSellingPoints.map(({ title, icon, description }) => (
                      <CompactUniqueSellingPoint
                        key={title}
                        title={title}
                        icon={icon.fields.asset}
                        description={description}
                      />
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>

        {userReviews && (
          <div className={userReviewGroupWrapper}>
            <Container>
              <Row>
                <Col>
                  <div className={userReviewGroupTitle}>
                    <UnderlineCalloutHeader text={i18n._(t`User Reviews`)} />
                  </div>
                  {userReviews.map(
                    ({ name, smallPhoto, shortReview, isPro, rating }, idx) => (
                      <CompactUserReview
                        key={`compact-user-review-${idx}`}
                        name={name}
                        photo={smallPhoto.fields.asset}
                        review={shortReview}
                        isPro={isPro}
                        rating={rating}
                      />
                    )
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {bottomCta && (
          <AdjustLink preferAndroidStore={false}>
            {(url: string) => (
              <TextWithImage
                title={bottomCta.title}
                text={bottomCta.text}
                image={bottomCta.image}
                ctaText={bottomCta.ctaText}
                ctaPath={useWSFLink ? i18n._(t`/signup/`) : url}
              />
            )}
          </AdjustLink>
        )}
      </article>
      <PCMFooter
        description={description}
        mediaUrl={shareableImageAsset.resize.src}
      />
    </LandingPageLayout>
  )
}

export default PCMPage

export const pageQuery = graphql`
  query PCMPage($id: String!) {
    contentfulPcmPage(id: { eq: $id }) {
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
      title
      pageTitle
      description
      featureImage {
        title
        fields {
          asset {
            fluid(
              maxWidth: 1220
              resizingBehavior: FILL
              cropFocus: FACES
              sizes: "(min-width: 1320px) 1220px, (min-width: 991px) calc(100vw - 100px), (min-width: 767px) calc(100vw - 40px), 100vw"
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      shareableImage: featureImage {
        fields {
          asset {
            resize(width: 1200, height: 630, cropFocus: FACES) {
              src
            }
          }
        }
      }
      useWSFLink
      bottomCta {
        title
        ctaText
        imageToTheLeft
        imageToTheTop
        image {
          ...ContentfulAsset_TextWithImage_LP
        }
        text {
          raw
        }
      }

      uniqueSellingPointTitle
      uniqueSellingPoints {
        title
        description {
          raw
        }
        icon {
          fields {
            asset {
              file {
                url
              }
            }
          }
        }
      }

      userReviews {
        name
        isPro
        rating
        shortReview
        photo {
          fields {
            asset {
              fixed(width: 200, height: 200, quality: 80) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
        smallPhoto {
          fields {
            asset {
              fixed(width: 60, height: 60, quality: 80) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }

      content {
        raw
        references {
          ... on ContentfulAsset {
            ...RTFContentfulAssetBlockFragment
            fields {
              asset {
                fluid(maxWidth: 375, resizingBehavior: FILL, cropFocus: FACES) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
          ... on ContentfulRecipe {
            ...RTFContentfulRecipeBlockFragment
          }
          ... on ContentfulDownloadCta {
            ...RTFContentfulDownloadCtaBlockFragment
          }
          ... on ContentfulSignupCta {
            ...RTFContentfulSignupCtaBlockFragment
          }
          ... on ContentfulWorkout {
            ...RTFContentfulWorkoutBlockFragment
          }
          ... on ContentfulArbitraryEmbeddedCode {
            ...RTFContentfulArbitraryEmbeddedCodeBlockFragment
          }
        }
      }
    }
  }
`
