import React, { useState, useEffect } from 'react'
import { t } from '@lingui/macro'

import {
  useUserAccount,
  AuthenticationState,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import { throttleRaf } from 'utils/dom'
import useIsOneTrustBannerClosed from 'hooks/use-is-one-trust-banner-closed'
import { useIsWebsiteHeaderDesktopVisible } from 'components/websiteHeader'
import FunnelCta from 'components/funnel-cta'

import { root } from './index.module.scss'

interface FixedCtaProps {
  visibleAfterScrollY?: number
}

const FixedCta = ({ visibleAfterScrollY = 0 }: FixedCtaProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const isWebsiteHeaderDesktopVisible = useIsWebsiteHeaderDesktopVisible()
  const { authenticationState } = useUserAccount()
  const isOneTrustBannerClosed = useIsOneTrustBannerClosed()

  useEffect(() => {
    const onScroll = throttleRaf(() => {
      const { scrollY } = window
      setIsVisible(scrollY >= visibleAfterScrollY)
    })

    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [setIsVisible, visibleAfterScrollY])

  return (
    <div
      data-visible={
        isVisible &&
        !isWebsiteHeaderDesktopVisible &&
        isOneTrustBannerClosed &&
        authenticationState !== AuthenticationState.Authenticated
      }
      className={root}
      id="fixed-cta"
    >
      <FunnelCta text={i18n._(t`Start Your Free Trial`)} />
    </div>
  )
}

export default FixedCta
