import * as Sentry from '@sentry/gatsby'

import { ConfigType, SideEffect } from './types'
import setLocaleEffect from './effects/set-locale'
import getIpInfoEffect from './effects/get-ip-info'
import saveUtmParamsEffect from './effects/save-utm-params'
import updateBirthdayEffect from './effects/update-birthday'
import setCurrencyEffect from './effects/set-currency'
import registerOnboardedEffect from './effects/register-onboarded'
import createAccountEmailEffect from './effects/create-account-email'
import initializeFacebookEffect from './effects/initialize-facebook'
import initializeFirebaseEffect from './effects/initialize-firebase'
import createAccountFacebookEffect from './effects/create-account-facebook'
import createAccountGoogleEffect from './effects/create-account-google'
import handlePlpUserEffect from './effects/handle-plp-user'
import subscribeEffect from './effects/subscribe'
import scaEffect from './effects/sca'
import afterSignupCompletionEffect from './effects/after-signup-completion'
import afterLogoutEffect from './effects/after-logout'
import afterAccountCreatedAppboyEffect from './effects/after-account-created-appboy'
import afterSignupCompletionAppboyEffect from './effects/after-signup-completion-appboy'
import updateAccountInfoEffect from './effects/update-account-info'

// This config object is filled by `setConfiguration()`
const config: ConfigType = {}

// This is the source list containing all side effects. It's meant to not
// change over time.
const allSideEffects: SideEffect[] = [
  setLocaleEffect,
  getIpInfoEffect,
  saveUtmParamsEffect,
  updateBirthdayEffect,
  setCurrencyEffect,
  registerOnboardedEffect,
  createAccountEmailEffect,
  initializeFacebookEffect,
  initializeFirebaseEffect,
  createAccountFacebookEffect,
  createAccountGoogleEffect,
  afterAccountCreatedAppboyEffect,
  handlePlpUserEffect,
  updateAccountInfoEffect,
  subscribeEffect,
  scaEffect,
  afterSignupCompletionEffect,
  afterSignupCompletionAppboyEffect,
  afterLogoutEffect,
]

// This is the list of side effects that didn't run yet. Initially it contains
// all side effects. When a side effect is about to be executed it's removed
// from the list
let upcomingSideEffects = [...allSideEffects]

// Run on logout. Reset the list to its initial state of containing all side
// effects.
const resetSideEffects = () => {
  allSideEffects.forEach(({ reset }) => {
    if (reset) reset()
  })
  upcomingSideEffects = [...allSideEffects]
}

// Extract the side effects whose condition is true from the list of upcoming
// side effects.
const extractNextSideEffects = () => {
  const [newUpcomingSideEffects, nextSideEffects] = upcomingSideEffects.reduce<
    [SideEffect[], SideEffect[]]
  >(
    ([remaining, next], effect) => {
      if (effect.condition(config)) {
        next.push(effect)
        if (effect.isRecurring) {
          remaining.push(effect)
        }
      } else {
        remaining.push(effect)
      }
      return [remaining, next]
    },
    [[], []]
  )
  upcomingSideEffects = newUpcomingSideEffects
  return nextSideEffects
}

const runInSilence = async (promiseFn: () => Promise<void> | void) => {
  try {
    await promiseFn()
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'WSF side effect',
      data: err,
      level: Sentry.Severity.Warning,
    })
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
}

const executeSideEffects = (sideEffectsToExecute: SideEffect[] = []) => {
  return Promise.all(
    sideEffectsToExecute.map(({ execute, name, isSilent }) => {
      Sentry.addBreadcrumb({
        category: 'WSF side effect',
        message: name,
        level: Sentry.Severity.Info,
      })
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.info('Run side effect: ', name)
      }
      return isSilent
        ? runInSilence(() => execute(config, resetSideEffects))
        : execute(config, resetSideEffects)
    })
  )
}

export const performSideEffects = async (iterationCount = 0): Promise<void> => {
  const maxIterations = 5 // If we have more than 5 iterations it seems like
  // some side effects have an insufficient condition causing an infinite
  // recurison
  const sideEffectsToExecute = extractNextSideEffects()
  if (sideEffectsToExecute.length > 0) {
    if (iterationCount < maxIterations) {
      await executeSideEffects(sideEffectsToExecute)
      return performSideEffects(++iterationCount)
    } else {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(`Aborted side effects at ${iterationCount} iterations`)
      }
      Sentry.addBreadcrumb({
        category: 'WSF side effect',
        message: `Aborted side effects at ${iterationCount} iterations`,
        data: {
          queuedSideEffects: sideEffectsToExecute
            .map(({ name }) => name)
            .join(' '),
        },
        level: Sentry.Severity.Error,
      })
    }
  }
}

export const setConfiguration = (_config: ConfigType) => {
  Object.assign(config, _config)
}

export default performSideEffects
