import React, { useEffect, FunctionComponent, useState } from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@gatsbyjs/reach-router'
import { H } from 'react-document-section'
import Image from 'gatsby-image'

import { isFiniteNumber } from 'utils/number'
import Logo from 'components/logo'
import LandingPageLayout from 'components/layout/LandingPageLayout'

import { NPSPageQueryResult } from './nps-page-types'
import { parseSearch } from './nps-page-lib'
import NPSPageEntryForm from './nps-page-entry-form'
import {
  root,
  header,
  content,
  logo,
  title,
  entryContainer,
  successContainer,
  successImage,
  background,
  blueberries,
  equipment,
} from './index.module.scss'

const NPSPage: FunctionComponent<{ data: NPSPageQueryResult }> = ({
  data: { blueberryImage, equipmentImage, contentfulNpsPage: page },
}) => {
  const { search } = useLocation()
  const { score: initialScore } = parseSearch(search)

  const [canSubmit, setCanSubmit] = useState(false)
  const [didSubmit, setDidSubmit] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [score, setScore] = useState<number | undefined>()

  const handleScore = (score?: number) => {
    if (isFiniteNumber(score)) {
      setScore(score)
      setCanSubmit(true)
    }
  }

  // Hydration on production deployment was failing, hack to force score
  // update
  useEffect(() => {
    handleScore(initialScore)
  }, [initialScore])

  return (
    <LandingPageLayout
      title={didSubmit ? page.submitSuccessMessage : page.title}
      hrefLang={page.fields.hrefLang}
      defaultLang={page.fields.defaultLang}
    >
      <div className={root}>
        <div className={header}>
          <Logo className={logo} />
        </div>
        <div className={content}>
          {didSubmit ? (
            <div data-testid="npsPageSuccess" className={successContainer}>
              <H data-testid="npsPageTitle" className={title} />
              <div className={successImage}>
                <Image fluid={page.submitSuccessImage.fields.asset.fluid} />
              </div>
            </div>
          ) : (
            <div className={entryContainer}>
              <H data-testid="npsPageTitle" className={title} />
              <NPSPageEntryForm
                page={page}
                score={score}
                feedback={feedback}
                setScore={(score) => {
                  handleScore(score)
                }}
                setFeedback={setFeedback}
                canSubmit={canSubmit}
                // Submit feedback only when the submit button is pressed
                handleSubmit={(event) => {
                  event.preventDefault()
                  isFiniteNumber(score) && setDidSubmit(true)
                }}
              />
            </div>
          )}
        </div>
        <div className={background}>
          <div className={blueberries}>
            <Image fluid={blueberryImage.childImageSharp.fluid} />
          </div>
          <div className={equipment}>
            <Image fluid={equipmentImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </LandingPageLayout>
  )
}

export default NPSPage

export const pageQuery = graphql`
  query NPSPage($id: String!) {
    contentfulNpsPage(id: { eq: $id }) {
      title
      minimumScore
      minimumScoreLabel
      maximumScore
      maximumScoreLabel
      feedbackEntryTitle
      feedbackEntryPlaceholder
      submitCtaLabel
      submitSuccessMessage
      submitSuccessImage {
        fields {
          asset {
            fluid(maxWidth: 438) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
    }

    blueberryImage: file(name: { eq: "nps-background-blueberries" }) {
      childImageSharp {
        fluid(maxHeight: 210) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    equipmentImage: file(name: { eq: "nps-background-equipment" }) {
      childImageSharp {
        fluid(maxHeight: 334) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
