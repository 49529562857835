import { useLocation } from '@gatsbyjs/reach-router'

import { getCurrentI18nInfo, supportedLocales } from 'utils/i18n'

export default function useLanguageSelector(hrefLang: EF.HrefLangNode[]) {
  const { pathname } = useLocation()
  const currentI18nInfo = getCurrentI18nInfo()

  const hrefLangMap = hrefLang.reduce(
    (acc, { node_locale: nodeLocale, fields: { path } }) => {
      return { ...acc, [nodeLocale]: path }
    },
    {} as { [key: string]: string }
  )

  const options = supportedLocales
    .map(({ locale, name }) => {
      if (locale === currentI18nInfo.locale) {
        return {
          text: name,
          value: pathname,
        }
      } else if (locale in hrefLangMap) {
        return {
          text: name,
          value: hrefLangMap[locale],
        }
      }
      return undefined
    })
    .filter(Boolean) as { text: string; value: string }[]

  return {
    currentValue: pathname,
    displayValue: currentI18nInfo?.language,
    options,
  }
}
