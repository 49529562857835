import { useEffect } from 'react'

const useScheduledCallback = (
  cb: () => unknown,
  scheduleTime: Date,
  otherProps: unknown[] = []
) => {
  useEffect(() => {
    const diff = scheduleTime.getTime() - Date.now()
    if (diff > 0) {
      const timeout = setTimeout(cb, diff)
      return () => clearTimeout(timeout)
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cb, scheduleTime, ...otherProps])
}

export default useScheduledCallback
