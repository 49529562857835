import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import { FixedObject } from 'gatsby-image'

import ImageWithWave, { WaveType } from './image-with-wave'
import {
  root,
  container,
  content,
  metaRow,
  metaList,
  metaKey,
  metaValue,
  title as titleStyle,
  text as textStyle,
} from './index.module.scss'

interface MetaInfo {
  key: string
  value: ReactNode
}

interface ActivityCardProps {
  linkTo: string
  title: string
  wave: WaveType
  metaInfoList: Readonly<[MetaInfo, MetaInfo]>
  text: string
  image: FixedObject
}

const calcNrOfLines = (titleHeight: number) => {
  const lineHeight = 32
  return Math.ceil(titleHeight / lineHeight)
}

const ActivityCard = ({
  linkTo,
  image,
  wave,
  metaInfoList,
  title,
  text,
}: ActivityCardProps) => {
  const titleRef = useRef<HTMLParagraphElement>(null)
  const [nrOfTitleLines, setNrOfTitleLines] = useState(1)

  useEffect(() => {
    const titleEl = titleRef.current
    if (titleEl) {
      const { height } = titleEl.getBoundingClientRect()
      const nrOfLines = calcNrOfLines(height)
      if (nrOfLines !== nrOfTitleLines) {
        setNrOfTitleLines(nrOfLines)
      }
    }
  }, [titleRef, nrOfTitleLines])

  return (
    <Link to={linkTo} className={root} draggable={false}>
      <article className={container}>
        <ImageWithWave fixed={image} wave={wave} />
        <div className={content} data-title-line-count={nrOfTitleLines}>
          <div className={metaRow}>
            <dl className={metaList}>
              {metaInfoList.map(({ key, value }) => (
                <React.Fragment key={key}>
                  <dt className={metaKey}>{key}</dt>
                  <dd className={metaValue}>{value}</dd>
                </React.Fragment>
              ))}
            </dl>
          </div>
          <p className={titleStyle} ref={titleRef}>
            {title}
          </p>
          <p className={textStyle}>{text}</p>
        </div>
      </article>
    </Link>
  )
}

export default ActivityCard
