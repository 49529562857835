import React, { useState, ChangeEvent, useEffect, FormEvent } from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import PasswordInput from 'components/input/password-input'
import { PrimaryButton } from 'components/Buttons'

import { form } from './index.module.scss'

const PASSWORD_MIN_CHAR = 12
const PASSWORD_MAX_CHAR = 256
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,256})/

const validatePassword = (password: string) =>
  !PASSWORD_REGEX.test(password)
    ? i18n._(
        t(
          'input.password.error'
        )`must be between 12 and 256 characters and must contain a capital letter, lowercase letter and number.`
      )
    : ''

const validateMatchingPasswords = (
  password: string,
  passwordConfirmation: string
) => {
  if (password !== passwordConfirmation) {
    return i18n._(t('password_reset.match')`should match password`)
  }
  return ''
}

const getPasswordError = validatePassword

const getPasswordConfirmationError = (
  password: string,
  passwordConfirmation: string
) =>
  validatePassword(passwordConfirmation) ||
  validateMatchingPasswords(password, passwordConfirmation)

interface PasswordResetFormProps {
  disabled: boolean
  onPressPasswordReset: (newPassword: string) => Promise<void>
  success: boolean
}

const PasswordResetForm = ({
  disabled,
  onPressPasswordReset,
  success,
}: PasswordResetFormProps) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    const isValid =
      !getPasswordError(password) &&
      !getPasswordConfirmationError(password, passwordConfirmation)

    setIsValid(isValid)
  }, [password, passwordConfirmation])

  useEffect(() => {
    if (success) {
      setPassword('')
      setPasswordConfirmation('')
    }
  }, [success])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onPressPasswordReset(password)
  }

  return (
    <form className={form} onSubmit={handleSubmit}>
      <PasswordInput
        label={i18n._(t('password_reset.new')`New password`)}
        name="password"
        value={password}
        min={PASSWORD_MIN_CHAR}
        max={PASSWORD_MAX_CHAR}
        errorMessage={getPasswordError(password)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
      />
      <PasswordInput
        label={i18n._(t('password_reset.confirm')`Confirm new password`)}
        name="passwordConfirmation"
        value={passwordConfirmation}
        min={PASSWORD_MIN_CHAR}
        max={PASSWORD_MAX_CHAR}
        errorMessage={getPasswordConfirmationError(
          password,
          passwordConfirmation
        )}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPasswordConfirmation(e.target.value)
        }
      />
      <PrimaryButton
        type="submit"
        text={i18n._(t('password_reset.reset')`Reset password`)}
        disabled={disabled || !isValid}
        block
      />
    </form>
  )
}

export default PasswordResetForm
