import { graphql, useStaticQuery } from 'gatsby'

interface QueryResult {
  site: EF.Site
}

const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteName
        siteUrl
        facebookAppId
        twitterUserName
        appstoreUrl
        playstoreUrl
        firebase {
          apiKey
          authDomain
          databaseURL
          projectId
          storageBucket
          messagingSenderId
        }
      }
    }
  }
`

const useSiteMetadata = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<QueryResult>(query)
  return siteMetadata
}

export default useSiteMetadata
