import React from 'react'

import { useMatchMedia } from 'hooks'
import { useIsWebsiteHeaderDesktopVisible } from 'components/websiteHeader'
import LazyLoad from 'components/utils/lazy-load'
import FixedCta from 'components/fixed-cta'

interface FixedSignupCtaProps {
  which: 'mobile' | 'desktop'
}

const FixedSignupCta = ({ which }: FixedSignupCtaProps) => {
  const isWebsiteHeaderDesktopVisible = useIsWebsiteHeaderDesktopVisible()
  const { matches: isSafeToShowDesktopCta } = useMatchMedia(
    '(min-width: 1300px)'
  )

  if (isSafeToShowDesktopCta && which === 'desktop') {
    return (
      <LazyLoad loadWhenIdle resolve={() => import('./desktop')}>
        {(DesktopSignupCta) => <DesktopSignupCta />}
      </LazyLoad>
    )
  }

  if (
    isSafeToShowDesktopCta === false &&
    which === 'mobile' &&
    isWebsiteHeaderDesktopVisible
  ) {
    return (
      <LazyLoad loadWhenIdle resolve={() => import('./mobile')}>
        {(MobileSignupCta) => <MobileSignupCta />}
      </LazyLoad>
    )
  }

  if (
    isSafeToShowDesktopCta === false &&
    which === 'mobile' &&
    !isWebsiteHeaderDesktopVisible
  ) {
    return <FixedCta visibleAfterScrollY={50} />
  }

  return null
}

export default FixedSignupCta
