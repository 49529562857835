/**
 * Check if passed in value is a finite number (not NaN or +- Infinity),
 * and casts the value type as number if isFinite check returns positive
 *
 * Why not Number.isFinite?
 * We want to use Number.isFinite on values that are not number primitives
 * (i.e. Number.isFinite(null) should be valid). Due to unresolved issues
 * in the typescript core, Number.isFinite is typed as needing a number
 * as it's input, which is cumbersome for most common uses. The return cast
 * also allows to continue using the value as a number without further checks
 */

export const isFiniteNumber = (n: unknown): n is number =>
  Number.isFinite(n as number)

export const filterOnlyNumbers = (text: string) =>
  Number((text.match(/[0-9.]/g) || []).join(''))

export const isLessThanMin = (minValue: number, val: string | number) =>
  minValue !== null && !isNaN(minValue) && val < minValue

export const isGreaterThanMax = (maxValue: number, val: string | number) =>
  maxValue !== null && !isNaN(maxValue) && val > maxValue

export const validateMinMax = (
  minValue: number,
  maxValue: number,
  val: string | number,
  errMsgMin: string,
  errMsgMax: string
): string | void => {
  if (isLessThanMin(minValue, val)) return errMsgMin
  else if (isGreaterThanMax(maxValue, val)) return errMsgMax
}
