import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { ControlIconName } from './types'

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  iconName: ControlIconName
}

interface QueryResult {
  controlIcons: { edges: { node: { name: string; publicURL: string } }[] }
}

const query = graphql`
  {
    controlIcons: allFile(
      filter: { relativeDirectory: { eq: "icons/controls" } }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`

const ControlIcon = ({ iconName, ...props }: Props) => {
  const { controlIcons } = useStaticQuery<QueryResult>(query)
  if (controlIcons) {
    const file = controlIcons.edges.find(
      ({ node: { name } }) => name === iconName
    )
    if (file) {
      return <img src={file.node.publicURL} alt="" {...props} />
    }
  }
  return null
}

export default ControlIcon
