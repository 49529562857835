import React, { useState, ChangeEvent, useEffect, FormEvent } from 'react'
import { t } from '@lingui/macro'
import validator from 'email-validator'

import { i18n } from 'utils/i18n'
import Input from 'components/input'
import PasswordInput from 'components/input/password-input'
import { PrimaryButton } from 'components/Buttons'

import { form, emailInput } from './index.module.scss'

const getEmailError = (value: string): string | undefined =>
  validator.validate(value)
    ? undefined
    : i18n._(t('input.error.email')`must be an email address`)

const LoginForm = ({
  disabled,
  onPressEmailLogin,
}: {
  disabled: boolean
  onPressEmailLogin: (email: string, password: string) => Promise<void>
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (!getEmailError(email)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [email, password])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onPressEmailLogin(email, password)
  }

  return (
    <form className={form} onSubmit={handleSubmit}>
      <Input
        label={i18n._(t`Email`)}
        name="email"
        type="email"
        className={emailInput}
        value={email}
        errorMessage={getEmailError(email)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
      />
      <PasswordInput
        label={i18n._(t`Password`)}
        name="password"
        value={password}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
      />
      <PrimaryButton
        type="submit"
        text={i18n._(t('user.profile.login')`Log in`)}
        disabled={disabled || !isValid}
        block
      />
    </form>
  )
}

export default LoginForm
