import React from 'react'

import { Hidden, Visible } from 'components/grid'
import GrayShapeSVG from 'assets/images/signup-themes/default/bg-shape-gray.svg'
import PeachShapeSVG from 'assets/images/signup-themes/default/bg-shape-peach.svg'

import {
  root,
  grayShape,
  peachShape,
  whiteBackground,
} from './background.module.scss'

const Background = () => (
  <div className={root}>
    <Hidden md>
      <img className={peachShape} src={PeachShapeSVG} alt="" />
    </Hidden>
    <Visible md>
      <img className={grayShape} src={GrayShapeSVG} alt="" />
      <img className={peachShape} src={PeachShapeSVG} alt="" />
    </Visible>
    <div className={whiteBackground} />
  </div>
)

export default Background
