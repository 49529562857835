import React from 'react'

import { breakpoints } from 'components/grid'
import ResponsiveSwitch from 'components/utils/responsiveUtils/responsive-switch'
import { useMatchMedia } from 'hooks'

import WebsiteHeaderMobile from './websiteHeaderMobile'
import WebsiteHeaderDesktop from './websiteHeaderDesktop'

const query = `(min-width: ${breakpoints.xl}px)`

export const useIsWebsiteHeaderDesktopVisible = () => {
  const { matches } = useMatchMedia(query)
  return matches
}

interface Props {
  hrefLang: EF.HrefLangNode[]
}

const WebsiteHeader = ({ hrefLang }: Props) => (
  <ResponsiveSwitch
    query={query}
    renderFalse={<WebsiteHeaderMobile hrefLang={hrefLang} />}
    renderTrue={<WebsiteHeaderDesktop hrefLang={hrefLang} />}
  />
)

export default WebsiteHeader
