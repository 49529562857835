import {
  UserAccountStore,
  UserAccountAttributeKey,
} from 'gatsby-plugin-8fit-user-account'

export const isNewUser = () => {
  const { getAttribute } = UserAccountStore
  return (
    !!getAttribute('id') &&
    !!getAttribute('isNewUser') &&
    !getAttribute('isSessionRestored')
  )
}

export const isOnboardingFilled = () => {
  const { getAttribute } = UserAccountStore
  const requiredAttributesForOnboarded = [
    'goal',
    'gender',
    'age',
    'birthday',
    'weight_gr',
    'height_cm',
    'body_fat',
    'body_fat_target',
  ] as UserAccountAttributeKey[]
  return requiredAttributesForOnboarded.every((key) => !!getAttribute(key))
}

export const isSignupComplete = () => {
  const { getAttribute } = UserAccountStore
  const isPro = getAttribute('is_pro')
  const chosenPlan = getAttribute('SignupScreenPlansOverview')
  return isPro || chosenPlan === null
}

export const isPartner = (partnerName: string) => {
  const plp = UserAccountStore.getAttribute('ContentfulPartnershipLandingPage')
  return !!plp && new RegExp(partnerName, 'i').test(plp.partnerName)
}
