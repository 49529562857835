import React from 'react'
import { graphql, Link, prefetchPathname } from 'gatsby'
import { H, DocumentOutlineSection } from 'react-document-section'
import { noop } from 'lodash'
import { t } from '@lingui/macro'

import {
  useUserAccount,
  getAttributeType as getUserAccountAttributeType,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import { useDidMount } from 'hooks'
import { interpolate } from 'utils/string'
import MetaTagRobots from 'components/meta/robots'
import NotificationCentral from 'components/notification-central'
import PromiseReporter, {
  usePromiseReporter,
} from 'components/notification-central/promise-reporter'
import NotificationOverlay from 'components/notification-central/overlay'
import Layout from 'components/layout'
import Logo from 'components/logo'
import LazyLoad from 'components/utils/lazy-load'
import LoadingSpinner from 'components/utils/loading-spinner'

import performSideEffects, { setConfiguration } from './side-effects'
import SignupRouter from './signup-router'
import Background from './themes/default/background'
import SignupSegment from './themes/default/segment'
import {
  root,
  loadingSpinner,
  homepageLink,
  logo,
  title,
} from './index.module.scss'
import Screen from './themes/default/screen'
import {
  ScreenType,
  SignupSegmentType,
  SignupScreenType,
  SignupError,
  SegmentType,
} from './types'

type SignupScreenQuestionnaireComponent = typeof import('./screens/questionnaire-screen')
type SignupScreenBodyfatComponent = typeof import('./screens/bodyfat-screen')
type SignupScreenSignupComponent = typeof import('./screens/signup-screen')
type SignupScreenPlansOverviewComponent = typeof import('./screens/plans-overview')
type SignupScreenPrepaidSubscriptionComponent = typeof import('./screens/prepaid-subscription')
type SignupScreenCreditCardPaymentComponent = typeof import('./screens/payment-screen')
type SignupScreenCompleteComponent = typeof import('./screens/final-screen')

type SignupScreenComponent =
  | SignupScreenQuestionnaireComponent
  | SignupScreenBodyfatComponent
  | SignupScreenSignupComponent
  | SignupScreenPlansOverviewComponent
  | SignupScreenPrepaidSubscriptionComponent
  | SignupScreenCreditCardPaymentComponent
  | SignupScreenCompleteComponent

interface SignupDataContext {
  slug: string
  noIndex: boolean
  segments: SignupSegmentType[]
}

interface SignupFunnelProps {
  data: {
    site: EF.Site
  }
  pageContext: {
    signupFunnel: SignupDataContext
  }
}

const screenComponents = {
  SignupScreenQuestionnaire: () => import('./screens/questionnaire-screen'),
  SignupScreenBodyfat: () => import('./screens/bodyfat-screen'),
  SignupScreenSignup: () => import('./screens/signup-screen'),
  SignupScreenPlansOverview: () => import('./screens/plans-overview'),
  SignupScreenPrepaidSubscription: () =>
    import('./screens/prepaid-subscription'),
  SignupScreenCreditCardPayment: () => import('./screens/payment-screen/index'),
  SignupScreenComplete: () => import('./screens/final-screen'),
}

const ComponentCache = (() => {
  const loadedComponents: {
    [key in ScreenType]?: SignupScreenComponent
  } = {}
  return {
    isLoaded: (screenType: ScreenType) => screenType in loadedComponents,
    getComponent: (screenType: ScreenType) => async () => {
      if (!loadedComponents[screenType]) {
        loadedComponents[screenType] = await screenComponents[screenType]()
      }
      return loadedComponents[screenType]
    },
  }
})()

const renderLoadingMessage = () => (
  <span>
    <LoadingSpinner className={loadingSpinner} />
    {i18n._(t`Processing`)}
  </span>
)

const renderErrorMessage = (
  err: EF.ErrorWithReadableMessage & Partial<Pick<SignupError, 'errorKey'>>
) => {
  if (err.hasReadableMessage) return err.message

  const { errorKey } = err
  const errorMessages: { [key: string]: string } = {
    email_taken: i18n._(t`error.message.email_taken`),
    invalid_token: i18n._(t`error.message.invalid_token`),
    not_permitted: i18n._(t`error.message.not_permitted`),
    default: i18n._(t`error.message.default`),
  }

  return (errorKey && errorMessages[errorKey]) || errorMessages.default
}

const getTitleInterpolationValues = (
  getUserAccountValue: getUserAccountAttributeType
) => {
  const name = getUserAccountValue('name')
  const [firstName] = name?.match(/[^\s]+/) || [name]
  let planTitle = i18n._(t`free plan`)
  const chosenPlan = getUserAccountValue('SignupScreenPlansOverview')
  if (chosenPlan?.period_name) {
    planTitle = chosenPlan.period_name.endsWith('year')
      ? i18n._(t`yearly plan`)
      : i18n._(t`3-Month plan`)
  }
  return { firstName, planTitle }
}

const PrefetchNextScreensPageData = ({
  nextScreens,
}: {
  nextScreens: { screen: SignupScreenType; screenIndex: number }[]
}) => {
  useDidMount(() => {
    nextScreens.map(({ screen: { path } }) => prefetchPathname(path))
  })
  return null
}

const SignupFunnel = ({
  data: {
    site: {
      siteMetadata: { facebookAppId, firebase },
    },
  },
  pageContext: {
    signupFunnel: { slug, noIndex, segments: unprocessedSegments },
  },
}: SignupFunnelProps) => {
  const { reportPromiseProgress } = usePromiseReporter()
  const { getAttribute } = useUserAccount()

  setConfiguration({ facebookAppId, firebase })

  useDidMount(() => {
    // The initial side effects can be "run in the background", meaning that
    // the "Processing …" loading message shouldn't pop up. So, let's increase
    // the delay for it up to 2000ms.
    const customDelay = 2000
    reportPromiseProgress(performSideEffects(), customDelay).catch(noop)
  })

  const onBeforeNavigate = () => reportPromiseProgress(performSideEffects())
  const titleInterpolationValues = getTitleInterpolationValues(getAttribute)

  return (
    <SignupRouter
      signupFunnelSlug={slug}
      segments={unprocessedSegments}
      onBeforeNavigate={onBeforeNavigate}
    >
      {({
        screen: currentScreen,
        screenIndex: currentScreenIndex,
        prevScreens,
        nextScreens,
        segments,
        segmentIndex,
      }) => {
        const currentSegment = segments[segmentIndex]
        return (
          <Layout
            title={i18n._(t`Signup for 8fit`)}
            pageTitle={`${interpolate(
              currentScreen.title as string,
              titleInterpolationValues
            )} | 8fit`}
            className={root}
            hrefLang={[]}
            defaultLang={{ fields: { path: slug } }}
          >
            <MetaTagRobots noindex={noIndex} nofollow />
            {currentSegment.segmentType !==
              SegmentType.SignupSegmentCompletion && <Background />}
            <H className={title} />
            {segments.map((segment, i) => (
              <SignupSegment
                key={`segment-${i}`}
                segment={segment}
                prev={i < segmentIndex}
                current={i === segmentIndex}
                next={i > segmentIndex}
              >
                {segment.segmentScreens.map(({ screen, screenIndex }) => (
                  <Screen
                    key={screenIndex}
                    prev={prevScreens.some(
                      (prevScreen) => prevScreen.screenIndex === screenIndex
                    )}
                    current={screenIndex === currentScreenIndex}
                    next={nextScreens.some(
                      (nextScreen) => nextScreen.screenIndex === screenIndex
                    )}
                  >
                    <PrefetchNextScreensPageData nextScreens={nextScreens} />
                    <DocumentOutlineSection
                      title={interpolate(
                        screen.title as string,
                        titleInterpolationValues
                      )}
                    >
                      <LazyLoad
                        // @ts-ignore
                        resolve={ComponentCache.getComponent(screen.screenType)}
                      >
                        {(ScreenComponent) => (
                          // @ts-ignore
                          <ScreenComponent
                            // @ts-ignore At this point isCurrentScreen isn't part of `screen`
                            isCurrentScreen={screenIndex === currentScreenIndex}
                            {...screen}
                          />
                        )}
                      </LazyLoad>
                    </DocumentOutlineSection>
                  </Screen>
                ))}
              </SignupSegment>
            ))}
            {currentSegment.segmentType !==
              SegmentType.SignupSegmentCompletion && (
              <Link to={i18n._(t`/`)} className={homepageLink}>
                <Logo alt={i18n._(t`Go to homepage`)} className={logo} />
              </Link>
            )}
            <NotificationOverlay />
          </Layout>
        )
      }}
    </SignupRouter>
  )
}

const SignupFunnelWrapper = (props: SignupFunnelProps) => {
  return (
    <NotificationCentral>
      <PromiseReporter
        renderProcessingMessage={renderLoadingMessage}
        renderErrorMessage={renderErrorMessage}
      >
        <SignupFunnel {...props} />
      </PromiseReporter>
    </NotificationCentral>
  )
}

export default SignupFunnelWrapper

export const pageQuery = graphql`
  query SignupFunnel {
    site {
      siteMetadata {
        facebookAppId
        firebase {
          apiKey
          authDomain
          databaseURL
          projectId
          storageBucket
          messagingSenderId
        }
      }
    }
  }
`
