import { navigate } from 'gatsby'
import { t } from '@lingui/macro'
import validator from 'email-validator'

import { i18n } from 'utils/i18n'
import { gramsToLbs, gramsToKgs, centimetersToFeetAndInches } from 'utils/math'
import { validateMinMax } from 'utils/number'

import {
  ScreenType,
  SignupScreenType,
  SignupSegmentType,
  SegmentType,
  SegmentScreen,
  getUserAccountAttributeType,
  SignupInputFieldInterface,
  UNIT_CHOICES,
  SignupField,
  SignupFieldTypes,
  SignupSelectFieldInterface,
  SignupSegmentAccountCreation,
  SignupSegmentCompletion,
} from '../types'

export const createScreenList = (
  _segments: SignupSegmentType[] = [],
  basePath = '/'
) => {
  const screenList: SegmentScreen[] = []
  const getFieldsFromScreen = (screen: SignupScreenType) => {
    if (
      screen.screenType === ScreenType.SignupScreenQuestionnaire ||
      screen.screenType === ScreenType.SignupScreenSignup
    ) {
      return [...screen.questions]
    }
    return [screen]
  }

  const normalizeScreen = (screen: SignupScreenType) => {
    // Add path
    screen.path = `${basePath}${screen.slug}/`
    // Normalize title in case it comes from a multiline field
    if (
      typeof screen.title === 'object' &&
      screen.title &&
      'title' in screen.title
    ) {
      return { ...screen, title: screen.title.title }
    }
    return screen
  }

  const getSegmentScreens = (
    segment: SignupSegmentType
  ): SignupScreenType[] => {
    if (segment.segmentType === SegmentType.SignupSegmentOnboarding) {
      return [...segment.screens]
    } else if (
      segment.segmentType === SegmentType.SignupSegmentAccountCreation
    ) {
      return [segment.signupScreen, segment.oAuthPolicyConfirmationScreen]
    } else if (segment.segmentType === SegmentType.SignupSegmentCompletion) {
      const screens = [
        segment.plansScreen,
        segment.prepaidSubscriptionScreen,
        segment.creditCardScreen,
        segment.finalScreen,
      ]
      return screens.filter(Boolean) as NonNullable<typeof screens[0]>[]
    }
    return []
  }

  let index = 0
  const segments = _segments.map((_segment, segmentIndex) => {
    const segmentScreens = getSegmentScreens(_segment)
      .filter(Boolean)
      .map((screen) => ({
        screen: normalizeScreen(screen),
        screenIndex: index++,
      }))
    return { ..._segment, segmentScreens, segmentIndex }
  })

  segments.forEach((segment) => {
    const { segmentScreens, segmentIndex } = segment
    segmentScreens.forEach(({ screen, screenIndex }) => {
      let { slug } = screen
      if (screenIndex === 0) {
        slug = ''
      }

      screenList.push({
        slug,
        segment,
        // @ts-ignore TODO: even tho I put the type after checking data, still conflict
        segments,
        segmentIndex,
        screen,
        screenIndex,
        fields: getFieldsFromScreen(screen),
      })
    })
  })

  // Add next screens
  screenList.forEach((screen) => {
    const { segment, slug } = screen
    let nextScreens: (SegmentScreen | undefined)[] = [
      screenList[screen.screenIndex + 1],
    ]
    const findScreen = ({ slug }: { slug: string }) =>
      screenList.find((screen) => screen.slug === slug)

    const mapScreens = (screens: SignupScreenType[] = []) =>
      screens.map(findScreen)

    if (segment.segmentType === SegmentType.SignupSegmentAccountCreation) {
      if (segment.signupScreen.slug === slug) {
        nextScreens = mapScreens([segment.oAuthPolicyConfirmationScreen])
      } else {
        const finalScreenOfTheSegment = findScreen(
          segment.oAuthPolicyConfirmationScreen
        )
        if (finalScreenOfTheSegment) {
          nextScreens = [screenList[finalScreenOfTheSegment.screenIndex + 1]]
        }
      }
    } else if (
      segment.segmentType === SegmentType.SignupSegmentCompletion &&
      segment.plansScreen &&
      segment.plansScreen.slug === slug
    ) {
      nextScreens = mapScreens([segment.creditCardScreen, segment.finalScreen])
    }
    screen.nextScreens = nextScreens.filter(Boolean)
  })

  // Add prev screens
  screenList.forEach((screen) => {
    const { segment, slug } = screen

    if (segment.segmentType === SegmentType.SignupSegmentCompletion) {
      const hasPlansScreen = segment.plansScreen !== null
      const firstScreen = hasPlansScreen
        ? segment.plansScreen
        : segment.creditCardScreen
      const isOnFirstScreen = firstScreen.slug === slug
      if (isOnFirstScreen) {
        screen.prevScreens = []
        return
      }
    }
    screen.prevScreens = screenList.filter(({ nextScreens }) =>
      nextScreens.includes(screen)
    )
  })

  // Add getNext function
  screenList.forEach((screen) => {
    const { nextScreens, prevScreens } = screen
    const createGetAdjacentScreenFn = (
      adjacentScreens: (SegmentScreen | undefined)[] = []
    ) =>
      adjacentScreens.length === 1
        ? () => adjacentScreens[0]
        : (getUserAccountAttribute: getUserAccountAttributeType) =>
            adjacentScreens.find(
              (screen) =>
                screen &&
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                !shouldScreenBeSkipped(screen, getUserAccountAttribute)
            )
    screen.getNextScreen = createGetAdjacentScreenFn(nextScreens)
    screen.getPrevScreen = createGetAdjacentScreenFn(prevScreens)
  })
  return screenList
}

const isIso8601 = (val: string) =>
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(val)

const formatMetric = (value: number, unitChoice: UNIT_CHOICES) => {
  if (unitChoice === UNIT_CHOICES.WEIGHT)
    return i18n._(t`${gramsToKgs(value)}kg`)
  if (unitChoice === UNIT_CHOICES.HEIGHT) return i18n._(t`${value}cm`)

  return `${value}`
}

const formatImperial = (value: number, unitChoice: UNIT_CHOICES) => {
  if (unitChoice === UNIT_CHOICES.WEIGHT)
    return i18n._(t`${gramsToLbs(value)}lbs`)
  if (unitChoice === UNIT_CHOICES.HEIGHT) {
    const { feet, inches } = centimetersToFeetAndInches(value)
    return i18n._(t`${feet}′ ${inches}″`)
  }

  return `${value}`
}

const formatNumberValue = (
  value: number,
  unitChoice: UNIT_CHOICES,
  measurementSystem = 'metric'
) => {
  if (measurementSystem === 'metric') return formatMetric(value, unitChoice)
  if (measurementSystem === 'imperial') return formatImperial(value, unitChoice)

  return `${value}`
}

const getSignupInputFieldErrors = (
  field: SignupInputFieldInterface,
  value: number | string,
  getUserAccountAttribute: getUserAccountAttributeType
) => {
  const { type: inputType, minValue, maxValue, unitChoice } = field
  const measurementSystem = getUserAccountAttribute('units')
  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,256})/

  switch (inputType) {
    case 'number': {
      const formatBoundaryValue = (val: number) =>
        formatNumberValue(val, unitChoice, measurementSystem)

      return isNaN(value as number)
        ? i18n._(t`must be a number`)
        : validateMinMax(
            minValue,
            maxValue,
            value,
            i18n._(t`can't be less than ${formatBoundaryValue(minValue)}`),
            i18n._(t`can't be higher than ${formatBoundaryValue(maxValue)}`)
          )
    }
    case 'text':
      return typeof value !== 'string'
        ? i18n._(t`must be text`)
        : validateMinMax(
            minValue,
            maxValue,
            value.length,
            i18n._(
              'input.error.character.min',
              { min: minValue },
              { defaults: "can't be shorter than {min} characters" }
            ),
            i18n._(
              'input.error.character.max',
              { max: maxValue },
              { defaults: "can't be longer than {max} characters" }
            )
          )
    case 'password':
      return !PASSWORD_REGEX.test(value as string)
        ? i18n._(
            t(
              'input.password.error'
            )`must be between 12 and 256 characters and must contain a capital letter, lowercase letter and number.`
          )
        : null
    case 'email':
      return !validator.validate(value as string)
        ? i18n._(t('input.error.email')`must be an email address`)
        : validateMinMax(
            minValue,
            maxValue,
            (value as string).length,
            i18n._(
              'input.error.character.min',
              { min: minValue },
              { defaults: "can't be shorter than {min} characters" }
            ),
            i18n._(
              'input.error.character.max',
              { max: maxValue },
              { defaults: "can't be longer than {max} characters" }
            )
          )
    default:
      return undefined
  }
}

export const getFieldErrors = (
  field: SignupScreenType | SignupField,
  getUserAccountAttribute: getUserAccountAttributeType
): string | void => {
  // @ts-ignore TODO: field is union type. some properties are missing in other type. Separate condition for each type
  const { fieldType, screenType, name } = field
  const type = fieldType || screenType
  const valueType = name || screenType
  const value = getUserAccountAttribute(valueType)

  switch (type) {
    case SignupFieldTypes.SignupSelectField: {
      if (
        !(field as SignupSelectFieldInterface).choices.some(
          (choice) => choice.value === value
        )
      ) {
        return i18n._(t`isn't a valid choice`)
      }
      break
    }
    case SignupFieldTypes.SignupPolicyConsentField: {
      if (!isIso8601(value)) {
        return i18n._(t`must be checked`)
      }
      break
    }
    case SignupFieldTypes.SignupPolicyConsentHealthData: {
      if (!isIso8601(value)) {
        return i18n._(t`must be checked`)
      }
      break
    }
    case SignupFieldTypes.SignupNewsletterConsentField: {
      if (value !== undefined && typeof value !== 'boolean') {
        return i18n._(t`isn't a valid choice`)
      }
      break
    }
    case ScreenType.SignupScreenBodyfat: {
      if (isNaN(value)) {
        return i18n._(t`Select a range`)
      }
      break
    }
    case SignupFieldTypes.SignupInputField: {
      const validationError = getSignupInputFieldErrors(
        field as SignupInputFieldInterface,
        value,
        getUserAccountAttribute
      )

      if (validationError) return validationError

      break
    }

    default: {
      if (value === undefined) {
        return i18n._(t`Data not present`)
      }
    }
  }
}

export const isFieldValid = (
  field: SignupField | SignupScreenType,
  getUserAccountAttribute: getUserAccountAttributeType
) => {
  return !getFieldErrors(field, getUserAccountAttribute)
}

const checkScreenSlug = (
  screen: SegmentScreen,
  desiredSegmentType: SegmentType,
  checkFn: (segment: SignupSegmentType, slug: string) => boolean
) => {
  const { segment, slug } = screen
  if (segment.segmentType === desiredSegmentType) {
    return checkFn(segment, slug)
  }
  return false
}

const isScreenSignupScreen = (screen: SegmentScreen) =>
  checkScreenSlug(
    screen,
    SegmentType.SignupSegmentAccountCreation,
    (segment, slug) =>
      slug === (segment as SignupSegmentAccountCreation).signupScreen.slug
  )

const isScreenOAuthPolicyConsentScreen = (screen: SegmentScreen) =>
  checkScreenSlug(
    screen,
    SegmentType.SignupSegmentAccountCreation,
    (segment, slug) =>
      slug ===
      (segment as SignupSegmentAccountCreation).oAuthPolicyConfirmationScreen
        .slug
  )
const isScreenPlansScreen = (screen: SegmentScreen) =>
  checkScreenSlug(
    screen,
    SegmentType.SignupSegmentCompletion,
    (segment, slug) =>
      slug ===
      ((segment as SignupSegmentCompletion).plansScreen &&
        (segment as SignupSegmentCompletion).plansScreen.slug)
  )

const isScreenPrepaidSubscriptionScreen = (screen: SegmentScreen) =>
  checkScreenSlug(
    screen,
    SegmentType.SignupSegmentCompletion,
    (segment, slug) =>
      slug ===
      (segment as SignupSegmentCompletion).prepaidSubscriptionScreen?.slug
  )

const isScreenCreditCardScreen = (screen: SegmentScreen) =>
  checkScreenSlug(
    screen,
    SegmentType.SignupSegmentCompletion,
    (segment, slug) =>
      slug === (segment as SignupSegmentCompletion).creditCardScreen.slug
  )

const isScreenFinalScreen = (screen: SegmentScreen) =>
  checkScreenSlug(
    screen,
    SegmentType.SignupSegmentCompletion,
    (segment, slug) =>
      slug === (segment as SignupSegmentCompletion).finalScreen.slug
  )

const doesScreenNeedAuthentication = (screen: SegmentScreen) => {
  const {
    segment: { segmentType },
  } = screen

  return segmentType === SegmentType.SignupSegmentCompletion
}

const isAuthenticated = (
  getUserAccountAttribute: getUserAccountAttributeType
) => !!getUserAccountAttribute('id')

/**
 * Returns true if the given screen has all its field correctly filled out or is otherwise valid
 */
export const isScreenValid = (
  screen: SegmentScreen,
  getUserAccountAttribute: getUserAccountAttributeType
) => {
  const isUserAuthenticated = isAuthenticated(getUserAccountAttribute)
  // If the screen needs authentication but the user isn't authenticated, it's not yet valid
  if (doesScreenNeedAuthentication(screen) && !isUserAuthenticated) {
    return false
  }

  // If the screen is the 'create-account' screen and there's neither an oAuthKey nor are they
  // authenticated then it's not yet valid
  if (isScreenSignupScreen(screen)) {
    const oAuthKey = getUserAccountAttribute('oAuthKey')
    if (!oAuthKey && !isUserAuthenticated) {
      return false
    }
  }

  // If the screen is the OAuthPolicyConsentScreen but the user isn't authenticated then it's
  // not yet valid
  if (isScreenOAuthPolicyConsentScreen(screen) && !isUserAuthenticated) {
    return false
  }

  const hasPrepaidSubscriptionFunnel = getUserAccountAttribute(
    'hasPrepaidSubscriptionFunnel'
  )
  const isPro = getUserAccountAttribute('is_pro')

  // If the screen is the prepaid subscription screen it's not (yet) valid if
  // • It's paid subscription funnel and there shouldn't be the prepaid subscription screen
  // • The user isn't pro
  if (isScreenPrepaidSubscriptionScreen(screen)) {
    if (!hasPrepaidSubscriptionFunnel || !isPro) {
      return false
    }
  }

  // If the screen is the credit card screen it's not (yet) valid if
  // • It's the prepaid subscription funnel and there shouldn't be a cc screen
  // • The user isn't pro
  if (isScreenCreditCardScreen(screen)) {
    if (hasPrepaidSubscriptionFunnel || !isPro) {
      return false
    }
  }

  const areAllFieldsValid = screen.fields.every((field) =>
    isFieldValid(field, getUserAccountAttribute)
  )

  // The screen is valid if all of its fields are valid
  return areAllFieldsValid
}

const shouldScreenBeSkipped = (
  screen: SegmentScreen,
  getUserAccountAttribute: getUserAccountAttributeType
) => {
  const isUnauthenticatedScreen = !doesScreenNeedAuthentication(screen)
  const signupOption = getUserAccountAttribute('SignupScreenSignupOptions')
  const isEmailSelectedSignupOption = signupOption === 'Email'
  const isPro = getUserAccountAttribute('is_pro')
  const chosenPlan = getUserAccountAttribute('SignupScreenPlansOverview')
  const isChosenPlanFreePlan = chosenPlan === null
  const hasPrepaidSubscriptionFunnel = getUserAccountAttribute(
    'hasPrepaidSubscriptionFunnel'
  )
  const shouldNotPay = isPro || isChosenPlanFreePlan

  // Skip all onboarding and account creation screens if the user is already authenticated
  if (isUnauthenticatedScreen && isAuthenticated(getUserAccountAttribute)) {
    return true
  }

  // Skip signup screen if a user selected other social login
  if (isScreenSignupScreen(screen)) {
    return !isEmailSelectedSignupOption
  }

  // Skip oauth policy consent screen if email was selected as a signup option
  if (isScreenOAuthPolicyConsentScreen(screen)) {
    return isEmailSelectedSignupOption
  }

  // Skip Plans Screen if user is already pro
  if (isScreenPlansScreen(screen)) {
    return isPro
  }

  // Skip No Payment screen if the user should go through the CC screen or is already pro
  if (isScreenPrepaidSubscriptionScreen(screen)) {
    return !hasPrepaidSubscriptionFunnel || shouldNotPay
  }

  // Skip Credit card screen if the user should go through the No Payment funnel or is already
  // pro or the selected plan was a free plan
  if (isScreenCreditCardScreen(screen)) {
    return hasPrepaidSubscriptionFunnel || shouldNotPay
  }

  return false
}

/**
 * The "latest valid screen" refers to the last screen in the screenList which is not yet correctly
 * filled out. It's the screen which is safe to redirect to.
 */
export const getLatestValidScreen = (
  screenList: SegmentScreen[] = [],
  getUserAccountAttribute: getUserAccountAttributeType
) => {
  const latestValidScreen = screenList.find((screen) => {
    return (
      !shouldScreenBeSkipped(screen, getUserAccountAttribute) &&
      !isScreenValid(screen, getUserAccountAttribute)
    )
  })
  return latestValidScreen || screenList[0]
}

/**
 * Return a list of all the errors on a screen
 */
export const getCurrentScreenErrors = (
  getUserAccountAttribute: getUserAccountAttributeType,
  currentScreen?: SegmentScreen
) => {
  return currentScreen?.fields
    .map((field): {
      field: SignupField | SignupScreenType
      invalidationError: string
    } | void => {
      const invalidationError = getFieldErrors(field, getUserAccountAttribute)
      if (invalidationError) return { field, invalidationError }
    })
    .filter(Boolean)
}

const hasPlanScreen = (screens: SegmentScreen[]) => {
  return screens.some(isScreenPlansScreen)
}

const getScreensToNotGoBackFrom = (screens: SegmentScreen[]) => {
  // If the WSF is a prepaid subscription flow it's gonna be:
  // ['create-account', 'subscribe-prepaid', 'subscribe-cc', 'welcome']
  // If it's a normal flow it's gonna be
  // ['create-account', 'subscribe', 'welcome']
  const screensNotToGoBackFrom = screens.filter((screen: SegmentScreen) => {
    if (
      isScreenSignupScreen(screen) ||
      isScreenFinalScreen(screen) ||
      isScreenPlansScreen(screen)
    ) {
      return true
    }
    if (!hasPlanScreen(screens)) {
      return (
        isScreenPrepaidSubscriptionScreen(screen) ||
        isScreenCreditCardScreen(screen)
      )
    }
    return false
  })
  return screensNotToGoBackFrom
}

export const isCurrentScreenCorrect = (
  screens: SegmentScreen[],
  latestValidScreen: SegmentScreen,
  isInitialNavigation: boolean,
  currentScreen?: SegmentScreen,
  previousSlug?: string | null
) => {
  if (!currentScreen) {
    return false
  }
  const previousScreen = screens.find((scr) => scr.slug === previousSlug)

  const isCurrentSegmentWrong =
    previousScreen && currentScreen.segmentIndex < previousScreen?.segmentIndex

  if (isCurrentSegmentWrong) {
    return false
  }

  if (isInitialNavigation) {
    return true
  }

  const shouldNotGoBackFromLatestValidScreen = getScreensToNotGoBackFrom(
    screens
  ).some(
    (screenToNotGoBackFrom) =>
      latestValidScreen.screenIndex === screenToNotGoBackFrom.screenIndex
  )

  // If the latest valid screen is a screen to not go back from then
  // the current screen must be the latest valid screen. Otherwise the
  // current screen can be _before_ the latest valid screen to allow
  // the user to navigate back within the funnel.
  if (shouldNotGoBackFromLatestValidScreen) {
    return currentScreen.screenIndex === latestValidScreen.screenIndex
  } else {
    return currentScreen.screenIndex <= latestValidScreen.screenIndex
  }
}

export const goToScreenFn = (
  basePath: string,
  screens: SegmentScreen[],
  onBeforeNavigate: () => Promise<unknown>
) => async (slug = '', options?: object) => {
  const isScreenExistent = screens.some((screen) => screen.slug === slug)
  if (isScreenExistent) {
    const pathname = `${basePath}${slug.replace(/\/?$/, '/')}`
    try {
      await onBeforeNavigate()
      navigate(pathname, options)
    } catch (err) {
      return err
    }
  }
}
