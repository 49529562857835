import React, { useState } from 'react'
import { t } from '@lingui/macro'
import { H, DocumentOutlineSection } from 'react-document-section'

import { classNames } from 'utils/dom'
import { i18n } from 'utils/i18n'
import chevronUp from 'assets/images/signup/chevronUp.svg'

import {
  sectionTitle,
  accordionTitle,
  accordionContainer,
  accordion,
  panelVisible,
  panelHidden,
  faqText,
  chevron,
} from './index.module.scss'

interface FAQ {
  question: string
  answer: string
}

const FaqSection = ({
  FAQs,
  className,
}: {
  FAQs: FAQ[]
  className?: string
}) => {
  const [selectedFaq, setSelectedFaq] = useState<number | undefined>(undefined)
  const toggleFaq = (index: number) => {
    if (selectedFaq === index) {
      return setSelectedFaq(undefined)
    }
    setSelectedFaq(index)
  }
  return (
    <DocumentOutlineSection title={i18n._(t`Common questions`)}>
      <div className={className}>
        <H className={classNames([sectionTitle, accordionTitle])} />

        {FAQs.map((faq: FAQ, index: number) => {
          return (
            <div key={index} className={accordionContainer}>
              <DocumentOutlineSection title={faq.question}>
                <button className={accordion} onClick={() => toggleFaq(index)}>
                  <H />
                  <img
                    aria-expanded={selectedFaq !== index}
                    alt=""
                    className={chevron}
                    src={chevronUp}
                  />
                </button>
                <div
                  className={selectedFaq === index ? panelVisible : panelHidden}
                >
                  <p className={faqText}>{faq.answer}</p>
                </div>
              </DocumentOutlineSection>
            </div>
          )
        })}
      </div>
    </DocumentOutlineSection>
  )
}

export default FaqSection
