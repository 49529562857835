/** Generate a url search params string (shallow) */
export const serialize = (params: { [key: string]: unknown } = {}) => {
  const paramStr = Object.entries(params)
    .filter(([, value]) => value)
    .map(
      ([name, value]) =>
        `${encodeURIComponent(name)}=${encodeURIComponent(value as string)}`
    )
    .join('&')
  return paramStr ? `?${paramStr}` : ''
}

/**
 * Parse a url search params string (shallow).
 * Note: only returns string values, does not infer number, boolean, etc
 * */
export const parse = <T extends string>(
  search = ''
): { [key in T]?: string } => {
  if (/^\?[^=]+=[^&]+/.test(search)) {
    return search
      .substr(1)
      .split('&')
      .reduce((acc: { [key in T]?: string }, str) => {
        const [key, val] = str.split('=')
        // @ts-ignore 😢
        acc[decodeURIComponent(key)] = decodeURIComponent(val)
        return acc
      }, {})
  }
  return {}
}

/**
 * Takes a URL and prepends a given protocol if the URL is relative.
 */
export const ensureAbsoluteUrl = (protocol: 'http' | 'https', url = '') =>
  url.replace(/^(https?:)?\/\//, `${protocol}://`)

/**
 * Checks if the passed path has the pattern of a Gatsby page URL or not and returns boolean
 */
export const isGatsbyPage = (path: string) =>
  /^\/$|^\/[a-z0-9/-]+\/$/i.test(path)
