import * as Sentry from '@sentry/gatsby'

import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import sessionStorage from 'utils/sessionStorage'
import { wait } from 'utils/async'
import { handleScaResponse } from 'components/payment/lib/stripe'

import { SideEffect } from '../types'

const fetchAccountUntilPro = async (attemptCount = 3) => {
  // During testing we found that Stripe's webhook might not have gotten to the
  // BE yet. So, let's wait for a couple hundred milliseconds and then fetch
  // the account data.
  await wait(300)
  // By setting `shouldPreventMerge` to `true` we avoid updating the account
  // data and triggering WSF navigation and side effects. That way we make sure
  // that we only proceed once we have the `is_pro` status and not already if
  // only the `subscription` is present.
  const uaAttributes = await UserAccountStore.fetchAccount(true)
  if (uaAttributes?.is_pro || (uaAttributes && attemptCount <= 0)) {
    // It's either pro or we maxed out the number of attempts and gave up.
    // Merge the attributes into the user account.
    UserAccountStore.mergeAttributes(uaAttributes)
    return
  }
  // It's not pro yet and we still have attempts left. Retry the whole thing:
  await fetchAccountUntilPro(attemptCount - 1)
}

const scaEffect: SideEffect = {
  // Handle Strong Customer Authentication
  name: 'SCA',
  isSilent: false,
  isRecurring: true,
  // The subscribe side effect sets the SCAData
  condition: () => sessionStorage.has('SCAData'),
  execute: async () => {
    // We checked in the `condition` that 'SCAData' is present:
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const scaData = sessionStorage.read('SCAData')!
    // The sessionStorage entry was only to transfer the data from one side
    // effect to another, so we can remove it now
    sessionStorage.remove('SCAData')

    await handleScaResponse(scaData)
    if (scaData.type !== 'no_action_required') {
      // Update the user account data. Retry it until the user is pro.
      await fetchAccountUntilPro()
    }
    if (!UserAccountStore.getAttribute('is_pro')) {
      // Alrighty right, something went wrong! If anything within the payment
      // process had failed an error would have been thrown and we wouldn't
      // have gotten up to this point. The fact that we're here means that the
      // payment must have completed successfully. And yet the user isn't pro.
      // That's not a good sign. Let's log this to Sentry:
      Sentry.captureMessage(
        'Payment complete, but user still free',
        Sentry.Severity.Warning
      )
      // If you're developing, also log it to the console
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Payment complete, but user still free')
      }
    }
  },
}

export default scaEffect
