import React from 'react'
import { t } from '@lingui/macro'

import { classNames } from 'utils/dom'
import { i18n } from 'utils/i18n'
import LinkSwitch from 'components/utils/link-switch'

import {
  ButtonLinkProps,
  ButtonLinkWithIconProps,
  ImageIconButtonLinkProps,
} from './types'
import {
  primary,
  secondary,
  srOnlyText,
  back,
  close,
  chevron,
  leftArrow,
  closeCross,
  chevronLeft,
  chevronRight,
  imageIcon,
  icon,
  text,
} from './index.module.scss'

const Button = ({ text, block, ...props }: ButtonLinkProps) => (
  <LinkSwitch data-block={block} title={text} {...props}>
    {text}
  </LinkSwitch>
)

export const PrimaryButtonLink = ({ className, ...props }: ButtonLinkProps) => (
  <Button className={classNames([primary, className])} {...props} />
)

export const SecondaryButtonLink = ({
  className,
  ...props
}: ButtonLinkProps) => (
  <Button className={classNames([secondary, className])} {...props} />
)

const ButtonWithIcon = ({
  text,
  block,
  icon,
  classNameText,
  ...props
}: ButtonLinkWithIconProps) => (
  <LinkSwitch data-block={block} title={text} {...props}>
    {icon}
    <span className={classNameText}>{text}</span>
  </LinkSwitch>
)

export const ArrowBackButtonLink = ({
  className,
  ...props
}: Omit<ButtonLinkProps, 'text'>) => (
  <ButtonWithIcon
    className={classNames([back, className])}
    text={i18n._(t`Back`)}
    icon={<span className={leftArrow} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const CloseButtonLink = ({
  className,
  ...props
}: Omit<ButtonLinkProps, 'text'>) => (
  <ButtonWithIcon
    className={classNames([close, className])}
    text={i18n._(t`Close`)}
    icon={<span className={closeCross} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const ChevronLeftButtonLink = ({
  className,
  ...props
}: ButtonLinkProps) => (
  <ButtonWithIcon
    className={classNames([chevron, className])}
    icon={<span className={chevronLeft} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const ChevronRightButtonLink = ({
  className,
  ...props
}: ButtonLinkProps) => (
  <ButtonWithIcon
    className={classNames([chevron, className])}
    icon={<span className={chevronRight} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const ImageIconButtonLink = ({
  className,
  imageIconUrl,
  ...props
}: ImageIconButtonLinkProps) => (
  <ButtonWithIcon
    className={classNames([imageIcon, className])}
    icon={
      <span
        className={icon}
        style={{ backgroundImage: `url(${imageIconUrl})` }}
      />
    }
    classNameText={text}
    {...props}
  />
)
