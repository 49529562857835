import React from 'react'
import { t } from '@lingui/macro'

import {
  useUserAccount,
  AuthenticationState,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import { ButtonLinkProps, PrimaryButtonLink } from 'components/Buttons'

interface FunnelCtaProps extends Omit<ButtonLinkProps, 'path'> {
  text: string
  path?: string
}

const FunnelCta = ({
  text,
  path = i18n._(t`/signup/`),
  ...props
}: FunnelCtaProps) => {
  const { authenticationState } = useUserAccount()
  return (
    <PrimaryButtonLink
      path={
        authenticationState === AuthenticationState.Authenticated
          ? i18n._(t`/profile/`)
          : path
      }
      text={text}
      {...props}
    />
  )
}

export default FunnelCta
