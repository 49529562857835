import React from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import GatsbyImage from 'gatsby-image'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Col, Container, Row } from 'components/grid'
import CarouselInGrid from 'components/carousel-in-grid'
import DifficultyLabel from 'components/labels/difficulty-label'
import DurationLabel from 'components/labels/duration-label'
import icFitnessDumbbell from 'assets/images/icons/fitness-dumbbell.svg'
import icFitnessDumbbellLift from 'assets/images/icons/fitness-dumbbell-lift.svg'

import { EnOnlyLabel } from '../components/atomic-components'

import {
  root,
  title,
  description as descriptionStyle,
  metaInfoList as metaInfoListStyle,
  metaInfoKey,
  metaInfoValue,
  previewCardImage,
  prepInfo,
  prepInfoEntry,
  prepInfoIcon,
  prepInfoContent,
  prepInfoText,
  prepInfoKey,
  prepInfoValue,
} from './workout-details.module.scss'

interface Props {
  description: string
  durationMins: number
  muscleGroup: string
  equipment: string[]
  previewImages: EF.FixedImageNode[]
}

const WorkoutDetailsSection = ({
  description,
  durationMins,
  muscleGroup,
  equipment,
  previewImages,
}: Props) => {
  const metaInfoList = [
    {
      key: i18n._(t('workout_details.difficulty')`Difficulty`),
      value: <DifficultyLabel />,
    },
    {
      key: i18n._(t('workout_details.duration')`Duration`),
      value: <DurationLabel min={durationMins} />,
    },
    {
      key: i18n._(t('workout_details.language')`Language`),
      value: <EnOnlyLabel />,
    },
  ]

  const prepInfoList = [
    {
      name: 'musclegroup',
      key: i18n._(t('workout_page.workout_details.good_for')`Good for`),
      icon: icFitnessDumbbellLift,
      value: muscleGroup,
    },
    {
      name: 'equipment',
      key: i18n._(t('workout_page.workout_details.youll_need')`You'll need`),
      icon: icFitnessDumbbell,
      value: equipment.join(', '),
    },
  ]

  return (
    <DocumentOutlineSection
      title={i18n._(t('workout_page.workout_details.title')`Workout details`)}
    >
      <section>
        <Container className={root}>
          <Row>
            <Col xs={12} lgOffset={1} lg={10}>
              <H className={title} />
              <p className={descriptionStyle}>{description}</p>
              <dl className={metaInfoListStyle}>
                {metaInfoList.map(({ key, value }) => (
                  <React.Fragment key={key}>
                    <dt className={metaInfoKey}>{key}</dt>
                    <dd className={metaInfoValue}>{value}</dd>
                  </React.Fragment>
                ))}
              </dl>
            </Col>
          </Row>
        </Container>
        <CarouselInGrid>
          {previewImages.map(({ fields: { asset: { fixed } } }, i) => (
            <GatsbyImage
              key={i}
              className={previewCardImage}
              fixed={fixed}
              draggable={false}
            />
          ))}
        </CarouselInGrid>
        <Container>
          <Row>
            <Col xs={12} lgOffset={1} lg={10}>
              <dl className={prepInfo}>
                {prepInfoList.map(({ name, key, icon, value }) => (
                  <div key={name} className={prepInfoEntry}>
                    <img
                      src={icon}
                      alt=""
                      role="presentation"
                      className={prepInfoIcon}
                    />
                    <div className={prepInfoContent}>
                      <dt className={prepInfoKey}>
                        <span className={prepInfoText}>{key}:</span>
                      </dt>
                      <dd className={prepInfoValue} data-name={name}>
                        <span className={prepInfoText}>{value}</span>
                      </dd>
                    </div>
                  </div>
                ))}
              </dl>
            </Col>
          </Row>
        </Container>
      </section>
    </DocumentOutlineSection>
  )
}

export default WorkoutDetailsSection
