import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { t, Trans } from '@lingui/macro'
import { H } from 'react-document-section'
import { find } from 'lodash/fp'
import * as Sentry from '@sentry/gatsby'

import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import SeamlessWebsiteLayout from 'components/layout/seamless-website'
import Breadcrumb from 'components/breadcrumb'
import { Container, Row, Col } from 'components/grid'
import { getPlan, getYearlySavingAmount, is1YearPlan } from 'utils/payment'
import { DEFAULT_CURRENCY } from 'templates/signup/constants'
import PlanCard from 'components/PlanCard'
import { formatPrice } from 'utils/format'
import { getDateFromUTCSecondsFromEpoch } from 'utils/date-time'
import ToastMessage, { ToastStatus } from 'components/toast-message'
import { serialize } from 'utils/url'
import { useWSFBackendPlans } from 'hooks/use-backend-plans'

import AuthenticatedPage from '../AuthenticatedPage'
import { getBenefits } from '../data'
import { navigateToProfileWithToast } from '../utils'

import {
  container,
  title,
  subtitle,
  breadcrumb,
  cardCol,
  dividerLine,
  stopButton,
} from './index.module.scss'
import DowngradeWarningPopup from './DowngradeWarningPopup'
import CancelWarningPopup from './CancelWarningPopup'

interface ManageSubscriptionPageProps {
  pageContext: {
    hrefLang: EF.HrefLangNode[]
    defaultLang: EF.DefaultLangNode
  }
}

const dayInSeconds = 24 * 60 * 60

const getBreadcrumbItems = () => [
  {
    href: i18n._(t`/profile/`),
    text: i18n._(t('user.profile.title')`Profile`),
  },
  {
    href: i18n._(t`/profile/manage-subscription/`),
    text: i18n._(t('user.profile.manage.subscription')`Manage subscription`),
  },
]

/**
 * This extracts plan information from the plan identifier.
 *
 * @param planId The plan identifier like "pro1712-stripe-3mo-30usd"
 */
const getPlanInfoFromId = (planId: string) => {
  const match = /stripe-(\d+)(m|mo|yr)-(\d+)(\w+)/.exec(planId)
  if (match) {
    const [, periodCount, periodToken, , priceCurrency] = match
    let periodName = `${periodCount}.`
    if (periodCount === '1') {
      periodName += periodToken === 'yr' ? 'year' : 'month'
    } else {
      periodName += periodToken === 'yr' ? 'years' : 'months'
    }
    return {
      period_name: periodName,
      device_currency: priceCurrency.toUpperCase(),
    }
  }
  return null
}

const getPlanData = (
  plans: EF.GatsbySource8fitBackendPlan[],
  planId: string,
  currency?: EF.supportedCurrency
) => {
  const activePlan = find(({ identifier }: EF.Plan) => identifier === planId)(
    plans
  )
  const localPlans = plans.filter(
    ({ trial_period_days: trialDays }) => trialDays === 0
  )
  const userCurrency =
    currency || activePlan?.device_currency || DEFAULT_CURRENCY
  const planInfoFromId = getPlanInfoFromId(planId)
  const getPeriodPlan = getPlan(localPlans)
  const yearlyPlan = getPeriodPlan('1.year', userCurrency)
  const quarterlyPlan = getPeriodPlan('3.months', userCurrency)
  const monthlyPlan = getPeriodPlan('1.month', userCurrency)
  const fallbackPlan =
    (planInfoFromId &&
      getPeriodPlan(
        planInfoFromId.period_name,
        planInfoFromId.device_currency
      )) ||
    monthlyPlan

  const yearlySaving = getYearlySavingAmount(
    activePlan || fallbackPlan,
    activePlan && is1YearPlan(activePlan) ? monthlyPlan : yearlyPlan
  )

  return {
    activePlan,
    planList: [
      activePlan || quarterlyPlan,
      activePlan?.period_name === '1.year' ? quarterlyPlan : yearlyPlan,
      activePlan?.period_name === '1.month' ? quarterlyPlan : monthlyPlan,
    ],
    yearlySaving,
    monthlyPlan,
    fallbackPlan,
    userCurrency,
  }
}

const getButtonText = (plan: EF.Plan, activePlan?: EF.Plan) => {
  if (!activePlan) return i18n._(t('user.profile.change.plan')`Change plan`)

  return activePlan.period < plan.period
    ? i18n._(t('user.profile.upgrade.cta')`Upgrade and save`)
    : i18n._(t('user.profile.downgrade.cta')`Downgrade`)
}

const ManageSubscriptionPage = ({
  pageContext: { hrefLang, defaultLang },
}: ManageSubscriptionPageProps) => {
  const pageTitle = i18n._(
    t('user.profile.manage.subscription')`Manage subscription`
  )
  const {
    accountState: { currency, subscription, is_pro: isPro },
    cancelSubscriptionStripe,
  } = useUserAccount()
  const [showDowngradeWarningPopup, setShowDowngradeWarningPopup] = useState(
    false
  )
  const [showCancelWarningPopup, setShowCancelWarningPopup] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<EF.Plan | null>(null)
  const [toastMessage, setToastMessage] = useState<{
    message: string
    status: ToastStatus
  } | null>(null)
  const plans = useWSFBackendPlans()

  const toggleDowngradeWarningPopup = () => {
    setShowDowngradeWarningPopup(!showDowngradeWarningPopup)
  }

  const toggleCancelWarningPopup = () => {
    setShowCancelWarningPopup(!showCancelWarningPopup)
  }

  // This is purely for TS. Only a user with a subscription can enter this page.
  if (!subscription) {
    Sentry.addBreadcrumb({
      category: 'Web Profile',
      message: 'User is on Manage Subscription without subscription',
      level: Sentry.Severity.Debug,
    })
    return null
  }

  const {
    activePlan,
    planList,
    yearlySaving,
    monthlyPlan,
    fallbackPlan,
    userCurrency,
  } = getPlanData(plans, subscription.plan, currency)

  const endDate = getDateFromUTCSecondsFromEpoch(
    subscription.ends_at + dayInSeconds
  ).toLocaleDateString()

  const goToPaymentPage = (plan: EF.Plan | null = selectedPlan) => {
    navigate(
      `${i18n._(t`/profile/subscribe/`)}${serialize({
        plan: plan?.identifier,
      })}`
    )
  }

  const onSelectPlan = (plan: EF.Plan) => {
    setSelectedPlan(plan)
    if (activePlan && activePlan.period > plan.period) {
      toggleDowngradeWarningPopup()
      return
    }
    goToPaymentPage(plan)
  }

  const onPressCancel = async () => {
    try {
      await cancelSubscriptionStripe()
      navigateToProfileWithToast('cancelSuccess', ToastStatus.SUCCESS)
    } catch (e) {
      Sentry.captureException(e)
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      setToastMessage({
        message: i18n._(
          t('error.message.default')`Unfortunately an error occurred.`
        ),
        status: ToastStatus.ERROR,
      })
    }
  }

  return (
    <AuthenticatedPage>
      <SeamlessWebsiteLayout
        hrefLang={hrefLang}
        defaultLang={defaultLang}
        title={pageTitle}
        pageTitle={`${pageTitle} | 8fit`}
      >
        <Container className={container}>
          <Row>
            <Col>
              <Breadcrumb items={getBreadcrumbItems()} className={breadcrumb} />
            </Col>
          </Row>
          <Row>
            <Col>
              <H className={title} />
              <p className={subtitle}>
                <Trans id="user.profile.change.subscription.details">
                  Any changes will be applied in the next billing date.
                </Trans>
              </p>
            </Col>
          </Row>
          <Row>
            {planList.map((plan) => (
              <Col
                key={plan.identifier}
                className={cardCol}
                xs={12}
                sm={6}
                lg={4}
              >
                <PlanCard
                  plan={plan}
                  currency={userCurrency}
                  onSelectPlan={onSelectPlan}
                  benefits={getBenefits(!!isPro)}
                  yearlySaving={yearlySaving}
                  headerText={
                    is1YearPlan(plan)
                      ? i18n._(
                          t('user.profile.1year.title')`1 year - best value!`
                        )
                      : undefined
                  }
                  buttonText={getButtonText(plan, activePlan)}
                  isActive={
                    activePlan &&
                    activePlan.period_name === plan.period_name &&
                    activePlan.device_currency === plan.device_currency
                  }
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <hr className={dividerLine} />
              <button className={stopButton} onClick={toggleCancelWarningPopup}>
                <Trans id="user.profile.autorenew.stop.cta">
                  Stop auto-renewal
                </Trans>
              </button>
            </Col>
          </Row>
        </Container>
        <DowngradeWarningPopup
          isOpen={showDowngradeWarningPopup}
          onPressClose={toggleDowngradeWarningPopup}
          yearlySaving={formatPrice(
            getYearlySavingAmount(
              selectedPlan || monthlyPlan,
              activePlan || fallbackPlan
            ),
            userCurrency
          )}
          onPressDowngrade={() => goToPaymentPage()}
        />
        <CancelWarningPopup
          isOpen={showCancelWarningPopup}
          onPressClose={toggleCancelWarningPopup}
          onPressCancel={onPressCancel}
          endDate={endDate}
        />
        {toastMessage && (
          <ToastMessage
            message={toastMessage.message}
            status={toastMessage.status}
            onPressClose={() => setToastMessage(null)}
          />
        )}
      </SeamlessWebsiteLayout>
    </AuthenticatedPage>
  )
}

export default ManageSubscriptionPage
