import React from 'react'

import VideoPlayer, { VideoPlayerProps } from '../components/video-player'

import { videoPlayer } from './video.module.scss'

const VideoSection = (props: VideoPlayerProps) => {
  return (
    <div className={videoPlayer}>
      <VideoPlayer {...props} />
    </div>
  )
}

export default VideoSection
