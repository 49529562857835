import React, { forwardRef } from 'react'
import { Link, GatsbyLinkProps } from 'gatsby'

import { isGatsbyPage } from 'utils/url'

export interface LinkSwitchProps
  extends Omit<GatsbyLinkProps<unknown>, 'to' | 'ref'> {
  path: string
}

const LinkSwitch = forwardRef<HTMLAnchorElement, LinkSwitchProps>(
  ({ path, children, ...props }, ref) => {
    if (isGatsbyPage(path)) {
      return (
        <Link to={path} innerRef={ref} {...props}>
          {children}
        </Link>
      )
    }

    return (
      <a href={path} ref={ref} {...props}>
        {children}
      </a>
    )
  }
)

export default LinkSwitch
