import React from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import StarRating from 'components/star-rating'

import AppStoreRatingBadge from '../badges/app-store-badge'
import PlayStoreRatingBadge from '../badges/play-store-badge'

import { ratingAppStore, ratingPlayStore } from './index.module.scss'

const StoreRatingRow = () => {
  return (
    <>
      <div className={ratingAppStore}>
        <AppStoreRatingBadge />
        <StarRating
          rating={4.7}
          caption={i18n._(
            t('homepage.header.rating_count.appstore')`82K Ratings`
          )}
        />
      </div>
      <div className={ratingPlayStore}>
        <PlayStoreRatingBadge />
        <StarRating
          rating={4.5}
          caption={i18n._(
            t('homepage.header.rating_count.playstore')`146K Ratings`
          )}
        />
      </div>
    </>
  )
}

export default StoreRatingRow
