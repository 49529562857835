import React from 'react'
import { FixedObject } from 'gatsby-image'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import DurationLabel from 'components/labels/duration-label'
import DifficultyLabel from 'components/labels/difficulty-label'
import ActivityCard from 'components/activity-card'

interface WorkoutCardProps {
  linkTo: string
  title: string
  durationMins: number
  description: string
  image: FixedObject
}

const WorkoutCard = ({
  linkTo,
  image,
  durationMins,
  title,
  description,
}: WorkoutCardProps) => {
  const metaInfoList = [
    {
      key: i18n._(t('workout_details.duration')`Duration`),
      value: <DurationLabel min={durationMins} />,
    },
    {
      key: i18n._(t('workout_details.difficulty')`Difficulty`),
      value: <DifficultyLabel />,
    },
  ] as const

  return (
    <ActivityCard
      linkTo={linkTo}
      wave={2}
      image={image}
      title={title}
      metaInfoList={metaInfoList}
      text={description}
    />
  )
}

export default WorkoutCard
