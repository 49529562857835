import { t } from '@lingui/macro'
import React from 'react'

import { i18n } from 'utils/i18n'

import { root } from './index.module.scss'

const DifficultyLabel = () => {
  return (
    <span className={root}>
      {i18n._(t('workout_difficulty.all_levels')`All levels`)}
    </span>
  )
}

export default DifficultyLabel
