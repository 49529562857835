import React from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import GatsbyImage from 'gatsby-image'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Col, Row } from 'components/grid'

import RecipeCategoryLabel from '../components/recipe-category-label'

import {
  image as imageStyle,
  categoryList,
  categoryItem,
  horizontalLine,
  nutritionInfoTitle,
  nutritionInfoList as nutritionInfoListStyle,
  nutritionInfoBlob,
  nutritionInfoKey,
  nutritionInfoValue,
} from './image-nutrition-info.module.scss'

const ImageNutritionInfo = ({
  image,
  categories,
  protein,
  fat,
  carbs,
}: Pick<
  Contentful.Recipe,
  'image' | 'categories' | 'protein' | 'fat' | 'carbs'
>) => {
  const nutritionInfoList = [
    {
      nutrient: i18n._(t('recipe.label.protein')`Protein`),
      grams: protein,
    },
    {
      nutrient: i18n._(t('recipe.label.fat')`Fat`),
      grams: fat,
    },
    {
      nutrient: i18n._(t('recipe.label.carbs')`Net Carbs`),
      grams: carbs,
    },
  ]

  return (
    <DocumentOutlineSection
      title={i18n._(
        t('recipe.nutrition_information.title')`Nutrition information`
      )}
    >
      <Row>
        <Col
          xs={12}
          smOffset={1}
          sm={10}
          mdOffset={0}
          md={6}
          lgOffset={1}
          lg={5}
          xlOffset={0}
          xl={12}
        >
          <div className={imageStyle}>
            <GatsbyImage
              fluid={(image as EF.FluidImageNode).fields.asset.fluid}
            />
          </div>
        </Col>
        <Col xs={12} smOffset={1} sm={10} mdOffset={0} md={6} lg={5} xl={12}>
          <ul className={categoryList}>
            {categories.map((category) => (
              <li key={category} className={categoryItem}>
                <RecipeCategoryLabel category={category} />
              </li>
            ))}
          </ul>
          <hr className={horizontalLine} />
          <H className={nutritionInfoTitle} />
          <dl className={nutritionInfoListStyle}>
            {nutritionInfoList.map(({ nutrient, grams }) => (
              <div className={nutritionInfoBlob} key={nutrient}>
                <dt className={nutritionInfoKey}>{nutrient}</dt>
                <dd className={nutritionInfoValue}>{grams}g</dd>
              </div>
            ))}
          </dl>
        </Col>
      </Row>
    </DocumentOutlineSection>
  )
}

export default ImageNutritionInfo
