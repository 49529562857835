import React from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'

import { classNames } from 'utils/dom'

import {
  root,
  iconContainer,
  blub as blubStyle,
  icon as iconStyle,
  textWrapper,
  title as titleStyle,
  description as descriptionStyle,
  buttonsContainer,
} from './index.module.scss'

interface UspProps {
  className?: string
  icon: string
  blub: string
  title: string
  description: string
  buttons?: JSX.Element
}

const Usp = ({
  className,
  icon,
  blub,
  title,
  description,
  buttons,
}: UspProps) => {
  return (
    <DocumentOutlineSection title={title}>
      <section className={classNames([root, className])}>
        <div className={iconContainer}>
          <img className={blubStyle} src={blub} alt="" role="presentation" />
          <img className={iconStyle} src={icon} alt="" role="presentation" />
        </div>
        <div className={textWrapper}>
          <H className={titleStyle} />
          <p className={descriptionStyle}>{description}</p>
          <div className={buttonsContainer}>{buttons}</div>
        </div>
      </section>
    </DocumentOutlineSection>
  )
}

export default Usp
