import React from 'react'
import GatsbyImage from 'gatsby-image'
import { H } from 'react-document-section'

import { Col, Container, Row } from 'components/grid'

import { PlayButton } from '../components/atomic-components'

import {
  root,
  background,
  image as imageStyle,
  wave,
  contentWrapper,
  title,
  playButton,
} from './hero.module.scss'

interface Props {
  image: EF.FluidImageNode
  onClickPlay: () => void
}

const Hero = ({ image, onClickPlay }: Props) => {
  return (
    <header className={root}>
      <div className={background}>
        <div className={imageStyle}>
          <GatsbyImage
            draggable={false}
            fluid={image.fields.asset.fluid}
            loading="eager"
          />
        </div>
        <div className={wave} />
      </div>
      <Container>
        <Row>
          <Col xs={12} mdOffset={6} md={6} xlOffset={6} xl={6}>
            <div className={contentWrapper}>
              <H className={title} />
              <PlayButton className={playButton} onClick={onClickPlay} />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Hero
