import React, {
  FunctionComponent,
  Context,
  createContext,
  useContext,
} from 'react'
import { useLocation } from '@gatsbyjs/reach-router'

import {
  getCurrentI18nInfo,
  getI18nInfoWhere,
  SupportedLocaleName,
  PublicLocale,
} from 'utils/i18n'

const getAltLangPathsFromGraphQLResult = (hrefLangList: EF.HrefLangNode[]) =>
  hrefLangList.reduce(
    (accum: AltLanguageObject, { node_locale: locale, fields }) => {
      if (fields) {
        const i18nInfo = getI18nInfoWhere({
          locale: locale as SupportedLocaleName,
        })

        if (i18nInfo) accum[i18nInfo.publicLocale] = fields.path
      }

      return accum
    },
    {}
  )

type AltLanguageObject = Partial<Record<PublicLocale, string>>

interface AltLanguagesContextValue {
  alternativeLanguagePaths: AltLanguageObject
  defaultLanguage: { path: string }
  currentLanguage: { lang: string; path: string }
}

const AltLanguagesContext: Context<AltLanguagesContextValue> = createContext({
  alternativeLanguagePaths: {},
  defaultLanguage: { path: '' },
  currentLanguage: { lang: 'en', path: '' },
})

export const AltLanguagesProvider: FunctionComponent<{
  hrefLang: EF.HrefLangNode[]
  defaultLang: EF.DefaultLangNode
}> = ({ hrefLang, defaultLang, children }) => {
  const { pathname } = useLocation()

  const alternativeLanguagePaths = getAltLangPathsFromGraphQLResult(hrefLang)

  const defaultLanguage = {
    path: defaultLang.fields.path,
  }

  const currentLanguage = {
    lang: getCurrentI18nInfo().publicLocale,
    path: pathname,
  }

  return (
    <AltLanguagesContext.Provider
      value={{ alternativeLanguagePaths, defaultLanguage, currentLanguage }}
    >
      {children}
    </AltLanguagesContext.Provider>
  )
}

export const useAltLanguages = () => useContext(AltLanguagesContext)
