import { sessionStorage } from './clientStorage'

type sessionStorageKey = keyof EF.AllowedSessionStorageShape

type readFn = <K extends sessionStorageKey>(
  key: K
) => EF.AllowedSessionStorageShape[K] | undefined

type writeFn = <K extends sessionStorageKey>(
  key: K,
  data: EF.AllowedSessionStorageShape[K]
) => void

type hasFn = <K extends sessionStorageKey>(key: K) => boolean

type removeFn = <K extends sessionStorageKey>(key: K) => void

const read: readFn = (key) => {
  const item = sessionStorage.getItem(key)
  return item ? JSON.parse(item) : undefined
}

const write: writeFn = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data))
}

const has: hasFn = (key) => !!sessionStorage.getItem(key)

const remove: removeFn = (key) => sessionStorage.removeItem(key)

export default {
  read,
  write,
  has,
  remove,
}
