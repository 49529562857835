import cookies from 'browser-cookies'

import { parse } from './url'

const COOKIE_NAME = 'ef_utm_data'
let cookieLastUpdatedAt: number

export interface UTMParams {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_term: string
  utm_adgroup: string
  utm_publisher: string
  utm_section: string
  utm_creative: string
  utm_content: string
}

export type UTMParam = keyof UTMParams

export const getLastUpdatedAt = () => cookieLastUpdatedAt

export const hasUtmCookieBeenWritten = () => cookieLastUpdatedAt !== undefined

export const readUtmParamsFromCookie = <K extends string>(): {
  [key in K]?: string
} => {
  const res: { [key in K]?: string } = {}
  const pattern = /(utm_\w+)=([^|]+)/g
  const value = cookies.get(COOKIE_NAME)

  if (value && pattern.test(value)) {
    value.replace(pattern, (match, key: K, value: string) => {
      res[key] = value

      return match
    })
  }

  return res
}

const writeUtmParamsToCookie = ({
  utm_source: source = '',
  utm_medium: medium = '',
  utm_campaign: campaign = '',
  utm_term: term = '',
  utm_adgroup: adgroup = '',
  utm_publisher: publisher = '',
  utm_section: section = '',
  utm_creative: creative = '',
  utm_content: content = '',
}: Partial<UTMParams>) => {
  const expires = 7 // expires after 7 days
  const path = '/'
  const domain = location.hostname.replace(/^www\./i, '')

  cookies.set(
    COOKIE_NAME,
    `utm_source=${source}|utm_medium=${medium}|utm_campaign=${campaign}|utm_term=${term}|utm_adgroup=${adgroup}|utm_publisher=${publisher}|utm_section=${section}|utm_creative=${creative}|utm_content=${content}`,
    { expires, path, domain }
  )

  cookieLastUpdatedAt = Date.now()
}

export const saveUtmParamsFromSearch = () => {
  if (typeof location !== 'object' || !location.search) return

  const parsed = parse<UTMParam>(location.search)

  if (Object.keys(parsed).length) writeUtmParamsToCookie(parsed)
}
