// extracted by mini-css-extract-plugin
export var burgerIcon = "V-puJ";
export var burgerMenu = "zyg2Q";
export var desktopMenu = "QQ+Qi";
export var drawerContainer = "n1QkK";
export var drawerCta = "W9U8G";
export var drawerTopMenu = "A867M";
export var homepageLink = "NHhp2";
export var languageSelector = "UfJQb";
export var logo = "hBcd-";
export var mobileMenu = "EP869";
export var nav = "N37r2";
export var signupLink = "afBRr";