const IPINFO_API_KEY = '829e12f36ed062'
const COUNTRIES_IMPERIAL_UNITS = ['GB', 'IE', 'US']
const COUNTRIES_SUNDAY_FIRST = [
  'AS',
  'AZ',
  'BW',
  'CA',
  'CN',
  'FO',
  'GE',
  'GL',
  'GU',
  'HK',
  'IE',
  'IL',
  'IN',
  'IS',
  'JM',
  'JP',
  'KG',
  'KR',
  'LA',
  'MH',
  'MN',
  'MO',
  'MP',
  'MT',
  'NZ',
  'PH',
  'PK',
  'SG',
  'SY',
  'TH',
  'TT',
  'TW',
  'UM',
  'US',
  'UZ',
  'VI',
  'ZW',
]
const COUNTRIES_NEWSLETTER_CONSENT = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'GB',
  'CA',
  'AU',
]

export const getIpInfo = async () => {
  const response = await fetch(`https://ipinfo.io/json?token=${IPINFO_API_KEY}`)
  return response.json()
}

const getWeekFirstDay = (countryCode: string) =>
  COUNTRIES_SUNDAY_FIRST.includes(countryCode) ? 'sunday' : 'monday'

const getMeasurementUnits = (countryCode: string) =>
  COUNTRIES_IMPERIAL_UNITS.includes(countryCode) ? 'imperial' : 'metric'

const isNewsletterConsentNeeded = (countryCode: string) =>
  COUNTRIES_NEWSLETTER_CONSENT.includes(countryCode)

const getDataFromIpInfo = async () => {
  const location = await getIpInfo()
  const { ip, country } = location
  const countryCode = country.toUpperCase()
  return {
    ip,
    country,
    location,
    country_code: countryCode,
    first_day: getWeekFirstDay(countryCode),
    units: getMeasurementUnits(countryCode) as 'imperial' | 'metric',
    newsletter_sign_up: isNewsletterConsentNeeded(countryCode)
      ? undefined
      : true,
  }
}

export default getDataFromIpInfo
