import React, {
  useState,
  useContext,
  PropsWithChildren,
  ReactNode,
} from 'react'

interface NotificationObject {
  message: ReactNode
  buttonLabel?: string | null
  dismissable?: boolean
}

export class Notification implements NotificationObject {
  message: ReactNode
  buttonLabel: string | null
  dismissable: boolean

  constructor({
    message = '',
    buttonLabel = 'OK',
    dismissable = true,
  }: NotificationObject) {
    this.message = message
    this.buttonLabel = buttonLabel
    this.dismissable = dismissable
  }

  isEqual(other: Notification | null) {
    return (
      other &&
      (['message', 'buttonLabel', 'dismissable'] as const).every(
        (property) => this[property] === other[property]
      )
    )
  }
}

const NotificationStore = React.createContext<{
  notification?: Notification | null
  setNotification(notification: NotificationObject): void
  clearNotification(): void
}>({
  setNotification: () => undefined,
  clearNotification: () => undefined,
})

const NotificationCentral = ({ children }: PropsWithChildren<{}>) => {
  const [
    currentNotification,
    setCurrentNotification,
  ] = useState<Notification | null>(null)

  const setNotification = (params: NotificationObject) => {
    const newNotification = new Notification(params)
    if (!newNotification.isEqual(currentNotification)) {
      setCurrentNotification(newNotification)
    }
  }

  const clearNotification = () => setCurrentNotification(null)

  return (
    <NotificationStore.Provider
      value={{
        notification: currentNotification,
        setNotification,
        clearNotification,
      }}
    >
      {children}
    </NotificationStore.Provider>
  )
}

export default NotificationCentral

export const useNotificationCentral = () => useContext(NotificationStore)
