import { useMemo, useState, useEffect } from 'react'
import { noop } from 'lodash'

const useMatchMedia = (query: string) => {
  const mediaQueryList = useMemo(
    () =>
      typeof matchMedia === 'function' && query ? matchMedia(query) : null,
    [query]
  )
  const [matches, setMatches] = useState(
    mediaQueryList ? mediaQueryList.matches : undefined
  )

  useEffect(() => {
    const onMatchChange = ({ matches }: { matches: boolean }) => {
      setMatches(matches)
    }

    if (mediaQueryList) {
      mediaQueryList.addListener(onMatchChange)

      return () => {
        mediaQueryList.removeListener(onMatchChange)
      }
    }

    return noop
  }, [mediaQueryList])

  return { matches }
}

export default useMatchMedia
