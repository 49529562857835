import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import PeachShapeSVG from 'assets/images/signup-themes/default/bg-shape-peach.svg'
import GrayShapeSVG from 'assets/images/signup-themes/default/bg-shape-gray.svg'

import {
  container,
  grayShape,
  peachShape,
  mel as melStyle,
} from './Background.module.scss'

const query = graphql`
  query LoginPageBackground {
    mel: file(relativePath: { eq: "background/mel.png" }) {
      childImageSharp {
        fixed(width: 901, height: 905) {
          width
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const Background = () => {
  const { mel } = useStaticQuery<{ mel: EF.LocalFixedImage }>(query)
  return (
    <div className={container}>
      <img
        className={grayShape}
        src={GrayShapeSVG}
        alt=""
        role="presentation"
      />
      <img
        className={peachShape}
        src={PeachShapeSVG}
        alt=""
        role="presentation"
      />
      <span className={melStyle}>
        <GatsbyImage fixed={mel.childImageSharp.fixed} />
      </span>
    </div>
  )
}

export default Background
