import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { Hidden } from 'components/grid'
import { SelectiveVisible, Visible } from 'components/grid/hidden-visible'

import {
  background,
  backgroundImageMobile,
  backgroundImageTablet,
  backgroundImageDesktop,
  backgroundDecorator,
  backgroundBottomWave,
} from './index.module.scss'

const query = graphql`
  query HomepageHeaderBackground {
    mobile: file(
      relativePath: { eq: "homepage/header-background-mobile.jpg" }
    ) {
      childImageSharp {
        fixed(width: 575, height: 809) {
          width
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    tablet: file(
      relativePath: { eq: "homepage/header-background-tablet.jpg" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 1020) {
          width
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    desktop: file(
      relativePath: { eq: "homepage/header-background-desktop.jpg" }
    ) {
      childImageSharp {
        fixed(width: 1750, height: 825) {
          width
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const Background = () => {
  const { mobile, tablet, desktop } = useStaticQuery<{
    mobile: EF.LocalFixedImage
    tablet: EF.LocalFixedImage
    desktop: EF.LocalFixedImage
  }>(query)
  return (
    <div className={background}>
      <div>
        <Hidden
          sm
          className={backgroundImageMobile}
          style={{ width: mobile.childImageSharp.fixed.width }}
        >
          <GatsbyImage fixed={mobile.childImageSharp.fixed} loading="eager" />
        </Hidden>
        <SelectiveVisible
          xs="hidden"
          sm="visible"
          xl="hidden"
          className={backgroundImageTablet}
          style={{ width: tablet.childImageSharp.fixed.width }}
        >
          <GatsbyImage fixed={tablet.childImageSharp.fixed} loading="eager" />
        </SelectiveVisible>
        <Visible
          xl
          className={backgroundImageDesktop}
          style={{ width: desktop.childImageSharp.fixed.width }}
        >
          <GatsbyImage fixed={desktop.childImageSharp.fixed} loading="eager" />
        </Visible>
      </div>
      <div className={backgroundDecorator} />
      <div className={backgroundBottomWave} />
    </div>
  )
}

export default Background
