import React from 'react'

import logo from 'assets/images/icons/logo.svg'
import logoWhite from 'assets/images/icons/logo-white.svg'

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    'src' | 'alt'
  > {
  alt?: string
  white?: boolean
}

const Logo = ({ white, ...props }: Props) => (
  <img src={white ? logoWhite : logo} alt="8fit" {...props} />
)

export default Logo
