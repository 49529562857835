import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'

import { SideEffect } from '../types'

const createAccountFacebookEffect: SideEffect = {
  name: 'Create account with Facebook',
  isSilent: false,
  isRecurring: true,
  condition: () => {
    const { getAttribute } = UserAccountStore
    return (
      getAttribute('id') === undefined &&
      getAttribute('SignupScreenSignupOptions') === 'Facebook' &&
      !!getAttribute('oAuthKey') &&
      getAttribute('policy_last_accepted_at') !== undefined &&
      getAttribute('policy_health_data_consent') !== undefined
    )
  },
  execute: async () => {
    const fbAccessToken = UserAccountStore.getAttribute('oAuthKey')
    // We already checked for the existence of oAuthKey in the condition
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await UserAccountStore.createAccountWithFacebook(fbAccessToken!)
    UserAccountStore.deleteAttribute('oAuthKey')
  },
}

export default createAccountFacebookEffect
