import { useLocation } from '@gatsbyjs/reach-router'

import { parse } from 'utils/url'

const useGetParams = <T extends string>() => {
  const { search } = useLocation()
  return parse<T>(search)
}

export default useGetParams
