import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t, Trans } from '@lingui/macro'
import GatsbyImage, { FluidObject } from 'gatsby-image'

import { i18n } from 'utils/i18n'
import { Container, Row, Col, Hidden, Visible } from 'components/grid'
import { SecondaryButtonLink } from 'components/Buttons'

import {
  root,
  title as titleStyle,
  description,
  paragraph,
} from './index.module.scss'

const query = graphql`
  query UserJourneyQuery {
    belen: file(relativePath: { eq: "homepage/user-journey-belen.png" }) {
      childImageSharp {
        fluid(
          sizes: "(min-width: 576px) calc(66.67vw - 60px), (min-width: 992px) 50vw, (min-width: 1280px) 640px, calc(100vw - 60px)"
          maxWidth: 640
          srcSetBreakpoints: [
            290
            315
            324
            496
            515
            580
            600
            630
            648
            992
            1030
            1200
          ]
          webpQuality: 95
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

interface UserJourneyQueryResult {
  belen: {
    childImageSharp: { fluid: FluidObject }
  }
}

const UserJourney = () => {
  const { belen } = useStaticQuery<UserJourneyQueryResult>(query)

  return (
    <DocumentOutlineSection
      title={i18n._(t('homepage.user_journey.title')`Start your 8fit journey`)}
    >
      <section className={root}>
        <Container>
          <Row>
            <Col xs={10} xsOffset={1} sm={8} smOffset={2}>
              <Hidden lg>
                <H className={titleStyle} />
              </Hidden>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={8} smOffset={2} lg={6} lgOffset={1}>
              <GatsbyImage fluid={belen.childImageSharp.fluid} />
            </Col>
            <Col xs={12} sm={8} smOffset={2} lg={4} lgOffset={0}>
              <Visible lg>
                <H className={titleStyle} />
              </Visible>
              <div className={description}>
                <Trans id="homepage.user_journey.description">
                  <p className={paragraph}>
                    We encourage you to take your fitness journey one step at a
                    time, and we don't subscribe to a "no pain, no gain"
                    mindset.
                  </p>
                  <p className={paragraph}>
                    We offer realistic guidance — no quick-fixes because they
                    just simply do not work.
                  </p>
                  <p className={paragraph}>
                    You will learn how to take care of your whole self by making
                    gradual habit changes.
                  </p>
                </Trans>
              </div>
              <SecondaryButtonLink
                path={i18n._(t`/transformation-stories/belen/`)}
                text={i18n._(t('homepage.user_journey.cta')`Read more`)}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </DocumentOutlineSection>
  )
}

export default UserJourney
