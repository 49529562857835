import React from 'react'

import greyUnfilledBlub from 'assets/images/blubs/grey-unfilled.svg'
import { Row, Col } from 'components/grid'

import {
  valuesList as valuesListStyle,
  valueListItem as valueListItemStyle,
  iconContainer,
  valueBlub,
  valueIcon,
  valueTitle,
  valueDescription,
} from './index.module.scss'

export interface ValueItem {
  title: string
  description: string
  icon: string
}

const Values = ({ valuesList }: { valuesList: ValueItem[] }) => (
  <Row tagName="ul" className={valuesListStyle}>
    {valuesList.map(({ title, description, icon }) => (
      <Col
        tagName="li"
        className={valueListItemStyle}
        key={title}
        xs={12}
        md={6}
        lg={3}
      >
        <div className={iconContainer}>
          <img
            src={greyUnfilledBlub}
            className={valueBlub}
            alt=""
            role="presentation"
          />
          <img src={icon} className={valueIcon} alt="" role="presentation" />
        </div>
        <p className={valueTitle}>{title}</p>
        <p className={valueDescription}>{description}</p>
      </Col>
    ))}
  </Row>
)

export default Values
