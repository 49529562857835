import React, { FunctionComponent } from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { useMatchMedia } from 'hooks'
import { i18n } from 'utils/i18n'
import { UnderlineCalloutHeader } from 'components/UnderlineText'
import { breakpoints } from 'components/grid'
import { PrimaryButtonLink } from 'components/Buttons'
import Carousel from 'components/carousel'
import UserReview, { UserReviewQueryType } from 'components/userReview'

import {
  section,
  title,
  carousel,
  userReview,
} from './user-review-carousel.module.scss'

interface UserReviewCarouselProps {
  userReviewList: UserReviewQueryType[]
  ctaText: string
  ctaPath: string
}

const UserReviewCarousel: FunctionComponent<UserReviewCarouselProps> = ({
  userReviewList,
  ctaText,
  ctaPath,
}) => {
  const { matches: isMedium } = useMatchMedia(
    `(min-width: ${breakpoints.md}px)`
  )

  if (userReviewList.length === 0) return null

  return (
    <DocumentOutlineSection
      title={<UnderlineCalloutHeader text={i18n._(t`User Reviews`)} />}
    >
      <section className={section}>
        <H className={title} />
        <Carousel
          className={carousel}
          gap={isMedium ? 80 : 40}
          keyPrefix="user-review"
          defaultIndex={isMedium ? 1 : 0}
          showDots
        >
          {userReviewList.map(
            ({ name, photo, review: { review }, isPro, rating }, idx) => (
              <UserReview
                key={`user-review-${idx}`}
                className={userReview}
                name={name}
                photo={photo.fields.asset}
                review={review}
                isPro={isPro}
                rating={rating}
              />
            )
          )}
        </Carousel>
        <PrimaryButtonLink text={ctaText} path={ctaPath} />
      </section>
    </DocumentOutlineSection>
  )
}

export default UserReviewCarousel
