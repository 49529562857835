import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import { classNames } from 'utils/dom'

import { container, card } from './SupportedCards.module.scss'

interface SupportedCardsProps {
  className?: string
}

const query = graphql`
  {
    mastercard: file(name: { eq: "mastercard" }) {
      childImageSharp {
        fluid(maxHeight: 50) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    visa: file(name: { eq: "visa" }) {
      childImageSharp {
        fluid(maxHeight: 50) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    amex: file(name: { eq: "amex" }) {
      childImageSharp {
        fluid(maxHeight: 50) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const SupportedCards = ({ className = '' }: SupportedCardsProps) => {
  const { mastercard, visa, amex } = useStaticQuery<{
    mastercard: EF.LocalFluidImage
    visa: EF.LocalFluidImage
    amex: EF.LocalFluidImage
  }>(query)
  return (
    <div className={classNames([container, className])}>
      <div data-card-type="mastercard" className={card}>
        <Image fluid={mastercard.childImageSharp.fluid} />
      </div>
      <div data-card-type="visa" className={card}>
        <Image fluid={visa.childImageSharp.fluid} />
      </div>
      <div data-card-type="amex" className={card}>
        <Image fluid={amex.childImageSharp.fluid} />
      </div>
    </div>
  )
}

export default SupportedCards
