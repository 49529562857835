import React from 'react'

import RecipeCard from 'components/recipe-card'

import ActivityCardsCarousel from '.'
import { recipes as recipesStyle } from './recipes-workouts.module.scss'

type RecipeList = Pick<
  Contentful.Recipe,
  | 'title'
  | 'categories'
  | 'preparationTimeMin'
  | 'calories'
  | 'fields'
  | 'image'
>[]

interface Props {
  title: string
  description: string
  recipes: RecipeList
}

const RecipeCardsCarousel = ({ title, description, recipes }: Props) => {
  return (
    <ActivityCardsCarousel
      title={title}
      titleId="recipes"
      description={description}
      className={recipesStyle}
    >
      {recipes.map(
        ({
          title,
          categories,
          preparationTimeMin,
          calories,
          fields,
          image,
        }) => (
          <RecipeCard
            key={fields.path}
            title={title}
            categories={categories}
            preparationTimeMin={preparationTimeMin}
            calories={calories}
            image={(image as EF.FixedImageNode).fields.asset.fixed}
            linkTo={fields.path}
          />
        )
      )}
    </ActivityCardsCarousel>
  )
}

export default RecipeCardsCarousel
