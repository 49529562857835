import React, { FunctionComponent } from 'react'

import { classNames } from 'utils/dom'

import Layout, { LayoutProps } from '.'
import { root, contentOrigin } from './website.module.scss'

// should have a new footer
const LandingPageLayout: FunctionComponent<LayoutProps> = ({
  children,
  className,
  classNameContentOrigin = '',
  ...props
}) => (
  <Layout className={classNames([root, className])} {...props}>
    <div className={classNames([contentOrigin, classNameContentOrigin])}>
      {children}
    </div>
  </Layout>
)

export default LandingPageLayout
