import React, { FunctionComponent } from 'react'
import { omit } from 'lodash/fp'

import {
  getClassname as _getClassname,
  colSizeNames,
  modifiers,
  Vessel,
  VesselProps,
  HTMLElementProps,
  ColSizeName,
  Modifier,
} from './common'
import * as styles from './flexboxgrid.module.scss'

type ColSizeNameProps = {
  [key in ColSizeName]?: number
}

export interface ColProps extends HTMLElementProps, ColSizeNameProps {
  tagName?: string
  xsOffset?: number
  smOffset?: number
  mdOffset?: number
  lgOffset?: number
  xlOffset?: number
}

const getClassname = (parts: string[]) => _getClassname(parts, styles)

export const Col: FunctionComponent<ColProps> = (props) => {
  const colClasses = []

  colSizeNames.forEach((colSizeName) => {
    if (colSizeName in props) {
      colClasses.push(
        getClassname([
          'col',
          colSizeName,
          String(props[colSizeName as keyof ColProps]),
        ])
      )
    }

    const offsetSizeName = `${colSizeName}Offset`
    if (offsetSizeName in props) {
      colClasses.push(
        getClassname([
          'col',
          colSizeName,
          'offset',
          String(props[offsetSizeName as keyof ColProps]),
        ])
      )
    }
  })

  if (colClasses.length === 0) {
    colClasses.push(getClassname(['col', colSizeNames[0]]))
  }

  const restProps = omit([
    ...colSizeNames,
    ...colSizeNames.map((colSizeName) => `${colSizeName}Offset`),
  ])(props)

  return <Vessel gridClassNames={colClasses} {...restProps} />
}

type ModifierProps = {
  [key in Modifier]?: ColSizeName
}

export interface RowProps extends VesselProps, ModifierProps {}

export const Row: FunctionComponent<RowProps> = (props) => {
  const rowClasses = [getClassname(['row'])]

  modifiers.forEach((modifier) => {
    if (modifier in props) {
      rowClasses.push(
        getClassname([modifier, props[modifier as keyof VesselProps]])
      )
    }
  })
  const restProps = omit(modifiers)(props)

  return <Vessel gridClassNames={rowClasses} {...restProps} />
}

export interface ContainerProps extends VesselProps {
  fluid?: boolean
}

export const Container: FunctionComponent<ContainerProps> = ({
  fluid,
  ...props
}) => (
  <Vessel
    gridClassNames={[getClassname([fluid ? 'containerFluid' : 'container'])]}
    {...props}
  />
)

export { breakpoints } from './common'

export { Hidden, Visible } from './hidden-visible'
