import { useRef, useEffect } from 'react'

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval
  useEffect(() => {
    function runAtInterval() {
      savedCallback.current && savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(runAtInterval, delay)
      return () => clearInterval(id)
    }
    return undefined
  }, [delay])
}

export default useInterval
