import React, { FunctionComponent } from 'react'

import SocialIcon from 'components/icons/SocialIcon'

import {
  FacebookSharingLink,
  TwitterSharingLink,
  TwitterSharingLinkProps,
  PinterestSharingLink,
  PinterestSharingLinkProps,
} from './share-links'
import { icon } from './index.module.scss'

export const FacebookButton: FunctionComponent = (props) => (
  <FacebookSharingLink {...props}>
    <SocialIcon socialNetworkName="facebook" className={icon} />
  </FacebookSharingLink>
)

export const TwitterButton: FunctionComponent<TwitterSharingLinkProps> = (
  props
) => (
  <TwitterSharingLink {...props}>
    <SocialIcon socialNetworkName="twitter" className={icon} />
  </TwitterSharingLink>
)

export const PinterestButton: FunctionComponent<PinterestSharingLinkProps> = (
  props
) => (
  <PinterestSharingLink {...props}>
    <SocialIcon socialNetworkName="pinterest" className={icon} />
  </PinterestSharingLink>
)
