import React from 'react'
import { t } from '@lingui/macro'

import {
  useUserAccount,
  AuthenticationState,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import { classNames } from 'utils/dom'
import {
  SecondaryButtonLink,
  ImageIconButtonLink,
  ButtonLinkProps,
} from 'components/Buttons'
import ProfileImagePlaceholderSVG from 'assets/images/icons/profile-image-placeholder.svg'

interface LoginCtaProps extends Omit<ButtonLinkProps, 'text' | 'path'> {
  classNameUnauthenticated?: string
  classNameAuthenticated?: string
}

const LoginCta = ({
  classNameUnauthenticated,
  classNameAuthenticated,
  className,
  ...props
}: LoginCtaProps) => {
  const {
    authenticationState,
    accountState: { name, email, profile_image_url: profileImageUrl },
  } = useUserAccount()
  if (authenticationState === AuthenticationState.Authenticated) {
    return (
      <ImageIconButtonLink
        path={i18n._(t`/profile/`)}
        text={name || email || ''}
        imageIconUrl={profileImageUrl || ProfileImagePlaceholderSVG}
        className={classNames([className, classNameAuthenticated])}
        {...props}
      />
    )
  }
  return (
    <SecondaryButtonLink
      path={i18n._(t`/login/`)}
      text={i18n._(t('user.profile.login')`Log in`)}
      data-state={
        authenticationState === AuthenticationState.Processing
          ? 'disabled'
          : undefined
      }
      className={classNames([className, classNameUnauthenticated])}
      {...props}
    />
  )
}

export default LoginCta
