// extracted by mini-css-extract-plugin
export var categoryIcon = "AW1ms";
export var categoryLink = "MERnt";
export var categoryName = "ZvFYb";
export var categoryNameWord = "Q1uS-";
export var categoryNavigationList = "EYD6A";
export var categorySection = "V+QVr";
export var categorySectionRoot = "JXJmT";
export var contentWrapper = "kuTp4";
export var root = "_1IDOw";
export var subcategoryItem = "WJoX1";
export var subcategoryLink = "_0bkre";
export var subcategoryList = "V3FuI";
export var title = "-sI8P";