import { wait } from 'utils/async'

let appboyWebSdk
let isUserCreated = false
let isCompleted = false

export const initAppboySdk = async () => {
  if (!appboyWebSdk) {
    appboyWebSdk = await import('appboy-web-sdk')
    appboyWebSdk.initialize(process.env.GATSBY_APPBOY_API_KEY, {
      enableLogging: process.env.NODE_ENV !== 'production',
    })
    appboyWebSdk.display.automaticallyShowNewInAppMessages()
    appboyWebSdk.openSession()
  }
  return appboyWebSdk
}

export const getAppboySdk = () => appboyWebSdk

export const setAppboyUser = (userId) =>
  new Promise((resolve) => {
    appboyWebSdk.getUser().getUserId((currentUserId) => {
      if (currentUserId !== userId) {
        appboyWebSdk.changeUser(userId)
      }
      isUserCreated = true
      resolve()
    })
  })

export const createAppboyUser = ({
  userId,
  language,
  country,
  gender,
  email,
  name,
  hasNewsletterAccepted,
  usersrc,
  usercmp,
}) =>
  new Promise((resolve, reject) => {
    if (!appboyWebSdk) {
      return reject(new Error('SDK not initialized yet'))
    } else if (isUserCreated) {
      return reject(new Error('User already exists'))
    }
    appboyWebSdk.changeUser(userId)
    const genders = appboyWebSdk.ab.User.Genders
    const user = appboyWebSdk.getUser()
    user.setLanguage(language)
    user.setCountry(country)
    user.setEmail(email)
    user.setFirstName(name)
    user.setGender(gender === 'male' ? genders.MALE : genders.FEMALE)
    user.setCustomUserAttribute('newsletter_sign_up', hasNewsletterAccepted)
    user.setCustomUserAttribute('usersrc', usersrc)
    user.setCustomUserAttribute('usercmp', usercmp)
    isUserCreated = true
    // This is a horrible hack. Due to Appboy's two way event system we need
    // to postpone sending the event until we *hope* that the event goes through.
    // This is why we're using a timeout here. However, if we make the user wait
    // for the entirety of the timeout (make them wait for 8 seconds) they might
    // think something's broken (they'd be right: It's Appboy's event system 🥁)
    // and leave the site. If we don't make them wait at all maybe they complete
    // the whole signup and leave the site before the timeout fires. So, I'm
    // splitting the timeout into two: The first three seconds we make the user
    // wait and the other 5 seconds are done "in the background" until the event
    // is finally logged. This is highly arbitrary code. Please fix once Appboy
    // is fixed (so, never).
    wait(3 * 1000)
      .then(() => {
        resolve()
        return wait(5 * 1000)
      })
      .then(() => {
        appboyWebSdk.logCustomEvent('web_account_created', {
          newsletter_sign_up: hasNewsletterAccepted,
          usersrc,
          usercmp,
        })
      })
  })

export const completeAppboyOnboarding = ({ usersrc, usercmp, isPro }) =>
  new Promise((resolve, reject) => {
    if (!appboyWebSdk) {
      return reject(new Error('SDK not initialized yet'))
    } else if (!isUserCreated) {
      return reject(new Error("User doesn't exist yet"))
    } else if (isCompleted) {
      return reject(new Error('Onboarding already completed'))
    }
    appboyWebSdk.logCustomEvent('web_onboarding_complete', {
      usersrc,
      usercmp,
    })
    const user = appboyWebSdk.getUser()
    user.setCustomUserAttribute('is_pro', String(isPro))
    isCompleted = true
    resolve()
  })

export const subscribeNewsletter = async (email) => {
  await initAppboySdk()
  const userId = `${email}_8`
  appboyWebSdk.changeUser(userId)
  const user = appboyWebSdk.getUser()
  user.setEmail(email)
  user.setCustomUserAttribute('blog_newsletter', true)
}
