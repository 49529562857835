import React, { useState, forwardRef } from 'react'
import { Trans, t } from '@lingui/macro'
import { Link } from 'gatsby'
import { ReactStripeElements } from 'react-stripe-elements'

import Payment from 'components/payment'
import { i18n } from 'utils/i18n'
import { calcDiscountedPrice } from 'utils/math'
import { Visible } from 'components/grid'
import { DiscountData } from 'components/payment/types'
import PlanDescription from 'components/payment-page/plan-description'
import {
  calcSubscriptionStartDate,
  convertPeriodToMonthCount,
  getRenewalDateFrom,
  getRenewalPeriod,
} from 'utils/payment'
import { useUserAccount } from 'gatsby-plugin-8fit-user-account'

import UserInfo from './UserInfo'
import {
  container,
  discountDisplayContainer,
  discountDisplay as discountDisplayStyle,
  disclaimer,
  tnc,
} from './StripePaymentArea.module.scss'

interface StripePaymentAreaProps {
  discountDisplay: string
  displayPrice: number
  plan: EF.GatsbySource8fitBackendPlan
  prefilledVoucherData: EF.DiscountInformation | null
  submitButtonText: string
  onReceiveDiscountInformation: (data: DiscountData) => void
  onSubscribeStart: () => void
  onSubscribe: (props: ReactStripeElements.TokenResponse) => Promise<void>
}

const StripePaymentArea = forwardRef(
  (
    {
      discountDisplay,
      displayPrice: displayPriceProp,
      plan,
      prefilledVoucherData,
      submitButtonText,
      onReceiveDiscountInformation,
      onSubscribeStart,
      onSubscribe,
    }: StripePaymentAreaProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const originalPrice = plan.additionalInfo.normalizedPrice
    const [
      discountData,
      setDiscountData,
    ] = useState<EF.DiscountInformation | null>(prefilledVoucherData)
    let displayPrice =
      displayPriceProp < originalPrice ? displayPriceProp : undefined
    if (!prefilledVoucherData && discountData?.percent_off) {
      // The user applied a coupon
      displayPrice = calcDiscountedPrice(
        originalPrice,
        discountData.percent_off
      )
    }
    const hasDiscount = !!displayPrice && displayPrice < originalPrice
    // We're on a CRM LP so it's likely the user isn't logged in. But if they do then lets use this
    // information in case they happen to have a subscription:
    const {
      accountState: { subscription },
    } = useUserAccount()

    return (
      <div className={container} ref={ref}>
        {discountDisplay && (
          <div className={discountDisplayContainer}>
            <div className={discountDisplayStyle}>{discountDisplay}</div>
          </div>
        )}
        <Visible md>
          <UserInfo />
        </Visible>
        <Payment
          originalPrice={originalPrice}
          currencyCode={plan.device_currency}
          displayPrice={displayPrice}
          prefilledVoucherData={prefilledVoucherData || undefined}
          onReceiveDiscountInformation={(data) => {
            if (!prefilledVoucherData && data?.code) {
              setDiscountData(data)
              onReceiveDiscountInformation(data)
            }
          }}
          period={plan.period_name}
          submitButtonText={submitButtonText}
          onSubscribe={onSubscribe}
          onSubscribeStart={onSubscribeStart}
        />
        <PlanDescription
          className={disclaimer}
          currencyCode={plan.device_currency}
          fullPrice={originalPrice}
          displayPrice={displayPrice || originalPrice}
          hasDiscount={hasDiscount}
          discountInformation={discountData}
          renewalDate={getRenewalDateFrom(
            calcSubscriptionStartDate(subscription),
            plan.period_name
          )}
          renewalPeriodMonths={convertPeriodToMonthCount(plan.period)}
          renewalPeriodText={getRenewalPeriod()[plan.period_name]}
          trialPeriodDays={plan.trial_period_days}
        />
        <div className={tnc}>
          <Trans>
            See our{' '}
            <strong>
              <Link to={i18n._(t`/terms-of-service/`)}>Terms of Service</Link>
            </strong>
          </Trans>
        </div>
      </div>
    )
  }
)

export default StripePaymentArea
