export const CURRENCY_SYMBOLS = new Map<
  EF.supportedCurrency,
  EF.supportedCurrencySymbol
>([
  ['USD', '$'],
  ['CAD', '$'],
  ['AUD', '$'],
  ['EUR', '€'],
  ['GBP', '£'],
  ['BRL', 'R$'],
  ['NZD', '$'],
  ['CHF', 'CHF'],
  ['MXN', '$'],
])

export const SUPPORTED_CURRENCIES = Array.from(CURRENCY_SYMBOLS.keys())

export const DEFAULT_CURRENCY = 'USD'

export const BODYFAT_RANGES_FEMALE = [
  [7, 12],
  [12, 17],
  [17, 22],
  [22, 27],
  [27, 32],
  [32, 42],
  [42, 47],
  [47, 52],
]

export const BODYFAT_RANGES_MALE = [
  [4, 9],
  [9, 14],
  [14, 19],
  [19, 24],
  [24, 29],
  [29, 34],
  [34, 39],
  [39, 44],
  [44, 54],
]
