import React from 'react'

import { root } from './index.module.scss'

const CategoryLabel = ({
  category,
  identifier,
}: {
  category: string
  identifier: string
}) => (
  <span className={root} data-category={identifier}>
    {category}
  </span>
)

export default CategoryLabel
