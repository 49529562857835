import React from 'react'
import { t } from '@lingui/macro'
import { Link } from 'gatsby'

import {
  AuthenticationState,
  useUserAccount,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import FunnelCta from 'components/funnel-cta'
import LoginCta from 'components/login-cta'
import LanguageSelector from 'components/language-selector/LanguageSelector'
import { PrimaryButtonLink } from 'components/Buttons'
import Logo from 'components/logo'

import {
  root,
  logo,
  linkList,
  linkListLogo,
  linkListText,
  rightSection,
  loginButton,
  profileButton,
  signupCta,
} from './websiteHeaderDesktop.module.scss'

interface LinkItem {
  id: string
  text: string
  to: string
}

interface Props {
  hrefLang: EF.HrefLangNode[]
  navigationList?: LinkItem[]
}

const WebsiteHeaderDesktop = ({
  hrefLang,
  navigationList = [
    {
      id: 'workouts',
      text: i18n._(t('header.links.workouts')`Workouts`),
      to: i18n._(t`/#workouts`),
    },
    {
      id: 'recipes',
      text: i18n._(t('header.links.meals')`Meals`),
      to: i18n._(t`/#recipes`),
    },
    {
      id: 'transformation-stories',
      text: i18n._(t('header.links.stories')`Success stories`),
      to: i18n._(t`/transformation-stories/`),
    },
    {
      id: 'articles',
      text: i18n._(t('header.links.articles')`Articles`),
      to: i18n._(t`/articles/`),
    },
  ],
}: Props) => {
  const {
    authenticationState,
    accountState: { is_pro: isPro },
  } = useUserAccount()
  const buttonState =
    authenticationState === AuthenticationState.Processing
      ? 'disabled'
      : undefined
  const isAuthenticated =
    authenticationState === AuthenticationState.Authenticated

  return (
    <header className={root}>
      <nav>
        <ul className={linkList}>
          <li className={linkListLogo}>
            <Link to={i18n._(t`/`)}>
              <Logo alt={i18n._(t`Homepage`)} className={logo} />
            </Link>
          </li>
          {navigationList.map(({ id, text, to }) => (
            <li key={id} className={linkListText}>
              <Link to={to} data-testid={id}>
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className={rightSection}>
        <LoginCta
          data-state={buttonState}
          classNameUnauthenticated={loginButton}
          classNameAuthenticated={profileButton}
        />
        {!isAuthenticated && (
          <FunnelCta
            text={i18n._(t('website_header.signup_cta')`Start FREE trial`)}
            id="nav-bar-cta"
            data-state={buttonState}
            className={signupCta}
          />
        )}
        {isAuthenticated && !isPro && (
          <PrimaryButtonLink
            path={i18n._(t`/profile/plans-overview/`)}
            text={i18n._(t('user.profile.unlock')`Unlock PRO`)}
            id="nav-bar-cta"
            data-state={buttonState}
            className={signupCta}
          />
        )}
        <LanguageSelector hrefLang={hrefLang} />
      </div>
    </header>
  )
}

export default WebsiteHeaderDesktop
