import { omit } from 'lodash'
import {
  UserAccountAttributes,
  setAttributeType,
  getAttributeType,
  UserAccountAttributeKey,
  BackendUserAccountAttributes,
} from '../types'

import ApiClient from './8fit-api'

interface EventHandlers {
  setAttribute: () => void
  authenticate: (authToken: string) => void
  logout: () => void
}

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

class UserAccount {
  attributes = new Map<
    keyof Partial<UserAccountAttributes>,
    UserAccountAttributes[UserAccountAttributeKey]
  >()
  authToken?: string | null = null
  private eventHandlers = {} as Partial<EventHandlers>
  private apiClient: undefined | ApiClient

  private apiClientMethodProxy = <
    M extends Exclude<keyof ApiClient, 'isAuthenticated' | 'authToken'>
  >(
    methodName: M
  ) => async (...args: Parameters<ApiClient[M]>) => {
    if (!this.apiClient) {
      const { authToken, onAuthenticate, onLogout } = this
      const { default: ApiClient } = await import('./8fit-api')
      this.apiClient = new ApiClient({
        authToken,
        apiRoot: process.env.GATSBY_API_ROOT as string,
        onAuthenticate,
        onLogout,
      })
    }
    // @ts-ignore We're doing our best already with the types. Idk how to make TS happy here
    return this.apiClient[methodName](...args) as ThenArg<
      ReturnType<ApiClient[M]>
    >
  }

  fetchAccount = async (shouldPreventMerge?: boolean) => {
    const attributes = await this.apiClientMethodProxy('fetchAccount')()
    if (attributes && !shouldPreventMerge) {
      this.mergeAttributes(attributes)
    }
    return attributes as BackendUserAccountAttributes | null
  }

  createAccountWithEmail = async () => {
    const result = await this.apiClientMethodProxy('createAccountWithEmail')(
      this.toJson()
    )
    const { name, email, id, onboarded, authentication_token } = result
    this.setSession(authentication_token)
    this.mergeAttributes({
      name,
      email,
      id,
      isNewUser: !onboarded,
    })
    return result
  }

  updateAccount = async () => {
    const attrs = omit(this.toJson(), ['password']) // omit sending the password on account update
    const attributes = await this.apiClientMethodProxy('updateAccount')(
      attrs
    )
    if (attributes) {
      this.mergeAttributes(attributes)
    }
    return attributes
  }

  loginWithEmail = async (email: string, password: string) => {
    const result = await this.apiClientMethodProxy('loginWithEmail')(
      email,
      password
    )
    const { authentication_token: authToken } = result
    this.setSession(authToken)
    return result
  }

  logout = this.apiClientMethodProxy('logout')

  createAccountWithFacebook = async (accessToken: string) => {
    const {
      user_id: id,
      email,
      authentication_token: authToken,
      new_user: isNewUser,
    } = await this.apiClientMethodProxy('createAccountWithFacebook')(
      accessToken
    )
    this.setSession(authToken)
    this.mergeAttributes({ id, email, isNewUser })
  }

  createAccountWithGoogle = async (idToken: string) => {
    const {
      user_id: id,
      email,
      authentication_token: authToken,
      new_user: isNewUser,
    } = await this.apiClientMethodProxy('createAccountWithGoogle')(idToken)
    this.setSession(authToken)
    this.mergeAttributes({ id, email, isNewUser })
  }

  loginWithFacebook = async (accessToken: string) => {
    const {
      user_id: id,
      email,
      authentication_token: authToken,
      new_user: isNewUser,
    } = await this.apiClientMethodProxy('loginWithFacebook')(accessToken)
    this.setSession(authToken)
    this.mergeAttributes({ id, email, isNewUser })
  }

  loginWithGoogle = async (idToken: string) => {
    const {
      user_id: id,
      email,
      authentication_token: authToken,
      new_user: isNewUser,
    } = await this.apiClientMethodProxy('loginWithGoogle')(idToken)
    this.setSession(authToken)
    this.mergeAttributes({ id, email, isNewUser })
  }

  validateDiscountCode = (code: string) =>
    this.apiClientMethodProxy('validateDiscountCode')({
      code,
      userId: this.getAttribute('id'),
    })

  subscribeStripe = async ({
    method,
    ...args
  }: {
    method?: 'PUT' | 'POST'
    user_id: string
    plan: string
    stripe_token: string
    code?: string
    search?: string
  }) => {
    if (!method) {
      const subscription = this.getAttribute('subscription')
      const isStripeSubscription = subscription?.backend === 'stripe'
      method = isStripeSubscription ? 'PUT' : 'POST'
    }
    const response: EF.SCAResponse = await this.apiClientMethodProxy(
      method === 'PUT' ? 'updateSubscriptionStripe' : 'createSubscriptionStripe'
    )(args)
    if (response.type === 'no_action_required') {
      await this.fetchAccount()
    }
    return response
  }

  cancelSubscriptionStripe = async () => {
    await this.apiClientMethodProxy('cancelSubscriptionStripe')()
    await this.fetchAccount()
  }

  updateStripePayment = async (args: { stripe_token: string }) => {
    const response: EF.SCAResponse = await this.apiClientMethodProxy(
      'updateStripePayment'
    )(args)
    if (response.type === 'no_action_required') {
      await this.fetchAccount()
    }
    return response
  }

  subscribePrepaid = async (args: {
    user_id: string
    plan: string
    code: string
    utm_source?: string
    utm_medium?: string
    utm_term?: string
    utm_adgroup?: string
    utm_publisher?: string
    utm_section?: string
    utm_creative?: string
    utm_content?: string
  }) => {
    await this.apiClientMethodProxy('createPrepaidSubscription')(args)
    await this.fetchAccount()
  }

  fetchUserInfo = this.apiClientMethodProxy('fetchUserInfo')

  becomeUser = async (token: string) => {
    await this.logout()
    await this.apiClientMethodProxy('becomeUser')(token)
    await this.fetchAccount()
  }

  setEventHandler = <K extends keyof EventHandlers>(
    event: K,
    handler: EventHandlers[K] | null
  ) => {
    if (!handler) {
      delete this.eventHandlers[event]
    } else {
      this.eventHandlers[event] = handler
    }
  }

  execEventHandler = <K extends keyof EventHandlers>(
    event: K,
    args = [] as Parameters<EventHandlers[K]>
  ) => {
    const eventHandler = this.eventHandlers[event]
    if (typeof eventHandler === 'function') {
      // @ts-ignore: It has problems I don't understand
      eventHandler(...args)
    }
  }

  setSession = async (authToken: string) => {
    if (authToken && authToken !== this.authToken) {
      this.authToken = authToken
      if (this.apiClient && !this.apiClient.authToken) {
        this.apiClient.authToken = authToken
      }
      await this.fetchAccount()
    }
  }

  onAuthenticate = (authToken: string) => {
    this.authToken = authToken
    this.execEventHandler('authenticate', [authToken])
  }

  onLogout = () => {
    delete this.authToken
    this.attributes.clear()
    this.execEventHandler('logout')
  }

  getAttribute: getAttributeType = (key) =>
    // @ts-ignore: The getAttributeType is better than the typing coming from Map
    this.attributes.get(key)

  setAttribute: setAttributeType = (key, value) => {
    if (process.env.NODE_ENV === 'development' && typeof key !== 'string') {
      throw new Error('`key` needs to be a string')
    }
    if (this.attributes.get(key) !== value) {
      this.attributes.set(key, value)
      this.execEventHandler('setAttribute')
    }
  }

  deleteAttribute = (key: UserAccountAttributeKey) =>
    this.attributes.delete(key)

  mergeAttributes = <K extends UserAccountAttributeKey>(
    newAttributes: Partial<UserAccountAttributes>
  ) => {
    let hasChanged = false
    Object.entries(newAttributes).forEach(([key, value]) => {
      if (this.attributes.get(key as K) !== value) {
        this.attributes.set(key as K, value as UserAccountAttributes[K])
        hasChanged = true
      }
    })
    if (hasChanged) {
      this.execEventHandler('setAttribute')
    }
  }

  toJson = () => {
    const res: Partial<UserAccountAttributes> = {}
    this.attributes.forEach((value, key) => {
      // @ts-ignore: I don't understand the problem here
      res[key] = value
    })
    return res
  }

  isAuthenticated = () => this.attributes.get('id') !== undefined

  resetPassword = ({
    newPassword,
    token,
  }: {
    newPassword: string
    token: string | undefined
  }) =>
    this.apiClientMethodProxy('resetPassword')({
      newPassword,
      token,
    })
}

export default new UserAccount()
