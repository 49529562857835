// extracted by mini-css-extract-plugin
export var arrowLeft = "arKoe";
export var arrowRight = "vi7Gf";
export var content = "citI6";
export var featureImage = "Y6ZNK";
export var featureImageContainer = "CephR";
export var header = "TIQbP";
export var richTextArea = "T4LRy";
export var title = "_3wj10";
export var titleVideoSection = "Hawka";
export var video = "dkU2N";