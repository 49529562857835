import React from 'react'
import { graphql } from 'gatsby'
import { sortBy } from 'lodash/fp'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import WebsiteLayout from 'components/layout/website'
import MetaTagRobots from 'components/meta/robots'
import OpenGraphTags from 'components/meta/open-graph-tags'
import Hreflang from 'components/meta/Hreflang'
import SeoIndexPageHeader from 'components/headers/seoIndexPage'
import CategoryNavigation from 'components/categoryNavigation'
import FixedCta from 'components/fixed-cta'
import { Container, Row, Col } from 'components/grid'
import ArticlePreviewList from 'components/articlePreview/list'
import Pagination from 'components/pagination'

import { ArticlesPageQueryResult, MainCategoryQueryResult } from './types'
import {
  header as headerStyle,
  descriptionParagraph,
  articleListTitle,
} from './articles.module.scss'

const parseCategoryNavigationData = (
  categoryNavigation: MainCategoryQueryResult[]
) => {
  return sortBy(['sortOrder'], categoryNavigation).map(
    ({
      fields: { path },
      icon: { fields: iconFields },
      subCategories,
      ...rest
    }) => {
      return {
        ...rest,
        ...iconFields,
        path,
        subCategories: sortBy(['sortOrder'], subCategories).map(
          ({ fields: { path }, ...rest }) => ({
            path,
            ...rest,
          })
        ),
      }
    }
  )
}

const ArticlesPage = ({
  data: {
    articlesPageData: {
      title = '',
      description: { description = '' },
      metaDescriptionForSeo: { metaDescriptionForSeo: metaDescription = '' },
      imageDesktop: ogImage,
      categoryNavigation,
      fields: {
        path,
        pageTitle,
        imageMobile,
        imageDesktop,
        hrefLang,
        defaultLang,
      },
      node_locale: locale,
    },
    listOfArticlesData,
  },
  pageContext: { numberOfPages, pageNumber },
}: ArticlesPageQueryResult) => (
  <WebsiteLayout
    title={title}
    pageTitle={pageTitle}
    description={metaDescription}
    hrefLang={hrefLang}
    defaultLang={defaultLang}
  >
    <MetaTagRobots noindex={false} nofollow={false} />
    <OpenGraphTags
      title={pageTitle}
      description={metaDescription}
      image={
        ogImage
          ? {
              alt: ogImage.fields.asset.title,
              ...ogImage.fields.asset.resize,
            }
          : null
      }
      locale={locale}
    />
    <Hreflang />
    <SeoIndexPageHeader
      className={headerStyle}
      imgMobile={imageMobile?.fields.asset.fixed}
      imgDesktop={imageDesktop?.fields.asset.fixed}
    >
      {description.split(/\n+/).map((paragraph, i) => (
        <p key={i} className={descriptionParagraph}>
          {paragraph.trim()}
        </p>
      ))}
    </SeoIndexPageHeader>
    <CategoryNavigation
      categoryList={parseCategoryNavigationData(categoryNavigation)}
    />
    <FixedCta visibleAfterScrollY={300} />
    <DocumentOutlineSection title={i18n._(t`Recent Articles`)}>
      <main>
        <Container>
          <Row>
            <Col>
              <H className={articleListTitle} />
            </Col>
          </Row>
        </Container>
        <ArticlePreviewList articleList={listOfArticlesData?.edges || []} />
        <Container>
          <Row>
            <Col>
              <Pagination
                totalPageCount={numberOfPages}
                currentPage={pageNumber}
                basePath={path}
                withMetatags
              />
            </Col>
          </Row>
        </Container>
      </main>
    </DocumentOutlineSection>
  </WebsiteLayout>
)

export default ArticlesPage

export const pageQuery = graphql`
  query ArticlesPage(
    $id: String!
    $skip: Int!
    $limit: Int!
    $locale: String!
  ) {
    articlesPageData: contentfulCategory(id: { eq: $id }) {
      title
      node_locale
      description {
        description
      }
      metaDescriptionForSeo {
        metaDescriptionForSeo
      }
      imageDesktop {
        fields {
          asset {
            title
            resize(width: 1200, height: 630, cropFocus: BOTTOM_RIGHT) {
              src
              width
              height
            }
          }
        }
      }
      categoryNavigation: category {
        sortOrder
        name: displayName
        icon {
          fields {
            categoryIconMobile: asset {
              fixed(width: 120, height: 105, cropFocus: TOP) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
            categoryIconDesktop: asset {
              fixed(width: 198, height: 160, cropFocus: TOP) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
          }
        }
        fields {
          path
        }
        subCategories: category {
          sortOrder
          name: displayName
          fields {
            path
          }
        }
      }
      fields {
        path
        pageTitle
        imageMobile {
          fields {
            asset {
              fixed(width: 130, height: 120) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
          }
        }
        imageDesktop {
          fields {
            asset {
              fixed(width: 800, height: 400, quality: 70) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
          }
        }
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
    }
    listOfArticlesData: allContentfulArticlePage(
      filter: {
        node_locale: { eq: $locale }
        slug: { regex: "/^(?!___)/" }
        content: { raw: { ne: null } }
      }
      sort: { fields: originalPublishDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          category {
            displayName
            fields {
              path
            }
          }
          featureImage {
            title
            fields {
              asset {
                fluid(
                  maxWidth: 668
                  maxHeight: 650
                  cropFocus: CENTER
                  quality: 85
                ) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
          fields {
            path
            excerpt
          }
        }
      }
    }
  }
`
