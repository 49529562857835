import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { Container, Row, Col } from 'components/grid'

import {
  root,
  phone as phoneStyle,
  card as cardStyle,
  phoneChrome,
} from './index.module.scss'

interface PhoneMealsQueryResult {
  phone: EF.LocalFluidImage
  cards: {
    nodes: EF.LocalFluidImage[]
  }
}

const query = graphql`
  query PhoneMeals {
    phone: file(
      name: { eq: "meals-phone" }
      relativeDirectory: { eq: "about/usp-section" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 1676
          maxHeight: 884
          sizes: "(min-width: 576px) 1243px, (min-width: 1200px) 1676px, 906px"
          srcSetBreakpoints: [
            906
            1243
            1359
            1676
            1812
            1864
            2486
            2514
            3352
          ]
          pngQuality: 60
          webpQuality: 85
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    cards: allFile(
      filter: { relativeDirectory: { eq: "about/usp-section/recipe-cards" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          fluid(
            maxWidth: 335
            sizes: "(min-width: 576px) 249px, (min-width: 1200px) 335px, 181px"
            srcSetBreakpoints: [181, 249, 271, 335, 362, 373, 498, 502, 670]
            pngQuality: 60
            webpQuality: 85
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

const PhoneMeals = () => {
  const { phone, cards } = useStaticQuery<PhoneMealsQueryResult>(query)

  return (
    <div className={root} role="presentation">
      <Container>
        <Row>
          <Col>
            <div className={phoneStyle}>
              <GatsbyImage fluid={phone.childImageSharp.fluid} />

              {cards.nodes.map((card, i) => (
                <div className={cardStyle} data-index={i} key={i}>
                  <GatsbyImage fluid={card.childImageSharp.fluid} />
                </div>
              ))}

              <div className={phoneChrome} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PhoneMeals
