import React from 'react'

import { classNames } from 'utils/dom'

import {
  underlineGeneral,
  underlineTopPageHeader,
  underlineCalloutHeader,
  underlineArticleHeader,
  underlineLargeArticleHeader,
  underlineSmallArticleHeader,
  underlineSectionHeader,
  underlineSectionSmallHeader,
} from './index.module.scss'

export enum UnderlineColors {
  Green = 'green',
  Warm = 'warm',
  Cool = 'cool',
}

export interface UnderlineProps {
  text: string
  color?: UnderlineColors
  className?: string
  containerStyle?: string
  tagName?: 'div' | 'span'
}

export const UnderlineText = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  containerStyle = '',
  tagName = 'div',
}: UnderlineProps) => {
  const Comp = tagName
  return (
    <Comp className={containerStyle}>
      {text.split(/\s+/).map((str, index, array) => (
        <span
          data-color={color}
          className={classNames([underlineGeneral, className])}
          key={`${str}-${index}`}
        >
          {index < array.length - 1 ? `${str} ` : str}
        </span>
      ))}
    </Comp>
  )
}

export const UnderlineTopPageHeader = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  containerStyle = '',
  tagName,
}: UnderlineProps) => (
  <UnderlineText
    containerStyle={containerStyle}
    color={color}
    className={classNames([underlineTopPageHeader, className])}
    text={text}
    tagName={tagName}
  />
)

export const UnderlineCalloutHeader = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  containerStyle = '',
  tagName,
}: UnderlineProps) => (
  <UnderlineText
    containerStyle={containerStyle}
    color={color}
    className={classNames([underlineCalloutHeader, className])}
    text={text}
    tagName={tagName}
  />
)

export const UnderlineArticleHeader = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  tagName,
}: UnderlineProps) => (
  <UnderlineText
    color={color}
    className={classNames([underlineArticleHeader, className])}
    text={text}
    tagName={tagName}
  />
)

export const UnderlineLargeArticleHeader = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  containerStyle = '',
  tagName,
}: UnderlineProps) => (
  <UnderlineText
    containerStyle={containerStyle}
    color={color}
    className={classNames([underlineLargeArticleHeader, className])}
    text={text}
    tagName={tagName}
  />
)

export const UnderlineSmallArticleHeader = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  containerStyle = '',
  tagName,
}: UnderlineProps) => (
  <UnderlineText
    containerStyle={containerStyle}
    color={color}
    className={classNames([underlineSmallArticleHeader, className])}
    text={text}
    tagName={tagName}
  />
)

export const UnderlineSectionHeader = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  containerStyle = '',
  tagName,
}: UnderlineProps) => (
  <UnderlineText
    containerStyle={containerStyle}
    color={color}
    className={classNames([underlineSectionHeader, className])}
    text={text}
    tagName={tagName}
  />
)

export const UnderlineSectionSmallHeader = ({
  text,
  color = UnderlineColors.Green,
  className = '',
  containerStyle = '',
  tagName,
}: UnderlineProps) => (
  <UnderlineText
    containerStyle={containerStyle}
    color={color}
    className={classNames([underlineSectionSmallHeader, className])}
    text={text}
    tagName={tagName}
  />
)
