import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import Layout from 'components/layout'
import LPHeader from 'components/headers/lp'
import USPsWithCarousel from 'components/usps-with-carousel'
import { UnderlineColors } from 'components/UnderlineText'
import TextWithImage from 'components/text-with-image'
import PricePlanSection from 'components/PricePlanSection'
import LegalLPFooter from 'components/footer/PCMFooter/legal-lp-footer'
import AdjustLink from 'components/utils/adjust-link'

import UserReviewCarousel from './user-review-carousel'
import { WUAPageQueryResult, Feature, ContentfulWUAPage } from './types'
import { features as featuresStyle, footer } from './index.module.scss'

const renderFeatures = (features: Feature[], url?: string) => {
  if (features.length === 0) return null
  return (
    <section className={featuresStyle}>
      {features.map(
        ({ id, title, text, image, imageToTheLeft, imageToTheTop }) => (
          <TextWithImage
            key={id}
            title={title}
            text={text}
            image={image}
            imageToTheLeft={imageToTheLeft}
            imageToTheTop={imageToTheTop}
            ctaPath={url}
          />
        )
      )}
    </section>
  )
}

const renderPage: FunctionComponent<ContentfulWUAPage> = (
  {
    fields: { pageTitle, hrefLang, defaultLang },
    title,
    metaDescription,
    headerText,
    headerHeroImage,
    headerCtaText,
    uspsSectionTitle,
    uspList,
    uspsSectionCtaText,
    uspsSectionCarouselImages,
    features,
    pricePlanSection,
    userReviewList,
    userReviewsSectionCtaText,
  },
  ctaPath: string
) => {
  return (
    <Layout
      pageTitle={pageTitle}
      title={title}
      description={metaDescription || headerText}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <LPHeader
        text={headerText}
        heroImage={headerHeroImage}
        ctaText={headerCtaText}
        ctaPath={ctaPath}
      />
      <USPsWithCarousel
        title={uspsSectionTitle}
        underlineColor={UnderlineColors.Green}
        uspList={uspList}
        carouselImages={uspsSectionCarouselImages}
        ctaText={uspsSectionCtaText}
        ctaPath={ctaPath}
      />
      {renderFeatures(features, ctaPath)}
      <PricePlanSection
        title={pricePlanSection.title}
        sellingPoints={pricePlanSection.sellingPoints}
        description={pricePlanSection.description.description}
        buttonText={pricePlanSection.buttonText}
        leftBackgroundImage={pricePlanSection.leftBackgroundImage}
        rightBackgroundImage={pricePlanSection.rightBackgroundImage}
        isBigPriceHidden={pricePlanSection.isBigPriceHidden}
        signupPath={ctaPath}
      />
      <UserReviewCarousel
        userReviewList={userReviewList}
        ctaText={userReviewsSectionCtaText}
        ctaPath={ctaPath}
      />
      <footer className={footer}>
        <LegalLPFooter />
      </footer>
    </Layout>
  )
}

const WUAPage: FunctionComponent<WUAPageQueryResult> = ({
  data: { contentfulWuaPage },
}) => {
  if (contentfulWuaPage.useAdjustLink) {
    return (
      <AdjustLink preferAndroidStore={false}>
        {(url: string) => renderPage(contentfulWuaPage, url)}
      </AdjustLink>
    )
  }

  return renderPage(contentfulWuaPage, i18n._(t`/signup/`))
}

export default WUAPage

export const pageQuery = graphql`
  query WUAPage($id: String!) {
    contentfulWuaPage(id: { eq: $id }) {
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
        pageTitle
      }
      pageTitle
      slug
      metaDescription
      title
      useAdjustLink
      headerText
      headerCtaText
      headerHeroImage {
        ...ContentfulAsset_LPHeader_HeroImage
      }
      uspsSectionTitle
      uspList {
        title
        description {
          raw
        }
        icon {
          fields {
            asset {
              file {
                url
              }
            }
          }
        }
      }
      uspsSectionCtaText
      uspsSectionCarouselImages {
        ...ContentfulAsset_USPsWithCarousel_CarouselImage
      }
      features {
        id
        title
        text {
          raw
        }
        image {
          ...ContentfulAsset_TextWithImage_LP
        }
        imageToTheLeft
        imageToTheTop
        ctaText
      }
      pricePlanSection {
        title
        sellingPoints
        description {
          description
        }
        buttonText
        leftBackgroundImage {
          ...ContentfulAsset_PricePlanSection
        }
        rightBackgroundImage {
          ...ContentfulAsset_PricePlanSection
        }
        isBigPriceHidden
      }
      userReviewList {
        name
        isPro
        rating
        review {
          review
        }
        photo {
          fields {
            asset {
              fixed(width: 200, height: 200, quality: 80) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
      userReviewsSectionCtaText
    }
  }
`
