import React, { useMemo, useEffect, useContext, ReactNode } from 'react'
import { useLocation } from '@gatsbyjs/reach-router'

import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import { useWillUnmount } from 'hooks'

import { SegmentScreen, SignupSegmentType, SignupScreenType } from '../types'

import {
  createScreenList,
  getLatestValidScreen,
  getCurrentScreenErrors,
  goToScreenFn,
  isCurrentScreenCorrect as getIsCurrentScreenCorrect,
} from './process-signup-data'

export interface RouterScreenType {
  screen: SignupScreenType
  screenIndex: number
}

interface RouterProps {
  signupFunnelSlug?: string
  segments: SignupSegmentType[]
  onBeforeNavigate: () => Promise<void>
  children: (props: {
    screen: SignupScreenType
    screenIndex: number
    prevScreens: RouterScreenType[]
    nextScreens: RouterScreenType[]
    segments: (SignupSegmentType & {
      segmentScreens: RouterScreenType[]
    })[]
    segmentIndex: number
  }) => ReactNode
}

interface SignupRouterContextType {
  screen: SignupScreenType
  screens: SignupScreenType[]
  goToScreen: (slug: string, options?: object) => Promise<void | Error>
  goToNextScreen: () => Promise<void | Error>
  goToPrevScreen: () => Promise<void | Error>
  currentScreenErrors: {
    field: { name: string }
    invalidationError: string
  }[]
  prevScreens: SegmentScreen[]
  segment: SignupSegmentType
}

// @ts-ignore TODO: create default context value
const SignupRouterContext = React.createContext()

export const SignupRouterInfo = SignupRouterContext.Consumer

export const useSignupRouterInfo: () => SignupRouterContextType = () =>
  // @ts-ignore initial undefined value
  useContext(SignupRouterContext)

const useSignupLocation = (signupFunnelSlug = 'signup') => {
  const { pathname } = useLocation()
  const slugPattern = new RegExp(
    `^((\\/[^/]{2,5})?\\/${signupFunnelSlug}\\/?)([^\\/?#]*)\\/?(\\?.*|#.*)?$`
  )
  const match = pathname.match(slugPattern)
  let basePath = match?.[1] || ''
  const slug = match?.[3] || ''
  basePath = basePath.replace(/\/?$/, '/')
  return { basePath, slug }
}

let previousSlug: string | null = null

const SignupRouter = ({
  segments,
  onBeforeNavigate,
  children,
  signupFunnelSlug,
}: RouterProps) => {
  const { basePath, slug } = useSignupLocation(signupFunnelSlug)
  const screens: SegmentScreen[] = useMemo(
    () => createScreenList(segments, basePath),
    [segments, basePath]
  )

  const goToScreen = useMemo(
    () => goToScreenFn(basePath, screens, onBeforeNavigate),
    [basePath, onBeforeNavigate, screens]
  )

  const { getAttribute: getUserAccountAttribute } = useUserAccount()
  const latestValidScreen: SegmentScreen = getLatestValidScreen(
    screens,
    getUserAccountAttribute
  )
  let currentScreen = screens.find((screen) => screen.slug === slug)

  const isCurrentScreenCorrect = getIsCurrentScreenCorrect(
    screens,
    latestValidScreen,
    previousSlug === null,
    currentScreen,
    previousSlug
  )

  useEffect(() => {
    if (!isCurrentScreenCorrect) {
      goToScreen(latestValidScreen.slug, { replace: true })
    }
  })

  if (!isCurrentScreenCorrect) {
    currentScreen = latestValidScreen
  }
  previousSlug = currentScreen?.slug || null

  useWillUnmount(() => {
    // componentWillUnmount: Reset slug
    previousSlug = null
  })

  const isSessionRestored = getUserAccountAttribute('isSessionRestored')
  useEffect(() => {
    if (isSessionRestored) {
      goToScreen(latestValidScreen.slug, { replace: true })
    }
  }, [isSessionRestored]) // eslint-disable-line react-hooks/exhaustive-deps

  const goToNextScreen = () => {
    if (!currentScreen) return Promise.reject(new Error('No current screen'))

    const nextScreen = currentScreen.getNextScreen(getUserAccountAttribute)
    return nextScreen
      ? goToScreen(nextScreen.slug)
      : Promise.reject(new Error('No next screen'))
  }

  const goToPrevScreen = () => {
    if (!currentScreen) return Promise.reject(new Error('No current screen'))

    const prevScreen = currentScreen.getPrevScreen(getUserAccountAttribute)
    return prevScreen
      ? goToScreen(prevScreen.slug)
      : Promise.reject(new Error('No previous screen'))
  }

  const currentScreenErrors = getCurrentScreenErrors(
    getUserAccountAttribute,
    currentScreen
  )

  const context = {
    ...currentScreen,
    screens,
    goToScreen,
    goToNextScreen,
    goToPrevScreen,
    currentScreenErrors,
  }

  return (
    <SignupRouterContext.Provider value={context}>
      <SignupRouterContext.Consumer>
        {children as (value: object) => ReactNode}
      </SignupRouterContext.Consumer>
    </SignupRouterContext.Provider>
  )
}

export default SignupRouter
