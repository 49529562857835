import { initFirebase } from 'sdks/google'

import { SideEffect } from '../types'

const initializeFirebaseEffect: SideEffect = {
  name: 'Initialize Firebase SDK',
  isSilent: true,
  condition: (config) =>
    typeof config.firebase === 'object' && window.firebase === undefined,
  execute: (config) => initFirebase(config.firebase),
}

export default initializeFirebaseEffect
