import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'

import { SideEffect } from '../types'

let isLoggedIn = false

const afterLogoutEffect: SideEffect = {
  name: 'After log out',
  isSilent: false,
  condition: () => {
    // Yeah, it's a bit hacky. We're basically implementing a side effect in
    // the condition function 😬
    if (!isLoggedIn && UserAccountStore.getAttribute('id')) {
      // Let's keep an eye on whether the user has been logged in at some point
      isLoggedIn = true
      return false
    }
    if (isLoggedIn && !UserAccountStore.getAttribute('id')) {
      // So the user was logged in, but now isn't anymore
      // That's out call! 📞
      return true
    }
    return false
  },
  execute: (_, logout) => {
    logout()
    isLoggedIn = false
  },
}

export default afterLogoutEffect
