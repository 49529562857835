export const lbsToGrams = (lbs: number) => Math.round(lbs * 453.592)

export const gramsToLbs = (grams: number) => Math.round(grams * 0.00220462262)

export const gramsToKgs = (grams: number) => Math.round(grams / 1000)

export const kgsToGrams = (kgs: number) => kgs * 1000

export const centimetersToInches = (cm: number) => cm * 0.393701

export const inchesToCentimeters = (ins: number) => ins * 2.54

export const centimetersToFeetAndInches = (centimeters: number) => {
  const realInches = centimetersToInches(centimeters)
  const realFeet = realInches / 12
  const feet = Math.floor(realFeet)
  const inches = Math.floor((realFeet - feet) * 12)

  return { feet, inches }
}

export const feetAndInchesToCentimeters = (feet: number, inches: number) =>
  inchesToCentimeters(feet * 12) + inchesToCentimeters(inches)

export const calcDiscountedPrice = (
  originalPrice: number,
  discountPercentage: number
) => originalPrice * ((100 - discountPercentage) / 100)

/**
 * This returns the number of digits in a number. So, it's 1 for all numbers 0 through 9, 2 for 10
 * through 99, 3 for 100 to 999, etc…
 */
export const calcNumberLength = (nr: number) => 1 + (Math.log10(nr) | 0)
