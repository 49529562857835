import categorisation from '../../config/onetrust/categorisation.json'

import { waitForWindowIdle, waitForWindowLoad } from './dom'
import { waitRetry } from './async'

const getOneTrustSDK = () => {
  if (typeof window === 'object') return window.OneTrust
  return undefined
}

export const waitForOneTrustSDK = async () => {
  if (process.env.GATSBY_DEACTIVATE_ONETRUST === 'yes') {
    throw new Error('OneTrust is deactivated')
  }
  const isOneTrustSDKPresent = () => !!getOneTrustSDK()
  if (isOneTrustSDKPresent()) {
    return getOneTrustSDK()
  }
  await waitForWindowLoad()
  if (isOneTrustSDKPresent()) {
    return getOneTrustSDK()
  }
  await waitForWindowIdle()
  if (isOneTrustSDKPresent()) {
    return getOneTrustSDK()
  }
  // WTF. Okay, just retry it for 5 seconds before we give up
  try {
    return waitRetry(
      () => {
        if (isOneTrustSDKPresent()) {
          return getOneTrustSDK()
        }
        return undefined
      },
      { timeout: 500, retries: 10 }
    )
  } catch {
    // It's hopeless.
    throw new Error('OneTrust SDK failed to load')
  }
}

export const getActiveCategories = () => {
  if (typeof window === 'object') {
    // If window.OnetrustActiveGroups isn't available yet return C0001 because
    // that group is always active
    return (window.OnetrustActiveGroups ?? 'C0001').split(',').filter(Boolean)
  }
  return ['C0001']
}

const getCategorisationForUrl = (url: string) => {
  const trackingHostList = Object.keys(
    categorisation
  ) as (keyof typeof categorisation)[]
  const trackingHost = trackingHostList.find((host) => url.includes(host))
  if (trackingHost) {
    return categorisation[trackingHost]
  }
  // C0001 is the category that is always active. So, if we don't find the URL in our categorisation
  // then we can activate it, meaning we can assign it to C0001
  return ['C0001']
}

const getCategorisationForUrlList = (urls: string[] = []) => {
  const categorySet = urls.reduce((set, url) => {
    getCategorisationForUrl(url).forEach((category) => set.add(category))
    return set
  }, new Set<string>())
  return Array.from(categorySet).sort()
}

const areAllCategoriesActive = (categories?: string[]) => {
  if (process.env.GATSBY_DEACTIVATE_ONETRUST === 'yes') {
    return true
  }
  const activeCategories = getActiveCategories()
  return categories?.every((cat) => activeCategories.includes(cat))
}

export const areUrlsAllowed = (urlList: string[]) => {
  const categories = getCategorisationForUrlList(urlList)
  return areAllCategoriesActive(categories)
}

export const onConsentChanged = (
  callback: Parameters<OneTrust['OnConsentChanged']>[0]
) => {
  // We don't use `OneTrust.OnConsentChanged()` because it doesn't allow removing the event
  // listener. OneTrust also triggers events on `window`. So, we'll listen for that event instead.
  if (typeof window !== 'object') return
  // @ts-ignore TS doesn't know that this custom event exists
  window.addEventListener('consent.onetrust', callback)
  return () => {
    // @ts-ignore Again, TS doesn't know about this custom event
    window.removeEventListener('consent.onetrust', callback)
  }
}

export const isBannerClosed = () => {
  const OneTrust = getOneTrustSDK()
  return OneTrust?.IsAlertBoxClosedAndValid() ?? false
}

/**
 * This inserts a script into the page through OneTrust's SDK. If the cookie banner is still being
 * shown it waits for the consent changed event. If the user didn't allow this script to load it
 * doesn't load.
 *
 * @param url The URL of the script to load
 * @returns Promise which resolves successfully if the script loads and otherwise is rejected
 */
export const insertScript = (url: string) => {
  if (
    process.env.GATSBY_DEACTIVATE_ONETRUST === 'yes' ||
    areUrlsAllowed([url])
  ) {
    // If OneTrust is disabled or the URL is strictly necessary don't use OneTrust to inject the
    // script, but do it directly
    return new Promise((resolve, reject) => {
      const mountedScriptTag = document.getElementsByTagName('script')[0]
      const newScriptTag = document.createElement('script')
      newScriptTag.src = url
      newScriptTag.onload = resolve
      newScriptTag.onerror = reject
      const parentNode = mountedScriptTag?.parentNode
      if (parentNode) {
        parentNode.insertBefore(newScriptTag, mountedScriptTag)
      }
    })
  }
  return new Promise<void>(async (resolve, reject) => {
    let OneTrust: OneTrust | undefined
    const err = new Error("OneTrust SDK isn't available")
    try {
      OneTrust = await waitForOneTrustSDK()
    } catch {
      return reject(err)
    }
    if (!OneTrust) return reject(err)
    const categories = getCategorisationForUrl(url)
    const insertScriptIfAllowed = () => {
      if (!areAllCategoriesActive(categories)) {
        return reject(
          new Error(
            `User didn't allow scripts of this group (${categories.join(
              ', '
            )}) to be loaded.`
          )
        )
      }
      if (OneTrust) {
        OneTrust.InsertScript(url, 'body', resolve, null, categories[0])
      }
    }
    if (
      OneTrust.IsAlertBoxClosedAndValid() ||
      // By default C0001 is always active, so we might already be able to inject the script
      // without needing to wait for the cookie banner to be closed
      areAllCategoriesActive(categories)
    ) {
      // Cookie banner is already closed or script is already allowed to be added
      insertScriptIfAllowed()
    } else {
      // Cookie banner is still shown. Let's wait until it's resolved
      OneTrust.OnConsentChanged(insertScriptIfAllowed)
    }
  })
}
