import { useEffect } from 'react'

const useOnLoad = (cb: () => unknown) => {
  useEffect(() => {
    if (typeof document === 'object' && document.readyState === 'complete') {
      cb()
    } else if (typeof window === 'object') {
      const onLoad = () => {
        window.removeEventListener('load', onLoad)
        cb()
      }

      window.addEventListener('load', onLoad)

      return () => window.removeEventListener('load', onLoad)
    }

    return undefined
  }, [cb])
}

export default useOnLoad
