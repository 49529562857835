import { useState } from 'react'

import { useDidMount } from 'hooks'
import { isBannerClosed, onConsentChanged } from 'utils/onetrust'

const useIsOneTrustBannerClosed = () => {
  const [isOneTrustBannerClosed, setIsOneTrustBannerClosed] = useState(
    isBannerClosed()
  )

  useDidMount(() => {
    if (isBannerClosed()) return

    return onConsentChanged(() => {
      setIsOneTrustBannerClosed(isBannerClosed())
    })
  })

  return isOneTrustBannerClosed
}

export default useIsOneTrustBannerClosed
