import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'

import {
  PressImageQueryResult,
  PressReviewLogoType,
  QueryVectorNode,
  QueryBitmapNode,
  VectorLogo,
  BitmapLogo,
  PressReviewLogo,
  PressReview,
} from './pressReviewCarouselTypes'

const logoFromQuery = (
  result: PressImageQueryResult,
  type: PressReviewLogoType,
  name: string
): PressReviewLogo | undefined => {
  const { edges }: { edges: (QueryVectorNode | QueryBitmapNode)[] } = result[
    type
  ]
  const logo = edges.map(({ node }) => node).find((logo) => logo.name === name)

  if (!logo) return undefined

  return type === 'vector'
    ? { type: 'vector', image: (logo as VectorLogo).publicURL }
    : { type: 'bitmap', image: (logo as BitmapLogo).childImageSharp.fixed }
}

export const getReviews = (
  queryResult: PressImageQueryResult
): PressReview[] => [
  {
    name: 'Forbes',
    logo: logoFromQuery(queryResult, 'vector', 'forbes'),
    text: i18n._(
      t(
        'press-review:Forbes'
      )`For anyone looking to make a real lifestyle change but needs a little help along the way, this app is a great option.`
    ),
  },
  {
    name: "press-review:Men's Health",
    logo: logoFromQuery(queryResult, 'vector', 'mens-health'),
    text: i18n._(
      t(
        "press-review:Men's Health"
      )`8fit makes it easy to skip the gym and opt for an at-home program without compromising the quality of your workout.`
    ),
  },
  {
    name: 'Tech Crunch',
    logo: logoFromQuery(queryResult, 'vector', 'tech-crunch'),
    text: i18n._(
      t(
        'press-review:Tech Crunch'
      )`...a focus on personalisation and by recognising that a combination of exercise and nutrition or meal planning is the best path to a healthier lifestyle.`
    ),
  },
  {
    name: 'Health Line',
    logo: logoFromQuery(queryResult, 'vector', 'health-line'),
    text: i18n._(
      t(
        'press-review:Health Line'
      )`There’s a saying that abs are made in the kitchen. That’s why 8fit combines several exercise programs with meal plans`
    ),
  },
  {
    name: 'Well + Good',
    logo: logoFromQuery(queryResult, 'bitmap', 'well-good'),
    text: i18n._(
      t(
        'press-review:Well + Good'
      )`The level of customization the meal plan offered was my favorite part of the app. As someone with too many random dietary restrictions to count, I loved that I could select specific “problem” ingredients to leave out of suggested meals…`
    ),
  },
]
