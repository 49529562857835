import React, { ReactNode } from 'react'
import Image, { FixedObject } from 'gatsby-image'
import { times } from 'lodash'
import { Trans, t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import Star from 'assets/images/icons/star.svg'

import {
  container,
  leftCell,
  photo as photoStyle,
  name as nameStyle,
  proBadge,
  review as reviewStyle,
  star,
} from './CompactUserReview.module.scss'

export interface CompactUserReviewQueryType {
  name: string
  shortReview: string
  smallPhoto: { fields: { asset: { fixed: FixedObject } } }
  isPro?: boolean
  rating?: number
  className?: string
}

export interface CompactUserReviewProps {
  name: string
  review: string
  photo: { fixed: FixedObject }
  isPro?: boolean
  rating?: number
  className?: string
}

const renderRating = (rating: number): ReactNode => (
  <div aria-label={i18n._(t`Rated ${rating} out of 5 stars`)}>
    {times(rating, (idx) => (
      <img
        key={`user-rating-star-${idx}`}
        src={Star}
        className={star}
        alt=""
        role="presentation"
      />
    ))}
  </div>
)

const CompactUserReview = ({
  name,
  photo,
  review,
  isPro = false,
  rating = 5,
}: CompactUserReviewProps) => {
  return (
    <div className={container}>
      <div className={leftCell}>
        <div className={photoStyle}>
          <Image fixed={photo.fixed} alt="" />
        </div>
      </div>
      <div>
        <div>
          <span className={nameStyle}>{name}</span>
          {isPro && (
            <span className={proBadge}>
              <Trans>PRO USER</Trans>
            </span>
          )}
        </div>
        {renderRating(rating)}
        <div className={reviewStyle}>{review}</div>
      </div>
    </div>
  )
}

export default CompactUserReview
