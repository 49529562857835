import React from 'react'
import { graphql } from 'gatsby'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { useMatchMedia, useDidMount } from 'hooks'
import { i18n } from 'utils/i18n'
import { classNames, loadScript, saferEval } from 'utils/dom'
import { interpolate } from 'utils/string'
import sessionStorage from 'utils/sessionStorage'
import { calcDiscountedPrice } from 'utils/math'
import LandingPageLayout from 'components/layout/LandingPageLayout'
import { Container, Row, Col, breakpoints } from 'components/grid'
import USPsWithCarousel from 'components/usps-with-carousel'
import Carousel from 'components/carousel'
import UserReview, { UserReviewQueryType } from 'components/userReview'
import { CompactUniqueSellingPointQueryType } from 'components/uniqueSellingPoint/CompactUniqueSellingPoint'
import SocialLinks from 'components/SocialLinks'
import {
  UnderlineColors,
  UnderlineTopPageHeader,
  UnderlineCalloutHeader,
} from 'components/UnderlineText'
import TextWithImage from 'components/text-with-image'
import PricePlanSection, {
  PricePlanSectionQueryType,
} from 'components/PricePlanSection'
import { PrimaryButtonLink } from 'components/Buttons'

import AppPreviewSection, {
  AppPreviewSectionQueryType,
} from './AppPreviewSection'
import {
  container,
  sectionTitle,
  sectionBg,
  userReviewContainer,
  userReviewCarousel,
  userReviewTitle,
  userReviewItem,
  buttonContainer,
  socialLinks,
} from './index.module.scss'
import Header from './Header'

interface PartnershipLandingPageProps {
  data: {
    contentfulPartnershipLandingPage: {
      fields: {
        hrefLang: EF.HrefLangNode[]
        defaultLang: EF.DefaultLangNode
        plan: EF.GatsbySource8fitBackendPlan
        discountInformation: EF.DiscountInformation
        hasPrepaidSubscriptionFunnel: boolean
        inlinedScript?: {
          toLoad: string
          eval: string
        }
      }
      stripeCouponId: string
      partnerImage: EF.FixedImageNode
      paymentPlanTitle: string
      paymentPlanDescription: string
      title: string
      pageTitle: string
      description: string
      headerButtonText: string
      desktopFeatureImage: EF.FluidImageNode
      mobileFeatureImage: EF.FluidImageNode
      appPreviewSection: AppPreviewSectionQueryType
      uspSectionTitle: string
      USPs: CompactUniqueSellingPointQueryType[]
      carouselImages: EF.FluidImageNode[]
      pricePlanSection: PricePlanSectionQueryType
      partnershipSectionTitle: string
      partnershipSectionDescription: { partnershipSectionDescription: string }
      partnershipImage: EF.FluidImageNode
      userReviews: UserReviewQueryType[]
      philosophyTitle: string
      philosophy: { philosophy: string }
      philosophyImage: EF.FluidImageNode
      genericButtonText: string
      partnerName: string
    }
  }
}

const PartnershipLandingPage = ({
  data: {
    contentfulPartnershipLandingPage: {
      fields: {
        hrefLang,
        defaultLang,
        plan,
        discountInformation,
        hasPrepaidSubscriptionFunnel,
        inlinedScript,
      },
      title,
      pageTitle,
      description,
      headerButtonText,
      desktopFeatureImage: {
        fields: { asset: desktopFeatureImageAsset },
      },
      mobileFeatureImage: {
        fields: { asset: mobileFeatureImageAsset },
      },
      appPreviewSection,
      uspSectionTitle,
      USPs,
      carouselImages,
      pricePlanSection,
      partnershipSectionTitle,
      partnershipSectionDescription: { partnershipSectionDescription },
      partnershipImage,
      userReviews,
      philosophyTitle,
      philosophy: { philosophy },
      philosophyImage,
      genericButtonText,
      partnerImage: {
        fields: { asset: partnerImageAsset },
      },
      paymentPlanTitle,
      paymentPlanDescription,
      partnerName,
    },
  },
}: PartnershipLandingPageProps) => {
  const headline = interpolate(title, {
    percent_off: discountInformation?.percent_off,
  })
  const signupPath = i18n._(t`/signup-dlp/`)

  useDidMount(() => {
    sessionStorage.write('ContentfulPartnershipLandingPage', {
      partnerImage: partnerImageAsset.fixed.src, // PhotoInfo component uses the image url.
      title: paymentPlanTitle,
      description: paymentPlanDescription,
      partnerName,
    })
    sessionStorage.write(
      'hasPrepaidSubscriptionFunnel',
      hasPrepaidSubscriptionFunnel
    )
    sessionStorage.write('DiscountInformation', discountInformation)
    sessionStorage.write('SignupScreenPlansOverview', plan)
  })

  useDidMount(async () => {
    if (inlinedScript) {
      await loadScript(inlinedScript.toLoad, true)
      saferEval(inlinedScript.eval)
    }
  })

  const { matches: isMedium } = useMatchMedia(
    `(min-width: ${breakpoints.md}px)`
  )

  function renderUSPs() {
    return (
      <USPsWithCarousel
        title={uspSectionTitle}
        underlineColor={UnderlineColors.Warm}
        uspList={USPs}
        carouselImages={carouselImages}
      />
    )
  }

  function renderPricePlanSection() {
    if (!pricePlanSection) return null

    const { normalizedPrice } = plan.additionalInfo
    let displayPrice = normalizedPrice
    if (discountInformation?.percent_off) {
      displayPrice = calcDiscountedPrice(
        normalizedPrice,
        discountInformation.percent_off
      )
    }

    return (
      <PricePlanSection
        title={pricePlanSection.title}
        description={pricePlanSection.description.description}
        sellingPoints={pricePlanSection.sellingPoints}
        buttonText={pricePlanSection.buttonText}
        leftBackgroundImage={pricePlanSection.leftBackgroundImage}
        rightBackgroundImage={pricePlanSection.rightBackgroundImage}
        signupPath={signupPath}
        plan={plan}
        discountPercentage={discountInformation?.percent_off || undefined}
        displayPrice={displayPrice}
        isBigPriceHidden={pricePlanSection.isBigPriceHidden}
      />
    )
  }

  function renderPartnership() {
    return (
      <TextWithImage
        title={partnershipSectionTitle}
        underlineColor={UnderlineColors.Warm}
        imageToTheLeft
        text={partnershipSectionDescription}
        image={partnershipImage}
      />
    )
  }

  function renderUserReviews() {
    return (
      <DocumentOutlineSection
        title={<UnderlineCalloutHeader text={i18n._(t`User Reviews`)} />}
      >
        <section className={classNames([sectionBg, userReviewContainer])}>
          <div className={userReviewTitle}>
            <H className={sectionTitle} />
          </div>
          <Carousel
            className={userReviewCarousel}
            gap={isMedium ? 80 : 40}
            keyPrefix="user-review"
            defaultIndex={isMedium ? 1 : 0}
            showDots
          >
            {userReviews.map(
              ({ name, photo, review: { review }, isPro, rating }, idx) => (
                <UserReview
                  key={`user-review-${idx}`}
                  className={userReviewItem}
                  name={name}
                  photo={photo.fields.asset}
                  review={review}
                  isPro={isPro}
                  rating={rating}
                />
              )
            )}
          </Carousel>
          <PrimaryButtonLink path={signupPath} text={genericButtonText} />
        </section>
      </DocumentOutlineSection>
    )
  }

  function renderOurPhilosophy() {
    return (
      <TextWithImage
        title={philosophyTitle}
        underlineColor={UnderlineColors.Warm}
        text={philosophy}
        image={philosophyImage}
        ctaText={genericButtonText}
        ctaPath={signupPath}
      />
    )
  }

  function renderFooter() {
    return (
      <Container>
        <Row>
          <Col>
            <div className={buttonContainer}>
              <SocialLinks inverted className={socialLinks} />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <LandingPageLayout
      title={pageTitle || headline}
      description={description}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
      className={container}
    >
      <DocumentOutlineSection
        title={
          <UnderlineTopPageHeader
            text={headline}
            color={UnderlineColors.Warm}
          />
        }
      >
        <Header
          description={description}
          headerButtonText={headerButtonText}
          desktopFeatureImage={desktopFeatureImageAsset}
          mobileFeatureImage={mobileFeatureImageAsset}
          signupPath={signupPath}
        />
        <AppPreviewSection
          title={appPreviewSection.title}
          description={appPreviewSection.description}
          image={appPreviewSection.image.fields.asset}
          buttonText={interpolate(appPreviewSection.buttonText, {
            percent_off: discountInformation?.percent_off,
          })}
          signupPath={signupPath}
        />
        {renderUSPs()}
        {renderPricePlanSection()}
        {renderPartnership()}
        {renderUserReviews()}
        {renderOurPhilosophy()}
        {renderFooter()}
      </DocumentOutlineSection>
    </LandingPageLayout>
  )
}

export default PartnershipLandingPage

export const pageQuery = graphql`
  query PartnershipLandingPage($id: String!) {
    contentfulPartnershipLandingPage(id: { eq: $id }) {
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
        plan {
          ...GatsbySource8fitBackendPlanFull
        }
        discountInformation: coupon {
          code: id
          percent_off
          duration
        }
        hasPrepaidSubscriptionFunnel
        inlinedScript {
          toLoad
          eval
        }
      }
      stripeCouponId
      partnerImage {
        fields {
          asset {
            fixed(width: 54, height: 54, quality: 80, cropFocus: CENTER) {
              src
            }
          }
        }
      }
      paymentPlanTitle
      paymentPlanDescription
      title
      slug
      pageTitle
      description
      headerButtonText
      desktopFeatureImage: featureImage {
        fields {
          asset {
            alt: title
            fluid(
              maxWidth: 1280
              maxHeight: 683
              resizingBehavior: FILL
              cropFocus: LEFT
              quality: 80
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      mobileFeatureImage: featureImage {
        fields {
          asset {
            alt: title
            fluid(
              maxWidth: 768
              maxHeight: 505
              resizingBehavior: FILL
              cropFocus: CENTER
              quality: 80
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      appPreviewSection {
        title
        description
        buttonText
        image {
          fields {
            asset {
              fluid(
                maxWidth: 683
                maxHeight: 425
                resizingBehavior: FILL
                cropFocus: CENTER
                quality: 80
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      uspSectionTitle
      USPs {
        title
        description {
          raw
        }
        icon {
          fields {
            asset {
              file {
                url
              }
            }
          }
        }
      }
      carouselImages {
        ...ContentfulAsset_USPsWithCarousel_CarouselImage
      }

      pricePlanSection {
        title
        sellingPoints
        description {
          description
        }
        buttonText
        leftBackgroundImage {
          ...ContentfulAsset_PricePlanSection
        }
        rightBackgroundImage {
          ...ContentfulAsset_PricePlanSection
        }
        isBigPriceHidden
      }

      partnershipSectionTitle
      partnershipImage {
        ...ContentfulAsset_TextWithImage_LP
      }
      partnershipSectionDescription {
        partnershipSectionDescription
      }
      userReviews {
        name
        isPro
        rating
        review {
          review
        }
        photo {
          fields {
            asset {
              fixed(width: 200, height: 200, quality: 80) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
      philosophyTitle
      philosophy {
        philosophy
      }
      philosophyImage {
        ...ContentfulAsset_TextWithImage_LP
      }
      genericButtonText
      partnerName
    }
  }
`
