import { setTag } from '@sentry/gatsby'

import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import sessionStorage from 'utils/sessionStorage'

import { SideEffect } from '../types'

const handlePlpUserEffect: SideEffect = {
  name: 'Check if a user came from PLP',
  isSilent: true,
  condition: () =>
    !!(
      sessionStorage.has('ContentfulPartnershipLandingPage') &&
      !UserAccountStore.getAttribute('ContentfulPartnershipLandingPage')
    ),
  execute: () => {
    const plpInfo = sessionStorage.read('ContentfulPartnershipLandingPage')
    UserAccountStore.setAttribute('ContentfulPartnershipLandingPage', plpInfo)
    UserAccountStore.setAttribute(
      'hasPrepaidSubscriptionFunnel',
      sessionStorage.read('hasPrepaidSubscriptionFunnel')
    )
    UserAccountStore.setAttribute(
      'DiscountInformation',
      sessionStorage.read('DiscountInformation')
    )
    UserAccountStore.setAttribute(
      'SignupScreenPlansOverview',
      sessionStorage.read('SignupScreenPlansOverview')
    )
    setTag('Partnership', plpInfo?.partnerName)
  },
  reset: () => {
    sessionStorage.remove('ContentfulPartnershipLandingPage')
    sessionStorage.remove('DiscountInformation')
    sessionStorage.remove('SignupScreenPlansOverview')
  },
}

export default handlePlpUserEffect
