import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react'

import loadingSvg from 'assets/images/loading.svg'

import { spin } from './index.module.scss'

type Props = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

const LoadingSpinner = ({ className = '', ...props }: Props) => (
  <img
    src={loadingSvg}
    alt=""
    role="presentation"
    className={`${spin} ${className}`}
    {...props}
  />
)

export default LoadingSpinner
