import React, { FunctionComponent } from 'react'
import { useMatch } from '@gatsbyjs/reach-router'
import { Link } from 'gatsby'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'

import {
  breadcrumb,
  breadcrumbItem,
  link,
  disabledLink,
} from './breadcrumb.module.scss'

interface BreadcrumbItem {
  href: string
  text: string
}

const BreadcrumbItem: FunctionComponent<BreadcrumbItem> = ({ href, text }) => {
  const match = useMatch(href)
  if (match) {
    return <span className={disabledLink}>{text}</span>
  }
  return (
    <Link className={link} to={href}>
      {text}
    </Link>
  )
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]
  className?: string
}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  items,
  className,
}) => (
  <nav aria-label={i18n._(t`Breadcrumb navigation`)} className={className}>
    <ol className={breadcrumb}>
      {items.map((item) => (
        <li key={item.href} className={breadcrumbItem}>
          <BreadcrumbItem {...item} />
        </li>
      ))}
    </ol>
  </nav>
)

export default Breadcrumb
