import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import { SupportedLocaleName } from 'utils/i18n'
import { useMatchMedia } from 'hooks'
import SeamlessWebsiteLayout from 'components/layout/seamless-website'
import MetaTagRobots from 'components/meta/robots'
import HomepageHeader from 'components/headers/homepage'
import OpenGraphTags from 'components/meta/open-graph-tags'
import Hreflang from 'components/meta/Hreflang'
import { breakpoints } from 'components/grid'
import FixedCta from 'components/fixed-cta'

import WorkoutCardsCarousel from './activity-cards-carousel/workouts'
import RecipeCardsCarousel from './activity-cards-carousel/recipes'
import ArticlesSection from './articles-section'
import UserJourney from './user-journey'

type WorkoutSectionCarousel = Pick<
  Contentful.WorkoutClass,
  'title' | 'description' | 'durationMins' | 'featureImage' | 'fields'
>[]

type RecipeSectionCarousel = Pick<
  Contentful.Recipe,
  | 'title'
  | 'categories'
  | 'preparationTimeMin'
  | 'calories'
  | 'fields'
  | 'image'
>[]

interface HomepageProps {
  data: {
    contentfulHomepage: {
      title: string
      pageTitle: string
      fields: {
        hrefLang: EF.HrefLangNode[]
        defaultLang: EF.DefaultLangNode
      }
      description: {
        description: string
      }
      metaDescription: string
      ogImage: EF.ResizeImageNode
      workoutSectionTitle: string
      workoutSectionDescription: string
      workoutSectionCarousel: WorkoutSectionCarousel
      recipeSectionTitle: string
      recipeSectionDescription: {
        recipeSectionDescription: string
      }
      recipeSectionCarousel: RecipeSectionCarousel
      articlesSectionTitle: string
      articlesSectionDescription: {
        articlesSectionDescription: string
      }
      node_locale: SupportedLocaleName
    }
    listOfArticlesData: {
      nodes: Array<{
        id: string
        title: string
        featureImage: EF.FixedImageNode
        fields: {
          path: string
          readingTimeEstimate: number
          mainCategory: {
            displayName: string
            identifier: string
          }
        }
      }>
    }
  }
}

const Homepage: FunctionComponent<HomepageProps> = ({
  data: {
    contentfulHomepage: {
      title,
      pageTitle,
      fields: { hrefLang, defaultLang },
      description: { description },
      metaDescription,
      ogImage,
      workoutSectionTitle,
      workoutSectionDescription,
      workoutSectionCarousel,
      recipeSectionTitle,
      recipeSectionDescription: { recipeSectionDescription },
      recipeSectionCarousel,
      articlesSectionTitle,
      articlesSectionDescription: { articlesSectionDescription },
      node_locale: locale,
    },
    listOfArticlesData,
  },
}) => {
  const { matches: isSmOrLarger } = useMatchMedia(
    `(min-width: ${breakpoints.sm}px)`
  )
  const fixedCtaScrollYPosition = isSmOrLarger ? 560 : 250

  return (
    <SeamlessWebsiteLayout
      title={title}
      pageTitle={pageTitle}
      description={description}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <MetaTagRobots />
      <OpenGraphTags
        title={pageTitle}
        description={metaDescription}
        image={
          ogImage
            ? {
                alt: ogImage.fields.asset.title,
                ...ogImage.fields.asset.resize,
              }
            : null
        }
        locale={locale}
      />
      <Hreflang />
      <HomepageHeader />
      <WorkoutCardsCarousel
        title={workoutSectionTitle}
        description={workoutSectionDescription}
        workouts={workoutSectionCarousel}
      />
      <RecipeCardsCarousel
        title={recipeSectionTitle}
        description={recipeSectionDescription}
        recipes={recipeSectionCarousel}
      />
      <UserJourney />
      <ArticlesSection
        title={articlesSectionTitle}
        description={articlesSectionDescription}
        articles={listOfArticlesData?.nodes}
      />
      <FixedCta visibleAfterScrollY={fixedCtaScrollYPosition} />
    </SeamlessWebsiteLayout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query Homepage($id: String!, $node_locale: String!) {
    contentfulHomepage(id: { eq: $id }) {
      title
      pageTitle
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
      description {
        description
      }
      metaDescription
      ogImage {
        fields {
          asset {
            title
            resize {
              src
              width
              height
            }
          }
        }
      }
      workoutSectionTitle
      workoutSectionDescription
      workoutSectionCarousel {
        title
        description
        durationMins
        fields {
          path
        }
        featureImage {
          fields {
            asset {
              fixed(
                width: 309
                height: 214
                cropFocus: LEFT
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
      recipeSectionTitle
      recipeSectionDescription {
        recipeSectionDescription
      }
      recipeSectionCarousel {
        title
        categories
        preparationTimeMin
        calories
        fields {
          path
        }
        image {
          fields {
            asset {
              fixed(
                width: 309
                height: 214
                cropFocus: CENTER
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
      articlesSectionTitle
      articlesSectionDescription {
        articlesSectionDescription
      }
      node_locale
    }
    listOfArticlesData: allContentfulArticlePage(
      filter: {
        slug: { regex: "/^(?!___)/" }
        content: { raw: { ne: null } }
        node_locale: { eq: $node_locale }
      }
      sort: { fields: originalPublishDate, order: DESC }
      limit: 6
    ) {
      nodes {
        id
        title
        featureImage {
          title
          fields {
            asset {
              fixed(
                width: 309
                height: 214
                cropFocus: FACES
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
        fields {
          path
          readingTimeEstimate
          mainCategory {
            displayName
            identifier
          }
        }
      }
    }
  }
`
