import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import LandingPageLayout from 'components/layout/LandingPageLayout'
import {
  UnderlineSectionSmallHeader,
  UnderlineTopPageHeader,
} from 'components/UnderlineText'
import Logo from 'components/logo'
import { EmailForm, SuccessMessage } from 'components/EmailCaptureForm'
import { Visible, Hidden } from 'components/grid'

import {
  page,
  fullHeight,
  container,
  header,
  content,
  logo,
  title as titleStyle,
  form,
  footer,
  contact,
} from './index.module.scss'

interface NewsletterSubscriptionPageProps {
  data: {
    contentfulNewsletterEmailCapture: {
      fields: {
        hrefLang: EF.HrefLangNode[]
        defaultLang: EF.DefaultLangNode
      }
      title: string
      description: string
      ctaLabel: string
      placeholder: string
    }
  }
}

const NewsletterSubscriptionPage = ({
  data: {
    contentfulNewsletterEmailCapture: {
      title,
      description,
      ctaLabel,
      placeholder,
      fields: { hrefLang, defaultLang },
    },
  },
}: NewsletterSubscriptionPageProps) => {
  const [headerText, setHeaderText] = useState(title)
  const [isSubmitted, setIsSubmitted] = useState(false)

  function onSubmitComplete(bool: boolean) {
    setIsSubmitted(bool)
    setHeaderText(i18n._(t`Thank you!`))
  }

  return (
    <LandingPageLayout
      title={title}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
      className={page}
      classNameContentOrigin={fullHeight}
    >
      <div className={container}>
        <div className={content}>
          <div className={header}>
            <Logo className={logo} />
            <Visible md>
              <UnderlineTopPageHeader
                text={headerText}
                containerStyle={titleStyle}
              />
            </Visible>
            <Hidden md>
              <UnderlineSectionSmallHeader
                text={headerText}
                containerStyle={titleStyle}
              />
            </Hidden>
          </div>
          {isSubmitted ? (
            <SuccessMessage className={form} hideTitle />
          ) : (
            <EmailForm
              description={description}
              ctaLabel={ctaLabel}
              placeholder={placeholder}
              className={form}
              submitCompleted={onSubmitComplete}
              hideTitle
            />
          )}
        </div>
      </div>

      <div className={footer}>
        <a
          href={i18n._(t`https://8fit.zendesk.com/hc/en-us`)}
          className={contact}
        >
          {i18n._(t`Contact Support`)}
        </a>
        <Link to={i18n._(t`/impressum/`)}>{i18n._(t`Impressum`)}</Link>
      </div>
    </LandingPageLayout>
  )
}

export default NewsletterSubscriptionPage

export const pageQuery = graphql`
  query NewsletterSubscriptionPage($id: String!) {
    contentfulNewsletterEmailCapture(id: { eq: $id }) {
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
      title
      description
      ctaLabel
      placeholder
    }
  }
`
