import React, { useState, forwardRef, ReactNode, ReactElement } from 'react'
import { noop } from 'lodash'

import { classNames } from 'utils/dom'
import { useUniqueId } from 'hooks'
import { SwitchButtons } from 'components/switch/switch-buttons'

import {
  container,
  root,
  positionRoot,
  label as labelStyle,
  errorMessageContainer,
  errorMessage as errorMessageStyle,
  inputsRow,
  controlsRow,
  inputDefault,
  unitSwitchContainer,
} from './index.module.scss'

const DefaultInputElement = forwardRef(
  (
    { ...props }: React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return <input ref={ref} className={inputDefault} {...props} />
  }
)

export type unitType = 'imperial' | 'metric'

export interface UnitChoice {
  value: unitType
  text: string
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  children?: ReactNode
  optional?: boolean
  errorMessage?: string
  successMessage?: string
  className?: string
  classNameControlsRow?: string
  inputRef?: React.MutableRefObject<HTMLInputElement | null>
  unit?: string
  unitChoices?: UnitChoice[]
  onUnitChange?: (
    value: unitType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
}

const Input = ({
  value = '',
  unit,
  unitChoices,
  label = '',
  onFocus = noop,
  onBlur = noop,
  optional = false,
  onUnitChange = noop,
  errorMessage = '',
  successMessage = '',
  children = undefined,
  className = undefined,
  classNameControlsRow = undefined,
  inputRef,
  ...props
}: InputProps) => {
  const [hasFocus, setHasFocus] = useState(false)
  const isInvalid = !!errorMessage
  const hasValue = value !== ''
  const hasSuccessMessage = !!successMessage
  const id = useUniqueId('input-')
  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    ...props,
    id,
    value,
    onFocus(e) {
      setHasFocus(true)
      onFocus(e)
    },
    onBlur(e) {
      setHasFocus(false)
      onBlur(e)
    },
    required: !optional,
    'aria-invalid': isInvalid,
  }

  let state

  if (hasSuccessMessage) {
    state = 'success'
  }
  if (isInvalid) {
    state = 'error'
  }

  return (
    // Disabling the rule because the control is within children or otherwise
    // DefaultInputElement which seems to slip from jsx-a11y
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <div className={container}>
      <div
        data-focused={hasFocus}
        data-has-value={hasValue}
        data-state={state}
        className={classNames([root, className])}
      >
        <div className={positionRoot}>
          <label className={labelStyle} htmlFor={id}>
            {label}
            {hasSuccessMessage && <span>{successMessage}</span>}
          </label>
          <div className={classNames([controlsRow, classNameControlsRow])}>
            <div className={inputsRow}>
              {children ? (
                React.cloneElement(
                  React.Children.only(children as ReactElement),
                  inputProps
                )
              ) : (
                <DefaultInputElement {...inputProps} ref={inputRef} />
              )}
            </div>
            {unit && unitChoices?.length && (
              <SwitchButtons
                className={unitSwitchContainer}
                hasFocus={hasFocus}
                options={unitChoices}
                selectedValue={unit}
                uniqueGroupName={`${props.name}-switchButtons`}
                onChange={(e) => {
                  onUnitChange(e.target.value as unitType, e)
                }}
              />
            )}
          </div>
        </div>
      </div>
      {hasValue && isInvalid && (
        <div className={errorMessageContainer}>
          <span role="alert" className={errorMessageStyle}>
            {label} {errorMessage}
          </span>
        </div>
      )}
    </div>
  )
}

export default Input
