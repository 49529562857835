import React, { InputHTMLAttributes } from 'react'

import { classNames } from 'utils/dom'

import {
  unitSwitchLabel,
  unitSwitchRadio,
  selected,
} from './switch-buttons.module.scss'

interface Option {
  value: string
  text: string
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  options: Option[]
  selectedValue: string
  uniqueGroupName: string
  className?: string
  hasFocus: boolean
}

export function SwitchButtons(props: Props) {
  const {
    options,
    selectedValue,
    uniqueGroupName,
    className,
    hasFocus,
    ...inputProps
  } = props

  return (
    <div className={className}>
      {options.map(({ value, text }, index) => (
        <label
          data-focused={hasFocus}
          className={classNames([
            unitSwitchLabel,
            value === selectedValue && selected,
          ])}
          key={value + index}
        >
          <input
            className={unitSwitchRadio}
            type="radio"
            name={uniqueGroupName}
            id={value + text + index}
            autoComplete="off"
            checked={value === selectedValue}
            value={value}
            {...inputProps}
          />
          {text}
        </label>
      ))}
    </div>
  )
}
