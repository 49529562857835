import { setTag } from '@sentry/gatsby'

import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import {
  SUPPORTED_CURRENCIES,
  DEFAULT_CURRENCY,
} from 'templates/signup/constants'

import { SideEffect } from '../types'

const setCurrencyEffect: SideEffect = {
  name: 'Set Currency',
  isSilent: true,
  condition: () => {
    const countryCode = UserAccountStore.getAttribute('country_code')
    const currency = UserAccountStore.getAttribute('currency')
    return !!countryCode && !currency
  },
  execute: async () => {
    const {
      default: { getCurrency },
    } = await import('locale-currency')
    const countryCode = UserAccountStore.getAttribute('country_code')
    let currency = getCurrency(countryCode || '') as EF.supportedCurrency
    if (!SUPPORTED_CURRENCIES.includes(currency)) {
      currency = DEFAULT_CURRENCY
    }
    UserAccountStore.setAttribute('currency', currency)
    setTag('currency', currency)
  },
}

export default setCurrencyEffect
