export const wait = (duration = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, duration)
  })

export const intervalTimes = (
  intervalDelay: number,
  times: number,
  cb: () => void
) => {
  let count = 0
  const interval = setInterval(() => {
    if (count >= times) {
      clearInterval(interval)
      return
    }
    cb()
    count++
  }, intervalDelay)
}

export const waitRetry = async <T>(
  fn: () => undefined | T,
  { timeout, retries }: { timeout: number; retries: number }
) => {
  for (let i = 0; i < retries; i++) {
    await wait(timeout)
    const res = fn()
    if (res !== undefined) {
      return res
    }
  }
  throw new Error("Result didn't occur within number of retries")
}
