import React from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Container, Row, Col, Visible, Hidden } from 'components/grid'
import { SecondaryButtonLink } from 'components/Buttons'
import FunnelCta from 'components/funnel-cta'
import iconMeals from 'assets/images/about/usp-section/meals.svg'
import iconProgress from 'assets/images/about/usp-section/progress.svg'
import iconWellbeing from 'assets/images/about/usp-section/wellbeing.svg'
import iconWorkout from 'assets/images/about/usp-section/workout.svg'
import cool3Filled from 'assets/images/blubs/cool3-filled.svg'
import green2Filled from 'assets/images/blubs/green2-filled.svg'
import warm2Filled from 'assets/images/blubs/warm2-filled.svg'
import whiteFilled from 'assets/images/blubs/white-filled.svg'

import PhoneMeals from './phone-meals'
import PhoneWorkouts from './phone-workouts'
import Usp from './usp'
import {
  spacerWorkouts,
  workouts,
  viewMoreCta,
  wellbeing,
  meals,
  progress,
  colCta,
} from './index.module.scss'

const UspSection = () => {
  return (
    <section>
      <Container>
        <div className={spacerWorkouts} />
        <Row>
          <Col xs={12} sm={5} smOffset={1} md={5} xl={5}>
            <Usp
              className={workouts}
              icon={iconWorkout}
              blub={warm2Filled}
              title={i18n._(
                t('about.usp_section.workouts.title')`Fun, varied workouts`
              )}
              description={i18n._(
                t(
                  'about.usp_section.workouts.description'
                )`Combine full-body HIIT training with workouts like cardio boxing, yoga, Pilates and more.`
              )}
              buttons={
                <>
                  <Hidden xl>
                    <FunnelCta
                      text={i18n._(
                        t('about.cta.startFreeTrial')`Start FREE trial`
                      )}
                    />
                  </Hidden>
                  <SecondaryButtonLink
                    path={i18n._(t`/#workouts`)}
                    text={i18n._(t('about.cta.viewMore')`View more`)}
                    className={viewMoreCta}
                  />
                  <Visible xl>
                    <FunnelCta
                      text={i18n._(
                        t('about.cta.startFreeTrial')`Start FREE trial`
                      )}
                    />
                  </Visible>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
      <PhoneWorkouts />
      <Container>
        <Row>
          <Col sm={5} smOffset={1}>
            <Visible sm>
              <Usp
                className={wellbeing}
                icon={iconWellbeing}
                blub={cool3Filled}
                title={i18n._(
                  t('about.usp_section.wellbeing.title')`Whole-body health`
                )}
                description={i18n._(
                  t(
                    'about.usp_section.wellbeing.description'
                  )`Self-care guidance, meditation and stretching for overall wellbeing.`
                )}
              />
            </Visible>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            smOffset={8}
            sm={4}
            mdOffset={7}
            md={5}
            xl={7}
            xlOffset={5}
          >
            <Usp
              className={meals}
              icon={iconMeals}
              blub={green2Filled}
              title={i18n._(
                t('about.usp_section.meals.title')`Healthy & personalised meals`
              )}
              description={i18n._(
                t(
                  'about.usp_section.meals.description'
                )`Personalise your meal plan based on dietary preferences – dairy-free, gluten-free, vegetarian, and more. Exclude any foods, whether it’s a preference or allergy.`
              )}
              buttons={
                <>
                  <Hidden xl>
                    <FunnelCta
                      text={i18n._(
                        t('about.cta.startFreeTrial')`Start FREE trial`
                      )}
                    />
                  </Hidden>
                  <SecondaryButtonLink
                    path={i18n._(t`/#recipes`)}
                    text={i18n._(t('about.cta.viewMore')`View more`)}
                    className={viewMoreCta}
                  />
                  <Visible xl>
                    <FunnelCta
                      text={i18n._(
                        t('about.cta.startFreeTrial')`Start FREE trial`
                      )}
                    />
                  </Visible>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
      <PhoneMeals />
      <Container>
        <Row>
          <Col xs={12} smOffset={7} sm={5} lg={6} xl={5}>
            <Visible sm>
              <Usp
                className={progress}
                icon={iconProgress}
                blub={whiteFilled}
                title={i18n._(
                  t('about.usp_section.progress.title')`Progress tracking`
                )}
                description={i18n._(
                  t(
                    'about.usp_section.progress.description'
                  )`Small wins are big motivators. Set weekly goals to stay on track.`
                )}
              />
            </Visible>
            <Hidden sm>
              <Usp
                className={wellbeing}
                icon={iconWellbeing}
                blub={cool3Filled}
                title={i18n._(
                  t('about.usp_section.wellbeing.title')`Whole-body health`
                )}
                description={i18n._(
                  t(
                    'about.usp_section.wellbeing.description'
                  )`Self-care guidance, meditation and stretching for overall wellbeing.`
                )}
              />
            </Hidden>
          </Col>
        </Row>
        <Row>
          <Col className={colCta}>
            <FunnelCta
              text={i18n._(t('about.cta.startFreeTrial')`Start FREE trial`)}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default UspSection
