// extracted by mini-css-extract-plugin
export var centerButtons = "_2gqD5";
export var container = "rDSkg";
export var dot = "_3fomz";
export var dotWrapper = "bTnG7";
export var fallback = "+oyds";
export var fallbackItem = "AT+PT";
export var fallbackItemFluid = "KD6zT";
export var overlayButtonContainer = "_812wp";
export var overlayStepButton = "jNPC3";
export var stepButton = "XX--5";
export var stepButtonBack = "PfYTo";
export var stepButtonImg = "ECQaC";
export var stepButtonNext = "_46Kh4";