import React, { FunctionComponent } from 'react'

import { classNames } from 'utils/dom'

import { decorationTop, decorationBottom, content } from './index.module.scss'

const CloudyDecoratedSection: FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <>
      <div className={decorationTop} />
      <div className={classNames([content, className])}>{children}</div>
      <div className={decorationBottom} />
    </>
  )
}

export default CloudyDecoratedSection
