import { navigate } from 'gatsby'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { ToastStatus } from 'components/toast-message'

import { allowedToastCodes } from './data'

export const navigateToProfileWithToast = (
  toastCode: allowedToastCodes,
  status: ToastStatus
) => {
  navigate(`${i18n._(t`/profile/`)}`, {
    state: { toastCode, status },
  })
}
