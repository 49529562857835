import React from 'react'
import GatsbyImage, { FixedObject, FluidObject } from 'gatsby-image'

import wave1 from 'assets/images/waves/warm1-1.svg'
import wave2 from 'assets/images/waves/warm1-2.svg'
import wave3 from 'assets/images/waves/green1-1.svg'
import wave4 from 'assets/images/waves/grey1-1.svg'

import { root, imageContainer, bottomWave } from './image-with-wave.module.scss'

export type WaveType = 1 | 2 | 3 | 4

interface BaseProps {
  wave: WaveType
}

interface FluidImage extends BaseProps {
  fluid: FluidObject
}

interface FixedImage extends BaseProps {
  fixed: FixedObject
}

type Props = FluidImage | FixedImage

const waves = {
  1: {
    src: wave1,
    width: 1750,
    height: 275,
    excess: 109,
  },
  2: {
    src: wave2,
    width: 309,
    height: 72,
    excess: 14,
  },
  3: {
    src: wave3,
    width: 309,
    height: 72,
    excess: 14,
  },
  4: {
    src: wave4,
    width: 309,
    height: 72,
    excess: 14,
  },
} as const

const isFluidImage = (obj: Props): obj is FluidImage =>
  'fluid' in obj && !!obj.fluid

const ImageWithWave = (props: Props) => {
  const wave = waves[props.wave]
  const waveRatio = wave.height / wave.width
  const waveExcessRatio = wave.excess / wave.width
  const gatsbyImageProps = isFluidImage(props)
    ? { fluid: props.fluid }
    : { fixed: props.fixed }
  const imageRatio = isFluidImage(props)
    ? 1 / props.fluid.aspectRatio
    : props.fixed.height / props.fixed.width
  const spacerRatio = imageRatio + waveExcessRatio

  return (
    <div className={root}>
      {/* This element takes up space for the entire element, including the wave: */}
      <div style={{ paddingBottom: `${spacerRatio * 100}%` }} />
      {/* This element includes the actual image & wave, positioned absolutely: */}
      <div className={imageContainer}>
        <GatsbyImage draggable={false} {...gatsbyImageProps} />
        <div
          className={bottomWave}
          style={{
            backgroundImage: `url(${wave.src})`,
            paddingBottom: `${waveRatio * 100}%`,
            marginBottom: `-${waveExcessRatio * 100}%`,
          }}
        />
      </div>
    </div>
  )
}

export default ImageWithWave
