// extracted by mini-css-extract-plugin
export var blueBerry = "q3+C+";
export var content = "H+LWm";
export var header = "bqpVQ";
export var loading = "cfK1G";
export var logo = "pmeG1";
export var paymentArea = "FqHN+";
export var peas = "VYDXT";
export var rtfHeader = "VcPta";
export var scrollButtonBelowTitleContainer = "XzEpI";
export var scrollButtonContainer = "Ss5lt";
export var title = "mGyLO";