import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { classNames } from 'utils/dom'

import { paymentOptions, paymentImage } from './SupportedCards.module.scss'

const query = graphql`
  {
    amex: file(name: { eq: "amexIcon" }) {
      childImageSharp {
        fixed(height: 20, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    visa: file(name: { eq: "visaIcon" }) {
      childImageSharp {
        fixed(height: 20, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mastercard: file(name: { eq: "mastercardIcon" }) {
      childImageSharp {
        fixed(height: 20, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const SupportedCards = ({ className }: { className?: string }) => {
  const { amex, visa, mastercard } = useStaticQuery<{
    amex: EF.LocalFixedImage
    visa: EF.LocalFixedImage
    mastercard: EF.LocalFixedImage
  }>(query)

  return (
    <div className={classNames([paymentOptions, className])}>
      <span className={paymentImage}>
        <GatsbyImage fixed={amex.childImageSharp.fixed} />
      </span>
      <span className={paymentImage}>
        <GatsbyImage
          fixed={mastercard.childImageSharp.fixed}
          className={paymentImage}
        />
      </span>
      <span className={paymentImage}>
        <GatsbyImage
          fixed={visa.childImageSharp.fixed}
          className={paymentImage}
        />
      </span>
    </div>
  )
}

export default SupportedCards
