import React, { useState, useEffect, useCallback } from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { classNames } from 'utils/dom'
import ControlIcon from 'components/icons/ControlIcon'

import { root, show, extraBottomMargin, icon, text } from './index.module.scss'

const JumpToTop = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [isFixedCtaVisible, setIsFixedCtaVisible] = useState(false)
  const onClick = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

  const handleScroll = useCallback(() => {
    setIsVisible(window.scrollY > window.innerHeight)

    setIsFixedCtaVisible(
      document.getElementById('fixed-cta')?.getAttribute('data-visible') ===
        'true'
    )
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <button
      className={classNames([
        root,
        isVisible ? show : undefined,
        isFixedCtaVisible ? extraBottomMargin : undefined,
      ])}
      onClick={onClick}
    >
      <ControlIcon className={icon} iconName="arrow-up" />
      <span className={text}>
        {i18n._(t('jump_to_top.screen_reader_description')`Jump to top`)}
      </span>
    </button>
  )
}

export default JumpToTop
