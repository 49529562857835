import React, {
  FunctionComponent,
  DetailedHTMLProps,
  HTMLAttributes,
} from 'react'
import { Helmet } from 'react-helmet'
import { DocumentOutlineSection } from 'react-document-section'

import { useSiteMetadata } from 'hooks'
import { getCurrentI18nInfo } from 'utils/i18n'
import { AltLanguagesProvider } from 'components/meta/AltLanguages'
import { ViewportWidthProvider } from 'components/utils/responsiveUtils'
import DebugGrid from 'components/grid/debug'
import JumpToTop from 'components/jump-to-top'

import './index.module.scss'

export interface LayoutProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  hrefLang: EF.HrefLangNode[]
  defaultLang: EF.DefaultLangNode
  title?: string
  pageTitle?: string
  description?: string
  className?: string
  classNameContentOrigin?: string
}

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  title,
  pageTitle,
  description,
  hrefLang,
  defaultLang,
  ...props
}) => {
  const siteMetadata = useSiteMetadata()

  return (
    <>
      <Helmet title={pageTitle || title || siteMetadata.title}>
        {description ? <meta name="description" content={description} /> : null}
        <html lang={getCurrentI18nInfo().publicLocale} />
      </Helmet>
      <AltLanguagesProvider hrefLang={hrefLang} defaultLang={defaultLang}>
        <ViewportWidthProvider>
          <DocumentOutlineSection title={title || siteMetadata.title}>
            <div {...props}>{children}</div>
            <JumpToTop />
          </DocumentOutlineSection>
        </ViewportWidthProvider>
      </AltLanguagesProvider>
      {process.env.NODE_ENV === 'development' ? <DebugGrid /> : null}
    </>
  )
}

export default Layout
