import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { H } from 'react-document-section'

import { Container, Row, Col } from 'components/grid'
import WebsiteLayout from 'components/layout/website'
import MetaTagRobots from 'components/meta/robots'
import OpenGraphTags from 'components/meta/open-graph-tags'
import Hreflang from 'components/meta/Hreflang'
import ScrollIndicator from 'components/scroll-indicator'
import FixedCta from 'components/fixed-cta'

import { TransformationStoryOverviewPageQueryResult } from './types'
import StoryOverView from './StoryOverView'
import {
  header,
  content,
  title as titleStyle,
  headerImageContainer,
} from './index.module.scss'

const TransformationStoryOverview = ({
  data: {
    contentfulTransformationStoryOverviewPage: {
      fields: { hrefLang, defaultLang },
      node_locale: locale,
      title,
      pageTitle,
      metaDescription,
      featureImage: {
        fields: { asset: featureImageAsset },
      },
      ogImage,
      transformationStories,
    },
  },
}: TransformationStoryOverviewPageQueryResult) => {
  return (
    <WebsiteLayout
      title={title}
      pageTitle={pageTitle}
      description={metaDescription}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <MetaTagRobots />
      <OpenGraphTags
        title={pageTitle}
        description={metaDescription}
        image={
          ogImage
            ? {
                alt: ogImage.fields.asset.title,
                ...ogImage.fields.asset.resize,
              }
            : null
        }
        locale={locale}
      />
      <Hreflang />
      <div className={header}>
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <H className={titleStyle} data-testid="pageTitle" />
            </Col>
            <Col xs={12} lg={4}>
              <div className={headerImageContainer}>
                <Image
                  fluid={featureImageAsset.fluid}
                  alt={featureImageAsset.title}
                  critical
                />
              </div>
            </Col>
          </Row>
          <ScrollIndicator isShifted />
        </Container>
      </div>
      <FixedCta />
      <div className={content}>
        {transformationStories.map(
          (
            { title, subTitle, backgroundImage, description, photo, fields },
            index
          ) => {
            return (
              <StoryOverView
                key={`transformation-overview-${index}`}
                isLeft={!!(index % 2)}
                title={title}
                subTitle={subTitle}
                backgroundImage={backgroundImage.fields.asset}
                description={description.description}
                photo={photo.fields.asset}
                path={fields.path}
              />
            )
          }
        )}
      </div>
    </WebsiteLayout>
  )
}

export default TransformationStoryOverview

export const pageQuery = graphql`
  query TransformationStoryOverview($id: String!) {
    contentfulTransformationStoryOverviewPage(id: { eq: $id }) {
      node_locale
      title
      pageTitle
      metaDescription
      featureImage {
        fields {
          asset {
            fluid(
              maxWidth: 462
              maxHeight: 374
              resizingBehavior: FILL
              cropFocus: FACES
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      ogImage {
        fields {
          asset {
            title
            resize {
              src
              width
              height
            }
          }
        }
      }
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
      transformationStories {
        title
        subTitle
        description {
          description
        }
        fields {
          path
        }
        backgroundImage {
          fields {
            asset {
              fluid(maxWidth: 560, maxHeight: 360, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        photo {
          fields {
            asset {
              fluid(
                maxWidth: 300
                maxHeight: 370
                resizingBehavior: FILL
                cropFocus: FACES
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
