import { ComponentClass, FunctionComponent, useState } from 'react'

import { useDidMount } from 'hooks'

type ElementConstructor = FunctionComponent | ComponentClass

export interface LazyLoadProps<M extends ElementConstructor> {
  resolve: () => Promise<{ default: M } | null | undefined>
  fallback?: () => JSX.Element | null
  loadWhenIdle?: boolean
  children(defaultExport: M): JSX.Element | null
}

const LazyLoad = <M extends ElementConstructor>({
  resolve,
  fallback = () => null,
  loadWhenIdle,
  children,
}: LazyLoadProps<M>) => {
  const [module, setModule] = useState<{ default: M } | null | undefined>()

  const loadModule = async () => {
    setModule(await resolve())
  }

  useDidMount(() => {
    if (loadWhenIdle && window.requestIdleCallback) {
      window.requestIdleCallback(loadModule)
    } else {
      loadModule()
    }
  })

  return !module ? fallback() : children(module.default)
}

export default LazyLoad
