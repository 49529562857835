// extracted by mini-css-extract-plugin
export var articleButton = "zOyWn";
export var background = "b0Sj+";
export var buttonContainer = "-c8y8";
export var carousel = "NU6SD";
export var carouselItem = "_0wXb9";
export var carouselWrapper = "Ka6oQ";
export var container = "_7WBeu";
export var description = "_1d0jU";
export var pageTitle = "F9CgZ";
export var pageTitleImage = "rorIk";
export var title = "XELOr";