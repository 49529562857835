// extracted by mini-css-extract-plugin
export var button = "UZDjR";
export var buttonWrapper = "zksym";
export var closeRequested = "VbHi7";
export var message = "bIsU9";
export var modal = "D338m";
export var modalAppear = "Cv092";
export var modalDisappear = "YhGjl";
export var overlay = "VTdMy";
export var overlayAppear = "KtnT6";
export var overlayDisappear = "_2YehD";