import { t } from '@lingui/macro'
import React from 'react'

import { i18n } from 'utils/i18n'

import { root } from './index.module.scss'

const CaloriesLabel = ({
  cal,
  isShort,
}: {
  cal: number
  isShort?: boolean
}) => {
  return (
    <span className={root}>
      {i18n._(
        isShort
          ? t('recipe.calories.short')`${cal} cal`
          : t('recipe.calories.long')`${cal} calories per serving`
      )}
    </span>
  )
}

export default CaloriesLabel
