import React, { useState, useLayoutEffect, useRef, ReactNode } from 'react'
import { noop } from 'lodash'

import { classNames } from 'utils/dom'

import { root, focused, value as valueStyle, select } from './index.module.scss'

interface Option {
  value: string
  text: string
}

interface Props
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  options: Option[]
  classNameValue?: string
  classNameSelect?: string
  displayValue?: ReactNode
}

const StyledSelect = ({
  value = undefined,
  options = [],
  onFocus = noop,
  onBlur = noop,
  className,
  classNameValue = '',
  classNameSelect = '',
  displayValue,
  ...props
}: Props) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const [minWidth, setMinWidth] = useState<number | undefined>(undefined)
  const [hasFocus, setHasFocus] = useState(false)

  useLayoutEffect(() => {
    const { current: selectEl } = selectRef
    if (!selectEl) return
    const boundingRect = selectEl.getBoundingClientRect()
    if (boundingRect.width !== minWidth) {
      setMinWidth(boundingRect.width)
    }
  }, [minWidth])

  if (!options.length) return null
  const { text } = options.find((o) => o.value === value) || options[0]

  return (
    <span
      className={classNames([root, className, hasFocus && focused])}
      style={{ minWidth }}
    >
      <span
        aria-hidden="true"
        className={classNames([valueStyle, classNameValue])}
      >
        {displayValue || text}
      </span>
      <select
        value={value}
        className={classNames([select, classNameSelect])}
        onFocus={(e) => {
          setHasFocus(true)
          onFocus(e)
        }}
        onBlur={(e) => {
          setHasFocus(false)
          onBlur(e)
        }}
        ref={selectRef}
        {...props}
      >
        {options.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>
    </span>
  )
}

export default StyledSelect
