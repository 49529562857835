import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'

import { SideEffect } from '../types'

const createAccountEmailEffect: SideEffect = {
  name: 'Create Account via Email',
  isSilent: false,
  isRecurring: true,
  condition: () => {
    const { getAttribute } = UserAccountStore
    return (
      getAttribute('id') === undefined &&
      getAttribute('SignupScreenSignupOptions') === 'Email' &&
      getAttribute('email') !== undefined &&
      getAttribute('password') !== undefined &&
      getAttribute('policy_last_accepted_at') !== undefined &&
      getAttribute('policy_health_data_consent') !== undefined
    )
  },
  execute: async () => {
    await UserAccountStore.createAccountWithEmail()
  },
}

export default createAccountEmailEffect
