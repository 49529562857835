// extracted by mini-css-extract-plugin
export var appIcon = "Cigql";
export var bannerContainer = "HcNkt";
export var closeButton = "DZi0b";
export var closeIcon = "n7vnK";
export var content = "HsSM6";
export var installText = "uHpcA";
export var star = "DX3ph";
export var subTitle = "ezeNV";
export var title = "fytKq";
export var userRatingRow = "_4nNs6";