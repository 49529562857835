import React, { useState, useEffect } from 'react'
import { noop } from 'lodash'
import { t } from '@lingui/macro'

import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import { parse } from 'utils/url'
import { i18n } from 'utils/i18n'
import PhotoInfo from 'components/payment/PhotoInfo'

const UserInfo = () => {
  const [user, setUser] = useState<EF.FetchUserBackendResponse | null>(null)

  const { fetchUserInfo } = useUserAccount()

  useEffect(() => {
    if (user) return

    const { search } = window.location
    const { user_id: userId } = parse(search)

    if (userId) {
      fetchUserInfo(userId)
        .then((result) => {
          setUser(result)
        })
        .catch(noop)
    }
  }, [fetchUserInfo, user])

  if (!user) return null

  return (
    <PhotoInfo
      photoImageUrl={user.profile_image_url}
      title={user.name}
      description={i18n._(t`You are securely logged in`)}
    />
  )
}

export default UserInfo
