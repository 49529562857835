import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  noindex?: boolean
  nofollow?: boolean
}

const MetaTagRobots = ({ noindex, nofollow }: Props) => {
  if (noindex || nofollow) {
    const content = [
      noindex ? 'noindex' : '',
      nofollow ? 'nofollow' : '',
    ].filter(Boolean)
    return (
      <Helmet>
        <meta name="robots" content={content.join(', ')} />
      </Helmet>
    )
  }
  return null
}

export default MetaTagRobots
