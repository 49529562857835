import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

import { SecondaryButtonLink } from 'components/Buttons'

import { list, listItem } from './pillNavigation.module.scss'

interface Item {
  href: string
  text: string
}

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  items: Item[]
}

const PillNavigation = ({ items, ...props }: Props) => (
  <nav {...props}>
    <ul className={list}>
      {items.map(({ href, text }) => (
        <li key={href} className={listItem}>
          <SecondaryButtonLink
            path={href}
            text={text}
            getProps={({ isCurrent }) =>
              isCurrent ? { 'data-state': 'disabled' } : {}
            }
          />
        </li>
      ))}
    </ul>
  </nav>
)
export default PillNavigation
