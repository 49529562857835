import React from 'react'
import { Trans, t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { StripeError } from 'gatsby-plugin-8fit-user-account'
import { PrimaryButton } from 'components/Buttons'
import AppStoreBadge from 'assets/images/icons/store-badge/app-store.svg'
import PlayStoreBadge from 'assets/images/icons/store-badge/play-store.svg'
import SubscriptionComplete from 'assets/images/icons/subscription-complete.svg'
import SubscriptionError from 'assets/images/icons/subscription-error.svg'
import AdjustLink from 'components/utils/adjust-link'

import {
  container,
  icon,
  title,
  description,
  errorReason,
  downloadText,
  storeLinks,
  storeBadge,
} from './SubscriptionStatusArea.module.scss'

export enum SubscriptionStatus {
  COMPLETE = 'complete',
  ERROR = 'error',
}

export interface SubscriptionState {
  status: SubscriptionStatus
  error?: StripeError | Error
}

interface SubscriptionStatusAreaProps {
  state: SubscriptionState
  onTryAgain: () => void
}

const ErrorState = ({
  onTryAgain,
  error,
}: {
  onTryAgain: () => void
  error?: StripeError | EF.ErrorWithReadableMessage
}) => (
  <div className={container}>
    <img className={icon} src={SubscriptionError} alt="" role="presentation" />
    <div className={title} data-testid="errorTitle">
      <Trans>We couldn’t process your payment</Trans>
    </div>
    <p className={description}>
      <Trans id="crm:error-state-desc">
        Don’t worry you haven’t been charged! <br /> Please try again or contact
        support if the issue continues.
      </Trans>
    </p>
    {(error instanceof StripeError || error?.hasReadableMessage) && (
      <p className={errorReason}>{error.message}</p>
    )}
    <PrimaryButton
      onClick={onTryAgain}
      data-testid="tryAgainButton"
      text={i18n._(t`Try again`)}
    />
  </div>
)

const SuccessState = () => (
  <div className={container}>
    <img
      className={icon}
      src={SubscriptionComplete}
      alt=""
      role="presentation"
    />
    <div className={title} data-testid="successTitle">
      <Trans>Welcome 8fitter!</Trans>
    </div>
    <p className={description}>
      <Trans id="crm:success-state-desc">
        Thank you for purchasing 8fit Pro. <br /> We are SO excited to welcome
        you to the 8fit family :) <br />
        Change starts here, so let’s get started with building you the perfect
        meal plan to help achieve your goals!
      </Trans>
    </p>
    <div className={downloadText}>
      <Trans>Haven't got the app? Download it now:</Trans>
    </div>
    <div className={storeLinks}>
      <AdjustLink
        preferAndroidStore={false}
        className={storeBadge}
        data-testid="appStoreButton"
      >
        <img src={AppStoreBadge} alt="App Store" />
      </AdjustLink>
      <AdjustLink
        preferAndroidStore
        className={storeBadge}
        data-testid="playStoreButton"
      >
        <img src={PlayStoreBadge} alt="Google Play Store" />
      </AdjustLink>
    </div>
  </div>
)

const SubscriptionStatusArea = ({
  state,
  onTryAgain,
}: SubscriptionStatusAreaProps) => {
  if (state.status === SubscriptionStatus.COMPLETE) {
    return <SuccessState />
  }

  return <ErrorState onTryAgain={onTryAgain} error={state.error} />
}

export default SubscriptionStatusArea
