import React, { PropsWithChildren, ReactNode, useContext } from 'react'
import * as Sentry from '@sentry/gatsby'
import { Extras } from '@sentry/types'

import { useNotificationCentral } from '.'

interface PromiseReporterProps {
  delay: number
  renderProcessingMessage(): ReactNode
  renderErrorMessage(error?: Error): string
}

interface PromiseReporterContext {
  reportPromiseProgress(
    promise: Promise<unknown>,
    customDelay?: number
  ): Promise<void>
  reportAsyncProgress(asyncFn: () => Promise<unknown>): Promise<void>
}

const PromiseReporterContext = React.createContext<PromiseReporterContext>({
  reportPromiseProgress: () => Promise.resolve(),
  reportAsyncProgress: () => Promise.resolve(),
})

const PromiseReporter = ({
  children,
  delay,
  renderProcessingMessage,
  renderErrorMessage,
}: PropsWithChildren<PromiseReporterProps>) => {
  const { setNotification, clearNotification } = useNotificationCentral()

  const reportPromiseProgress = (
    promise: Promise<unknown>,
    customDelay?: number
  ) => {
    if (
      process.env.NODE_ENV === 'development' &&
      (!promise || promise.constructor !== Promise)
    ) {
      throw new Error(`'reportPromiseProgress' must receive a promise`)
    }
    return new Promise<void>((resolve, reject) => {
      const timeout = setTimeout(() => {
        setNotification({
          message: renderProcessingMessage(),
          dismissable: false,
          buttonLabel: null,
        })
      }, customDelay || delay)
      promise
        .then(() => {
          clearNotification()
          resolve()
        })
        .catch((err) => {
          let extra: Extras | undefined
          if ('url' in err) {
            extra = {
              message: err.message,
              url: err.url,
              data: err.data?.errors || err.data,
            }
          }
          Sentry.captureEvent({
            exception: { values: [err] },
            level: Sentry.Severity.Warning,
            extra,
          })
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error(err)
          }
          setNotification({ message: renderErrorMessage(err) })
          reject(err)
        })
        .finally(() => {
          clearTimeout(timeout)
        })
    })
  }

  const reportAsyncProgress = (asyncFn: () => Promise<unknown>) =>
    reportPromiseProgress(asyncFn())

  return (
    <PromiseReporterContext.Provider
      value={{ reportPromiseProgress, reportAsyncProgress }}
    >
      {children}
    </PromiseReporterContext.Provider>
  )
}

PromiseReporter.defaultProps = {
  children: null,
  delay: 500,
  renderProcessingMessage: () => 'Processing',
  renderErrorMessage: (err) => err?.message || '',
} as PromiseReporterProps

export const usePromiseReporter = () => useContext(PromiseReporterContext)

export default PromiseReporter
