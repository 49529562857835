import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@gatsbyjs/reach-router'

import { useSiteMetadata } from 'hooks'
import { SupportedLocaleName } from 'utils/i18n'

type OpenGraphType =
  | 'music.song'
  | 'music.album'
  | 'music.playlist'
  | 'music.radio_station'
  | 'video.movie'
  | 'video.episode'
  | 'video.tv_show'
  | 'video.other'
  | 'article'
  | 'book'
  | 'profile'
  | 'website'

interface Image {
  alt: string
  src: string
  width: number
  height: number
}

interface OpenGraphTagsProps {
  title: string
  description: string
  locale?: SupportedLocaleName
  type?: OpenGraphType
  image?: Image
}

const OpenGraphTags = ({
  title,
  description,
  locale = 'en-US',
  type = 'article',
  image = {
    alt: '',
    src: '',
    width: 0,
    height: 0,
  },
}: OpenGraphTagsProps) => {
  const { siteName, siteUrl } = useSiteMetadata()
  const { pathname } = useLocation()

  return (
    <>
      <Helmet>
        <meta property="og:locale" content={locale.replace('-', '_')} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${siteUrl}${pathname}`} />
        <meta property="og:site_name" content={siteName} />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/8fitapp/"
        />
        <meta property="fb:app_id" content="437990893000274" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:site" content="@8fit" />
        <meta name="twitter:creator" content="@8fit" />
      </Helmet>
      {image?.src ? (
        <Helmet>
          <meta name="twitter:image" content={image.src} />
          <meta property="og:image" content={image.src} />
          {(['alt', 'width', 'height'] as (keyof Image)[]).map((aspect) =>
            image[aspect] ? (
              <meta
                key={aspect}
                property={`og:image:${aspect}`}
                content={String(image[aspect])}
              />
            ) : null
          )}
        </Helmet>
      ) : null}
    </>
  )
}

export default OpenGraphTags
