import React from 'react'
import { graphql } from 'gatsby'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { useMatchMedia } from 'hooks'
import { Container, Row, Col, breakpoints } from 'components/grid'
import WebsiteLayout from 'components/layout/website'
import Hreflang from 'components/meta/Hreflang'
import OpenGraphTags from 'components/meta/open-graph-tags'
import MetaTagRobots from 'components/meta/robots'
import ArticlePageHeader from 'components/headers/articlePage'
import Breadcrumb from 'components/breadcrumb'
import ArticlePreviewPolaroid from 'components/articlePreview/polaroid'
import { ArticlePageRTF } from 'components/rich-text-field'
import Carousel from 'components/carousel'
import EmailCaptureForm from 'components/EmailCaptureForm'

import { ArticlePageQueryResult, RelatedArticle } from './types'
import SharingButtons from './sharing-buttons'
import FixedSignupCta from './fixed-signup-cta'
import {
  breadcrumbBar,
  articleMainText,
  sidebar,
  rtfWrapper,
  sidebarAreaFixedCta,
  relatedArticlesSection,
  relatedArticlesTitle,
  relatedArticlesContainer,
} from './index.module.scss'

const renderRelatedArticles = (relatedArticles: RelatedArticle[]) =>
  relatedArticles.map(
    (
      {
        title,
        fields,
        category: {
          displayName,
          fields: { path: categoryPath },
        },
        featureImage,
      },
      index
    ) => {
      return (
        <ArticlePreviewPolaroid
          key={`${categoryPath}-${index}`}
          title={title}
          image={featureImage}
          category={displayName}
          path={fields.path}
          parentPath={categoryPath}
        />
      )
    }
  )

const ArticlePage = ({
  data: {
    contentfulArticlePage: {
      title,
      subtitle,
      description: { description },
      featureImage,
      featureImageHref,
      ogImage,
      author,
      content,
      noIndex,
      noFollow,
      fields: { pageTitle, breadcrumb, hrefLang, defaultLang, relatedArticles },
      node_locale: locale,
      hideDefaultNewsLetterEmailCapture,
    },
    defaultEmailCaptureForm,
  },
}: ArticlePageQueryResult) => {
  const { matches: isLarge } = useMatchMedia(`(min-width: ${breakpoints.lg}px)`)

  return (
    <WebsiteLayout
      title={title}
      pageTitle={pageTitle}
      description={description}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <MetaTagRobots noindex={noIndex} nofollow={noFollow} />
      <Hreflang />
      <OpenGraphTags
        title={pageTitle}
        description={description}
        image={
          ogImage
            ? {
                alt: ogImage.title,
                ...ogImage.fields.asset.resize,
              }
            : null
        }
        locale={locale}
      />
      <main>
        <article>
          <ArticlePageHeader
            subtitle={subtitle}
            image={featureImage}
            imageLink={featureImageHref}
            author={author}
          />
          <div className={breadcrumbBar}>
            <Container>
              <Row>
                <Col>
                  <Breadcrumb
                    items={breadcrumb
                      .filter(({ text, fields }) => text && fields)
                      .map(({ text, fields: { href } }) => ({
                        text,
                        href,
                      }))}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className={articleMainText}>
            <Container>
              <Row>
                <Col xs={0} md={4} className={sidebar}>
                  <div>
                    <SharingButtons
                      description={description}
                      imageUrl={ogImage?.fields.asset.resize.src.replace(
                        /^\/\//,
                        'https://'
                      )}
                    />
                  </div>
                  <div className={sidebarAreaFixedCta}>
                    <FixedSignupCta which="desktop" />
                  </div>
                </Col>
                <Col xs={12} md={8} lg={6}>
                  <div className={rtfWrapper}>
                    <ArticlePageRTF
                      data={content}
                      options={{ ParagraphBlock: { withDropCap: true } }}
                    />
                  </div>
                  {locale === 'en-US' &&
                    hideDefaultNewsLetterEmailCapture !== false && (
                      <EmailCaptureForm {...defaultEmailCaptureForm} />
                    )}
                  <FixedSignupCta which="mobile" />
                </Col>
              </Row>
            </Container>
          </div>
        </article>
      </main>
      {relatedArticles?.length ? (
        <section className={relatedArticlesSection}>
          <DocumentOutlineSection title={i18n._(t`Related Articles`)}>
            <Container>
              <Row>
                <Col>
                  <H className={relatedArticlesTitle} />
                </Col>
              </Row>
            </Container>
            <Carousel
              className={relatedArticlesContainer}
              gap={isLarge ? 80 : 20}
              defaultIndex={isLarge ? 1 : 0}
              keyPrefix="related-article"
              showDots
            >
              {renderRelatedArticles(relatedArticles)}
            </Carousel>
          </DocumentOutlineSection>
        </section>
      ) : null}
    </WebsiteLayout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePage($id: String!) {
    contentfulArticlePage(id: { eq: $id }) {
      title
      subtitle
      node_locale
      description {
        description
      }
      featureImage {
        title
        fields {
          asset {
            fluid(
              maxWidth: 913
              maxHeight: 600
              resizingBehavior: FILL
              cropFocus: FACES
              sizes: "(min-width: 768px) 628px, calc(100vw - 40px)"
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      featureImageHref
      ogImage: featureImage {
        title
        fields {
          asset {
            resize(width: 1200, height: 630, cropFocus: FACES) {
              src
              width
              height
            }
          }
        }
      }
      author {
        name
        image {
          fields {
            asset {
              fixed(width: 40) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            ...RTFContentfulAssetBlockFragment
            fields {
              asset {
                fluid(maxWidth: 628) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }

          ... on ContentfulRtfImage {
            ...RTFContentfulRtfImageBlockFragment
          }

          ... on ContentfulTooltip {
            ...RTFContentfulTooltipBlockFragment
          }

          ... on ContentfulSignupCta {
            ...RTFContentfulSignupCtaBlockFragment
          }

          ... on ContentfulArbitraryEmbeddedCode {
            ...RTFContentfulArbitraryEmbeddedCodeBlockFragment
          }

          ... on ContentfulRecipe {
            ...RTFContentfulRecipeBlockFragment
          }

          ... on ContentfulWorkout {
            ...RTFContentfulWorkoutBlockFragment
          }

          ... on ContentfulNewsletterEmailCapture {
            ...RTFContentfulNewsletterEmailCaptureBlockFragment
          }
        }
      }
      noIndex
      noFollow
      fields {
        pageTitle
        breadcrumb {
          ... on ContentfulCategory {
            text: displayName
            fields {
              href: path
            }
          }
        }
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
        relatedArticles {
          title
          description {
            description
          }
          fields {
            path
          }
          category {
            displayName
            fields {
              path
            }
          }
          featureImage {
            fields {
              asset {
                fixed(width: 280, height: 172) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
            }
          }
        }
      }
      hideDefaultNewsLetterEmailCapture
    }
    defaultEmailCaptureForm: contentfulNewsletterEmailCapture(
      isDefaultNewsletterCapture: { eq: true }
      node_locale: { eq: "en-US" }
    ) {
      title
      description
      ctaLabel
      placeholder
      isDefaultNewsletterCapture
      backgroundImage {
        fields {
          asset {
            fluid(maxWidth: 375, resizingBehavior: FILL, quality: 80) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
