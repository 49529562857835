import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  PropsWithChildren,
} from 'react'

import { useDidMount } from 'hooks'
import { localStorage } from 'utils/clientStorage'

import { UserAccountAttributes } from '../types'

import UserAccountStore from './user-account'

type AccountStateType = Partial<UserAccountAttributes>

export enum AuthenticationState {
  // Not authenticated
  None,
  // A token exists in the localStorage, but the network request is still underway
  Processing,
  // Authenticated
  Authenticated,
}

const UserAccountContext = React.createContext<
  Omit<typeof UserAccountStore, 'apiClientMethodProxy'> & {
    accountState: AccountStateType
    authenticationState: AuthenticationState
  }
>({
  ...UserAccountStore,
  accountState: {},
  authenticationState: AuthenticationState.None,
})

const storageKey = 'api_8fit_token'

export const UserAccountProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isRestoringSession, setIsRestoringSession] = useState<boolean>(false)

  const restoreSession = useCallback(async (authToken: string) => {
    setIsRestoringSession(true)
    try {
      await UserAccountStore.setSession(authToken)
      if (UserAccountStore.isAuthenticated()) {
        UserAccountStore.setAttribute('isSessionRestored', true)
      }
    } catch {
      // If anything happens it automatically logs out
      // No need to handle the exception
    }
    setIsRestoringSession(false)
  }, [])

  // Recreate session after mounting
  useDidMount(() => {
    const storedAuthToken = localStorage.getItem(storageKey)
    if (storedAuthToken) {
      restoreSession(storedAuthToken)
    }
  })

  // React to attribute updates
  const [accountState, setAccountState] = useState<AccountStateType>({})
  useEffect(() => {
    UserAccountStore.setEventHandler('setAttribute', () => {
      // `accountState` is used to react to attribute changes in the
      // User Account Store. It's a hack to create diff in order to
      // rerender everything that reads from our context
      setAccountState(UserAccountStore.toJson())
    })
    return () => UserAccountStore.setEventHandler('setAttribute', null)
  }, [])

  // Reflect authentication in persistent storage
  useEffect(() => {
    UserAccountStore.setEventHandler('authenticate', (authToken) => {
      localStorage.setItem(storageKey, authToken)
    })
    UserAccountStore.setEventHandler('logout', () => {
      localStorage.removeItem(storageKey)
      setAccountState(UserAccountStore.toJson())
    })
    return () => {
      UserAccountStore.setEventHandler('authenticate', null)
      UserAccountStore.setEventHandler('logout', null)
    }
  }, [])

  useEffect(() => {
    const onStorageChange = (e: StorageEvent) => {
      if (e.key === storageKey) {
        if (e.newValue === null && UserAccountStore.authToken) {
          // Auth token got removed from localStorage, but we're still logged in
          UserAccountStore.logout()
        } else if (
          e.newValue &&
          !UserAccountStore.authToken &&
          !isRestoringSession
        ) {
          // An auth token was added to localStorage, but we're not logged in yet
          restoreSession(e.newValue)
        }
      }
    }
    window.addEventListener('storage', onStorageChange)
    return () => window.removeEventListener('storage', onStorageChange)
  }, [restoreSession, isRestoringSession])

  let authenticationState = AuthenticationState.None
  if (UserAccountStore.isAuthenticated()) {
    authenticationState = AuthenticationState.Authenticated
  } else if (isRestoringSession) {
    authenticationState = AuthenticationState.Processing
  }

  return (
    <UserAccountContext.Provider
      value={{
        ...UserAccountStore,
        accountState,
        authenticationState,
      }}
    >
      {children}
    </UserAccountContext.Provider>
  )
}

export const UserAccount = UserAccountContext.Consumer

export const useUserAccount = () => useContext(UserAccountContext)
