import React from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import CarouselInGrid from 'components/carousel-in-grid'
import { Col, Container, Row } from 'components/grid'
import RecipeCard from 'components/recipe-card'

import { section, title } from './recommendations.module.scss'

interface Props {
  recommendations: Contentful.Recipe['recommendations']
}

const RecommendationsSection = ({ recommendations }: Props) => {
  return (
    <DocumentOutlineSection
      title={i18n._(t('recipe.recommendations.title')`Recommended for you`)}
    >
      <section className={section}>
        <Container>
          <Row>
            <Col xs={12} lgOffset={1} lg={10}>
              <H className={title} />
            </Col>
          </Row>
        </Container>
        <CarouselInGrid>
          {recommendations.map(
            ({
              title,
              categories,
              preparationTimeMin,
              calories,
              fields,
              image,
            }) => (
              <RecipeCard
                key={fields.path}
                linkTo={fields.path}
                title={title}
                categories={categories}
                preparationTimeMin={preparationTimeMin}
                calories={calories}
                image={(image as EF.FixedImageNode).fields.asset.fixed}
              />
            )
          )}
        </CarouselInGrid>
      </section>
    </DocumentOutlineSection>
  )
}

export default RecommendationsSection
