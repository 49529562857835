import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import sessionStorage from 'utils/sessionStorage'
import { readUtmParamsFromCookie } from 'utils/utm'

import { SideEffect } from '../types'

const subscribeEffect: SideEffect = {
  // Pay the subscription price with Stripe
  name: 'Subscribe Pro',
  isSilent: false,
  isRecurring: true,
  condition: () => {
    const getAttribute = UserAccountStore.getAttribute
    const ccPayment = getAttribute('SignupScreenCreditCardPayment')
    return (
      !!getAttribute('SignupScreenPlansOverview') &&
      !!ccPayment?.token?.id &&
      !getAttribute('is_pro') &&
      !sessionStorage.has('SCAData')
    )
  },
  execute: async () => {
    const getAttribute = UserAccountStore.getAttribute
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const plan = getAttribute('SignupScreenPlansOverview')!
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { token } = getAttribute('SignupScreenCreditCardPayment')!

    const discountInformation = getAttribute('DiscountInformation') as
      | EF.DiscountInformation
      | undefined

    const utmParams = readUtmParamsFromCookie()
    const fallbackUtmParams = {
      utm_source: '8fitweb',
      utm_medium: 'onboarding',
      utm_content: 'paywall',
      utm_term: 'web',
    }

    await UserAccountStore.updateAccount()

    const apiResponse = await UserAccountStore.subscribeStripe({
      ...fallbackUtmParams,
      ...utmParams,
      user_id: getAttribute('id') as string,
      // We checked the existence of token.id in the condition
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stripe_token: token!.id!,
      plan: plan.identifier,
      code: discountInformation?.code,
    })

    // Transer the data over to the SCA effect via sessionStorage
    sessionStorage.write('SCAData', apiResponse)
  },
  reset: () => {
    sessionStorage.remove('SCAData')
  },
}

export default subscribeEffect
