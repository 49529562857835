import React, { Component } from 'react'
import { Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Container, Row, Col } from 'components/grid'
import { ResponsiveElement } from 'components/utils/responsiveUtils'
import ControlIcon from 'components/icons/ControlIcon'

import { CategorySectionProps } from '../types'

import {
  itemTitle,
  itemChevron,
  subcategories,
  subcategoriesInner,
  subcategoriesList,
  subcategoriesItem,
  subcategoriesLink,
  categoryIcon,
  item,
} from './CategorySection.module.scss'

interface Props extends CategorySectionProps {
  opened: boolean
  onItemClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default class CategorySection extends Component<Props> {
  state = {
    subcategoryListHeight: 0,
  }

  onSubcategoryListSizeChange = ({ height }: { height: number }) => {
    this.setState({
      subcategoryListHeight: height,
    })
  }

  render() {
    const { name, path, icon, subCategories, opened, onItemClick } = this.props
    const { subcategoryListHeight } = this.state

    return (
      <div className={item} data-expanded={opened}>
        <div className={categoryIcon}>{icon && <GatsbyImage {...icon} />}</div>
        <button
          className={itemTitle}
          onClick={onItemClick}
          aria-controls={`article-menu-item-${path}`}
          aria-expanded={opened}
          aria-label={i18n._(t`Open list of ${name} subcategories`)}
        >
          <Container>
            <Row>
              <Col>
                <ControlIcon iconName="chevron-down" className={itemChevron} />
                {name}
              </Col>
            </Row>
          </Container>
        </button>
        <div
          className={subcategories}
          id={`article-menu-item-${path}`}
          aria-hidden={!opened}
          style={opened ? { maxHeight: subcategoryListHeight } : {}}
        >
          <ResponsiveElement
            onSizeChange={this.onSubcategoryListSizeChange}
            className={subcategoriesInner}
            metricsToListenFor={['height']}
          >
            <Container>
              <Row>
                <Col>
                  <ul className={subcategoriesList}>
                    {subCategories.map(({ name, path }) => (
                      <li key={path} className={subcategoriesItem}>
                        <Link to={path} className={subcategoriesLink}>
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </ResponsiveElement>
        </div>
      </div>
    )
  }
}
