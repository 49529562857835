// extracted by mini-css-extract-plugin
export var contact = "o+tFw";
export var container = "_1l4CH";
export var content = "l3YQ0";
export var description = "B24Xu";
export var footer = "oJTav";
export var form = "tFTH-";
export var fullHeight = "IVpCX";
export var header = "kVOvH";
export var logo = "osuf1";
export var page = "UeKvw";
export var title = "_8cQ5m";