import React, { useState } from 'react'
import { Link } from 'gatsby'
import { t } from '@lingui/macro'
import FocusTrap from 'focus-trap-react'

import { i18n } from 'utils/i18n'
import { useUniqueId } from 'hooks'
import Logo from 'components/logo'
import LanguageSelector from 'components/language-selector/LanguageSelector'
import { Hidden } from 'components/grid'
import FunnelCta from 'components/funnel-cta'
import BurgerMenuSVG from 'assets/images/icons/controls/burger-menu.svg'
import { CloseButton, SecondaryButtonLink } from 'components/Buttons'

import {
  nav,
  homepageLink,
  logo,
  desktopMenu,
  mobileMenu,
  burgerMenu,
  burgerIcon,
  signupLink,
  languageSelector,
  drawerContainer,
  drawerTopMenu,
  drawerCta,
} from './NavBar.module.scss'

const DrawerMenu = ({
  hrefLang,
  onClose,
}: {
  hrefLang: EF.HrefLangNode[]
  onClose: () => void
}) => (
  <section className={drawerContainer}>
    <div className={drawerTopMenu}>
      <CloseButton onClick={onClose} />
      <LanguageSelector hrefLang={hrefLang} />
    </div>
    <SecondaryButtonLink
      className={drawerCta}
      text={i18n._(t`Sign up`)}
      path={i18n._(t`/signup/`)}
      block
    />
  </section>
)

const NavBar = ({ hrefLang }: { hrefLang: EF.HrefLangNode[] }) => {
  const [expanded, setExpanded] = useState(false)
  const idBase = useUniqueId('input-')

  return (
    <nav className={nav}>
      <Link to={i18n._(t`/`)} className={homepageLink}>
        <Logo alt={i18n._(t`Go to homepage`)} className={logo} />
      </Link>
      <Hidden md className={mobileMenu}>
        <button
          aria-label={i18n._(t`Open menu`)}
          aria-haspopup="true"
          aria-expanded={expanded}
          aria-controls={`${idBase}-menu`}
          className={burgerMenu}
          onClick={() => setExpanded(true)}
        >
          <img
            src={BurgerMenuSVG}
            className={burgerIcon}
            alt=""
            role="presentation"
          />
        </button>
      </Hidden>
      <div className={desktopMenu}>
        <FunnelCta text={i18n._(t`Sign up`)} className={signupLink} />
        <LanguageSelector hrefLang={hrefLang} className={languageSelector} />
      </div>
      <div id={`${idBase}-menu`} hidden={!expanded}>
        <FocusTrap active={expanded}>
          <DrawerMenu hrefLang={hrefLang} onClose={() => setExpanded(false)} />
        </FocusTrap>
      </div>
    </nav>
  )
}

export default NavBar
