import React from 'react'
import Image, { FluidObject } from 'gatsby-image'
import { DocumentOutlineSection, H } from 'react-document-section'

import { UnderlineCalloutHeader } from 'components/UnderlineText'
import { PrimaryButtonLink } from 'components/Buttons'

import {
  container,
  title as titleStyle,
  description as descriptionStyle,
  imageContainer,
  buttonContainer,
  image as imageStyle,
} from './AppPreviewSection.module.scss'

export interface AppPreviewSectionQueryType {
  title: string
  description: string
  image: EF.FluidImageNode
  buttonText: string
}

export interface AppPreviewSectionProps {
  title: string
  description: string
  image: { fluid: FluidObject }
  buttonText: string
  signupPath: string
}

const AppPreviewSection = ({
  title,
  description,
  image,
  buttonText,
  signupPath,
}: AppPreviewSectionProps) => {
  return (
    <DocumentOutlineSection title={<UnderlineCalloutHeader text={title} />}>
      <section className={container}>
        <H className={titleStyle} />
        <p className={descriptionStyle}>{description}</p>
        <div className={imageContainer}>
          <Image fluid={image.fluid} className={imageStyle} />
        </div>
        <div className={buttonContainer}>
          <PrimaryButtonLink path={signupPath} text={buttonText} />
        </div>
      </section>
    </DocumentOutlineSection>
  )
}

export default AppPreviewSection
