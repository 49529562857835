import { setTag } from '@sentry/gatsby'

import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import getIpInfo from 'sdks/ip-info'

import { SideEffect } from '../types'

const getIpInfoEffect: SideEffect = {
  name: 'Get IP Info',
  isSilent: true,
  condition: () => UserAccountStore.getAttribute('location') === undefined,
  execute: async () => {
    const ipInfo = await getIpInfo()
    UserAccountStore.mergeAttributes(ipInfo)
    setTag('country', ipInfo.country)
  },
}

export default getIpInfoEffect
