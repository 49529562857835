import * as Sentry from '@sentry/gatsby'

import { loadScript } from 'utils/dom'

const stripeUrl = 'https://js.stripe.com/v3/'

let stripe: undefined | stripe.Stripe

export const getStripe = async (): Promise<stripe.Stripe | undefined> => {
  if (stripe) return stripe

  if (typeof window === 'object' && process.env.GATSBY_STRIPE_API_KEY) {
    try {
      if (!window.Stripe) {
        await loadScript(stripeUrl)
      }

      stripe = window.Stripe(process.env.GATSBY_STRIPE_API_KEY) as stripe.Stripe
      return stripe
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  return undefined
}

export const handleScaResponse = async (apiResponse: EF.SCAResponse) => {
  const s = await getStripe()

  if (!s) {
    return undefined
  }

  let stripeResponse

  if (apiResponse.type === 'payment_intent') {
    stripeResponse = await s.confirmCardPayment(apiResponse.client_secret)
  }

  if (apiResponse.type === 'setup_intent') {
    stripeResponse = await s.confirmCardSetup(apiResponse.client_secret)
  }

  if (stripeResponse?.error) {
    const err: EF.ErrorWithReadableMessage = {
      ...new Error(),
      ...stripeResponse.error,
      hasReadableMessage: true,
    }
    throw err
  }

  return undefined
}
