import React from 'react'
import { Helmet } from 'react-helmet'

import { useSiteMetadata } from 'hooks'

import { useAltLanguages } from './AltLanguages'

const Hreflang = () => {
  const { siteUrl } = useSiteMetadata()
  const {
    alternativeLanguagePaths,
    defaultLanguage,
    currentLanguage,
  } = useAltLanguages()

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${siteUrl}${defaultLanguage.path}`}
        />
        <link
          rel="alternate"
          hrefLang={currentLanguage.lang}
          href={`${siteUrl}${currentLanguage.path}`}
        />
        {alternativeLanguagePaths
          ? Object.entries(alternativeLanguagePaths).map(([lang, path]) => (
              <link
                rel="alternate"
                hrefLang={lang}
                href={`${siteUrl}${path}`}
                key={lang}
              />
            ))
          : null}
      </Helmet>
    </>
  )
}

export default Hreflang
