// extracted by mini-css-extract-plugin
export var background = "bAMG0";
export var breadcrumb = "fTN8Z";
export var container = "qkidm";
export var description = "tSLQG";
export var greenWave = "J1DwV";
export var headerImage = "_4VPuc";
export var headerImageContainer = "jiNOK";
export var scrollButton = "sDfVy";
export var textContainer = "wiKTq";
export var title = "_8NVct";
export var whiteWave = "_3NZ0L";