import React, { FunctionComponent } from 'react'
import GatsbyImage, { FluidObject } from 'gatsby-image'

import ScrollIndicator from 'components/scroll-indicator'

import { container, imageWrapper } from './hero-image.module.scss'

interface HeroImageProps {
  image: FluidObject
}

const HeroImage: FunctionComponent<HeroImageProps> = ({ image }) => {
  return (
    <div className={container}>
      <div className={imageWrapper}>
        <GatsbyImage fluid={image} />
      </div>
      <ScrollIndicator isShifted />
    </div>
  )
}

export default HeroImage
