import React from 'react'
import { t } from '@lingui/macro'
import { Link } from 'gatsby'

import { i18n } from 'utils/i18n'
import { PlayStoreButton, AppStoreButton } from 'components/store-button'
import { Col, Container, Row, Hidden, Visible } from 'components/grid'
import SocialLinks from 'components/SocialLinks'
import Logo from 'components/logo'
import LinkSwitch from 'components/utils/link-switch'
import { classNames } from 'utils/dom'

import {
  root,
  logo,
  linksList,
  linksSeparator,
  storeLinks,
  socialLinks,
} from './index.module.scss'

interface LinkItem {
  key: string
  text: string
  to: string
}

const LogoLink = () => (
  <Link to={i18n._(t`/`)}>
    <Logo white />
  </Link>
)

const renderList = (list: LinkItem[], className?: string) => (
  <ul className={className}>
    {list.map(({ key, to, text }) => (
      <li key={key}>
        <LinkSwitch path={to} data-testid="footerLink">
          {text}
        </LinkSwitch>
      </li>
    ))}
  </ul>
)

const Footer = () => {
  const firstColumnLinks: LinkItem[] = [
    {
      key: 'workouts',
      text: i18n._(t('footer.links.workouts')`Workouts`),
      to: i18n._(t`/#workouts`),
    },
    {
      key: 'meals',
      text: i18n._(t('footer.links.meals')`Meals`),
      to: i18n._(t`/#recipes`),
    },
    {
      key: 'transformation-stories',
      text: i18n._(t('footer.links.stories')`Success stories`),
      to: i18n._(t`/transformation-stories/`),
    },
    {
      key: 'articles',
      text: i18n._(t('footer.links.articles')`Articles`),
      to: i18n._(t`/articles/`),
    },
  ]

  const secondColumnLinks: LinkItem[] = [
    {
      key: 'about',
      text: i18n._(t('footer.links.about')`About`),
      to: i18n._(t`/about/`),
    },
    {
      key: 'careers',
      text: i18n._(t('footer.links.careers')`Careers`),
      to: i18n._(t`/careers/`),
    },
    {
      key: 'press',
      text: i18n._(t('footer.links.press')`Press`),
      to: i18n._(t`/press/`),
    },
    {
      key: 'help-center',
      text: i18n._(t('footer.links.helpCenter')`Help center`),
      to: i18n._(t`https://8fit.zendesk.com/hc/en-us`),
    },
  ]

  const thirdColumnLinks: LinkItem[] = [
    {
      key: 'terms-of-service',
      text: i18n._(t('footer.links.termsOfService')`Terms of service`),
      to: i18n._(t`/terms-of-service/`),
    },
    {
      key: 'privacy',
      text: i18n._(t('footer.links.privacyPolicy')`Privacy policy`),
      to: i18n._(t`/privacy/`),
    },
    {
      key: 'cookies',
      text: i18n._(t('footer.links.cookiesPolicy')`Cookies policy`),
      to: i18n._(t`/cookies-policy/`),
    },
    {
      key: 'impressum',
      text: i18n._(t('footer.links.impressum')`Impressum`),
      to: i18n._(t`/impressum/`),
    },
  ]

  return (
    <Container fluid tagName="footer" className={root}>
      <Row>
        <Col xs={12} md={2} lgOffset={1} lg={1} className={logo}>
          <LogoLink />
        </Col>
        <Col xs={6} md={2}>
          {renderList(
            firstColumnLinks,
            classNames([linksList, linksSeparator])
          )}
        </Col>
        <Col xs={6} md={2}>
          {renderList(secondColumnLinks, linksList)}
        </Col>
        <Col xs={6} md={2}>
          {renderList(thirdColumnLinks, linksList)}
        </Col>
      </Row>
      <Hidden lg>
        <Row>
          <Col className={storeLinks}>
            <AppStoreButton />
            <PlayStoreButton />
          </Col>
        </Row>
      </Hidden>
      <Row>
        <Col xs={12} lgOffset={3} lg={6} className={socialLinks}>
          <SocialLinks />
        </Col>
        <Col lgOffset={1} lg={2} className={storeLinks}>
          <Visible lg>
            <AppStoreButton />
            <PlayStoreButton />
          </Visible>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
