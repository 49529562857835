import React from 'react'

import { Col, Row } from 'components/grid'
import { classNames } from 'utils/dom'

import { horizontalLine } from './index.module.scss'

interface Props {
  className?: string
}

const HorizontalLine = ({ className }: Props) => {
  return (
    <Row>
      <Col xs={12} lgOffset={1} lg={10} xlOffset={0} xl={12}>
        <hr className={classNames([className, horizontalLine])} />
      </Col>
    </Row>
  )
}

export default HorizontalLine
