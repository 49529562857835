import React, { FunctionComponent } from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Col, Container, Row } from 'components/grid'
import CarouselInGrid from 'components/carousel-in-grid'
import WorkoutCard from 'components/workout-card'

import { root, title, cardWrapper } from './recommendations.module.scss'

interface Props {
  recommendations: Pick<
    Contentful.WorkoutClass,
    'title' | 'description' | 'durationMins' | 'featureImage' | 'fields'
  >[]
}

const RecommendationsSection: FunctionComponent<Props> = ({
  recommendations = [],
}) => {
  return (
    <DocumentOutlineSection
      title={i18n._(
        t('workout_page.recommendations.title')`Recommended for you`
      )}
    >
      <section className={root}>
        <Container>
          <Row>
            <Col xs={12} lgOffset={1} lg={10}>
              <H className={title} />
            </Col>
          </Row>
        </Container>
        <CarouselInGrid itemClassName={cardWrapper}>
          {recommendations.map(
            ({ title, description, durationMins, featureImage, fields }, i) => (
              <WorkoutCard
                key={i}
                title={title}
                description={description}
                durationMins={durationMins}
                image={(featureImage as EF.FixedImageNode).fields.asset.fixed}
                linkTo={fields.path}
              />
            )
          )}
        </CarouselInGrid>
      </section>
    </DocumentOutlineSection>
  )
}

export default RecommendationsSection
