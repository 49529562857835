import React, { FunctionComponent, FormEvent } from 'react'
import { range } from 'lodash/fp'

import { PrimaryButton } from 'components/Buttons'

import { ContentfulNPSPage } from './nps-page-types'
import {
  root,
  scoreSelect,
  minimumScoreLabel,
  maximumScoreLabel,
  scoreSelectInputs,
  scoreInputContainer,
  scoreInputLabel,
  scoreInput,
  feedbackSubmitContainer,
  feedbackInput,
  submitButton,
} from './nps-page-entry-form.module.scss'

interface NPSPageEntryFormProps {
  page: ContentfulNPSPage
  score: number | undefined
  feedback: string
  canSubmit: boolean
  setScore(value: number): unknown
  setFeedback(value: string): unknown
  handleSubmit(event: FormEvent<HTMLFormElement>): unknown
}

const NPSPageEntryForm: FunctionComponent<NPSPageEntryFormProps> = ({
  page,
  score,
  feedback,
  setScore,
  setFeedback,
  canSubmit,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit} className={root} data-testid="npsPageEntryForm">
    <div className={scoreSelect}>
      <p className={minimumScoreLabel}>{page.minimumScoreLabel}</p>
      <div className={scoreSelectInputs}>
        {range(page.minimumScore, page.maximumScore + 1).map((itemScore) => {
          const id = `score-${itemScore}`

          return (
            <label htmlFor={id} key={id} className={scoreInputContainer}>
              <input
                type="radio"
                name="score"
                value={itemScore}
                id={id}
                onChange={() => setScore(itemScore)}
                checked={itemScore === score}
                className={scoreInput}
                data-testid={`npsPageScoreInput${itemScore}`}
              />
              <span className={scoreInputLabel}>{itemScore}</span>
            </label>
          )
        })}
      </div>
      <p className={maximumScoreLabel}>{page.maximumScoreLabel}</p>
    </div>
    <div className={feedbackSubmitContainer}>
      <p>{page.feedbackEntryTitle}</p>
      <textarea
        value={feedback}
        placeholder={page.feedbackEntryPlaceholder}
        onChange={(event) => setFeedback(event.target.value)}
        className={feedbackInput}
        data-testid="npsPageFeedbackInput"
      />
      <PrimaryButton
        type="submit"
        className={submitButton}
        disabled={!canSubmit}
        data-testid="npsPageSubmitButton"
        text={page.submitCtaLabel}
      />
    </div>
  </form>
)

export default NPSPageEntryForm
