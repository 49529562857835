import { useSiteMetadata } from 'hooks'
import { isIOSDevice, isAndroidDevice } from 'utils/dom'

const usePlaformStoreUrl = (preferAndroidStore?: boolean) => {
  const { appstoreUrl, playstoreUrl } = useSiteMetadata()
  if (isIOSDevice()) {
    return appstoreUrl
  }
  if (isAndroidDevice()) {
    return playstoreUrl
  }
  return preferAndroidStore ? playstoreUrl : appstoreUrl
}

export default usePlaformStoreUrl
