// extracted by mini-css-extract-plugin
export var backgroundImage = "ZMB2W";
export var container = "O-2Ox";
export var contentWrapper = "BgRbO";
export var description = "cG1pg";
export var imageContainer = "AjODw";
export var imageWrapper = "F936y";
export var photo = "q0FmG";
export var rowEven = "wtY2s";
export var rowOdd = "GMhor";
export var subTitle = "GD8Vp";
export var title = "NR5q-";