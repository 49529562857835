import React from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import AdjustLink from 'components/utils/adjust-link'
import { AdjustLinkProps } from 'components/utils/adjust-link/types'
import StoreBadge from 'components/icons/StoreBadge'

import { link, image } from './index.module.scss'

const PlayStoreButton = ({ ...props }: AdjustLinkProps) => {
  return (
    <AdjustLink className={link} preferAndroidStore {...props}>
      <StoreBadge
        platform="play-store"
        alt={i18n._(t('play-store-download')`Get 8fit on Google Play`)}
        className={image}
      />
    </AdjustLink>
  )
}

export default PlayStoreButton
