import React from 'react'

import SocialIcon from 'components/icons/SocialIcon'
import { SocialNetworkName } from 'components/icons/types'

import { list as listStyle, icon } from './index.module.scss'

interface SocialListItem {
  url: string
  network: SocialNetworkName
}

const defaultList: SocialListItem[] = [
  {
    url: 'https://www.facebook.com/8fit/',
    network: 'facebook',
  },
  {
    url: 'https://www.instagram.com/8fit/',
    network: 'instagram',
  },
  {
    url: 'https://www.pinterest.com/8fit/',
    network: 'pinterest',
  },
  {
    url: 'https://twitter.com/8fit/',
    network: 'twitter',
  },
  {
    url: 'https://www.youtube.com/channel/UCCQX2N346oiju_6eTGkmwOg',
    network: 'youtube',
  },
]

interface Props {
  list?: SocialListItem[]
  className?: string
  inverted?: boolean
  hideIcon?: boolean
}

const SocialLinks = ({
  list = defaultList,
  className = '',
  inverted = false,
  hideIcon = false,
}: Props) => (
  <section className={className}>
    <ul className={listStyle}>
      {list.map(({ url, network }) => (
        <li key={network}>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="socialLink"
          >
            <SocialIcon
              className={icon}
              socialNetworkName={network}
              modifier={inverted ? '' : 'white'}
              width="40"
              height="40"
              data-hide-icon={hideIcon}
            />
          </a>
        </li>
      ))}
    </ul>
  </section>
)

export default SocialLinks
