import React, { FunctionComponent } from 'react'

import { useSiteMetadata } from 'hooks'
import {
  facebookShareUrl,
  twitterShareUrl,
  pinterestShareUrl,
} from 'utils/share-url'
import { ensureAbsoluteUrl } from 'utils/url'

import useShareUrl from './use-share-url'

type HTMLAnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export const FacebookSharingLink: FunctionComponent<HTMLAnchorProps> = ({
  children,
  ...props
}) => {
  const { facebookAppId } = useSiteMetadata()
  const shareUrl = useShareUrl('fb')
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={facebookShareUrl({
        appId: facebookAppId,
        link: shareUrl,
        redirectUri: 'https://facebook.com',
      })}
      {...props}
    >
      {children}
    </a>
  )
}

export interface TwitterSharingLinkProps extends HTMLAnchorProps {
  description: string
}

export const TwitterSharingLink: FunctionComponent<TwitterSharingLinkProps> = ({
  description,
  children,
  ...props
}) => {
  const { twitterUserName } = useSiteMetadata()
  const shareUrl = useShareUrl('tw')
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={twitterShareUrl({
        via: twitterUserName,
        text: `${description} ${shareUrl}`,
      })}
      {...props}
    >
      {children}
    </a>
  )
}

export interface PinterestSharingLinkProps extends HTMLAnchorProps {
  media: string
  description: string
}

export const PinterestSharingLink: FunctionComponent<PinterestSharingLinkProps> = ({
  media,
  description,
  children,
  ...props
}) => {
  const shareUrl = useShareUrl('pin')
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={pinterestShareUrl({
        media: ensureAbsoluteUrl('https', media),
        description,
        url: shareUrl,
      })}
      {...props}
    >
      {children}
    </a>
  )
}
