import { isFbSdkPresent, initFbSdk, canInitFbSdk } from 'sdks/facebook'

import { SideEffect } from '../types'

const initializeFacebookEffect: SideEffect = {
  name: 'Initialize Facebook SDK',
  isSilent: true,
  condition: (config) =>
    typeof config.facebookAppId === 'string' &&
    canInitFbSdk() &&
    !isFbSdkPresent(),
  execute: (config) => initFbSdk(config.facebookAppId),
}

export default initializeFacebookEffect
