import React, { FunctionComponent } from 'react'
import Image from 'gatsby-image'
import { Link } from 'gatsby'

import { classNames } from 'utils/dom'

import {
  container,
  image as imageStyle,
  textSection,
  title as titleStyle,
  category as categoryStyle,
} from './polaroid.module.scss'

export interface ArticlePreviewPolaroid {
  title: string
  category: string
  path: string
  parentPath: string
  image: EF.FixedImageNode
  description?: string
  isHighlighted?: boolean
  className?: string
}

const ArticlePreviewPolaroid: FunctionComponent<ArticlePreviewPolaroid> = ({
  title,
  category,
  image,
  path,
  parentPath,
  className = '',
}) => {
  return (
    <div className={classNames([container, className])}>
      <Link to={path}>
        <div className={imageStyle}>
          <Image fixed={image.fields.asset.fixed} alt={title} />
        </div>
      </Link>
      <div className={textSection}>
        <Link className={categoryStyle} to={parentPath}>
          {category}
        </Link>
        <Link className={titleStyle} to={path}>
          {title}
        </Link>
      </div>
    </div>
  )
}

export default ArticlePreviewPolaroid
