import React from 'react'

import { classNames } from 'utils/dom'
import ProfileImagePlaceholderSVG from 'assets/images/icons/profile-image-placeholder.svg'

import {
  container,
  profileImage,
  name,
  description as descriptionStyle,
} from './PhotoInfo.module.scss'

export interface PhotoInfoProps {
  title: string
  description: string
  photoImageUrl?: string | null
  className?: string
}

const PhotoInfo = ({
  photoImageUrl,
  title,
  description,
  className = '',
}: PhotoInfoProps) => {
  return (
    <div className={classNames([container, className])}>
      <div className={profileImage}>
        <img
          src={photoImageUrl || ProfileImagePlaceholderSVG}
          className={profileImage}
          alt=""
          role="presentation"
        />
      </div>
      <div>
        <div className={name} data-testid="photoInfoTitle">
          {title}
        </div>
        <p className={descriptionStyle}>{description}</p>
      </div>
    </div>
  )
}

export default PhotoInfo
