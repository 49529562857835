// extracted by mini-css-extract-plugin
export var description = "u+5Vr";
export var metaInfoKey = "Z+Zi3";
export var metaInfoList = "gpQ1t";
export var metaInfoValue = "iiG75";
export var prepInfo = "eUQ98";
export var prepInfoContent = "XGfaY";
export var prepInfoEntry = "oza6p";
export var prepInfoIcon = "CxzGq";
export var prepInfoKey = "Pc-sC";
export var prepInfoText = "y5Fhe";
export var prepInfoValue = "qF28s";
export var previewCardImage = "lzq0U";
export var root = "iqwE7";
export var title = "+tV59";