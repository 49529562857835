import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import { hasUtmCookieBeenWritten, readUtmParamsFromCookie } from 'utils/utm'

import { SideEffect } from '../types'

const saveUtmParamsEffect: SideEffect = {
  name: 'Save UTM Params in UserAccountStore',
  isSilent: true,
  condition: () =>
    hasUtmCookieBeenWritten() && !UserAccountStore.getAttribute('utm_term'),
  execute: async () => {
    const paramsFromCookie = readUtmParamsFromCookie()
    UserAccountStore.mergeAttributes(paramsFromCookie)
  },
}

export default saveUtmParamsEffect
