// extracted by mini-css-extract-plugin
export var articleMainText = "_1O+n7";
export var breadcrumbBar = "pmhxN";
export var relatedArticlesContainer = "ANRvF";
export var relatedArticlesSection = "O9pyq";
export var relatedArticlesTitle = "jMOZ+";
export var rtfWrapper = "J9sVn";
export var sharingButtons = "BS+nH";
export var sharingButtonsEntry = "cOyab";
export var sidebar = "p1rco";
export var sidebarAreaFixedCta = "fx7nY";