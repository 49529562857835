import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { H } from 'react-document-section'

import WebsiteLayout from 'components/layout/website'
import MetaTagRobots from 'components/meta/robots'
import OpenGraphTags from 'components/meta/open-graph-tags'
import Hreflang from 'components/meta/Hreflang'
import FixedCta from 'components/fixed-cta'
import { Container, Row, Col, Hidden, Visible } from 'components/grid'
import { TransformationStoryRTF } from 'components/rich-text-field'
import ArbitraryCodeEmbed from 'components/arbitraryCodeEmbed'
import TransformationArrow from 'assets/images/background/transformation-arrow.svg'

import { TransformationStoryPageQueryResults } from './types'
import {
  header,
  title,
  featureImageContainer,
  featureImage as featureImageStyle,
  video,
  arrowLeft,
  arrowRight,
  titleVideoSection,
  content as contentStyle,
  richTextArea,
} from './TransformationStoryPage.module.scss'

interface HeaderProps {
  featureImage: EF.FluidImageNode['fields']['asset']
}

const Header = ({ featureImage }: HeaderProps) => (
  <div className={header}>
    <Container>
      <Row>
        <Col>
          <Hidden lg>
            <H className={title} data-testid="pageTitle" />
          </Hidden>
          <div className={featureImageContainer}>
            <img
              className={arrowLeft}
              src={TransformationArrow}
              alt=""
              role="presentation"
            />
            <div className={featureImageStyle}>
              <Image fluid={featureImage.fluid} alt={featureImage.title} />
            </div>
            <img
              className={arrowRight}
              src={TransformationArrow}
              alt=""
              role="presentation"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

const TransformationStoryPage = ({
  data: {
    contentfulTransformationStoryPage: {
      fields: { hrefLang, defaultLang },
      node_locale: locale,
      title,
      pageTitle,
      metaDescription,
      ogImage,
      photo: {
        fields: { asset: featureImageAsset },
      },
      video: {
        fields: { iframeContent },
      },
      content,
    },
  },
}: TransformationStoryPageQueryResults) => {
  return (
    <WebsiteLayout
      title={title}
      pageTitle={pageTitle}
      description={metaDescription}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <MetaTagRobots />
      <OpenGraphTags
        title={pageTitle}
        description={metaDescription}
        image={
          ogImage
            ? {
                alt: ogImage.fields.asset.title,
                ...ogImage.fields.asset.resize,
              }
            : null
        }
        locale={locale}
      />
      <Hreflang />
      <Header featureImage={featureImageAsset} />
      <FixedCta />
      <div className={contentStyle}>
        <Container>
          <Row>
            <Col xs={0} lg={4} />
            <Col xs={12} lg={7}>
              <div className={titleVideoSection}>
                <Visible lg>
                  <H className={title} data-testid="pageTitle" />
                </Visible>
                <div className={video}>
                  <ArbitraryCodeEmbed
                    title={iframeContent.title}
                    src={iframeContent.relativePath}
                    initialAspectRatio={iframeContent.initialAspectRatio}
                    includedUrls={iframeContent.includedUrls}
                  />
                </div>
              </div>
              <div className={richTextArea}>
                <TransformationStoryRTF
                  data={content}
                  options={{ ParagraphBlock: { withDropCap: true } }}
                />
              </div>
            </Col>
            <Col xs={0} lg={1} />
          </Row>
        </Container>
      </div>
    </WebsiteLayout>
  )
}

export default TransformationStoryPage

export const pageQuery = graphql`
  query TransformationStoryPage($id: String!) {
    contentfulTransformationStoryPage(id: { eq: $id }) {
      node_locale
      slug
      pageTitle
      ogImage {
        fields {
          asset {
            title
            resize {
              src
              width
              height
            }
          }
        }
      }
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
      }
      title
      metaDescription
      description {
        description
      }
      photo {
        fields {
          asset {
            title
            fluid(maxWidth: 375, resizingBehavior: FILL, cropFocus: FACES) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      video {
        fields {
          iframeContent {
            title
            relativePath
            includedUrls
            initialAspectRatio
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            ...RTFContentfulAssetBlockFragment
            fields {
              asset {
                fluid(maxWidth: 375, resizingBehavior: FILL, cropFocus: FACES) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
