import React from 'react'
import * as Sentry from '@sentry/gatsby'
import { I18nProvider } from '@lingui/react'

import { saveUtmParamsFromSearch } from './src/utils/utm'
import {
  i18n,
  defaultLocale,
  getI18nInfoFromPathname,
  loadLanguageCatalogForPathname,
} from './src/utils/i18n'
import { getCatalogs } from './src/utils/i18n/catalogs.browser'
import {
  getActiveCategories,
  onConsentChanged,
  waitForOneTrustSDK,
} from './src/utils/onetrust'
import ErrorBoundary from './src/components/utils/ErrorBoundary'
import SentryUserSession from './src/components/utils/SentryUserSession'

const recordOnlineOfflineStatus = async () => {
  const handleOnlineChange = (e) => {
    Sentry.addBreadcrumb({
      category: 'OnLine',
      message: `Client online status changed to: ${e.type}`,
      level: Sentry.Severity.Info,
    })
  }
  if (window.addEventListener) {
    window.addEventListener('online', handleOnlineChange)
    window.addEventListener('offline', handleOnlineChange)
  }
}

const recordCookieConsent = () => {
  waitForOneTrustSDK()
    .then(() => {
      // For recurring visitors we're setting the tag here
      Sentry.setTag('cookie.consent', String(getActiveCategories()))
    })
    .catch((e) => {
      Sentry.addBreadcrumb({
        category: 'Cookie Consent',
        message: e.message,
        level: Sentry.Severity.Warning,
      })
    })
  onConsentChanged((e) => {
    Sentry.addBreadcrumb({
      category: 'Cookie Consent',
      data: { allowedCookies: e.detail },
      level: Sentry.Severity.Info,
    })
    // For first-time visitors this is relevant
    Sentry.setTag('cookie.consent', String(e.detail))
  })
}

export const onClientEntry = () => {
  saveUtmParamsFromSearch()
  loadLanguageCatalogForPathname(window.location.pathname)
}

export const onInitialClientRender = () => {
  recordOnlineOfflineStatus()
  recordCookieConsent()
}

export const onPrefetchPathname = ({ pathname }) => {
  loadLanguageCatalogForPathname(pathname)
}

export const onPreRouteUpdate = ({ location }) => {
  loadLanguageCatalogForPathname(location.pathname)
}

export const wrapPageElement = ({
  element,
  props: {
    location: { pathname },
  },
}) => {
  const { locale } = getI18nInfoFromPathname(pathname)

  i18n.activate(locale || defaultLocale)

  return (
    <ErrorBoundary>
      <SentryUserSession>
        <I18nProvider
          language={i18n.language}
          catalogs={getCatalogs()}
          i18n={i18n}
        >
          {element}
        </I18nProvider>
      </SentryUserSession>
    </ErrorBoundary>
  )
}
