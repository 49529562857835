import { plural } from '@lingui/macro'
import React from 'react'

import { i18n } from 'utils/i18n'

import { root } from './index.module.scss'

const DurationLabel = ({ min }: { min: number }) => {
  return (
    <time dateTime={`PT${min}M`} className={root}>
      {i18n._(
        plural({
          value: min,
          one: `# min`,
          other: `# min`,
        })
      )}
    </time>
  )
}

export default DurationLabel
