import { useEffect, useState } from 'react'

import { UTMParams, readUtmParamsFromCookie, getLastUpdatedAt } from 'utils/utm'

const useUtmParams = () => {
  const [params, setParams] = useState<Partial<UTMParams>>({})
  useEffect(() => {
    setParams(readUtmParamsFromCookie())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLastUpdatedAt()])
  return params
}

export default useUtmParams
