// extracted by mini-css-extract-plugin
export var appStoreButton = "_377Qd";
export var bottomRow = "_8slEb";
export var contentBottom = "jTWMC";
export var contentBottomInner = "_7ZauI";
export var contentTop = "EvT7o";
export var featureList = "R2Ozt";
export var featureListItem = "dlHeW";
export var playStoreButton = "PVQpX";
export var root = "_4MBAb";
export var scrollIndicatorWrapper = "T1lz4";
export var signupCtaWrapper = "d8YMA";
export var title = "z5BtY";