import React from 'react'
import ReactPlayer, { FilePlayerProps } from 'react-player/file'

import { root, player } from './video-player.module.scss'

export type VideoPlayerProps = FilePlayerProps

const VideoPlayer = (props: VideoPlayerProps) => {
  const onContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Don't show a context menu because we want to prevent easy download of our videos
    e.preventDefault()
  }

  return (
    <div className={root} onContextMenu={onContextMenu}>
      <ReactPlayer
        playsinline
        controls
        width="100%"
        height="100%"
        className={player}
        config={{
          attributes: {
            disablepictureinpicture: 'true',
            controlsList: 'nodownload',
          },
        }}
        {...props}
      />
    </div>
  )
}

export default VideoPlayer
