import React, { Component } from 'react'
import { isEqual, pick, noop } from 'lodash/fp'

type DOMRectMetric = Exclude<keyof DOMRect, 'toJSON'>

interface ResponsiveElementProps<K extends DOMRectMetric> {
  onSizeChange: (clientRect: Pick<DOMRect, K>) => void
  tagName: keyof React.ReactHTML
  metricsToListenFor: K[]
  viewportWidth?: number
  forwardProps: React.HTMLAttributes<HTMLElement>
}

type Props<K extends DOMRectMetric> = React.HTMLAttributes<HTMLElement> & {
  metricsToListenFor: K[]
} & Partial<Pick<ResponsiveElementProps<K>, 'onSizeChange' | 'tagName'>>

const ViewportWidthContext = React.createContext<{ viewportWidth?: number }>({
  viewportWidth: undefined,
})

let rafCalled = false

export class ViewportWidthProvider extends Component {
  state = {
    viewportWidth: undefined,
  }

  updateState = () => {
    this.setState({
      viewportWidth: window.innerWidth || document.documentElement.clientWidth,
    })
  }

  throttleUpdateState = () => {
    if (!rafCalled) {
      rafCalled = true
      window.requestAnimationFrame(() => {
        rafCalled = false
        this.updateState()
      })
    }
  }

  componentDidMount() {
    this.updateState()
    window.addEventListener('resize', this.throttleUpdateState)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttleUpdateState)
  }

  render() {
    return (
      <ViewportWidthContext.Provider
        value={{ viewportWidth: this.state.viewportWidth }}
        {...this.props}
      />
    )
  }
}

export const ViewportWidthConsumer = ViewportWidthContext.Consumer

class ResponsiveElementInner<K extends DOMRectMetric> extends Component<
  ResponsiveElementProps<K>
> {
  elSize = {}
  elRef: React.RefObject<HTMLElement>

  constructor(props: ResponsiveElementProps<K>) {
    super(props)
    this.elRef = React.createRef()
  }

  checkElementSize = () => {
    const { elRef, elSize } = this
    const { metricsToListenFor } = this.props
    if (elRef.current) {
      const clientRect = pick(
        metricsToListenFor,
        elRef.current.getBoundingClientRect()
      )
      if (!isEqual(clientRect, elSize)) {
        this.elSize = clientRect
        this.props.onSizeChange(clientRect)
      }
    }
  }

  componentDidMount() {
    if (document.readyState !== 'complete') {
      const onLoad = () => {
        window.removeEventListener('load', onLoad)
        this.checkElementSize()
      }
      window.addEventListener('load', onLoad)
    }
  }

  componentDidUpdate(prevProps: ResponsiveElementProps<K>) {
    if (prevProps.viewportWidth !== this.props.viewportWidth) {
      this.checkElementSize()
    }
  }

  render() {
    const { tagName: TagName, forwardProps } = this.props
    // @ts-ignore This is incredibly complicated to type
    return <TagName ref={this.elRef} {...forwardProps} />
  }
}

export const ResponsiveElement = <K extends DOMRectMetric>({
  onSizeChange = noop,
  tagName = 'div',
  metricsToListenFor,
  ...props
}: Props<K>) => {
  return (
    <ViewportWidthConsumer>
      {({ viewportWidth }) => (
        <ResponsiveElementInner
          onSizeChange={onSizeChange}
          tagName={tagName}
          viewportWidth={viewportWidth}
          metricsToListenFor={metricsToListenFor}
          forwardProps={props}
        />
      )}
    </ViewportWidthConsumer>
  )
}
