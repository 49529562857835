import { setTag } from '@sentry/gatsby'

import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'

import { SideEffect } from '../types'
import { isSignupComplete } from '../utils'

const afterSignupCompletionEffect: SideEffect = {
  name: 'After signup completion: General',
  isSilent: true,
  condition: () => isSignupComplete(),
  execute: async () => {
    const { getAttribute } = UserAccountStore
    setTag('is_pro', getAttribute('is_pro'))
  },
}

export default afterSignupCompletionEffect
