import React, { FunctionComponent } from 'react'
import { FluidObject } from 'gatsby-image'
import { H } from 'react-document-section'

import { Visible, Container, Row, Col, Hidden } from 'components/grid'
import Logo from 'components/logo'
import { PrimaryButtonLink } from 'components/Buttons'
import { SelectiveVisible } from 'components/grid/hidden-visible'

import HeroImage from './hero-image'
import {
  container,
  contentContainer,
  headerBar,
  logo,
  headerBarCtaCol,
  headerBarCta,
  rowTitle,
  title,
  text as textStyle,
  belowTextCta,
} from './index.module.scss'

export interface LPHeaderProps {
  text: string
  heroImage: {
    fields: {
      asset: {
        mobile: FluidObject
        desktop: FluidObject
      }
    }
  }
  ctaText: string
  ctaPath: string
}

const LPHeader: FunctionComponent<LPHeaderProps> = ({
  text,
  heroImage,
  ctaText,
  ctaPath,
}) => {
  return (
    <header className={container}>
      <Visible lg>
        <HeroImage image={heroImage.fields.asset.desktop} />
      </Visible>
      <div className={contentContainer}>
        <div className={headerBar}>
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <Logo className={logo} />
              </Col>
              <Col md={8} className={headerBarCtaCol}>
                <SelectiveVisible xs="hidden" md="visible" lg="hidden">
                  <PrimaryButtonLink
                    className={headerBarCta}
                    text={ctaText}
                    path={ctaPath}
                  />
                </SelectiveVisible>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className={rowTitle}>
            <Col xs={12} lg={7}>
              <H className={title} data-testid="pageTitle" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={10} lg={6}>
              <p className={textStyle}>{text}</p>
              <SelectiveVisible xs="visible" md="hidden" lg="visible">
                <PrimaryButtonLink
                  className={belowTextCta}
                  text={ctaText}
                  path={ctaPath}
                />
              </SelectiveVisible>
            </Col>
          </Row>
        </Container>
      </div>
      <Hidden lg>
        <HeroImage image={heroImage.fields.asset.mobile} />
      </Hidden>
    </header>
  )
}

export default LPHeader
