import { setupI18n } from '@lingui/core'
import { find } from 'lodash/fp'

import {
  defaultLocale,
  supportedLocales,
  SupportedLocaleName,
  PublicLocale,
  SupportedLocale,
  pathPrefixPattern,
} from '../../../config/i18n'

import { loadCatalog } from './catalogs.browser'

export { defaultLocale, supportedLocales, SupportedLocaleName, PublicLocale }

export const i18n = setupI18n({ language: defaultLocale })

export type LocalePredicate =
  | Partial<SupportedLocale>
  | ((locale: SupportedLocale) => boolean)

export const getI18nInfoWhere = (predicate: LocalePredicate) =>
  find(predicate, supportedLocales)

export const getCurrentI18nInfo = () =>
  getI18nInfoWhere({ locale: i18n.language as SupportedLocaleName }) ||
  supportedLocales[0]

const pathPrefixRegex = new RegExp(pathPrefixPattern)

export const getI18nInfoFromPathname = (pathname = '') => {
  const [pathPrefix] = pathname.replace(/\/?$/, '/').match(pathPrefixRegex) || [
    '/',
  ]

  return getI18nInfoWhere({ pathPrefix })
}

export const loadLanguageCatalogForPathname = async (pathname: string) => {
  const i18nInfo = getI18nInfoFromPathname(pathname)
  if (i18nInfo) {
    const catalogs = await loadCatalog(i18nInfo.locale)
    if (catalogs) i18n.load(catalogs)
  }
}
