import React, { useState } from 'react'
import { ReactStripeElements } from 'react-stripe-elements'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import StripePaymentInput from 'components/payment/StripePaymentInput'
import SupportedCards from 'components/payment/SupportedCards'
import DiscountPrice from 'components/payment/DiscountPrice'
import { PrimaryButton, ButtonProps } from 'components/Buttons'

import VoucherInput from './VoucherInput'
import { DiscountData } from './types'
import StripePaymentForm, {
  useStripeCCFormStateContext,
} from './StripePaymentForm'
import {
  voucherInput,
  discountSavingsLabel,
  supportedCardsContainer,
  submitButtonContainer,
  submitButton,
} from './index.module.scss'

export interface PaymentProps {
  displayPrice?: number
  originalPrice: number
  currencyCode: EF.supportedCurrency
  submitButtonText: string
  period: string
  prefilledVoucherData?: EF.DiscountInformation
  className?: string
  onSubscribe: (
    props: ReactStripeElements.TokenResponse
  ) => Promise<void | Error>
  onSubscribeStart?: () => void
  onReceiveDiscountInformation: (data: DiscountData) => void
}

const SubmitButton = (props: ButtonProps & { className: string }) => {
  const { isSubmitting, isValid } = useStripeCCFormStateContext()
  return <PrimaryButton disabled={isSubmitting || !isValid} {...props} />
}

const Payment = ({
  displayPrice,
  originalPrice,
  period,
  prefilledVoucherData,
  submitButtonText,
  onSubscribeStart,
  onSubscribe,
  onReceiveDiscountInformation,
  currencyCode,
  className = '',
}: PaymentProps) => {
  const [discountInformation, setDiscountInformation] = useState<
    EF.DiscountInformation | undefined
  >(prefilledVoucherData)

  return (
    <div className={className}>
      <DiscountPrice
        discountPrice={displayPrice}
        originalPrice={originalPrice}
        period={period}
        currencyCode={currencyCode}
      />
      <div className={voucherInput}>
        <VoucherInput
          prefilledData={prefilledVoucherData}
          onReceiveDiscountInformation={(data) => {
            if (!discountInformation) setDiscountInformation(data)
            onReceiveDiscountInformation(data)
          }}
        />
        {discountInformation?.percent_off && (
          <span className={discountSavingsLabel}>
            {i18n._(
              t(
                'payment.voucher_discount.label'
              )`${discountInformation.percent_off}% discount applies!`
            )}
          </span>
        )}
      </div>
      <SupportedCards className={supportedCardsContainer} />
      <StripePaymentForm
        onSubscribe={onSubscribe}
        onSubscribeStart={onSubscribeStart}
      >
        <StripePaymentInput />
        <div className={submitButtonContainer}>
          <SubmitButton
            className={submitButton}
            data-testid="submitButton"
            text={submitButtonText}
          />
        </div>
      </StripePaymentForm>
    </div>
  )
}

export default Payment
