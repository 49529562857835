import React, { useMemo } from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import {
  BLOCKS,
  Document as ContentfulRTFDocument,
} from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { calcNumberLength } from 'utils/math'
import { getPlainTextFromReactNode } from 'utils/react'

import { title, list, instruction, paragraph } from './instructions.module.scss'

interface Props {
  instructions: EF.RTFRawQueryResponse
}

const renderInstructionList = (
  instructions: EF.RTFRawQueryResponse,
  nrOfInstructions: number
) => {
  return renderRichText(instructions, {
    /* eslint-disable react/display-name */
    renderNode: {
      [BLOCKS.OL_LIST]: (_node, children) => {
        return (
          <ol
            className={list}
            data-number-length={calcNumberLength(nrOfInstructions)}
          >
            {children}
          </ol>
        )
      },
      [BLOCKS.LIST_ITEM]: (_node, children) => {
        return <li className={instruction}>{children}</li>
      },
      [BLOCKS.PARAGRAPH]: (_node, children) => {
        const text = getPlainTextFromReactNode(children)
        if (!text) {
          // There's no content, so don't return a <p>
          return null
        }
        return <p className={paragraph}>{text}</p>
      },
    },
    /* eslint-enable react/display-name */
  })
}

const InstructionsSection = ({ instructions }: Props) => {
  const nrOfInstructions = useMemo(() => {
    try {
      const rtfDocument = JSON.parse(instructions.raw) as ContentfulRTFDocument
      const orderedList = rtfDocument.content.find(
        (node) => node.nodeType === 'ordered-list'
      )
      return orderedList?.content.length ?? 1
    } catch {
      // Something didn't work out. Don't bother any more.
      return 1
    }
  }, [instructions])

  return (
    <DocumentOutlineSection
      title={i18n._(t('recipe.instructions.title')`Directions`)}
    >
      <H className={title} />
      {renderInstructionList(instructions, nrOfInstructions)}
    </DocumentOutlineSection>
  )
}

export default InstructionsSection
