import React from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'

import {
  root,
  rating as ratingStyle,
  fullStars as fullStarsStyle,
  caption as captionStyle,
} from './index.module.scss'

interface StarRatingProps {
  rating: number
  caption?: string
}

const STAR_WIDTH = 15
// The images include a margin of 4px on the right handside
const STAR_GAP = 4
const total = 5

const StarRating = ({ rating, caption }: StarRatingProps) => {
  const fullStars = Math.floor(rating)
  const partialStars = rating - fullStars
  const width = fullStars * STAR_WIDTH + partialStars * (STAR_WIDTH - STAR_GAP)
  return (
    <figure
      className={root}
      aria-label={i18n._(t`Rated ${rating} out of ${total}`)}
    >
      <span className={ratingStyle}>
        <div className={fullStarsStyle} style={{ width }} />
      </span>
      {caption && <figcaption className={captionStyle}>{caption}</figcaption>}
    </figure>
  )
}

export default StarRating
