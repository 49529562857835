import React, { FunctionComponent } from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'

import { Container, Row, Col } from 'components/grid'
import {
  FacebookButton,
  TwitterButton,
  PinterestButton,
} from 'components/socialSharing'
import {
  UnderlineSectionHeader,
  UnderlineColors,
} from 'components/UnderlineText'

import { root, rowTitle, rowIcons } from './index.module.scss'

export interface SharingFooterProps {
  title: string
  description: string
  mediaUrl: string
}

const SharingFooter: FunctionComponent<SharingFooterProps> = ({
  title,
  description,
  mediaUrl,
}) => {
  return (
    <DocumentOutlineSection
      title={
        <UnderlineSectionHeader color={UnderlineColors.Green} text={title} />
      }
    >
      <section className={root}>
        <Container>
          <Row center="xs" className={rowTitle}>
            <Col>
              <H />
            </Col>
          </Row>
          <Row center="xs" className={rowIcons}>
            <Col>
              <FacebookButton />
            </Col>
            <Col>
              <TwitterButton description={description} />
            </Col>
            <Col>
              <PinterestButton description={description} media={mediaUrl} />
            </Col>
          </Row>
        </Container>
      </section>
    </DocumentOutlineSection>
  )
}

export default SharingFooter
