import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { SocialNetworkName } from './types'

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  socialNetworkName: SocialNetworkName
  modifier?: 'white' | ''
}

interface QueryResult {
  allFile: { edges: { node: { name: string; publicURL: string } }[] }
}

const getNetworkName = (name: SocialNetworkName, modifier: 'white' | '') =>
  (modifier.length ? `${name}-${modifier}` : name).toLocaleLowerCase()

const query = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "icons/social" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`

const SocialIcon = ({ socialNetworkName, modifier = '', ...props }: Props) => {
  const { allFile } = useStaticQuery<QueryResult>(query)
  if (allFile) {
    const file = allFile.edges.find(
      ({ node: { name } }) =>
        name === getNetworkName(socialNetworkName, modifier)
    )
    if (file) {
      return (
        <img src={file.node.publicURL} alt={socialNetworkName} {...props} />
      )
    }
  }
  return null
}

export default SocialIcon
