import React from 'react'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import FunnelCta from 'components/funnel-cta'
import { Col, Container, Row } from 'components/grid'
import { AppStoreButton, PlayStoreButton } from 'components/store-button'

import { root, tagline, storeButton } from './index.module.scss'

const DownloadAppCtaSection = () => {
  return (
    <section className={root}>
      <Container>
        <Row center="xs">
          <Col>
            <FunnelCta text={i18n._(t`Try for free`)} />
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={12} lgOffset={1} lg={8}>
            <p className={tagline}>
              {i18n._(
                t(
                  'download_app_section.tagline'
                )`For our full app experience, including more activities and workout and meal plans personalized just for you, download our app now!`
              )}
            </p>
          </Col>
          <Col xs={12}>
            <AppStoreButton className={storeButton} />
            <PlayStoreButton className={storeButton} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default DownloadAppCtaSection
