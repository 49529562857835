import React from 'react'
import Image from 'gatsby-image'
import { H } from 'react-document-section'
import { Trans } from '@lingui/macro'

import { Container, Row, Col, Hidden, Visible } from 'components/grid'
import ConditionalLink from 'components/utils/conditional-link'

import {
  root,
  colContent,
  title,
  subtitle as subtitleStyle,
  authorRoot,
  authorImage,
  authorLabelWrittenBy,
  imageWrapperHalfWidth,
  imageWrapperCenterImg,
} from './articlePage.module.scss'

interface AuthorProps {
  name: string
  image: EF.FixedImageNode | EF.FluidImageNode
}

const Author = ({ name, image }: AuthorProps) => {
  return (
    <div className={authorRoot}>
      <div className={authorImage}>
        <Image {...image.fields.asset} alt="" />
      </div>
      <div>
        <Trans>
          <div className={authorLabelWrittenBy}>Written by</div>
          <div>{name} @ 8fit</div>
        </Trans>
      </div>
    </div>
  )
}

interface ArticlePageHeaderProps {
  subtitle?: string
  image: EF.FluidImageNode
  imageLink?: string
  author: AuthorProps
}

const ArticlePageHeader = ({
  subtitle,
  image,
  imageLink,
  author,
}: ArticlePageHeaderProps) => (
  <header className={root}>
    <Container>
      <Row>
        <Col xs={12} lg={6} className={colContent}>
          <div>
            <H data-testid="articleTitle" className={title} />
            {subtitle && <p className={subtitleStyle}>{subtitle}</p>}
          </div>
          <Visible lg>
            <Author {...author} />
          </Visible>
        </Col>
        <Col xs={12} lg={6}>
          <div className={imageWrapperHalfWidth}>
            <div className={imageWrapperCenterImg}>
              <ConditionalLink link={imageLink}>
                <Image fluid={image.fields.asset.fluid} alt={image.title} />
              </ConditionalLink>
            </div>
          </div>
          <Hidden lg>
            <Author {...author} />
          </Hidden>
        </Col>
      </Row>
    </Container>
  </header>
)

export default ArticlePageHeader
