import React, { FunctionComponent } from 'react'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Col, Container, Row, breakpoints } from 'components/grid'
import { useMatchMedia } from 'hooks'
import Carousel from 'components/carousel'
import ArticlesCard from 'components/article-card'
import { SecondaryButtonLink } from 'components/Buttons'

import {
  root,
  title as titleStyle,
  description as descriptionStyle,
  card,
  cta,
} from './index.module.scss'

interface Props {
  title: string
  description: string
  articles: Array<{
    id: string
    title: string
    featureImage: EF.FixedImageNode
    fields: {
      path: string
      readingTimeEstimate: number
      mainCategory: {
        displayName: string
        identifier: string
      }
    }
  }>
}

const ArticlesSection: FunctionComponent<Props> = ({
  title,
  description,
  articles,
}) => {
  const { matches: isLarge } = useMatchMedia(`(min-width: ${breakpoints.lg}px)`)

  if (!articles) return null

  return (
    <DocumentOutlineSection title={title}>
      <section className={root}>
        <Container>
          <Row>
            <Col xs={12} mdOffset={1} md={10}>
              <H className={titleStyle} />
              <p className={descriptionStyle}>{description}</p>
            </Col>
          </Row>
        </Container>
        <Carousel
          keyPrefix="carousel-in-grid"
          gap={isLarge ? 40 : 15}
          showDots
          defaultIndex={0}
        >
          {articles.map(({ featureImage, title, fields, id }) => (
            <div key={id} className={card}>
              <ArticlesCard
                linkTo={fields.path}
                title={title}
                image={featureImage.fields.asset.fixed}
                durationMins={fields.readingTimeEstimate}
                category={fields.mainCategory}
              />
            </div>
          ))}
        </Carousel>
        <SecondaryButtonLink
          path={i18n._(t`/articles/`)}
          text={i18n._(t('homepage.articles_section.cta')`View more articles`)}
          className={cta}
        />
      </section>
    </DocumentOutlineSection>
  )
}

export default ArticlesSection
