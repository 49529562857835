import { UserAccountStore } from 'gatsby-plugin-8fit-user-account'
import { readUtmParamsFromCookie } from 'utils/utm'
import {
  initAppboySdk,
  setAppboyUser,
  completeAppboyOnboarding,
} from 'sdks/appboy'

import { SideEffect } from '../types'
import { isSignupComplete } from '../utils'

const afterSignupCompletionAppboyEffect: SideEffect = {
  name: 'After signup completion: Appboy',
  isSilent: true,
  condition: () => isSignupComplete(),
  execute: async () => {
    const { getAttribute } = UserAccountStore
    const userId = getAttribute('id')
    const utmData = readUtmParamsFromCookie()
    await initAppboySdk() // Init if not initialized yet
    await setAppboyUser(userId)
    await completeAppboyOnboarding({
      usersrc: utmData.utm_source || 'web',
      usercmp: utmData.utm_campaign || 'undefined',
      isPro: getAttribute('is_pro'),
    })
  },
}

export default afterSignupCompletionAppboyEffect
