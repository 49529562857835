import { pipe, compact, uniq, join, flatten, noop } from 'lodash/fp'
import * as Sentry from '@sentry/gatsby'

import { insertScript } from './onetrust'

export const classNames = pipe(flatten, compact, uniq, join(' '))

export const isInBrowser = () => typeof window === 'object'

export const loadScript = (src: string, once = true) => {
  if (!isInBrowser()) return
  const { document } = window
  if (once && document.querySelector(`script[src='${src}']`)) {
    return undefined
  }
  return insertScript(src)
}

export const waitForWindowLoad = () => {
  return new Promise<void>((resolve) => {
    if (window.document.readyState === 'complete') {
      return resolve()
    }
    const onLoad = () => {
      window.removeEventListener('load', onLoad)
      resolve()
    }
    window.addEventListener('load', onLoad)
  })
}

export const waitForWindowIdle = () => {
  return new Promise<void>((resolve) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => resolve())
    } else {
      setTimeout(resolve)
    }
  })
}

export const saferEval = (code: string) => {
  // Safer alternative to eval() as described by MDN:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_eval!
  try {
    // eslint-disable-next-line no-new-func
    Function('"use strict";' + code + '')()
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(e)
    }
    Sentry.captureException(e)
  }
}

interface ExtendedCSSStyleDeclaration extends CSSStyleDeclaration {
  scrollSnapType: string | null
}

const createDragScrollMousedownHandler = (
  el: HTMLElement,
  onChange: (state: boolean) => unknown
) => (e: MouseEvent) => {
  const { target } = e
  if (target && (el === target || el.contains(target as Node))) {
    const { clientX: initialClientX } = e
    const { scrollLeft: initialScrollLeft } = el
    const {
      scrollBehavior: initialScrollBehavior,
      // TODO typings do not support scrollSnapType
      scrollSnapType: initialScrollSnapType,
    } = el.style as ExtendedCSSStyleDeclaration
    const translateMoveToScroll = (e: MouseEvent) => {
      const { clientX } = e
      const deltaClientX = clientX - initialClientX
      const scrollLeft = initialScrollLeft - deltaClientX
      el.scrollLeft = scrollLeft
    }
    const tearDown = () => {
      // TODO typings do not support scrollSnapType
      const style = el.style as ExtendedCSSStyleDeclaration
      style.scrollBehavior = initialScrollBehavior
      style.scrollSnapType = initialScrollSnapType
      window.removeEventListener('mousemove', translateMoveToScroll)
      window.removeEventListener('mouseup', tearDown)
      window.removeEventListener('mouseleave', tearDown)
      onChange(false)
    }
    const setUp = () => {
      // TODO typings do not support scrollSnapType
      const style = el.style as ExtendedCSSStyleDeclaration
      style.scrollBehavior = 'auto'
      style.scrollSnapType = 'none'
      window.addEventListener('mousemove', translateMoveToScroll, {
        passive: true,
      })
      window.addEventListener('mouseup', tearDown)
      window.addEventListener('mouseleave', tearDown)
      onChange(true)
    }
    setUp()
  }
}

export const applyDragScroll = (
  el: HTMLElement | null | undefined,
  onChange: (state: boolean) => unknown
) => {
  const isClient = typeof window === 'object'
  const isTouchDevice =
    isClient && ('ontouchstart' in window || 'onmsgesturechange' in window)
  if (el && isClient && !isTouchDevice) {
    const dragScrollMousedownHandler = createDragScrollMousedownHandler(
      el,
      onChange
    )
    window.addEventListener('mousedown', dragScrollMousedownHandler, {
      passive: true,
    })
    return () =>
      window.removeEventListener('mousedown', dragScrollMousedownHandler)
  }
  return noop
}

export const throttleRaf = (cb: () => unknown) => {
  let isRafRequested = false
  return () => {
    if (!isRafRequested) {
      isRafRequested = true
      window.requestAnimationFrame(() => {
        isRafRequested = false
        cb()
      })
    }
  }
}

type scrollFn = Window['scrollBy'] | Window['scrollTo'] | Window['scroll']

export const scrollWindow = (
  fn: scrollFn,
  scrollToOptions: ScrollToOptions
) => {
  // Not all browsers support scrollTo with an options object
  try {
    fn(scrollToOptions)
  } catch {
    const { top, left } = scrollToOptions
    fn(left || 0, top || 0)
  }
}

export const scrollElementIntoView = (id: string) => {
  const element = document.getElementById(id)
  if (element) element.scrollIntoView({ behavior: 'smooth' })
}

export const isAndroidDevice = () =>
  isInBrowser() && /android/i.test(navigator.userAgent)

export const isIOSDevice = () =>
  isInBrowser() && !!/iPad|iPhone|iPod/.test(navigator.platform)
