import React, { useState, useCallback } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@gatsbyjs/reach-router'
import { noop } from 'lodash'
import { t } from '@lingui/macro'
import * as Sentry from '@sentry/gatsby'

import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import SeamlessWebsiteLayout from 'components/layout/seamless-website'
import Breadcrumb from 'components/breadcrumb'
import { Container, Row, Col } from 'components/grid'
import PaymentPage from 'components/payment-page'
import { useDidMount } from 'hooks'
import { parse } from 'utils/url'
import { getPlan, calcSubscriptionStartDate } from 'utils/payment'
import { wait } from 'utils/async'
import { readUtmParamsFromCookie } from 'utils/utm'
import { validateDiscountCode } from 'components/payment/VoucherInput'
import { handleScaResponse } from 'components/payment/lib/stripe'
import { ToastStatus } from 'components/toast-message'
import { useWSFBackendPlans } from 'hooks/use-backend-plans'

import AuthenticatedPage from '../AuthenticatedPage'
import { navigateToProfileWithToast } from '../utils'

import { breadcrumb } from './index.module.scss'

interface PaymentDetailsPageProps {
  pageContext: {
    hrefLang: EF.HrefLangNode[]
    defaultLang: EF.DefaultLangNode
  }
}

// We need the plan and code params when we switch languages because otherwise
// we get redirected to the profile page which is unfortunate
const addSearchToHrefLang = (hrefLang: EF.HrefLangNode[], search: string) =>
  hrefLang.map((node) => {
    const {
      fields: { path },
    } = node
    return {
      ...node,
      fields: {
        path: `${path}${search}`,
      },
    }
  })

const getBreadcrumbItems = () => [
  {
    href: i18n._(t`/profile/`),
    text: i18n._(t('user.profile.title')`Profile`),
  },
  {
    href: i18n._(t`/profile/subscribe/`),
    text: i18n._(t('user.profile.subscribe')`Subscribe`),
  },
]

interface Toast {
  message: string
  status: ToastStatus
}

const PaymentDetailsPage = ({
  pageContext: { hrefLang, defaultLang },
}: PaymentDetailsPageProps) => {
  const { search } = useLocation()
  const params = parse(search)
  const plans = useWSFBackendPlans()
  const planFromParam =
    params.plan && plans.find(({ identifier }) => identifier === params.plan)
  const fallbackPlan = getPlan(plans)()
  // No worries, the fallbackPlan is just for the initial rendering. If you
  // don't have a valid plan set in your param you have no business here and
  // we'll gonna kick you from this page further down below
  const plan = planFromParam || fallbackPlan
  const [
    discountInformation,
    setDiscountInformation,
  ] = useState<EF.DiscountInformation | null>(
    params.code ? { code: params.code, percent_off: null } : null
  )
  const {
    validateDiscountCode: validateDiscountCodeApiFn,
    subscribeStripe,
    fetchAccount,
    accountState: { id: userId, subscription, is_pro: isPro },
  } = useUserAccount()
  const subscriptionStartDate = calcSubscriptionStartDate(subscription)
  const [toast, setToast] = useState<Toast | null>(null)
  const onCloseToastMessage = useCallback(() => setToast(null), [])

  useDidMount(async () => {
    const { code } = params
    if (code) {
      const result = await validateDiscountCode(validateDiscountCodeApiFn)(code)
      setDiscountInformation(result)
    }
  })

  useDidMount(() => {
    if (!planFromParam) {
      // You clearly shouldn't be on this page. Either your plan param isn't
      // set at all or the set plan doesn't exist
      navigate(i18n._(t`/profile/`)) // Bye bye 👋
    }
  })

  const onReceiveDiscountInformation = (data: EF.DiscountInformation) => {
    if (!discountInformation) {
      setDiscountInformation(data)
      setToast({
        message: i18n._(t`Discount successfully applied.`),
        status: ToastStatus.SUCCESS,
      })
    }
  }

  const onSubscribe = async (tokenResponse: stripe.TokenResponse) => {
    if (!tokenResponse.token?.id || !userId) {
      const message = tokenResponse.error?.message
      if (message) {
        setToast({
          message,
          status: ToastStatus.ERROR,
        })
      }
      Sentry.addBreadcrumb({
        category: 'Web Profile',
        message,
        level: Sentry.Severity.Info,
      })
      return
    }

    const stripeToken = tokenResponse.token.id

    const utmParams = readUtmParamsFromCookie()
    const fallbackUtmParams = {
      utm_source: '8fitweb',
      utm_medium: 'onboarding',
      utm_content: 'paywall',
      utm_term: 'web',
    }

    try {
      const scaResponse = await subscribeStripe({
        ...fallbackUtmParams,
        ...utmParams,
        user_id: userId,
        stripe_token: stripeToken,
        plan: plan.identifier,
        code: discountInformation?.code,
      })
      await handleScaResponse(scaResponse)
      if (scaResponse.type !== 'no_action_required') {
        // It might take a while until the Stripe webhook got back to the
        // backend and completes the upgrade. So, wait for a couple hundred
        // milliseconds & refetch the account data before navigating back:
        await wait(300)
        await fetchAccount()
      }
      Sentry.setTag('is_pro', isPro)
      navigateToProfileWithToast(
        isPro || !!subscription
          ? 'subscriptionUpdateSuccess'
          : 'subscriptionSuccess',
        ToastStatus.SUCCESS
      )
    } catch (e) {
      Sentry.captureException(e)
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      setToast({
        message: i18n._(
          t('error.message.default')`Unfortunately an error occurred.`
        ),
        status: ToastStatus.ERROR,
      })
    }
  }

  return (
    <AuthenticatedPage>
      <SeamlessWebsiteLayout
        title={i18n._(
          t('user.profile.payment.title')`Start your transformation`
        )}
        hrefLang={addSearchToHrefLang(hrefLang, search)}
        defaultLang={defaultLang}
      >
        <Container>
          <Row>
            <Col>
              <Breadcrumb items={getBreadcrumbItems()} className={breadcrumb} />
            </Col>
          </Row>
        </Container>
        <PaymentPage
          subtitle={i18n._(
            t('user.profile.payment.subtitle')`Enter your payment details`
          )}
          onSubscribe={onSubscribe}
          discountInformation={discountInformation || undefined}
          onReceiveDiscountInformation={onReceiveDiscountInformation}
          plan={plan}
          toastMessage={toast?.message}
          toastStatus={toast?.status}
          onCloseToastMessage={onCloseToastMessage}
          onSwitchLinkedPlan={noop}
          subscriptionStartDate={subscriptionStartDate}
        />
      </SeamlessWebsiteLayout>
    </AuthenticatedPage>
  )
}

export default PaymentDetailsPage
