import React, { FunctionComponent } from 'react'
import { omit, has, placeholder as _ } from 'lodash/fp'

import { useMatchMedia } from 'hooks'

import {
  getClassname as _getClassname,
  breakpoints,
  colSizeNames,
  Vessel,
  VesselProps,
  BreakpointName,
  ColSizeName,
} from './common'
import * as styles from './hidden-visible.module.scss'

const getClassname = (parts: string[]) => _getClassname(parts, styles)

const getHiddenVisibleClassnames = (
  classNamePrefix: string,
  props: VesselProps
) => {
  const hiddenClasses = colSizeNames
    .filter((name) => name in props)
    .map((name) => getClassname([classNamePrefix, name]))

  if (hiddenClasses.length === 0) {
    hiddenClasses.push(getClassname([classNamePrefix, colSizeNames[0]]))
  }

  return hiddenClasses
}

const getHiddenVisibleQuery = (isHidden: boolean, props: VesselProps) => {
  const query = (Object.keys(breakpoints) as BreakpointName[])
    // key in x checks the whole prototype chain, we want direct props
    .filter(has(_, props))
    .map((name) => `(min-width: ${breakpoints[name]}px)`)
    .join(' ')

  if (!query) return isHidden ? 'not all' : 'all'

  return isHidden ? `not all and ${query}` : query
}

export type HiddenVisibleProps = VesselProps & {
  classNamePrefix?: string
} & Partial<{ [key in BreakpointName]: boolean }>

const HiddenVisible: FunctionComponent<HiddenVisibleProps> = ({
  classNamePrefix = '',
  children,
  ...props
}) => {
  const hiddenClasses = getHiddenVisibleClassnames(classNamePrefix, props)
  const query = getHiddenVisibleQuery(classNamePrefix === 'hidden', props)
  const restProps = omit(colSizeNames, props)
  const { matches } = useMatchMedia(query)

  return (
    <Vessel gridClassNames={hiddenClasses} {...restProps}>
      {matches !== false ? (children as JSX.Element) : null}
    </Vessel>
  )
}

export const Hidden: FunctionComponent<HiddenVisibleProps> = (props) => (
  <HiddenVisible classNamePrefix="hidden" {...props} />
)

export const Visible: FunctionComponent<HiddenVisibleProps> = (props) => (
  <HiddenVisible classNamePrefix="visible" {...props} />
)

type SelectiveVisibleProps = {
  [P in ColSizeName]?: 'hidden' | 'visible'
}

export const SelectiveVisible: FunctionComponent<
  SelectiveVisibleProps & VesselProps
> = (props) => {
  const gridClassNames = colSizeNames.reduce((classNames, colSizeName) => {
    const value = props[colSizeName]
    if (value) {
      return [...classNames, getClassname(['nonexclusive', value, colSizeName])]
    }
    return classNames
  }, [] as string[])
  const restProps = omit(colSizeNames, props)
  return <Vessel gridClassNames={gridClassNames} {...restProps} />
}
