import React from 'react'
import Image from 'gatsby-image'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { Container, Row, Col, Hidden, Visible } from 'components/grid'
import { SecondaryButtonLink } from 'components/Buttons'

import {
  container,
  rowOdd,
  rowEven,
  subTitle as subTitleStyle,
  title as titleStyle,
  imageContainer,
  backgroundImage as backgroundImageStyle,
  photo as photoStyle,
  description as descriptionStyle,
} from './StoryOverView.module.scss'

interface StoryOverviewProps {
  title: string
  subTitle: string
  description: string
  backgroundImage: EF.FluidImageNode['fields']['asset']
  photo: EF.FluidImageNode['fields']['asset']
  path: string
  isLeft: boolean
}

const StoryOverView = ({
  title,
  subTitle,
  backgroundImage,
  description,
  photo,
  path,
  isLeft,
}: StoryOverviewProps) => {
  return (
    <div data-is-left={isLeft} className={container}>
      <DocumentOutlineSection title={title}>
        <Container>
          <Row
            reverse={isLeft ? 'lg' : undefined}
            className={isLeft ? rowOdd : rowEven}
          >
            <Col xs={12} lg={6}>
              <p className={subTitleStyle}>{subTitle}</p>
              <H className={titleStyle} />
              <Hidden lg>
                <div className={imageContainer}>
                  <div className={backgroundImageStyle}>
                    <Image
                      fluid={backgroundImage.fluid}
                      alt={backgroundImage.title}
                    />
                  </div>
                  <div className={photoStyle}>
                    <Image fluid={photo.fluid} alt={photo.title} />
                  </div>
                </div>
              </Hidden>
              <p className={descriptionStyle}>{description}</p>
              <SecondaryButtonLink path={path} text={i18n._(t`Read more`)} />
            </Col>
            <Col lg={6}>
              <Visible lg>
                <div className={imageContainer}>
                  <div className={backgroundImageStyle}>
                    <Image
                      fluid={backgroundImage.fluid}
                      alt={backgroundImage.title}
                    />
                  </div>
                  <div className={photoStyle}>
                    <Image fluid={photo.fluid} alt={photo.title} />
                  </div>
                </div>
              </Visible>
            </Col>
          </Row>
        </Container>
      </DocumentOutlineSection>
    </div>
  )
}

export default StoryOverView
