import React from 'react'
import { t } from '@lingui/macro'

import { useDidMount } from 'hooks'
import { i18n } from 'utils/i18n'
import { classNames } from 'utils/dom'

import {
  root,
  container,
  message as messageStyle,
  closeButton,
  crossLeft,
  crossRight,
} from './index.module.scss'

export enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

const ToastMessage = ({
  message,
  onPressClose,
  status = ToastStatus.SUCCESS,
  className,
}: {
  message: string
  onPressClose: () => void
  status?: ToastStatus
  className?: string
}) => {
  useDidMount(() => {
    if (status === ToastStatus.ERROR) return

    const timer = setTimeout(onPressClose, 6000)

    return () => clearTimeout(timer)
  })

  return (
    <figure
      className={classNames([root, className])}
      role="alert"
      data-testid="toast-message"
    >
      <div data-status={status} className={container}>
        <span data-status={status} className={messageStyle}>
          {message}
        </span>
        <button
          type="button"
          className={closeButton}
          onClick={onPressClose}
          aria-label={i18n._(t`Close`)}
        >
          <span className={crossLeft} data-status={status} />
          <span className={crossRight} data-status={status} />
        </button>
      </div>
    </figure>
  )
}

export default ToastMessage
