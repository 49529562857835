import { navigate } from 'gatsby-link'
import { useCallback, useEffect } from 'react'

import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import { wait } from 'utils/async'
import { useGetParams } from 'hooks'

const LogoutPage = () => {
  const { navigate: navigateTo } = useGetParams<'navigate'>()
  const { logout } = useUserAccount()

  const doLogout = useCallback(async () => {
    try {
      // There might be network requests still going on, fetching the user
      // account data. So, wait for a ¾ sec.
      await wait(750)
      await logout()
      navigate(navigateTo ?? '/login/')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }, [logout, navigateTo])

  useEffect(() => {
    doLogout()
  }, [doLogout])

  return null
}

export default LogoutPage
