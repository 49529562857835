import React from 'react'
import { t } from '@lingui/macro'

import { classNames } from 'utils/dom'
import { i18n } from 'utils/i18n'

import { ButtonProps, ButtonWithIconProps, ImageIconButtonProps } from './types'
import {
  primary,
  secondary,
  srOnlyText,
  back,
  close,
  chevron,
  leftArrow,
  closeCross,
  chevronLeft,
  chevronRight,
  imageIcon,
  icon,
  text,
} from './index.module.scss'

const Button = ({ text, block, ...props }: ButtonProps) => (
  <button data-block={block} title={text} {...props}>
    {text}
  </button>
)

export const PrimaryButton = ({ className, ...props }: ButtonProps) => (
  <Button className={classNames([primary, className])} {...props} />
)

export const SecondaryButton = ({ className, ...props }: ButtonProps) => (
  <Button className={classNames([secondary, className])} {...props} />
)

const ButtonWithIcon = ({
  text,
  block,
  icon,
  classNameText,
  ...props
}: ButtonWithIconProps) => (
  <button data-block={block} title={text} {...props}>
    {icon}
    <span className={classNameText}>{text}</span>
  </button>
)

export const ArrowBackButton = ({
  className,
  ...props
}: Omit<ButtonProps, 'text'>) => (
  <ButtonWithIcon
    className={classNames([back, className])}
    text={i18n._(t`Back`)}
    icon={<span className={leftArrow} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const CloseButton = ({
  className,
  ...props
}: Omit<ButtonProps, 'text'>) => (
  <ButtonWithIcon
    className={classNames([close, className])}
    text={i18n._(t`Close`)}
    icon={<span className={closeCross} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const ChevronLeftButton = ({ className, ...props }: ButtonProps) => (
  <ButtonWithIcon
    className={classNames([chevron, className])}
    icon={<span className={chevronLeft} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const ChevronRightButton = ({ className, ...props }: ButtonProps) => (
  <ButtonWithIcon
    className={classNames([chevron, className])}
    icon={<span className={chevronRight} />}
    classNameText={srOnlyText}
    {...props}
  />
)

export const ImageIconButton = ({
  className,
  imageIconUrl,
  ...props
}: ImageIconButtonProps) => (
  <ButtonWithIcon
    className={classNames([imageIcon, className])}
    icon={
      <span
        className={icon}
        style={{ backgroundImage: `url(${imageIconUrl})` }}
      />
    }
    classNameText={text}
    {...props}
  />
)
