import { Component } from 'react'
import * as Sentry from '@sentry/gatsby'

/**
 * Error boundaries do not catch errors for:
 *
 * List item
 * Event handlers
 * Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 * Server side rendering
 * Errors thrown in the error boundary itself (rather than its children)
 */

export default class ErrorBoundary extends Component {
  componentDidCatch(error: unknown) {
    Sentry.captureException(error)
  }

  render() {
    return this.props.children
  }
}
