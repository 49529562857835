// extracted by mini-css-extract-plugin
export var background = "d5vtd";
export var blueberries = "x9S75";
export var content = "+QS63";
export var entryContainer = "_5J31m";
export var equipment = "vmexz";
export var header = "RrrHh";
export var logo = "z-yP2";
export var root = "dLKx-";
export var successContainer = "YcNn8";
export var successImage = "GDXZV";
export var title = "k3592";