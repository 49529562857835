import { serialize } from './url'

export const generateShareableUrl = (
  siteUrl: string,
  pathname: string,
  shareMedium: 'fb' | 'tw' | 'pin',
  utmCampaign = '',
  utmSource = 'ContentLP'
) => {
  const utmCampaignSuffix = `_${shareMedium}`
  let newUtmCampaign = utmCampaignSuffix
  if (utmCampaign) {
    const originalCampaign = utmCampaign.replace(/_(fb|tw|pin)$/, '')
    newUtmCampaign = `${originalCampaign}${utmCampaignSuffix}`
  }
  return `${siteUrl}${pathname}${serialize({
    utm_source: utmSource,
    utm_medium: 'Referral',
    utm_campaign: newUtmCampaign,
  })}`
}
