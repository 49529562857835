import React, { useState } from 'react'
import { times } from 'lodash'
import { Trans, t } from '@lingui/macro'

import Close from 'assets/images/icons/controls/close.svg'
import GreenStar from 'assets/images/icons/green-star.svg'
import HalfStar from 'assets/images/icons/half-star.svg'
import { i18n } from 'utils/i18n'
import Logo from 'components/logo'
import AdjustLink from 'components/utils/adjust-link'

import {
  bannerContainer,
  closeIcon,
  closeButton,
  appIcon,
  content,
  title,
  userRatingRow,
  star,
  subTitle,
  installText,
} from './index.module.scss'

const UserRating = () => {
  return (
    <div className={userRatingRow}>
      <span>
        {times(4, (idx) => (
          <img
            key={`app-rating-star-${idx}`}
            src={GreenStar}
            className={star}
            alt=""
          />
        ))}
        <img key={`app-rating-star-5`} src={HalfStar} className={star} alt="" />
      </span>

      <span className={subTitle}>({i18n._(t`${80083}`)})</span>
    </div>
  )
}

const SmartAppBanner = () => {
  const [showBanner, setShowBanner] = useState(true)

  if (!showBanner) return null

  return (
    <div className={bannerContainer}>
      <button className={closeButton} onClick={() => setShowBanner(false)}>
        <img className={closeIcon} src={Close} alt="" />
      </button>
      <div className={appIcon}>
        <Logo />
      </div>
      <div className={content}>
        <div>
          <div className={title}>8fit</div>
          <UserRating />
          <div className={subTitle}>
            <Trans>FREE - On The App Store</Trans>
          </div>
        </div>
        <div className={installText}>
          <AdjustLink preferAndroidStore={false}>
            <Trans>Install Now</Trans>
          </AdjustLink>
        </div>
      </div>
    </div>
  )
}

export default SmartAppBanner
