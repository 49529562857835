import React from 'react'

import {
  FacebookButton,
  TwitterButton,
  PinterestButton,
} from 'components/socialSharing'

import { sharingButtons, sharingButtonsEntry } from './index.module.scss'

interface Props {
  description: string
  imageUrl: string
}

const SharingButtons = ({ description, imageUrl }: Props) => (
  <ul className={sharingButtons}>
    <li className={sharingButtonsEntry}>
      <FacebookButton />
    </li>
    <li className={sharingButtonsEntry}>
      <TwitterButton description={description} />
    </li>
    <li className={sharingButtonsEntry}>
      <PinterestButton description={description} media={imageUrl} />
    </li>
  </ul>
)

export default SharingButtons
