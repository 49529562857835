import { graphql } from 'gatsby'
import React from 'react'
import { H } from 'react-document-section'
import { plural, t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import SeamlessWebsiteLayoutAndMeta from 'components/layout/layout-meta'
import { Col, Container, Hidden, Row } from 'components/grid'
import UserJourneySection from 'components/user-journey/cow'
import DownloadAppCtaSection from 'components/download-app-cta-section'

import Header from './section/header'
import MetaInfoList from './section/meta-info-list'
import HorizontalLine from './components/horizontal-line'
import ImageNutritionInfo from './section/image-nutrition-info'
import IngredientsSection from './section/ingredients'
import InstructionsSection from './section/instructions'
import RecommendationsSection from './section/recommendations'
import {
  title as titleStyle,
  horizontalLineFirst,
  firstColumn,
  firstColumnContent,
  secondColumnContent,
  horizontalLineSecond,
} from './index.module.scss'

interface Props {
  data: {
    contentfulRecipe: Contentful.Recipe & {
      headerImage: EF.FluidImageNode
      ogImage: EF.ResizeImageNode
    }
  }
}

const getMetaDescription = (title: string, servingCount: number, min: number) =>
  `8fit ${i18n._(t`recipe`)}: ${title}. ${i18n._(
    plural({ value: servingCount, one: '# serving', other: '# servings' })
  )}. ${i18n._(t('recipe.label.preparation_time')`Preparation time`)}: ${i18n._(
    plural({ value: min, one: `# min`, other: `# min` })
  )}.`

const RecipePage = ({
  data: {
    contentfulRecipe: {
      node_locale: locale,
      title,
      preparationTimeMin,
      servingCount,
      calories,
      protein,
      fat,
      carbs,
      categories,
      fields: { pageTitle, hrefLang, defaultLang, structuredIngredientList },
      instructionList,
      imageFocalArea,
      headerImage,
      image,
      recommendations,
      ogImage,
    },
  },
}: Props) => {
  return (
    <SeamlessWebsiteLayoutAndMeta
      title={title}
      description={getMetaDescription(title, servingCount, preparationTimeMin)}
      pageTitle={pageTitle}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
      ogImage={ogImage}
      locale={locale}
    >
      <article>
        <Header
          image={headerImage.fields.asset.fluid}
          focalArea={imageFocalArea}
        />
        <Container>
          <Row>
            <Col
              xs={12}
              smOffset={1}
              sm={10}
              mdOffset={0}
              md={12}
              xlOffset={1}
              xl={10}
            >
              <H className={titleStyle} />
              <MetaInfoList
                preparationTimeMin={preparationTimeMin}
                servingCount={servingCount}
                calories={calories}
              />
              <HorizontalLine className={horizontalLineFirst} />
            </Col>
          </Row>
          <Row>
            <Col xlOffset={1} xl={3} className={firstColumn}>
              <div className={firstColumnContent}>
                <ImageNutritionInfo
                  image={image}
                  categories={categories}
                  protein={protein}
                  fat={fat}
                  carbs={carbs}
                />
              </div>
            </Col>
            <Col xl={1} />
            <Col
              xs={12}
              smOffset={1}
              sm={10}
              mdOffset={0}
              md={6}
              lgOffset={1}
              lg={5}
              xlOffset={0}
              xl={3}
            >
              <div className={secondColumnContent}>
                <IngredientsSection ingredients={structuredIngredientList} />
              </div>
            </Col>
            <Col xs={12} smOffset={1} sm={10} mdOffset={0} md={6} lg={5} xl={3}>
              <InstructionsSection instructions={instructionList} />
            </Col>
          </Row>
          <Hidden xl>
            <HorizontalLine className={horizontalLineSecond} />
            <ImageNutritionInfo
              image={image}
              categories={categories}
              protein={protein}
              fat={fat}
              carbs={carbs}
            />
          </Hidden>
        </Container>
        <UserJourneySection />
        <RecommendationsSection recommendations={recommendations} />
        <DownloadAppCtaSection />
      </article>
    </SeamlessWebsiteLayoutAndMeta>
  )
}

export default RecipePage

export const pageQuery = graphql`
  query RecipePageQuery($id: String!) {
    contentfulRecipe(id: { eq: $id }) {
      node_locale
      title
      preparationTimeMin
      servingCount
      calories
      protein
      fat
      carbs
      categories
      fields {
        pageTitle
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
        structuredIngredientList {
          name
          replacement
          required
          measure {
            amount
            unit
          }
        }
      }
      instructionList {
        raw
      }
      imageFocalArea
      headerImage: image {
        fields {
          asset {
            fluid(
              maxWidth: 1750
              maxHeight: 1165
              sizes: "(min-width: 1750px) 1750px, (min-width: 992px) 100vw, (min-width: 768px) 120vw, (min-width: 421px) 100vw, 421px"
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      image {
        fields {
          asset {
            fluid(
              maxWidth: 585
              toFormat: JPG
              # The sizes attribute is to tell the browser which width the image is gonna have on which viewport
              # The calculation looks super complicated. As I can't use JS variables in the query here's the explanation:
              # The image is xs={12}, sm={10}, md={6} xl={4}
              # We're taking the full viewport width (100vw) and subtract the grid outer margin (60px)
              # We add the gutter width (because <Row>s have negative margins; 24px) divide it by the number of columns (12)
              # That's 1 column's width. We multiply that by the number of columns the image is spanning across
              # Then we subtract the column's inner padding (24px, 12px to each side) to get the image's width
              sizes: "(min-width: 1200px) cal(((100vw - 60px + 24px) / 12) * 4 - 24px), (min-width: 768px) cal(((100vw - 60px + 24px) / 12) * 6 - 24px), (min-width: 576px) cal(((100vw - 60px + 24px) / 12) * 10 - 24px), calc(100vw - 60px)"
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      recommendations {
        title
        categories
        preparationTimeMin
        calories
        fields {
          path
        }
        image {
          fields {
            asset {
              fixed(
                width: 309
                height: 214
                cropFocus: CENTER
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
      ogImage: image {
        fields {
          asset {
            alt: title
            resize(
              width: 1200
              height: 630
              cropFocus: CENTER
              resizingBehavior: FILL
            ) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`
