import GatsbyImage, { FluidObject } from 'gatsby-image'
import React from 'react'

import ScrollIndicator from 'components/scroll-indicator'

import {
  root,
  image as imageStyle,
  wave,
  scrollIndicator,
} from './index.module.scss'

interface Props {
  image: FluidObject
}

const Header = ({ image }: Props) => {
  return (
    <header className={root}>
      <div className={imageStyle}>
        <GatsbyImage fluid={image} loading="eager" />
      </div>
      <div className={wave} />
      <div className={scrollIndicator}>
        <ScrollIndicator />
      </div>
    </header>
  )
}

export default Header
