import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { DocumentOutlineSection, H } from 'react-document-section'
import { t, Trans } from '@lingui/macro'
import GatsbyImage from 'gatsby-image'

import { i18n } from 'utils/i18n'
import CloudyDecoratedSection from 'components/cloudy-decorated-section'
import { Col, Container, Row } from 'components/grid'
import FunnelCta from 'components/funnel-cta'
import { SecondaryButtonLink } from 'components/Buttons'

import {
  image,
  content,
  title,
  paragraph,
  ctas,
  ctaButton,
} from './index.module.scss'

const query = graphql`
  {
    imageBenedikt: file(
      relativeDirectory: { eq: "user-transformations" }
      name: { eq: "BENEDIKT" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 772
          sizes: "(min-width: 1280px) 598px, (min-width: 992px) calc(6 * (100vw - 60px) / 12), (min-width: 576px) calc(10 * (100vw - 60px) / 12), calc(100vw - 32px)"
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const UserJourneySection = () => {
  const { imageBenedikt } = useStaticQuery<{
    imageBenedikt: EF.LocalFluidImage
  }>(query)

  return (
    <DocumentOutlineSection
      title={i18n._(t('user_journey.title')`Start your 8fit journey`)}
    >
      <section>
        <CloudyDecoratedSection>
          <Container>
            <Row>
              <Col xs={12} smOffset={1} sm={10} lg={6}>
                <div className={image}>
                  <GatsbyImage fluid={imageBenedikt.childImageSharp.fluid} />
                </div>
              </Col>
              <Col
                xs={12}
                smOffset={1}
                sm={10}
                lgOffset={0}
                lg={4}
                className={content}
              >
                <H className={title} />
                <Trans id="user_journey.text">
                  <p className={paragraph}>
                    We encourage you to take your fitness journey one step at a
                    time, and we don't subscribe to a "no pain, no gain"
                    mindset.
                  </p>
                  <p className={paragraph}>
                    We offer realistic guidance — no quick-fixes because they
                    just simply do not work.
                  </p>
                  <p className={paragraph}>
                    You will learn how to take care of your whole self by making
                    gradual habit changes.
                  </p>
                </Trans>
                <div className={ctas}>
                  <SecondaryButtonLink
                    path={i18n._(t`/transformation-stories/`)}
                    text={i18n._(t`Read more`)}
                    className={ctaButton}
                  />
                  <FunnelCta
                    text={i18n._(t`Try for free`)}
                    className={ctaButton}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </CloudyDecoratedSection>
      </section>
    </DocumentOutlineSection>
  )
}

export default UserJourneySection
