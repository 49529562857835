import React, { ReactNode, FunctionComponent } from 'react'

import { useMatchMedia, useUniqueId } from 'hooks'

export interface ResponsiveSwitchProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> {
  query: string
  renderTrue: ReactNode
  renderFalse: ReactNode
}

const ResponsiveSwitch: FunctionComponent<ResponsiveSwitchProps> = ({
  query,
  renderTrue,
  renderFalse,
  ...restProps
}) => {
  const id = useUniqueId('switch-')
  const { matches } = useMatchMedia(query)
  const shouldRenderTrue = matches || matches === undefined
  const shouldRenderFalse = !matches || matches === undefined

  return (
    <>
      <style>
        {`
          @media not all and ${query} {
            #${id}-true { display: none; }
          }
          @media ${query} {
            #${id}-false { display: none; }
          }
          `.replace(/\s+/g, ' ')}
      </style>
      <div {...restProps} id={`${id}-true`}>
        {shouldRenderTrue ? renderTrue : null}
      </div>
      <div {...restProps} id={`${id}-false`}>
        {shouldRenderFalse ? renderFalse : null}
      </div>
    </>
  )
}

export default ResponsiveSwitch
