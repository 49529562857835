import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text'
import Image from 'gatsby-image'
import { H } from 'react-document-section'
import { ReactStripeElements } from 'react-stripe-elements'
import * as Sentry from '@sentry/gatsby'

import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import { useOnLoad, useGetParams } from 'hooks'
import { Container, Row, Col, Hidden } from 'components/grid'
import LandingPageLayout from 'components/layout/LandingPageLayout'
import { handleScaResponse } from 'components/payment/lib/stripe'
import { DiscountData } from 'components/payment/types'
import { CRMHeaderRTF, CRMContentRTF } from 'components/rich-text-field'
import {
  CRMHeaderRTFGraphQLResult,
  CRMContentRTFGraphQLResult,
} from 'components/rich-text-field/renderer/types'
import LogoProBadge from 'assets/images/icons/logo-pro-badge.svg'
import { readUtmParamsFromCookie } from 'utils/utm'
import { PrimaryButton } from 'components/Buttons'

import StripePaymentArea from './StripePaymentArea'
import SubscriptionStatusArea, {
  SubscriptionStatus,
  SubscriptionState,
} from './SubscriptionStatusArea'
import Loading, { LoadingState } from './Loading'
import {
  header as headerStyle,
  content as contentStyle,
  logo,
  title as titleStyle,
  scrollButtonBelowTitleContainer,
  rtfHeader,
  blueBerry as blueBerryStyle,
  peas as peasStyle,
  scrollButtonContainer,
  paymentArea,
  loading,
} from './index.module.scss'

interface CRMPageProps {
  data: {
    contentfulCrmPage: {
      fields: {
        hrefLang: EF.HrefLangNode[]
        defaultLang: EF.DefaultLangNode
        plan: EF.GatsbySource8fitBackendPlan
        discountInformation: null | EF.DiscountInformation
      }
      description: string
      discountDisplay: string
      displayPrice: number
      pageContent: {
        title: string
        pageTitle: string
        scrollButtonBelowTitleText: string
        submitButtonText: string
        scrollButtonText: string
        header: RenderRichTextData<CRMHeaderRTFGraphQLResult>
        content: RenderRichTextData<CRMContentRTFGraphQLResult>
      }
    }
    blueBerry: EF.LocalFluidImage
    peas: EF.LocalFluidImage
  }
}

const CRMPage = ({
  data: {
    contentfulCrmPage: {
      fields: { hrefLang, defaultLang, plan, discountInformation },
      description,
      discountDisplay,
      displayPrice,
      pageContent: {
        title,
        pageTitle,
        scrollButtonBelowTitleText,
        submitButtonText,
        scrollButtonText,
        header,
        content,
      },
    },
    blueBerry,
    peas,
  },
}: CRMPageProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState | null>(null)
  const [
    subscriptionState,
    setSubscriptionState,
  ] = useState<SubscriptionState | null>(null)
  const stripePaymentAreaRef = useRef<HTMLDivElement>(null)
  const [discountCode, setDiscountCode] = useState<string | undefined>(
    discountInformation?.code
  )
  const paymentAreaRef = useRef<HTMLDivElement | null>(null)
  const { subscribeStripe, setAttribute } = useUserAccount()
  const { user_id } = useGetParams()

  useOnLoad(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user_id,
      })
    })
  })

  useEffect(() => {
    setAttribute('id', user_id)
    // eslint-disable-next-line @typescript-eslint/camelcase
  }, [setAttribute, user_id])

  const onClickScroll = () => {
    const paymentAreaElement = paymentAreaRef.current
    if (paymentAreaElement) {
      paymentAreaElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const onSubscribe = async (response: ReactStripeElements.TokenResponse) => {
    const { search } = window.location

    if (!response || !response.token) {
      setSubscriptionState({ status: SubscriptionStatus.ERROR })
      Sentry.addBreadcrumb({
        category: 'CRM',
        level: Sentry.Severity.Error,
        message: 'Stripe token generation failed',
        data: {
          response: JSON.stringify(response),
          user_id,
        },
      })
      return
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    if (!user_id) {
      setSubscriptionState({ status: SubscriptionStatus.ERROR })
      return
    }

    try {
      const utmParams = readUtmParamsFromCookie()

      const apiResult = await subscribeStripe({
        ...utmParams,
        user_id,
        search,
        code: discountCode,
        plan: plan.identifier,
        stripe_token: response.token.id,
        method: 'POST',
      })

      await handleScaResponse(apiResult)

      setSubscriptionState({ status: SubscriptionStatus.COMPLETE })
    } catch (error) {
      setSubscriptionState({ status: SubscriptionStatus.ERROR, error })
      Sentry.captureException(error)
    }
  }

  const onTryAgain = () => {
    setSubscriptionState(null)
    setLoadingState(null)
  }

  const onSubscribeStart = () => {
    setLoadingState(LoadingState.RUNNING)
    setTimeout(() => {
      setLoadingState(LoadingState.COMPLETED)
    }, 3000)
  }

  const renderLoading = () => {
    if (!loadingState) return null

    const height = stripePaymentAreaRef.current?.offsetHeight

    return (
      <div className={loading}>
        <Loading
          height={height}
          fadeOut={
            !!(loadingState === LoadingState.COMPLETED && subscriptionState)
          }
        />
      </div>
    )
  }

  return (
    <LandingPageLayout
      title={pageTitle || title}
      description={description}
      hrefLang={hrefLang}
      defaultLang={defaultLang}
    >
      <article>
        <div className={headerStyle}>
          <div className={blueBerryStyle}>
            <Image fluid={blueBerry.childImageSharp.fluid} />
          </div>
          <div className={peasStyle}>
            <Image fluid={peas.childImageSharp.fluid} />
          </div>
          <Container>
            <Row>
              <Col
                xs={12}
                sm={10}
                smOffset={1}
                md={8}
                mdOffset={2}
                lg={6}
                lgOffset={0}
              >
                <img className={logo} src={LogoProBadge} alt="" />
                <H className={titleStyle} data-testid="pageTitle" />
                {!!scrollButtonBelowTitleText && (
                  <Hidden lg className={scrollButtonBelowTitleContainer}>
                    <PrimaryButton
                      text={scrollButtonBelowTitleText}
                      onClick={onClickScroll}
                    />
                  </Hidden>
                )}
                <div className={rtfHeader}>
                  <CRMHeaderRTF data={header} />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className={paymentArea} ref={paymentAreaRef}>
                  {!subscriptionState && (
                    <StripePaymentArea
                      discountDisplay={loadingState ? '' : discountDisplay}
                      displayPrice={displayPrice}
                      plan={plan}
                      prefilledVoucherData={discountInformation}
                      submitButtonText={submitButtonText}
                      onReceiveDiscountInformation={(data: DiscountData) => {
                        setDiscountCode(data.code)
                      }}
                      onSubscribeStart={onSubscribeStart}
                      onSubscribe={onSubscribe}
                      ref={stripePaymentAreaRef}
                    />
                  )}
                  {subscriptionState && (
                    <SubscriptionStatusArea
                      state={subscriptionState}
                      onTryAgain={onTryAgain}
                    />
                  )}
                  {renderLoading()}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={contentStyle}>
          <CRMContentRTF
            data={content}
            options={{
              ContentfulTextWithImageSection: {
                ctaPath: '#',
                onClick: (e) => {
                  e.preventDefault()
                  onClickScroll()
                },
              },
            }}
          />
        </div>
        <Container>
          <Row>
            <Col>
              <div className={scrollButtonContainer}>
                <PrimaryButton
                  text={scrollButtonText}
                  onClick={onClickScroll}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </article>
    </LandingPageLayout>
  )
}

export default CRMPage

export const pageQuery = graphql`
  query CRMPage($id: String!) {
    contentfulCrmPage(id: { eq: $id }) {
      fields {
        hrefLang {
          node_locale
          fields {
            path
          }
        }
        defaultLang {
          fields {
            path
          }
        }
        plan {
          ...GatsbySource8fitBackendPlanFull
        }
        discountInformation: coupon {
          code: id
          percent_off
          duration
        }
      }
      slug
      displayPrice
      discountDisplay
      pageContent {
        pageTitle
        title
        scrollButtonBelowTitleText
        submitButtonText
        scrollButtonText
        header {
          raw
          references {
            ... on ContentfulUniqueSellingPoint {
              ...RTFContentfulUniqueSellingPointBlockFragment
            }
          }
        }
        content {
          raw
          references {
            ... on ContentfulUserReviewGroup {
              ...RTFContentfulUserReviewGroupBlockFragment
            }

            ... on ContentfulTextWithImageSection {
              ...RTFContentfulTextWithImageSectionBlockFragment_withAsset_LP
            }
          }
        }
      }
    }
    blueBerry: file(name: { eq: "blue-berry" }) {
      childImageSharp {
        fluid(maxHeight: 240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    peas: file(name: { eq: "peas" }) {
      childImageSharp {
        fluid(maxHeight: 240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
