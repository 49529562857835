import React from 'react'

import {
  container,
  loadingContainer,
  loadingSpinner,
} from './Loading.module.scss'

export enum LoadingState {
  RUNNING = 'running',
  COMPLETED = 'completed',
}

const Loading = ({
  fadeOut = false,
  height,
}: {
  fadeOut: boolean
  height?: number
}) => (
  <div data-fade-out={fadeOut} className={container} style={{ height }}>
    <div className={loadingContainer}>
      <div className={loadingSpinner} />
    </div>
  </div>
)

export default Loading
