import React, { createRef, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import FocusTrap from 'focus-trap-react'
import { t } from '@lingui/macro'

import {
  useUserAccount,
  AuthenticationState,
} from 'gatsby-plugin-8fit-user-account'
import { i18n } from 'utils/i18n'
import { useUniqueId } from 'hooks'
import { Visible } from 'components/grid'
import FunnelCta from 'components/funnel-cta'
import LoginCta from 'components/login-cta'
import { CloseButton } from 'components/Buttons/buttons'
import LanguageSelector from 'components/language-selector/LanguageSelector'
import { PrimaryButtonLink } from 'components/Buttons'
import Logo from 'components/logo'

import {
  root,
  logoContainer,
  logo,
  menuButtonOpen,
  menuButtonImg,
  flyOutMenuContainer,
  flyOutMenu,
  flyOutMenuNav,
  flyOutMenuList,
  flyOutMenuListItem,
  menuButtonClose,
  flyOutMenuHeader,
  flyOutMenuHider,
  signupCta,
} from './websiteHeaderMobile.module.scss'

interface LinkItem {
  id: string
  text: string
  to: string
}

const query = graphql`
  {
    menuStripes: file(
      name: { eq: "burger-menu" }
      relativeDirectory: { eq: "icons/controls" }
    ) {
      publicURL
    }
  }
`

interface Props {
  hrefLang: EF.HrefLangNode[]
  navigationList?: LinkItem[]
}

const WebsiteHeaderMobile = ({
  hrefLang,
  navigationList = [
    {
      id: 'workouts',
      text: i18n._(t('header.links.workouts')`Workouts`),
      to: i18n._(t`/#workouts`),
    },
    {
      id: 'meals',
      text: i18n._(t('header.links.meals')`Meals`),
      to: i18n._(t`/#recipes`),
    },
    {
      id: 'transformation-stories',
      text: i18n._(t('header.links.stories')`Success stories`),
      to: i18n._(t`/transformation-stories/`),
    },
    {
      id: 'articles',
      text: i18n._(t('header.links.articles')`Articles`),
      to: i18n._(t`/articles/`),
    },
  ],
}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const { menuStripes } = useStaticQuery<{
    menuStripes: { publicURL: string }
  }>(query)
  const idBase = useUniqueId()
  const openMenuButtonRef = createRef<HTMLButtonElement>()
  const {
    authenticationState,
    accountState: { is_pro: isPro },
  } = useUserAccount()
  const isAuthenticated =
    authenticationState === AuthenticationState.Authenticated

  const openMenu = () => {
    setExpanded(true)

    // disables background scrolling when the drawer is open
    if (typeof window !== 'undefined' && window.document) {
      document.body.style.width = `${document.body.clientWidth}px`
      document.body.style.overflow = 'hidden'
    }
  }

  const closeMenu = () => {
    setExpanded(false)
    window.requestAnimationFrame(() => {
      if (openMenuButtonRef?.current) {
        openMenuButtonRef.current.focus()
      }
    })

    // unsets background scrolling to use when drawer is closed
    document.body.style.width = ''
    document.body.style.overflow = ''
  }

  return (
    <header className={root}>
      <Link to={i18n._(t`/`)} className={logoContainer}>
        <Logo alt={i18n._(t`Homepage`)} className={logo} />
      </Link>
      <div>
        <Visible md>
          <LanguageSelector hrefLang={hrefLang} />
        </Visible>
        <button
          onClick={openMenu}
          className={menuButtonOpen}
          ref={openMenuButtonRef}
          aria-haspopup="true"
          aria-expanded={expanded}
          aria-controls={`${idBase}-menu`}
          aria-label={i18n._(t`Open menu`)}
          data-testid="menuOpenButton"
        >
          <img src={menuStripes.publicURL} className={menuButtonImg} alt="" />
        </button>
      </div>
      <div
        className={flyOutMenuContainer}
        id={`${idBase}-menu`}
        aria-hidden={!expanded}
      >
        <div className={flyOutMenuHider}>
          <FocusTrap
            active={expanded}
            focusTrapOptions={{
              initialFocus: false,
              clickOutsideDeactivates: true,
              onDeactivate: closeMenu,
            }}
          >
            <div className={flyOutMenu} aria-hidden={!expanded}>
              <div className={flyOutMenuHeader}>
                <CloseButton
                  className={menuButtonClose}
                  onClick={closeMenu}
                  aria-label={i18n._(t`Close menu`)}
                  data-testid="menuCloseButton"
                />
                <LanguageSelector
                  hrefLang={hrefLang}
                  testID="mobileLanguageSelector"
                />
              </div>
              <nav className={flyOutMenuNav}>
                <ul className={flyOutMenuList}>
                  <li className={flyOutMenuListItem}>
                    <Link
                      to={i18n._(t`/`)}
                      className={logoContainer}
                      onClick={closeMenu}
                    >
                      <Logo alt={i18n._(t`Homepage`)} className={logo} />
                    </Link>
                  </li>
                  {navigationList.map(({ id, text, to }) => (
                    <li key={id} className={flyOutMenuListItem}>
                      <Link
                        to={to}
                        data-testid="mobileNavLink"
                        onClick={closeMenu}
                      >
                        {text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <>
                {isAuthenticated && !isPro && (
                  <PrimaryButtonLink
                    block
                    path={i18n._(t`/profile/plans-overview/`)}
                    text={i18n._(t('user.profile.unlock')`Unlock PRO`)}
                    id="nav-bar-cta"
                    className={signupCta}
                    onClick={closeMenu}
                  />
                )}
                {!isAuthenticated && (
                  <FunnelCta
                    text={i18n._(
                      t('website_header.signup_cta')`Start FREE trial`
                    )}
                    className={signupCta}
                    id="nav-bar-menu-cta"
                    block
                    onClick={closeMenu}
                  />
                )}
              </>
              <LoginCta block onClick={closeMenu} />
            </div>
          </FocusTrap>
        </div>
      </div>
    </header>
  )
}

export default WebsiteHeaderMobile
