import React, {
  useState,
  useEffect,
  ReactNode,
  FunctionComponent,
  Children,
  ReactElement,
  useRef,
} from 'react'
import { noop } from 'lodash'
import { t } from '@lingui/macro'
import Flicking from '@egjs/react-flicking'

import { i18n } from 'utils/i18n'
import { useMatchMedia } from 'hooks'
import { classNames } from 'utils/dom'
import LazyLoad from 'components/utils/lazy-load'
import { Hidden, breakpoints } from 'components/grid'
import { ChevronLeftButton, ChevronRightButton } from 'components/Buttons'
import nextButtonSVG from 'assets/images/icons/controls/chevron-right-thin.svg'
import backButtonSVG from 'assets/images/icons/controls/chevron-left-thin.svg'

import {
  container,
  dotWrapper,
  dot,
  stepButton,
  stepButtonNext,
  stepButtonBack,
  stepButtonImg,
  fallback as fallbackStyle,
  fallbackItem,
  fallbackItemFluid,
  overlayButtonContainer,
  centerButtons,
  overlayStepButton,
} from './index.module.scss'

const isReactElement = (node: unknown): node is ReactElement =>
  !!(node as ReactElement).props

interface NewCarouselProps {
  keyPrefix: string
  withOverlayButtons?: boolean
  className?: string
  containerClassName?: string
  gap?: number
  defaultIndex?: number
  showDots?: boolean
  showButtons?: boolean
  hanger?: string
  anchor?: string
  isFluid?: boolean
  movingStep?: number
  onIndexChange?(index: number): unknown
}

const Carousel: FunctionComponent<NewCarouselProps> = ({
  children,
  className,
  keyPrefix,
  showDots,
  showButtons,
  hanger,
  anchor,
  withOverlayButtons = false,
  containerClassName = '',
  movingStep = 0,
  defaultIndex = 0,
  gap = 0,
  onIndexChange = noop,
  isFluid = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex)
  const [step, setStep] = useState(movingStep)
  const carouselRef = useRef<Flicking>(null)
  const childArray = Children.toArray(children)
  const maxIndex = childArray.length - 1

  const { matches: isSmall } = useMatchMedia(`(max-width: ${breakpoints.sm}px)`)
  const { matches: isLarge } = useMatchMedia(`(min-width: ${breakpoints.lg}px)`)

  useEffect(() => {
    if (movingStep === 0) {
      setStep(isSmall ? 1 : isLarge ? 4 : 3)
    }
  }, [isLarge, isSmall, movingStep])

  function onSelect({ index }: { index: number }) {
    if (carouselRef.current) {
      carouselRef.current.moveTo(index)
      setCurrentIndex(index)
    }
  }

  function onChange({ index }: { index: number }) {
    setCurrentIndex(index)
    onIndexChange(index)
  }

  function onPrevClick() {
    if (carouselRef.current) {
      const newIndex = currentIndex - step
      const prev = newIndex < 0 ? 0 : newIndex
      carouselRef.current.moveTo(prev)
      setCurrentIndex(prev)
    }
  }

  function onNextClick() {
    if (carouselRef.current) {
      const newIndex = currentIndex + step
      const next = newIndex > maxIndex ? maxIndex : newIndex
      carouselRef.current.moveTo(next)
      setCurrentIndex(next)
    }
  }

  function renderDots() {
    if (!showDots) return null
    return (
      <Hidden lg>
        <div className={dotWrapper}>
          {childArray.map((_item: ReactNode, idx: number) => (
            <span
              key={`${keyPrefix}-${idx}`}
              className={dot}
              aria-current={currentIndex === idx}
              data-index={idx}
            />
          ))}
        </div>
      </Hidden>
    )
  }

  function renderButtons() {
    if (!showButtons) return null
    if (withOverlayButtons) {
      return (
        <div className={overlayButtonContainer}>
          <div className={centerButtons}>
            <ChevronLeftButton
              onClick={onPrevClick}
              className={overlayStepButton}
              disabled={currentIndex <= 0}
              type="button"
              text={i18n._(t`Go to the previous item`)}
            />
            <ChevronRightButton
              onClick={onNextClick}
              className={overlayStepButton}
              disabled={currentIndex >= maxIndex}
              type="button"
              text={i18n._(t`Go to the next item`)}
            />
          </div>
        </div>
      )
    }
    return (
      <>
        <button
          onClick={onPrevClick}
          className={classNames([stepButton, stepButtonBack])}
          disabled={currentIndex <= 0}
        >
          <img
            className={stepButtonImg}
            src={backButtonSVG}
            alt={i18n._(t`Go to the previous item`)}
          />
        </button>
        <button
          onClick={onNextClick}
          className={classNames([stepButton, stepButtonNext])}
          disabled={currentIndex >= maxIndex}
        >
          <img
            className={stepButtonImg}
            src={nextButtonSVG}
            alt={i18n._(t`Go to the next item`)}
          />
        </button>
      </>
    )
  }

  function fallback() {
    return (
      <div className={container}>
        <div className={fallbackStyle}>
          {childArray.map((child) => {
            if (!isReactElement(child)) return child

            return React.cloneElement(child, {
              className: classNames([
                child.props.className,
                fallbackItem,
                isFluid && fallbackItemFluid,
              ]),
            })
          })}
        </div>
      </div>
    )
  }

  if (childArray.length < 2) {
    return fallback()
  }

  return (
    <LazyLoad
      resolve={() => import('@egjs/react-flicking')}
      loadWhenIdle
      fallback={fallback} // TODO: this runs while the module is loading as well. We need a loading state https://8fitapp.atlassian.net/browse/FRONTEND-984
    >
      {(Flicking) => (
        <div className={classNames([container, containerClassName])}>
          <Flicking
            ref={carouselRef}
            collectStatistics={false}
            className={className}
            gap={gap}
            defaultIndex={defaultIndex}
            onSelect={onSelect}
            onChange={onChange}
            zIndex={1}
            hanger={hanger}
            anchor={anchor}
            bounce={0}
            overflow
            bound
            autoResize
          >
            {children}
          </Flicking>
          {renderButtons()}
          {renderDots()}
        </div>
      )}
    </LazyLoad>
  )
}

export default Carousel
