import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  FormEvent,
} from 'react'
import Image from 'gatsby-image'
import { t, Trans } from '@lingui/macro'
import { Link } from 'gatsby'
import validator from 'email-validator'
import { DocumentOutlineSection, H } from 'react-document-section'
import * as Sentry from '@sentry/gatsby'

import { wait } from 'utils/async'
import { i18n } from 'utils/i18n'
import { subscribeNewsletter } from 'sdks/appboy'
import { classNames } from 'utils/dom'
import { SecondaryButton } from 'components/Buttons'

import {
  container,
  backgroundContainer,
  content,
  title as titleStyle,
  description as descriptionStyle,
  input,
  smallPrint,
  link,
  backgroundImage as backgroundImageStyle,
  cta,
  successTitle,
  successMessage,
} from './index.module.scss'

export interface EmailCaptureFormProps {
  title: string
  description: string
  ctaLabel: string
  placeholder?: string
  backgroundImage: EF.FluidImageNode
}

interface EmailFormProps {
  description: string
  ctaLabel: string
  placeholder?: string
  submitCompleted?: (bool: boolean) => void
  hideTitle?: boolean
  className?: string
}

export const EmailForm = ({
  description,
  ctaLabel,
  placeholder,
  submitCompleted,
  hideTitle,
  className = '',
}: EmailFormProps) => {
  const [isValid, setIsValid] = useState(false)
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setValue(value)
    setIsValid(validator.validate(value))
  }

  async function onSubmit(e: FormEvent) {
    e.preventDefault()
    if (!isValid) return

    try {
      await subscribeNewsletter(value)
      setIsLoading(true)
      await wait(1000)
      submitCompleted && submitCompleted(true)
    } catch (e) {
      Sentry.captureException(e)
    } // silently fail
  }

  return (
    <form
      data-testid="emailCaptureForm"
      className={classNames([content, className])}
      onSubmit={onSubmit}
    >
      {!hideTitle && <H className={titleStyle} />}
      <p className={descriptionStyle}>{description}</p>
      <input
        data-testid="emailInput"
        className={input}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <p className={smallPrint}>
        <Trans>
          By providing your email address, you agree to our{' '}
          <Link className={link} to={i18n._(t`/terms-of-service/`)}>
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link className={link} to={i18n._(t`/privacy/`)}>
            Privacy Policy
          </Link>
          .
        </Trans>
      </p>
      <SecondaryButton
        data-testid="submitButton"
        className={cta}
        disabled={isLoading}
        onClick={onSubmit}
        data-isloading={isLoading}
        text={isLoading ? ' ' : ctaLabel}
      />
    </form>
  )
}

export const SuccessMessage = ({
  hideTitle,
  className = '',
}: {
  hideTitle?: boolean
  className?: string
}) => {
  return (
    <div
      data-testid="successMessage"
      className={classNames([content, className])}
    >
      {!hideTitle && (
        <H data-testid="successMessageTitle" className={successTitle} />
      )}
      <p className={successMessage}>
        <Trans>
          You have subscribed to our email newsletter to receive weekly articles
          and great inspiration.
        </Trans>
      </p>
    </div>
  )
}

const EmailCaptureForm: FunctionComponent<EmailCaptureFormProps> = ({
  title,
  description,
  ctaLabel,
  placeholder,
  backgroundImage,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  return (
    <div className={container} data-issubmitted={isSubmitted}>
      <DocumentOutlineSection
        title={isSubmitted ? i18n._(t`Thank you!`) : title}
      >
        <div className={backgroundContainer}>
          <div data-issubmitted={isSubmitted} className={backgroundImageStyle}>
            {backgroundImage && (
              <Image fluid={backgroundImage.fields.asset.fluid} alt="" />
            )}
          </div>
        </div>
        {isSubmitted ? (
          <SuccessMessage />
        ) : (
          <EmailForm
            description={description}
            ctaLabel={ctaLabel}
            placeholder={placeholder}
            submitCompleted={setIsSubmitted}
          />
        )}
      </DocumentOutlineSection>
    </div>
  )
}

export default EmailCaptureForm
