import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@gatsbyjs/reach-router'

import { parse } from 'utils/url'
import { generateShareableUrl } from 'utils/shareable-url'

const query = graphql`
  query ShareUrlQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const useShareUrl = (shareMedium: 'fb' | 'tw' | 'pin') => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery<{ site: { siteMetadata: { siteUrl: string } } }>(query)
  const { search, pathname } = useLocation()
  const params = parse(search)
  const shareUrl = generateShareableUrl(
    siteUrl,
    pathname,
    shareMedium,
    params.utm_campaign,
    params.utm_source
  )
  return shareUrl
}

export default useShareUrl
