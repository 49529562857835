import React from 'react'
import { navigate } from 'gatsby'
import { t } from '@lingui/macro'

import { i18n } from 'utils/i18n'
import { useUserAccount } from 'gatsby-plugin-8fit-user-account'
import SeamlessWebsiteLayout from 'components/layout/seamless-website'
import PlanTable from 'components/PlanTable'
import FaqSection from 'components/FaqSection'
import { DEFAULT_CURRENCY } from 'templates/signup/constants'
import { getFaqs } from 'templates/signup/screens/plans-overview/data'
import { Container, Row, Col } from 'components/grid'
import { useWSFBackendPlans } from 'hooks/use-backend-plans'

import AuthenticatedPage from '../AuthenticatedPage'
import { getPlanTableContent } from '../data'

import { planSectionContainer } from './index.module.scss'
import PaywallHeader from './PaywallHeader'

interface PaywallPageProps {
  pageContext: {
    hrefLang: EF.HrefLangNode[]
    defaultLang: EF.DefaultLangNode
  }
}

const PaywallPage = ({
  pageContext: { hrefLang, defaultLang },
}: PaywallPageProps) => {
  const {
    accountState: { currency },
  } = useUserAccount()
  const plans = useWSFBackendPlans({ trial_period_days: 0 })
  const pageTitle = i18n._(t('user.profile.unlock.pro.cta')`Unlock Pro!`)

  const onSelectPlan = (plan: EF.Plan | null) => {
    navigate(`${i18n._(t`/profile/subscribe/`)}?plan=${plan?.identifier}`)
  }

  return (
    <AuthenticatedPage>
      <SeamlessWebsiteLayout
        hrefLang={hrefLang}
        defaultLang={defaultLang}
        title={pageTitle}
        pageTitle={`${pageTitle} | 8fit`}
      >
        <PaywallHeader />
        <Container>
          <Row>
            <Col lg={10} lgOffset={1} md={12} mdOffset={0}>
              <div className={planSectionContainer} data-testid="plan-table">
                <PlanTable
                  plans={plans}
                  currency={currency || DEFAULT_CURRENCY}
                  onSelectPlan={onSelectPlan}
                  tableContent={getPlanTableContent()}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} lgOffset={2} md={12} mdOffset={0}>
              <FaqSection FAQs={getFaqs()} />
            </Col>
          </Row>
        </Container>
      </SeamlessWebsiteLayout>
    </AuthenticatedPage>
  )
}

export default PaywallPage
