import React from 'react'
import { Link } from 'gatsby'
import { t, Trans } from '@lingui/macro'

import { Container, Row, Col } from 'components/grid'
import { i18n } from 'utils/i18n'

import { footerText, footerLink } from './index.module.scss'

const LegalLPFooter = () => {
  return (
    <Container>
      <Row>
        <Col>
          <div className={footerText}>
            <span className={footerLink}>
              <Link to={i18n._(t`/privacy/`)}>
                <Trans>Privacy Policy</Trans>
              </Link>
            </span>
            <span className={footerLink}>
              <Link to={i18n._(t`/terms-of-service/`)}>
                <Trans>Terms of service</Trans>
              </Link>
            </span>
            <span className={footerLink}>
              <Link to={i18n._(t`/impressum/`)}>
                <Trans>Impressum</Trans>
              </Link>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default LegalLPFooter
