import { Catalog } from '@lingui/core'

import { SupportedLocaleName } from '../../../config/i18n'

const catalogsMap: {
  [key in SupportedLocaleName]: () => Promise<{ default: Catalog }>
} = {
  'en-US': () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-en-US" */
      '../../locales/en-US/messages'
    ),
  de: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-de" */
      '../../locales/de/messages'
    ),
  es: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-es" */
      '../../locales/es/messages'
    ),
  fr: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-fr" */
      '../../locales/fr/messages'
    ),
  it: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-it" */
      '../../locales/it/messages'
    ),
  'pt-BR': () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-pt-BR" */
      '../../locales/pt-BR/messages'
    ),
}

let catalogs: { [key: string]: Catalog } = {}

const isLoading: { [key: string]: boolean } = {}

export const loadCatalog = async (locale: SupportedLocaleName) => {
  if (catalogsMap[locale] && !catalogs[locale] && !isLoading[locale]) {
    isLoading[locale] = true
    const languageCatalog = await catalogsMap[locale]()
    // We're setting a new object here instead of adding to the existing one
    // because Lingui internally uses a shallow comparison to determine if the
    // catalogs are new. That's why the object reference needs to change.
    // https://github.com/lingui/js-lingui/blob/v2.7.4/packages/react/src/I18nProvider.js#L106
    catalogs = { ...catalogs, [locale]: languageCatalog.default }
    delete isLoading[locale]
    return catalogs
  }
  return undefined
}

export const getCatalogs = () => catalogs
