import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { getCurrentI18nInfo, defaultLocale, supportedLocales } from 'utils/i18n'

interface StoreBadge {
  platform: string
  alt: string
  locale?: string
  className?: string
}

const query = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "icons/store-badge" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`

const StoreBadge = ({ platform, alt, locale, ...props }: StoreBadge) => {
  const { allFile } = useStaticQuery(query)
  if (allFile) {
    let localeSuffix = locale || getCurrentI18nInfo().locale
    localeSuffix =
      localeSuffix === defaultLocale ||
      !supportedLocales.find(({ locale }) => locale === localeSuffix)
        ? ''
        : `-${localeSuffix}`
    const fileName = `${platform.toLowerCase()}${localeSuffix}`
    const file = allFile.edges.find(
      ({ node: { name } }: { node: { name: string } }) => name === fileName
    )

    if (file) {
      return <img src={file.node.publicURL} alt={alt} {...props} />
    }
  }
  return null
}

export default StoreBadge
